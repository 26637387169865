import * as SessionStore from "./store/Session";
import * as AuthenticationStore from "./store/Authentication";
import * as WarningMessageStore from "./store/WarningMessage";
import * as NotificationStore from "./store/NotificationMessages";
import SessionStorageApi from "./apis/storage/SessionStorageApi";
import AuthenticationService from "./services/AuthenticationService";
import AuthenticationStorageApi from "./apis/storage/AuthenticationStorageApi";
import { AdminRoutes, adminRoutes, authenticatedRoutes, basicAuthenticatedRoutes, IRoute, Routes } from "./models/Routing";
import { IContextAdminPreference, IPreference, IPreferenceSerialized } from "./models/Preferences";
import AnalyticsService from "./services/AnalyticsService";
import AdminContextService from "./services/AdminContextService";
import ConsoleService from "./services/ConsoleService";
import PreferenceService from "./services/PreferenceService";
import { ServiceResponseJson } from "./services/ServiceBase";
import { IContextInfo } from "./models/AdminContext";
import sessionStorageApi from "./apis/storage/SessionStorageApi";
import { IGridParams } from "./components/common/grid/AdvancedGrid";
import { ApplicationFeature } from "./models/ApplicationModels";

export interface IIdleTimer {
  reset: () => void;
  pause: () => void;
  resume: () => void;
  getRemainingTime: () => number;
  getElapsedTime: () => number;
  getLastActiveTime: () => number;
  isIdle: () => boolean;
}

export interface IFeature {
  routeId: Routes | AdminRoutes;
  authorized: boolean;
}
export interface ISubFeature extends IFeature {
  subFeatures: Record<string, IFeature | ISubFeature>;
}

export interface ISessionManager {
  clearSession: () => void;
  extendSessionTimeout: number;
  extendSessionTimer: IIdleTimer;
  handleExtendSession: () => void;
  handleSessionActionTracked: () => void;
  handleSessionIdle: () => void;
  handleSessionWarningWindowAndTabSync: () => void;
  handleSessionLostWindowAndTabSync: () => void;
  handleSessionSync: () => void;
  handleSessionWarningClosed: () => void;
  handleSessionLostWarningClosed: () => void;
  retrieveAndStoreSessionData: (userId: string) => Promise<SessionStore.ISessionData>;
  impersonateUser: (idIdp: string, history: any) => void;
  endImpersonation: () => void;
  store: any;
  timeout: number;
  timeoutTimer: IIdleTimer;
  userActionThrottle: number;
  warningTimeout: number;
  warningTimer: IIdleTimer;
  sessionWarningWindowAndTabSyncTimer: IIdleTimer;
  sessionLostWindowAndTabSyncTimer: IIdleTimer;
  sessionSyncTimer: IIdleTimer;
  windowAndTabSyncTimeout: number;
  sessionSyncTimeout: number;
}

let sessionManagerInstance: SessionManager = null;

export class SessionManager implements ISessionManager {
  readonly store: any;
  readonly extendSessionTimeout: number;
  readonly windowAndTabSyncTimeout: number;
  readonly sessionSyncTimeout: number;
  readonly userActionThrottle: number;
  extendSessionTimer: IIdleTimer;
  warningTimer: IIdleTimer;
  sessionWarningWindowAndTabSyncTimer: IIdleTimer;
  sessionLostWindowAndTabSyncTimer: IIdleTimer;
  sessionSyncTimer: IIdleTimer;
  timeoutTimer: IIdleTimer;
  private windowsAndTabsActionsCount: number;
  private syncCount: string;
  private contextSyncCount: string;

  get warningTimeout() {
    return (
      this.store.getState().sessionState.sessionData.validSeconds * 1000 -
      this.store.getState().sessionState.sessionData.warningSeconds * 1000
    );
  }

  get timeout() {
    return this.store.getState().sessionState.sessionData.warningSeconds * 1000;
  }

  constructor(store?: any) {
    if (sessionManagerInstance) {
      return sessionManagerInstance;
    }

    if (!store) {
      throw 'you must initialze the session manager with store before it can be used without store';
    }

    sessionManagerInstance = this;
    this.store = store;
    this.extendSessionTimeout = 15000;
    this.windowAndTabSyncTimeout = 500;
    this.userActionThrottle = 500;
    this.sessionSyncTimeout = 800;
    this.windowsAndTabsActionsCount = null;
    this.extendSessionTimer = null;
    this.warningTimer = null;
    this.sessionWarningWindowAndTabSyncTimer = null;
    this.sessionLostWindowAndTabSyncTimer = null;
    this.timeoutTimer = null;
    this.syncCount = SessionStorageApi.getSyncCount();
    this.contextSyncCount = SessionStorageApi.getContextSyncCount();

    SessionStorageApi.initializeSessionTrackingStorage();
  }

  clearSession = async () => {
    await AuthenticationService.deleteUserSession();

    this.store.dispatch(WarningMessageStore.actionCreators.updateUnsavedChanges(false) as any);
    this.store.dispatch(AuthenticationStore.actionCreators.setAuthentication(false));
    this.store.dispatch(SessionStore.actionCreators.setSessionData(null));
    this.store.dispatch(NotificationStore.actionCreators.setNotificationData([], 0, false))
    SessionStorageApi.setImpersonatedUser(null);
    sessionStorageApi.setClosedNotifications([]);
    sessionStorageApi.setSessionLostWarning(false);
    sessionStorageApi.setSessionTimeoutWarning(false);
  };

  handleExtendSession() {
    if (this.store.getState().authenticationState.authenticated && !this.store.getState().sessionState.isLost) {
      AuthenticationService.extendUserSession();
      this.extendSessionTimer.reset();
    }
  }

  handleSessionActionTracked() {
    if (!this.store.getState().sessionState.isIdle) {
      SessionStorageApi.incrementOrResetActionsCount();
    }
  }
  handleSessionIdle() {
    if (!this.store.getState().sessionState.isLost) {
      SessionStorageApi.setSessionTimeoutWarning(true);
      this.store.dispatch(SessionStore.actionCreators.setSessionIsIdle(true));
    }
  }
  handleSessionWarningWindowAndTabSync() {
    try {
      if (this.store.getState().sessionState.isIdle && !SessionStorageApi.getSessionTimeoutWarning()) {
        this.store.dispatch(SessionStore.actionCreators.setSessionIsIdle(false));
        this.warningTimer.reset();
      }

      if (this.windowsAndTabsActionsCount !== SessionStorageApi.getActionsCount()) {
        this.windowsAndTabsActionsCount = SessionStorageApi.getActionsCount();
        this.warningTimer.reset();
      }
    } catch (error) {
      console.log(error);
    }

    this.sessionWarningWindowAndTabSyncTimer.reset();
  }
  handleSessionLostWindowAndTabSync() {
    try {
      if (this.store.getState().sessionState.isLost && !SessionStorageApi.getSessionLostWarning()) {
        this.store.dispatch(SessionStore.actionCreators.setSessionIsLost(false));
        this.warningTimer.reset();
      }
    } catch (error) {
      console.log(error);
    }

    this.sessionLostWindowAndTabSyncTimer.reset();
  }
  handleSessionSync() {
    try {
      const sessionState = this.store.getState().sessionState as SessionStore.ISessionState;
      const reduxPrefs = sessionState.sessionData.userPrefs;

      const syncCount = SessionStorageApi.getSyncCount();
      const memorySyncCount = this.syncCount;

      if (memorySyncCount < syncCount && SessionStorageApi.hasPreferences() && reduxPrefs) {
        this.syncCount = SessionStorageApi.getSyncCount();

        this.store.dispatch(SessionStore.actionCreators.setPreferences(SessionStorageApi.getPreferences()));
      }

      if (this.contextSyncCount < SessionStorageApi.getContextSyncCount()) {
        this.contextSyncCount = SessionStorageApi.getContextSyncCount();

        this.store.dispatch(SessionStore.actionCreators.setContext(SessionStorageApi.getContext()) as any);
      }

      const currUser = sessionState.sessionData.username;
      const impersonatedUser = SessionStorageApi.getImpersonatedUser();

      //if view as has been exited by another tab we should reload ourselves to get back to a good state
      if (sessionState.sessionData.impersonateMode && !impersonatedUser) {
        window.location.reload();
      }

      if (!sessionState.changingSession && !sessionState.sessionDeSync && impersonatedUser != null && impersonatedUser !== currUser) {
        this.store.dispatch(SessionStore.actionCreators.setSessionDeSync(true));
      }
      else if (!sessionState.changingSession && sessionState.sessionDeSync && (impersonatedUser == null || impersonatedUser === currUser)) {
        this.store.dispatch(SessionStore.actionCreators.setSessionDeSync(false));
      }
    } catch (error) {
      console.log(error);
    }

    this.sessionSyncTimer.reset();
  }
  handleSessionWarningClosed() {
    SessionStorageApi.setSessionTimeoutWarning(false);
    this.handleExtendSession();
  }
  handleSessionLostWarningClosed() {
    SessionStorageApi.setSessionLostWarning(false);
    AuthenticationStorageApi.clearAll();
    window.location.href = "/";
  }

  impersonateUser = async (idIdp: string, history: any) => {
    this.store.dispatch(SessionStore.actionCreators.setChangingSession(true));
    const sessionDataResponse = await this.retrieveAndStoreSessionData(idIdp, "Impersonate");
    SessionStorageApi.setImpersonatedUser(sessionDataResponse.username);
    this.store.dispatch(NotificationStore.actionCreators.setNotificationData([], 0, false))
    history.push(sessionDataResponse.destination.path);
    this.store.dispatch(SessionStore.actionCreators.setChangingSession(false));
  }

  endImpersonation = async () => {
    this.store.dispatch(SessionStore.actionCreators.setChangingSession(true));
    const sessionDataResponse = await this.retrieveAndStoreSessionData(null /*TODO security change should make it so that I don't need to preserve idIdp*/, "EndImpersonation");
    SessionStorageApi.setImpersonatedUser(null);
    this.store.dispatch(NotificationStore.actionCreators.setNotificationData([], 0, false))
    this.store.dispatch(SessionStore.actionCreators.setChangingSession(false));
  }

  setContext = async (context: IContextInfo) => {

    this.store.dispatch(SessionStore.actionCreators.setContext(context) as any);

    SessionStorageApi.setContext(context);

    SessionStorageApi.incrementContextSyncCount();
    this.contextSyncCount = SessionStorageApi.getContextSyncCount();
  }

  setPreference = async (preference: IPreference) => {

    const seralized: IPreferenceSerialized = { prefName: preference.prefName, value: (preference.value ? JSON.stringify(preference.value) : null) };

    this.store.dispatch(SessionStore.actionCreators.setPreference(preference) as any);

    const prefInStorage = SessionStorageApi.getPreferences();
    prefInStorage[preference.prefName] = preference.value;

    SessionStorageApi.setPreferences(prefInStorage);

    SessionStorageApi.incrementSyncCount();
    this.syncCount = SessionStorageApi.getSyncCount();

    await PreferenceService.setUserPreference(seralized);
  };


  async retrieveAndStoreSessionData(userId: string, impersonateMode: "None" | "Impersonate" | "EndImpersonation" = "None"): Promise<SessionStore.ISessionData> {
    let sessionDataResponse: ServiceResponseJson = null;

    if (impersonateMode === "Impersonate") {
      sessionDataResponse = await AuthenticationService.impersonateUserSession(userId);
    } else if (impersonateMode === "EndImpersonation") {
      sessionDataResponse = await AuthenticationService.endImpersonatedUserSession();
    }
    else {
      sessionDataResponse = await AuthenticationService.getSessionData();
    }

    if (!sessionDataResponse || !sessionDataResponse.ok) {
      return null;
    }

    sessionStorageApi.setSessionLostWarning(false);
    sessionStorageApi.setSessionTimeoutWarning(false);

    //get applications so that we can determine how many the user has assigned.
    const myApplicationsResponse = await ConsoleService.getMyApplications({ take: 5000 });

    let totalMyApplications = 0;
    let hasWorkspaceFeature = false;
    let canHideGeneralTechSupport = false;

    if (myApplicationsResponse && myApplicationsResponse.ok && myApplicationsResponse.data.results.length) {
      const results = myApplicationsResponse.data.results;
      totalMyApplications = results.length;
      hasWorkspaceFeature = results.find((a: ApplicationFeature) => a.hasWorkspaceView) != null;

      canHideGeneralTechSupport = totalMyApplications > 0 && results.every((a: ApplicationFeature) => a.hideGeneralTechSupport == 1);
    }

    const featurePermissions = this.getFeaturePermissionsFromPermissions(
      new Set(sessionDataResponse.data.permissions),
      sessionDataResponse.data.superAdmin,
      totalMyApplications, sessionDataResponse.data.isFederated
    );

    for (const key in sessionDataResponse.data.userPrefs) {
      if (sessionDataResponse.data.userPrefs[key]) {
        sessionDataResponse.data.userPrefs[key] = JSON.parse(sessionDataResponse.data.userPrefs[key]);
      }
    }

    const existingSessionAlreadyImpersonating = !!sessionDataResponse.data.preImpersonatedUser;
    const isImpersonating = impersonateMode === "Impersonate" || existingSessionAlreadyImpersonating;

    if (existingSessionAlreadyImpersonating) {
      SessionStorageApi.setImpersonatedUser(sessionDataResponse.data.username);
    }

    let destination = this.getDestinationFromPermissions(featurePermissions, totalMyApplications, authenticatedRoutes[Routes.Administration]);

    const permissions = new Set<string>(sessionDataResponse.data.permissions);
    const sessionData: SessionStore.ISessionData = {
      ...sessionDataResponse.data,
      permissions: permissions,
      internalUsersDomains: new Set(sessionDataResponse.data.internalUsersDomains),
      destination: destination,
      totalMyApplications,
      featurePermissions,
      impersonateMode: isImpersonating,
      hasWorkspaceFeature,
      hasAgreements: sessionDataResponse.data.hasAgreements,
      isBasic: sessionDataResponse.data.isBasic,
      hideGeneralTechSupport: canHideGeneralTechSupport
    };

    // Override destination if necessary
    sessionData.destination = this.overrideDestinationFromSessionData(sessionData);

    this.store.dispatch(SessionStore.actionCreators.setSessionData(sessionData));
    SessionStorageApi.setPreferences(sessionData.userPrefs);

    const getContextInfoTask = this.getContextInfo(permissions);

    AnalyticsService.GetReportPages().then(result => {
      // use .then() so as not to slow down session startup for users
      if (result.ok) {
        this.store.dispatch(SessionStore.actionCreators.setReportPages(result.data));
      }
    });

    this.store.dispatch(SessionStore.actionCreators.setContext(await getContextInfoTask));

    return sessionData;
  }

  private async getContextInfo(permissions: Set<string>): Promise<IContextInfo> {
    const prefs = SessionStorageApi.getPreferences();
    let contextPrefs = prefs ? prefs.ContextAdmin as IContextAdminPreference : null;

    const search = contextPrefs && contextPrefs.resourceGroupId ?
      { take: 1, filters: [{ logic: "and", filters: [{ field: "contextResourceGroupId", operator: "eq", value: contextPrefs.resourceGroupId }] }] } as IGridParams :
      { take: 2 } as IGridParams;

    let adminContext = await AdminContextService.getMyAdminContext(search);

    if (!adminContext.ok) {
      return { hasErrors: true };
    }

    if (adminContext.data.results.length === 0 && contextPrefs && contextPrefs.resourceGroupId != null) {
      // User got access to their preferred context taken away, just search for anything
      // clear preferences
      contextPrefs = null;
      this.setPreference({
        prefName: "ContextAdmin",
        value: null
      });
      adminContext = await AdminContextService.getMyAdminContext({ take: 2 }); // check if there's any contexts without prefs
    }

    if (adminContext.data.results.length === 0) {
      return { hasNone: true };
    }

    if (contextPrefs && contextPrefs.resourceGroupId == null) {
      // user has a preference of having no context
      return {};
    }

    const preSelectedContext = adminContext.data.results[0];

    let filters = [{ field: "ResourceGroupId", operator: "eq", value: preSelectedContext.contextResourceGroupId }];
    if (contextPrefs && contextPrefs.uniqueClientId) {
      filters.push({ field: "RelClientId", operator: "eq", value: contextPrefs.uniqueClientId });
    }

    let clientResults = await AdminContextService.getContextRelClients({ filters: [{ logic: "and", filters }], sort: [{ field: "RelClientName", dir: "asc" }] });

    if (clientResults.ok && adminContext.data.results.length === 0 && contextPrefs && contextPrefs.uniqueClientId) {
      // User got access to their preferred client taken away, just search for anything
      filters = [{ field: "ResourceGroupId", operator: "eq", value: preSelectedContext.contextResourceGroupId }];
      clientResults = await AdminContextService.getContextRelClients({ filters: [{ logic: "and", filters }], sort: [{ field: "RelClientName", dir: "asc" }] });
    }

    if (clientResults.ok && adminContext.data.results.length === 1 && clientResults.data.results.length < 2 && !contextPrefs && !permissions.has("EpiqAdminListUsers")) {
      return {
        onlyOne: true,
        admin: adminContext.data.results[0],
        relativityClient: clientResults.data.results[0]
      };
    }

    return {
      admin: adminContext.data.results[0],
      hasNoRelClients: clientResults.ok && clientResults.data && clientResults.data.results && clientResults.data.results.length === 0,
      relativityClient: clientResults.ok && clientResults.data.results.length === 1 ? clientResults.data.results[0] : null
    };
  }

  private getFeaturePermissionsFromPermissions(
    permissions: Set<string>,
    superAdmin: boolean,
    totalApplications: number, isFederated: boolean
  ): Record<string, IFeature | ISubFeature> {
    return {
      [Routes.Administration]: {
        routeId: Routes.Administration,
        authorized:
          permissions.has("EpiqAdminListUsers") ||
          permissions.has("EpiqAdminListUserGroups") ||
          permissions.has("EpiqAdminListProjects") ||
          permissions.has("EpiqAdminListRoles") ||
          permissions.has("EpiqAdminListActivityView") ||
          permissions.has("EpiqAdminListReport") ||
          permissions.has("EpiqAdminListResourceGroups") ||
          permissions.has("EpiqAdminListAssignments") ||
          permissions.has("EpiqAdminListApplication") ||
          permissions.has("EpiqAdminListEmployer") ||
          permissions.has("EpiqAdminListEmployerDomains") ||
          permissions.has("EpiqAdminListSSOGroups") ||
          permissions.has("EpiqAdminListResource") ||
          permissions.has("EpiqAdminListSecureUploadLocations") ||
          permissions.has("EpiqAdminListSiteBranding") ||
          permissions.has("EpiqAdminListAgreements") ||
          permissions.has("ListUsers") ||
          permissions.has("CreateUser"),
        subFeatures: {
          [AdminRoutes.UserList]: {
            authorized: superAdmin || permissions.has("EpiqAdminListUsers"),
            routeId: AdminRoutes.UserList
          },
          [AdminRoutes.ContextUserList]: {
            authorized: permissions.has("ListUsers") || permissions.has("CreateUser"),
            routeId: AdminRoutes.ContextUserList
          },
          [AdminRoutes.AdminContext]: {
            authorized: permissions.has("EpiqAdminCreateClientContext"),
            routeId: AdminRoutes.AdminContext
          },
          [AdminRoutes.UserBatchSendInviteList]: {
            authorized: superAdmin || permissions.has("EpiqAdminListUsers") || permissions.has("EpiqAdminSendInvite"),
            routeId: AdminRoutes.UserBatchSendInviteList
          },
          [AdminRoutes.UserGroups]: {
            routeId: AdminRoutes.UserGroups,
            authorized: superAdmin || permissions.has("EpiqAdminListUserGroups")
          },
          [AdminRoutes.UserDetails]: {
            authorized: superAdmin || permissions.has("EpiqAdminGetUser") || permissions.has("EpiqAdminCreateUser") || permissions.has("GetUser") || permissions.has("CreateUser"),
            routeId: AdminRoutes.UserDetails
          },
          [AdminRoutes.ProjectList]: {
            authorized: superAdmin || permissions.has("EpiqAdminListProjects"),
            routeId: AdminRoutes.ProjectList
          },
          [AdminRoutes.RolesList]: {
            routeId: AdminRoutes.RolesList,
            authorized: superAdmin || permissions.has("EpiqAdminListRoles")
          },
          [AdminRoutes.UserActivityList]: {
            authorized: superAdmin || permissions.has("EpiqAdminListActivityView"),
            routeId: AdminRoutes.UserActivityList
          },
          [AdminRoutes.ResourceGroupsList]: {
            authorized: superAdmin || permissions.has("EpiqAdminListResourceGroups"),
            routeId: AdminRoutes.ResourceGroupsList
          },
          [AdminRoutes.CreateUserGroup]: {
            authorized: superAdmin || permissions.has("EpiqAdminCreateUserGroup"),
            routeId: AdminRoutes.CreateUserGroup
          },
          [AdminRoutes.UserGroupDetails]: {
            authorized: superAdmin || permissions.has("EpiqAdminGetUserGroup"),
            routeId: AdminRoutes.UserGroupDetails
          },
          [AdminRoutes.ProjectDetails]: {
            authorized: superAdmin || permissions.has("EpiqAdminGetProject"),
            routeId: AdminRoutes.ProjectDetails
          },
          [AdminRoutes.RoleDetails]: {
            authorized: superAdmin || permissions.has("EpiqAdminGetRole"),
            routeId: AdminRoutes.RoleDetails
          },
          [AdminRoutes.Report]: {
            authorized: superAdmin || permissions.has("EpiqAdminListReport"),
            routeId: AdminRoutes.Report
          },
          [AdminRoutes.Assignments]: {
            authorized: superAdmin || permissions.has("EpiqAdminListAssignments"),
            routeId: AdminRoutes.Assignments
          },
          [AdminRoutes.ResourceGroupDetails]: {
            authorized: superAdmin || permissions.has("EpiqAdminGetResourceGroup"),
            routeId: AdminRoutes.ResourceGroupDetails
          },
          [AdminRoutes.AdminApplications]: {
            authorized: superAdmin || permissions.has("EpiqAdminListApplication"),
            routeId: AdminRoutes.AdminApplications
          },
          [AdminRoutes.AdminApplication]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminGetApplication") || permissions.has("EpiqAdminCreateApplication"),
            routeId: AdminRoutes.AdminApplication
          },
          [AdminRoutes.Employers]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminListEmployer"),
            routeId: AdminRoutes.Employers
          },
          [AdminRoutes.EmployerDetails]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminGetEmployer") || permissions.has("EpiqAdminCreateEmployer"),
            routeId: AdminRoutes.EmployerDetails
          },
          [AdminRoutes.Domains]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminListEmployerDomains"),
            routeId: AdminRoutes.Domains
          },
          [AdminRoutes.SsoGroupDetails]: {
            routeId: AdminRoutes.SsoGroupDetails,
            authorized: permissions.has("EpiqAdminGetSSOGroup")
          },
          [AdminRoutes.SsoGroups]: {
            routeId: AdminRoutes.SsoGroups,
            authorized: permissions.has("EpiqAdminListSSOGroups")
          },
          [AdminRoutes.ResourceList]: {
            routeId: AdminRoutes.ResourceList,
            authorized: superAdmin || permissions.has("EpiqAdminListResource")
          },
          [AdminRoutes.SecureUploadLocationsList]: {
            routeId: AdminRoutes.SecureUploadLocationsList,
            authorized: superAdmin || permissions.has("EpiqAdminListSecureUploadLocations")
          },
          [AdminRoutes.SecureUploadDetails]: {
            routeId: AdminRoutes.SecureUploadDetails,
            authorized: superAdmin || permissions.has("EpiqAdminGetSecureUploadLocations")
          },
          [AdminRoutes.SiteBranding]: {
            routeId: AdminRoutes.SiteBranding,
            authorized: superAdmin || permissions.has("EpiqAdminListSiteBranding")
          },
          [AdminRoutes.SiteBrandingDetails]: {
            routeId: AdminRoutes.SiteBrandingDetails,
            authorized: superAdmin || permissions.has("EpiqAdminGetSiteBranding") || permissions.has("EpiqAdminCreateSiteBranding")
          },
          [AdminRoutes.Messages]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminListAgreements"),
            routeId: AdminRoutes.Messages
          },
          [AdminRoutes.MessageDetails]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminGetAgreement") || permissions.has("EpiqAdminCreateAgreement"),
            routeId: AdminRoutes.MessageDetails
          },
          [AdminRoutes.NotificationDetails]: {
            authorized:
              superAdmin || permissions.has("EpiqAdminGetAgreement") || permissions.has("EpiqAdminCreateAgreement"),
            routeId: AdminRoutes.NotificationDetails
          },
        }
      },
      [Routes.Applications]: {
        routeId: Routes.Applications,
        authorized: totalApplications > 0
      },
      [Routes.Dashboard]: {
        routeId: Routes.Dashboard,
        authorized: permissions.has("ViewDashboard")
      },
      [Routes.DashboardReport]: {
        routeId: Routes.DashboardReport,
        authorized: permissions.has("ViewDashboard")
      },
      [Routes.DashboardReportPage]: {
        routeId: Routes.DashboardReportPage,
        authorized: permissions.has("ViewDashboard")
      },
      [Routes.Files]: {
        routeId: Routes.Files,
        authorized: permissions.has("ShowFilesFeature")
      },
      [Routes.SupportList]: {
        routeId: Routes.SupportList,
        authorized: true
      },
      [Routes.SupportWorkRequestList]: {
        routeId: Routes.SupportWorkRequestList,
        authorized: permissions.has("ShowWorkRequestFeature")
      },
      [Routes.SupportWorkRequestCreate]: {
        routeId: Routes.SupportWorkRequestCreate,
        authorized: permissions.has("ShowWorkRequestFeature")
      },
      [Routes.SupportRequestCreate]: {
        routeId: Routes.SupportRequestCreate,
        authorized: permissions.has("ShowSupportFeature")
      },
      //JWK Disabling this for now
      // [Routes.SupportSpeedTest]: {
      //   routeId: Routes.SupportSpeedTest,
      //   authorized: true
      // },
      [Routes.Approvals]: {
        routeId: Routes.Approvals,
        authorized: permissions.has("ShowApprovalFeature")
      },
      [Routes.ApprovalDetails]: {
        routeId: Routes.ApprovalDetails,
        authorized: permissions.has("ShowApprovalFeature")
      },
      [Routes.ChangePassword]: {
        routeId: Routes.ChangePassword,
        authorized: !isFederated
      }
    };
  }

  //ORDER MATTERS - These are in the preferred order of where to send the user.
  private getDestinationFromPermissions(
    featurePermissions: Record<string, IFeature | ISubFeature>,
    totalApplications: number,
    adminRoute: IRoute
  ): IRoute {
    if (featurePermissions[Routes.Administration].authorized) {
      const subFeatures = (featurePermissions[Routes.Administration] as ISubFeature).subFeatures;
      const firstAuthorizedMatch = Object.keys(subFeatures).find(key => subFeatures[key].authorized);

      return { ...adminRoute, path: adminRoutes[firstAuthorizedMatch].path };
    }

    if (featurePermissions[Routes.Applications].authorized && totalApplications > 0) {
      return authenticatedRoutes[Routes.Applications];
    }

    if (featurePermissions[Routes.Dashboard].authorized) {
      return authenticatedRoutes[Routes.Dashboard];
    }

    if (featurePermissions[Routes.Files].authorized) {
      return authenticatedRoutes[Routes.Files];
    }

    if (featurePermissions[Routes.SupportList].authorized) {
      return authenticatedRoutes[Routes.SupportList];
    }

    if (featurePermissions[Routes.SupportWorkRequestList].authorized) {
      return authenticatedRoutes[Routes.SupportWorkRequestList];
    }

    return authenticatedRoutes[Routes.Home];
  }

  // The session data might have properties that require the user
  // to be routed somewhere else first.
  private overrideDestinationFromSessionData(sessionData: SessionStore.ISessionData) {
    if (sessionData.hasAgreements) {
      return basicAuthenticatedRoutes[Routes.Agreement];
    }

    return sessionData.destination;
  }
}
