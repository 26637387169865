import { IAuditProps, MessageModel, NotificationModel } from "../models/MessageModel";
import { ServiceBase, ResponseMessages, ServiceResponse, ServiceResponseJson, ServiceResponseStr } from "./ServiceBase";
import { IGridParams as AdvancedGridParams } from "../components/common/grid/AdvancedGrid";

const GetMessagesResponseMsg: ResponseMessages = {
  FailedMessage: "Failed to get messages.",
  SuccessMessage: "Successfully retrieved messages."
};

const CreateMessageResponseMsg: ResponseMessages = {
  FailedMessage: "Create message failed.",
  SuccessMessage: "Create message succeeded."
};

const UpdateMessageResponseMsg: ResponseMessages = {
  FailedMessage: "Update message failed.",
  SuccessMessage: "Update message succeeded."
};

const DeleteMessageResponseMsg: ResponseMessages = {
  FailedMessage: "Delete message failed.",
  SuccessMessage: "Delete message succeeded."
};

const GetMessageInfo: ResponseMessages = {
  FailedMessage: "failed to get message info.",
  SuccessMessage: "getting message info succeeded."
}
const AuditAcceptOrDeclineAgreements: ResponseMessages = {
  FailedMessage: "Failed to accept/decline agreements.",
  SuccessMessage: "Successfully accept/decline agreements."
};

export default class MessageService extends ServiceBase {

  static async getMessages(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${MessageService.getMessageBaseUri()}?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetMessagesResponseMsg))
      .catch(async response => await this.handleResponseJson(response, GetMessagesResponseMsg));

    return result;
  }

  static async getNotificationMessages(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${MessageService.getNotificationMessageBaseUri()}?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetMessagesResponseMsg))
      .catch(async response => await this.handleResponseJson(response, GetMessagesResponseMsg));

    return result;
  }


  static async getMessageInfo(messageId: number) {
    const result: ServiceResponseJson = await fetch(`${MessageService.getMessageBaseUri()}/agreementDetails?id=${messageId}`, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetMessageInfo))
      .catch(async response => await this.handleResponseJson(response, GetMessageInfo));
    return result;
  }

  static async createMessage(createModel: MessageModel) {
    const url = `${MessageService.getMessageBaseUri()}`;

    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(createModel),
      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, CreateMessageResponseMsg))
      .catch(async response => await this.handleResponseJson(response, CreateMessageResponseMsg));

    return result;
  }

  static async updateMessage(updateMessage: MessageModel) {
    const result: ServiceResponseJson = await fetch(MessageService.getMessageBaseUri(), {
      body: JSON.stringify(updateMessage),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateMessageResponseMsg))
      .catch(response => this.handleResponseJson(response, UpdateMessageResponseMsg));

    return result;
  }

  static async DeleteMessage(agreementId: number) {
    let url = MessageService.getMessageBaseUri() + "/delete";

    const result: ServiceResponse = await fetch(url, {
      body: `"${agreementId}"`,
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, DeleteMessageResponseMsg))
      .catch(response => this.handleResponse(response, DeleteMessageResponseMsg));

    return result;
  }

  static async auditAcceptOrDeclineAgreement(agreementAuditModel: IAuditProps) {
    const url = `${MessageService.getMessageBaseUri()}/accept-decline-agreemnet-activity`
    const result: ServiceResponseStr = await fetch(url, {
      body: this.toPostBody(agreementAuditModel),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseStr(response, AuditAcceptOrDeclineAgreements))
      .catch(response => this.handleResponseStr(response, AuditAcceptOrDeclineAgreements));

    return result;
  }

  static async createNotification(createMessage: MessageModel) {
    const url = `${MessageService.getNotificationBaseUri()}`;

    const notificationModel = { id: createMessage.notificationId, ...createMessage }

    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(notificationModel),
      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, CreateMessageResponseMsg))
      .catch(async response => await this.handleResponseJson(response, CreateMessageResponseMsg));

    return result;
  }

  static async updateNotification(updateMessage: MessageModel) {
    const notificationModel = { id: updateMessage.notificationId, ...updateMessage }

    const result: ServiceResponseJson = await fetch(MessageService.getNotificationBaseUri(), {
      body: JSON.stringify(notificationModel),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateMessageResponseMsg))
      .catch(response => this.handleResponseJson(response, UpdateMessageResponseMsg));

    return result;
  }

  static async getNotificationInfo(notificationId: number) {
    const result: ServiceResponseJson = await fetch(`${MessageService.getNotificationBaseUri()}/${notificationId}`, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetMessageInfo))
      .catch(async response => await this.handleResponseJson(response, GetMessageInfo));
    return result;
  }

  static async DeleteNotification(notificationId: number) {
    let url = MessageService.getNotificationBaseUri() + "/delete";

    const result: ServiceResponse = await fetch(url, {
      body: `"${notificationId}"`,
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, DeleteMessageResponseMsg))
      .catch(response => this.handleResponse(response, DeleteMessageResponseMsg));

    return result;
  }


  static getMessageBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}agreement`;
  }

  static getNotificationMessageBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}notificationmessages`;
  }

  static getNotificationBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}notificationMessages`;
  }
}

