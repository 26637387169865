import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as ApplicationsStore from "../../store/Applications";
import Application from "./Application";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import AuthenticationStorageApi from "../../apis/storage/AuthenticationStorageApi";
import * as SessionStore from "../../store/Session";
import { ISessionManager } from "../../SessionManager";
import { AppInsightsHelper } from "../../AppInsights";
import "./Applications.scss";
import { Card, TabStrip, TabStripTab, TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { ApplicationFeature as ApplicationModel, Connectors, TabView } from "../../models/ApplicationModels";
import DialogBox from "../common/DialogBox";
import { IApplicationPreference, IPreference } from "../../models/Preferences";
import ApplicationNav from "../common/utilities/ApplicationNav";
import SearchIcon from "@material-ui/icons/Search";
import { Input, InputAdornment } from "@material-ui/core";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import SupportRequestPopup from "../servicerequest/pulse/SupportRequestPopup";
import { loginHelpStepsSettings, createSupportRequest, wizardSummary, warningMessage, jobMessagingConfig, wizardConfirmSubmitMessage, PublicFields, AppType } from "../../models/LoginHelpStepsSettings";
import { IFieldValueLookup } from "../../models/Wizard";
import RelWorkspaces from "./RelWorkspaces";
import "../common/AngleTab.scss";
import { SessionManager } from "../../SessionManager";

interface IProps {
  history: any;
  sessionManager: ISessionManager;
}
type Props = IProps &
  ApplicationsStore.IApplicationsState &
  typeof ApplicationsStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  pageLoading: boolean;
  isSearchTextDirty: boolean;
  filteredApplications: ApplicationModel[];
  searchText: string;
  viewAsApplinkWarning: boolean;
  position: string;
  selectedTabIndex: number;
  helpWizardSettings: IFieldValueLookup;
  displayFavoriteApps: boolean;
  loginSupportAppType: AppType;
};

const kAppListCutoff = 100;

class Applications extends React.Component<Props, State> {
  pendingRequest: any;
  requestStarted = false;
  anchor: any = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      pageLoading: true,
      isSearchTextDirty: false,
      filteredApplications: [],
      searchText: "",
      viewAsApplinkWarning: this.props.sessionData.impersonateMode,
      position: "right",
      selectedTabIndex: 0,
      helpWizardSettings: {
        [PublicFields.AppId]: { value: null, active: true },
        [PublicFields.FirstName]: { value: this.props.sessionData.firstName, active: true },
        [PublicFields.LastName]: { value: this.props.sessionData.lastName, active: true },
        [PublicFields.Email]: { value: this.props.sessionData.username, active: true },
        [PublicFields.IsFederated]: { value: this.federated(), active: true },
        [PublicFields.Url]: { value: null, active: true },
        [PublicFields.ContactSummary]: { value: null, active: true },
        [PublicFields.workSpaceName]: { value: null, active: true },
      },
      displayFavoriteApps: this.isDisplayFavAppsByPrefs(),
      loginSupportAppType: AppType.Relativity
    };
  }

  isDisplayFavAppsByPrefs = (): boolean => {
    const prefs = this.props.sessionData.userPrefs.Application as IApplicationPreference;
    if (prefs && prefs.showApps)
      return prefs.showApps === TabView.FavAppsOnly;

    return false; //default 
  }

  componentDidMount(): void {
    this.getApplications("");
    const prefs = this.props.sessionData.userPrefs.Application as IApplicationPreference;
    this.setState({
      selectedTabIndex: (prefs && prefs.tabView === TabView.AppView) ? 0 :
        ((this.props.sessionData.hasWorkspaceFeature || this.props.sessionData.superAdmin) ? 1 : 0)
    });
  }

  componentDidUpdate(data: any): void {
    if (this.state.pageLoading && !this.props.isLoading) {
      this.setState({ pageLoading: false, filteredApplications: this.props.applications });
    }
  }

  onTextBoxChange = (e: any) => {
    this.setState({ searchText: e.target.value, isSearchTextDirty: true });
  };

  getApplications(searchText: string): void {
    const userId = AuthenticationStorageApi.getUserId();

    if (!userId) {
      console.warn("unknown user, cannot access apps");
    } else {
      if (!this.props.isLoading) {
        this.props.requestApplications(userId, searchText);
      }
    }
  }

  toggleDialogEvent = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ viewAsApplinkWarning: false });
  }

  directNav = async (app: ApplicationModel) => {
    AppInsightsHelper.trackCustomEvent("Click App link tile", { applicationName: app.name });
    await ApplicationNav.waitLinkReady(app);
    window.location.href = app.startupUrl;
  }

  federated = () => {
    return this.props.sessionData.isFederated ? "Federated" : "Not Federated";
  }

  appFavoriteChange = (appId: number, favorite: boolean) => {
    this.setState({
      filteredApplications: this.state.filteredApplications.map(x => x.id === appId ? { ...x, isFavorite: favorite } : x)
    });
  }

  onHelpClicked = (app: ApplicationModel, workspaceName?: string) => {

    const appType = app.connectorTypeId && app.connectorTypeId === Connectors.GoAnywhereConnector ? AppType.GoAnywhere : AppType.Relativity;

    this.setState({
      loginSupportAppType: appType,
      helpWizardSettings: {
        ...this.state.helpWizardSettings,
        [PublicFields.AppId]: { value: app.id, active: true },
        [PublicFields.Url]: { value: app.url, active: true },
        [PublicFields.workSpaceName]: { value: workspaceName, active: true },
        [PublicFields.ContactSummary]: { // just for formatting purposes using markdown
          active: true, value: `User name: ${this.props.sessionData.firstName} ${this.props.sessionData.lastName}  \nUser ID: ${this.props.sessionData.username}  \nFederation Status: ${this.federated()}  \nApp URL: ${app.url}`
        }
      }
    });
  }

  getAppContaner = (apps: ApplicationModel[]) => {
    const customNames = this.props.sessionData.userPrefs.Application as IApplicationPreference;

    return (
      <Grid container spacing={2} className="app-container">
        {apps.slice(0, kAppListCutoff).map((value, index) => {
          const customName = customNames && customNames[value.id] ? customNames[value.id] : ""
          let redirectToAppName = null as string;

          switch (value.typeNameId) {
            case 2:
              redirectToAppName = "Relativity";
              break;
            case 3:
              redirectToAppName = "Epiq Discovery";
              break;
            default: redirectToAppName = value.typeName;
          }

          const onHelpFunc = value.loginSupport ? this.onHelpClicked : null;

          return (
            <Grid item md={3} sm={4} xs={12} key={index}>
              <Application app={value} appCustomName={customName} customNames={customNames} redirectToAppName={redirectToAppName}
                onHelpClicked={onHelpFunc} appFavoriteChange={this.appFavoriteChange} hideOverlayFavorite={this.props.sessionData.impersonateMode} />
            </Grid>
          );
        })}
      </Grid>
    )
  }

  handleTabSelection = (e: TabStripSelectEventArguments) => {
    this.setState({ selectedTabIndex: e.selected, searchText: "", isSearchTextDirty: true });
    this.saveTabPreference(e.selected);
  }

  saveTabPreference = async (selectedIndex: number, isTab: boolean = true) => {
    const sessionMananger = new SessionManager();
    const prefs = this.props.sessionData.userPrefs.Application as IApplicationPreference;
    let updatePrefs = isTab ? { ...prefs, tabView: selectedIndex === 0 ? TabView.AppView : TabView.WorkspaceView } :
      { ...prefs, showApps: selectedIndex === 0 ? TabView.AllApps : TabView.FavAppsOnly }

    const preference: IPreference = {
      prefName: "Application",
      value: updatePrefs
    }

    await sessionMananger.setPreference(preference);
  }

  render() {
    const prefs = this.props.sessionData.userPrefs.Application as IApplicationPreference;
    const { searchText, selectedTabIndex } = this.state;

    let applications = this.state.filteredApplications;
    if (searchText && applications) {

      let filteredCustomNames = new Set<Number>();

      for (var name in prefs) {
        if (prefs[name] && prefs[name].toLowerCase().includes(searchText.toLowerCase())) {
          filteredCustomNames.add(parseInt(name));
        }
      }

      applications = applications.filter(a =>
        a.name.toLowerCase().includes(searchText.toLowerCase())
        || a.url.toLowerCase().includes(searchText.toLowerCase())
        || filteredCustomNames.has(a.id)
      );
    }

    const favApps = applications && applications.filter(f => f.isFavorite === true);
    const nonFavApps = applications && applications.filter(f => f.isFavorite === false);

    const showMoreMessage = applications && kAppListCutoff < (this.state.displayFavoriteApps ? favApps.length : applications.length);

    const searchField = (
      <Grid container spacing={2} className="search-wrapper" justify="flex-end">
        <Grid item>
          <Input
            className="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder="Search nickname, application name, or URL" onChange={(e) => this.onTextBoxChange(e)}
          />
        </Grid>
      </Grid>
    );

    //TO DO: Keeping this here as it could be re-used later.
    //if (this.props.sessionData.redirectToAppName) {

    //  return (<Layover>
    //    <>
    //      Connecting to <b>{this.props.sessionData.redirectToAppName}</b> through <b>Epiq Access</b>
    //    </>
    //  </Layover>);
    //}

    let appsList = null;

    if (this.state.pageLoading || this.props.isLoading) {
      appsList =
        <div className="app-list">
          {(applications.length > 0 || this.state.isSearchTextDirty) && searchField}
          <div className="spin-container">
            <CircularProgress />
          </div>{" "}
        </div>
    } else if (this.state.displayFavoriteApps && favApps && favApps.length === 0) {
      appsList =
        <div className="app-list">
          {searchField}
          <div className="no-apps msg no-search-result">
            <p>
              <strong><span className="k-icon k-i-star"></span> No favorites have been saved</strong> <br />
              To indicate a favorite, first return to view All.<br /> Then, to mark a favorite, click the star.
            </p>
          </div>
        </div>
    } else if (applications && applications.length === 0) {
      appsList =
        <div className="app-list">
          {searchField}
          <div className="no-apps msg no-search-result">
            <p>No applications found.</p>
          </div>
        </div>
    }

    appsList = appsList || <div className="app-list">
      {searchField}
      {favApps && this.getAppContaner(favApps)}
      {nonFavApps && !this.state.displayFavoriteApps && this.getAppContaner(nonFavApps)}
      {showMoreMessage && <div className="info-message">Too many apps to display. Adjust filters to refine results</div>}
      <div className="warning">
        <DialogBox
          title={"View As for Applications"}
          content={"When viewing as another user, this page appears so that you can verify that the correct applications are listed. Understand that you cannot launch an application using the user's credentials."}
          okButtonLabelText={"Ok"}
          cancelButtonLabelText={""}
          visible={this.state.viewAsApplinkWarning}
          toggleDialogEvent={this.toggleDialogEvent}
        />
      </div>
    </div>

    const appListComponent =
      <div className="apps-page">
        <div>
          <h4 className="apps-page-title">{selectedTabIndex === 0 ? "Applications" : "Workspaces"}</h4>
          <p className="apps-page-description">{selectedTabIndex === 0 ? "Click a tile to open an application." : "Click a row to open a Relativity workspace."}</p>
        </div>
        {selectedTabIndex == 0 && <div className="app-fav-buttons">
          <ButtonGroup>
            <Button togglable={true}
              onClick={() => {
                this.setState({ displayFavoriteApps: false });
                this.saveTabPreference(0, false);
              }}
              selected={!this.state.displayFavoriteApps}>
              All
            </Button>
            <Button togglable={true} icon={this.state.displayFavoriteApps ? "star" : "star-outline"} onClick={() => {
              this.setState({ displayFavoriteApps: true });
              this.saveTabPreference(1, false);
            }}
              selected={this.state.displayFavoriteApps}>
              Favorites
            </Button>
          </ButtonGroup>
        </div>}
        <TabStrip className="angle-tabs" selected={selectedTabIndex} onSelect={this.handleTabSelection}>
          <TabStripTab title={"Applications"}>
            {appsList || <></>}
          </TabStripTab>
          {(this.props.sessionData.hasWorkspaceFeature || this.props.sessionData.superAdmin) && <TabStripTab title={"Workspaces"}>
            <RelWorkspaces onHelpClicked={this.onHelpClicked.bind(this)} />
          </TabStripTab>}

        </TabStrip>
      </div >

    return (<>
      {appListComponent}
      <SupportRequestPopup
        open={!!this.state.helpWizardSettings[PublicFields.AppId].value && !!this.state.helpWizardSettings[PublicFields.Url].value}
        onClose={(isCancel?: boolean) => {
          this.setState({ helpWizardSettings: { ...this.state.helpWizardSettings, [PublicFields.AppId]: { value: null, active: true }, [PublicFields.Url]: null, [PublicFields.ContactSummary]: null } });

        }}
        jobTypeNumber={2}
        disableSubmitButton={this.props.sessionData.preImpersonatedUser != null}
        createRequestObject={createSupportRequest}
        wizardSections={loginHelpStepsSettings(this.state.loginSupportAppType)}
        wizardSummary={wizardSummary}
        warningMessage={warningMessage}
        jobMessageConfig={jobMessagingConfig}
        externallyManagedFields={this.state.helpWizardSettings}
        confirmSubmitMessage={wizardConfirmSubmitMessage}
        wizardTextConfig={{ initialNextText: "NEXT" }}
        onSubmitClick={() => {
          this.setState({ helpWizardSettings: { ...this.state.helpWizardSettings, [PublicFields.AppId]: { value: null, active: true }, [PublicFields.Url]: null, [PublicFields.ContactSummary]: null, [PublicFields.workSpaceName]: { value: null, active: true } } });

        }}

      />
    </>
    );
  }
}

export default connect((state: IApplicationState) => ({ ...state.applicationsState, ...state.sessionState }), {
  ...ApplicationsStore.actionCreators
})(Applications as any);
