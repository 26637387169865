import { Grid, Paper } from "@material-ui/core";
import * as React from "react";
import { SecureUploadLocation } from "../../../models/SecureUploadLocations";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import { TextInput } from "../../common/TextInput";
import CommonHelper from "../../common/utilities/CommonHelper";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import SupportService from "../../../services/SupportService";
import { filterBy } from "@progress/kendo-data-query";

enum PlaceHolders {
  locationName = "Location Name",
  url = "Secure Form URL",
  destination = "Upload Destination"
}

enum ErrorMessages {
  LocationExists = "Location already exists.",
  Required = "This field is required.",
}

interface IProps {
  location: SecureUploadLocation;
  savedLocationDetails: SecureUploadLocation;
  createLocation: boolean;
  onLocationEdited: (loc: SecureUploadLocation, isValid?: boolean) => void;
  onLocationEditCancelled?: (loc: SecureUploadLocation) => void;
  permissions: Set<string>;
  isLocationNameExist: boolean;
  isUpdateSaved: boolean;
  resetViewMode: (isLocationInfoInEditMode: boolean) => void;
}
type Props = IProps;

type State = {
  isLocationDetailsInEditMode: boolean;
  locationInEdit: SecureUploadLocation;
  ftpSiteUrls: string[];
  filteredFtpSiteUrls: string[];
};

class SecureUploadInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLocationDetailsInEditMode: props.createLocation,
      locationInEdit: this.props.savedLocationDetails,
      ftpSiteUrls: [],
      filteredFtpSiteUrls: []
    };
  }

  componentDidMount() {
    this.loadFtpSiteUrls();
  }

  async loadFtpSiteUrls() {
    const result = await SupportService.getFtpSiteUrls();
    if (result.ok) {
      this.setState({
        ftpSiteUrls: result.data as Array<string>,
        filteredFtpSiteUrls: result.data as Array<string>,
      });
    }
  }

  onLocationInfoChange = (value: string, name: string, isValid: boolean) => {
    var loc: any = { ...this.state.locationInEdit };
    loc[name] = value;

    this.setState({ ...this.state, locationInEdit: loc }, () => {
      if (this.props.onLocationEdited) {
        this.props.onLocationEdited(loc, isValid && !!loc["name"] && !!loc["secureFormURL"] && !!loc["uploadDestination"] && !!loc["ftpSiteUrl"]);
      }
    });
  }

  onFtpSiteUrlChange = (e: ComboBoxChangeEvent) => {
    var loc: SecureUploadLocation = { ...this.state.locationInEdit };
    loc.ftpSiteUrl = e.value;

    this.setState({ ...this.state, locationInEdit: loc }, () => {
      if (this.props.onLocationEdited) {
        this.props.onLocationEdited(loc, !!loc["name"] && !!loc["secureFormURL"] && !!loc["uploadDestination"] && !!loc["ftpSiteUrl"]);
      }
    });
  }

  onFtpSiteFilterChange = (event: ComboBoxFilterChangeEvent) => {
    const data = this.state.ftpSiteUrls.slice();
    const filteredData = filterBy(data, event.filter);
    this.setState({ filteredFtpSiteUrls: filteredData });
  };

  onCancelEditLocationInfo = () => {
    this.setState({
      isLocationDetailsInEditMode: false,
      locationInEdit: CommonHelper.cloneObject(this.props.savedLocationDetails)
    },
      () => {
        if (this.props.onLocationEdited) {
          this.props.onLocationEdited(CommonHelper.cloneObject(this.props.savedLocationDetails), true);
        }
      });
  };


  render() {
    const locationName = this.state.locationInEdit.id > 0 ? this.state.locationInEdit.name : "Create Location";
    const isLocationDetailsInEditMode = this.props.isUpdateSaved ? !this.props.isUpdateSaved : this.state.isLocationDetailsInEditMode;

    const createLocation = (
      <fieldset>
        <div className="field-hint">* Required Fields</div>
        <div>
          <Grid container>
            <Grid item xs={6}>
              <div className="text-input-has-error">
                <TextInput
                  type="text"
                  name="name"
                  legacyMode={true}
                  label="* Location Name"
                  placeholder={PlaceHolders.locationName}
                  defaultValue={this.state.locationInEdit.name}
                  maxLength={50}
                  validations={[
                    { name: "required", errorMessage: ErrorMessages.Required },
                    {
                      name: "CustomValidation",
                      errorMessage: `${ErrorMessages.LocationExists}`,
                      predicate: `${this.props.isLocationNameExist}`
                    }
                  ]}
                  onChange={this.onLocationInfoChange}
                  displayCustomValidationMessage={this.props.isLocationNameExist}
                />
              </div>
              <div className="text-input-has-error">
                <TextInput
                  type="url"
                  placeholder={PlaceHolders.url}
                  defaultValue={this.state.locationInEdit.secureFormURL}
                  name="secureFormURL"
                  label="* Secure Form URL"
                  legacyMode={true}
                  maxLength={1500}
                  onChange={this.onLocationInfoChange}
                  validations={[
                    { name: "required", errorMessage: "This field is required." },
                    { name: "Url", errorMessage: "Please enter a valid URL." }
                  ]}
                />
              </div>
              <div className="text-input-has-error">
                <TextInput
                  type="text"
                  legacyMode={true}
                  name="uploadDestination"
                  label="* Upload Destination"
                  placeholder={PlaceHolders.destination}
                  defaultValue={this.state.locationInEdit.uploadDestination}
                  maxLength={255}
                  onChange={this.onLocationInfoChange}
                />
              </div>
              <div className="secure-upload-ftp-container">
                <div className="input-label">* FTP Site</div>
                <ComboBox
                  data={this.state.filteredFtpSiteUrls}
                  defaultValue={this.state.locationInEdit.ftpSiteUrl}
                  onChange={this.onFtpSiteUrlChange}
                  onFilterChange={this.onFtpSiteFilterChange}
                  name="ftpSiteUrl"
                  placeholder="(select one)"
                  filterable={true}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </fieldset>
    );


    return (
      <div className="grid-wrapper loc-grid-wrapper papergrid-space">
        <Grid container alignItems="center">
          <Grid item sm={6} xs={12}>
            <h3 className="section-head">{locationName} </h3>
          </Grid>
          <Grid item sm={6} xs={12} className="button-wrapper">
            {!this.props.createLocation && (
              <span className="edit-user">
                {isLocationDetailsInEditMode && (
                  <CancelIcon className="edit-icon" onClick={this.onCancelEditLocationInfo.bind(this)} />
                )}
                {!isLocationDetailsInEditMode &&
                  this.props.permissions.has("EpiqAdminUpdateSecureUploadLocations") && (<CreateIcon
                    className="edit-icon"
                    onClick={() => {
                      this.setState({
                        locationInEdit: this.props.location,
                        isLocationDetailsInEditMode: true,
                      },
                        () => { this.props.resetViewMode(false); });
                    }}
                  />
                  )}
              </span>
            )}
          </Grid>
        </Grid>
        {isLocationDetailsInEditMode && createLocation}
        {!isLocationDetailsInEditMode && (
          <div className="loc-info">
            <Grid container>
              <Grid item sm={8} xs={12}>
                <div className="loc-info-row">
                  <strong>Name: </strong> {this.state.locationInEdit.name}
                </div>
                <div className="loc-info-row">
                  <strong>Secure Form URL: </strong> {this.state.locationInEdit.secureFormURL}
                </div>
                <div className="loc-info-row">
                  <strong>Upload Destination: </strong> {this.state.locationInEdit.uploadDestination}
                </div>
                <div className="loc-info-row">
                  <strong>FTP Site: </strong> {this.state.locationInEdit.ftpSiteUrl}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default SecureUploadInfo;