import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { Employer, UpsertEmployerModel } from "../../../models/EmployerModel";
import { ShowEpiqOnlyControls } from "../../../models/Enums";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import { formatDate } from "@telerik/kendo-intl";
import ErrorIcon from "@material-ui/icons/Error";
import { TextInput } from "../../common/TextInput";
import CommonHelper from "../../common/utilities/CommonHelper";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import "./Employers.scss";

enum PlaceHolders {
  employerName = "Employer Name"
}

enum ErrorMessages {
  EmployerExists = "Employer already exists.",
  Required = "This field is required."
}

interface IProps {
  employer: Employer;
  savedEmployerDetails: Employer;
  createEmployer: boolean;
  onEmployerEdited: (emp: Employer, isValid?: boolean) => void;
  onEmployerEditCancelled?: (emp: Employer) => void;
  permissions: Set<string>;
  isEmployerNameExist: boolean;
  isUpdateSaved: boolean;
  resetViewMode: (isEmployerInfoInEditMode: boolean) => void;
}
type Props = IProps;

type State = {
  isEmployerDetailsInEditMode: boolean;
  employerInEdit: Employer;
};

type epiqDepts = "eds" | "ems" | "other";
type entityTypes = "corporation" | "lawfirm" | "government" | "other";

interface iEpiqDeptOptions {
  id: epiqDepts;
  name: string;
}

interface iEntityTypeOptions {
  id: entityTypes;
  name: string;
}

const epiqDeptOptions = [
  { name: "EDS", id: "eds" },
  { name: "EMS", id: "ems" },
  { name: "Other", id: "other" }
] as iEpiqDeptOptions[];
const entityTypeOptions = [
  { name: "Corporation", id: "corporation" },
  { name: "Law Firm", id: "lawfirm" },
  { name: "Government", id: "government" },
  { name: "Other", id: "other" }
];

class EmployerInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEmployerDetailsInEditMode: props.createEmployer,
      employerInEdit: this.props.savedEmployerDetails
    };
  }

  employerNameChange = (value: string, name: string, isValid: boolean) => {
    var emp = { ...this.state.employerInEdit };
    emp.name = value ? value : "";

    this.setState({ ...this.state, employerInEdit: emp }, () => {
      if (this.props.onEmployerEdited) {
        this.props.onEmployerEdited(emp, isValid);
      }
    });
  };

  onCancelEditEmployerInfo = () => {
    this.setState(
      {
        isEmployerDetailsInEditMode: false,
        employerInEdit: CommonHelper.cloneObject(this.props.savedEmployerDetails)
      },
      () => {
        if (this.props.onEmployerEdited) {
          this.props.onEmployerEdited(CommonHelper.cloneObject(this.props.savedEmployerDetails), true);
        }
      }
    );
  };

  onCheckBoxChange = (isChecked: boolean) => {
    var emp = { ...this.state.employerInEdit };
    emp.epiqClient = isChecked;

    this.setState({ ...this.state, employerInEdit: emp }, () => {
      if (this.props.onEmployerEdited) {
        this.props.onEmployerEdited(emp, true);
      }
    });
  };

  onEpiqDeptChange = (event: DropDownListChangeEvent) => {
    var emp = { ...this.state.employerInEdit };
    emp.epiqDept = event.value && event.value.name ? event.value.name : "";

    this.setState({ ...this.state, employerInEdit: emp }, () => {
      if (this.props.onEmployerEdited) {
        this.props.onEmployerEdited(emp, true);
      }
    });
  };

  onEntityTypeChange = (event: DropDownListChangeEvent) => {
    var emp = { ...this.state.employerInEdit };
    emp.entityType = event.value && event.value.name ? event.value.name : "";

    this.setState({ ...this.state, employerInEdit: emp }, () => {
      if (this.props.onEmployerEdited) {
        this.props.onEmployerEdited(emp, true);
      }
    });
  };

  onDescriptionChange = (value: string) => {
    var emp = { ...this.state.employerInEdit };
    emp.description = value ? value : "";

    this.setState({ ...this.state, employerInEdit: emp }, () => {
      if (this.props.onEmployerEdited) {
        this.props.onEmployerEdited(emp, true);
      }
    });
  };

  getNotesFieldLength = (employerInEdit: Employer, employer: Employer) => {
    return employerInEdit
      ? employerInEdit.description
        ? employerInEdit.description.length
        : 0
      : employer.description
        ? employer.description.length
        : 0;
  };

  render() {
    const employerName = this.state.employerInEdit.id > 0 ? this.state.employerInEdit.name : "Create Employer";
    const notesLength = this.getNotesFieldLength(this.state.employerInEdit, this.props.employer);
    const isEmployerDetailsInEditMode = this.props.isUpdateSaved
      ? !this.props.isUpdateSaved
      : this.state.isEmployerDetailsInEditMode;

    const createEmployer = (
      <fieldset>
        <div className="field-hint">* Required Fields</div>
        <div>
          <Grid container>
            <Grid item xs={6}>
              <div className="text-input-has-error">
                <TextInput
                  type="text"
                  name="employerName"
                  legacyMode={true}
                  label="* Employer Name"
                  placeholder={PlaceHolders.employerName}
                  defaultValue={this.state.employerInEdit.name}
                  maxLength={100}
                  validations={[
                    { name: "required", errorMessage: ErrorMessages.Required },
                    {
                      name: "CustomValidation",
                      errorMessage: `${ErrorMessages.EmployerExists}`,
                      predicate: `${this.props.isEmployerNameExist}`
                    }
                  ]}
                  onChange={this.employerNameChange}
                  displayCustomValidationMessage={this.props.isEmployerNameExist}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text-input-has-error">
                <Checkbox
                  defaultChecked={this.state.employerInEdit.epiqClient}
                  onChange={event => this.onCheckBoxChange(event.value)}
                  name="epiqClient"
                  label="Epiq Client"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="empLabel">Epiq Department</div>
              <div className="text-input-has-error">
                <DropDownList
                  name="ddlDept"
                  className="ddlDept"
                  data={epiqDeptOptions}
                  textField={"name"}
                  defaultValue={epiqDeptOptions.find(d => d.name == this.state.employerInEdit.epiqDept)}
                  onChange={this.onEpiqDeptChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="empLabel">Entity Type</div>
              <div className="text-input-has-error">
                <DropDownList
                  name="ddlEntity"
                  className="ddlEntity"
                  data={entityTypeOptions}
                  textField={"name"}
                  defaultValue={entityTypeOptions.find(e => e.name == this.state.employerInEdit.entityType)}
                  onChange={this.onEntityTypeChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} className="empDesc">
              <div className="empLabel">Description</div>
              <div className="text-input-has-error">
                <TextInput
                  type="textarea"
                  name="description"
                  defaultValue={this.state.employerInEdit.description}
                  onChange={this.onDescriptionChange.bind(this)}
                  notesLength={notesLength}
                  maxLength={255}
                  legacyMode={true}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </fieldset>
    );

    const createdDate = this.state.employerInEdit.createdDate
      ? formatDate(CommonHelper.convertUTCDateToLocalDate(this.state.employerInEdit.createdDate), "g")
      : "";

    return (
      <div className="grid-wrapper emp-grid-wrapper papergrid-space">
        <Grid container alignItems="center">
          <Grid item sm={6} xs={12}>
            <h3 className="section-head">{employerName} </h3>
          </Grid>
          <Grid item sm={6} xs={12} className="button-wrapper">
            {!this.props.createEmployer && (
              <span className="edit-user">
                {isEmployerDetailsInEditMode && (
                  <CancelIcon className="edit-icon" onClick={this.onCancelEditEmployerInfo.bind(this)} />
                )}
                {!isEmployerDetailsInEditMode &&
                  this.props.permissions.has("EpiqAdminUpdateEmployer") && (
                    <CreateIcon
                      className="edit-icon"
                      onClick={() => {
                        this.setState(
                          {
                            employerInEdit: this.props.employer,
                            isEmployerDetailsInEditMode: true
                          },
                          () => {
                            this.props.resetViewMode(false);
                          }
                        );
                      }}
                    />
                  )}
              </span>
            )}
          </Grid>
        </Grid>
        {isEmployerDetailsInEditMode && createEmployer}
        {!isEmployerDetailsInEditMode && (
          <div className="emp-info">
            <Grid container>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="emp-info-row">
                      <strong>Epiq Department: </strong> {this.state.employerInEdit.epiqDept}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="emp-info-row">
                      <strong>Employer ID: </strong> {this.state.employerInEdit.id}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="emp-info-row">
                      <strong>Epiq Client: </strong> {this.state.employerInEdit.epiqClient ? "True" : "False"}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="emp-info-row">
                      <strong>Created Date: </strong> {createdDate}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="emp-info-row">
                      <strong>Entity Type: </strong> {this.state.employerInEdit.entityType}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className="emp-info-row" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid container>
                  <Grid item>
                    <div className="emp-info-row">
                      <strong>Description: </strong> {this.state.employerInEdit.description}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default EmployerInfo;
