import React, { useState, useEffect, useRef } from "react";
import "./RelWorkspaces.scss";
import { AdvancedGrid, IGridParams } from "../common/grid/AdvancedGrid";
import { GridCellProps } from "@progress/kendo-react-grid";
import { IRelWorkspace } from "../../models/Relativity";
import RelativityService from "../../services/RelativityService";
import { Button } from "@progress/kendo-react-buttons";
import ApplicationNav from "../common/utilities/ApplicationNav";
import { ApplicationFeature } from "../../models/ApplicationModels";
import { IGridColumnProps } from "../common/grid/BaseGrid";
import ConsoleService from "../../services/ConsoleService";
interface IRefreshGridParams extends IGridParams {
  refreshToggle: boolean;
}

interface IProps {
  onHelpClicked: (app: ApplicationFeature, workspaceName?: string) => void;
}

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "workspaceName",
  Ascending: "asc",
  Descending: "desc"
};

const startingGridParams: IRefreshGridParams = {
  skip: 0,
  take: GridConfigurations.PageSize,
  sort: [{ field: GridConfigurations.OrderBy, dir: "asc" }],
  refreshToggle: false
};

type Props = IProps;

export default (props: Props) => {

  const gridRef = useRef<AdvancedGrid<IRelWorkspace, IRefreshGridParams>>(null);
  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [workspaces, setWorkspaces] = useState<Array<IRelWorkspace>>(null);
  const filterTimeOut = useRef(null);

  useEffect(() => { }, []);

  const openSupport = (workspace: IRelWorkspace) => {
    props.onHelpClicked(workspace.app, workspace.workspaceName);
  }

  const openLogin = async (props: IRelWorkspace) => {
    const result = await ConsoleService.preNavigate(props.app.id, null, props.workspaceId);
    if (result.ok && result.data && result.data.redirectUri) {
      window.open(result.data.redirectUri, '_blank', 'noopener,noreferrer');
    }

    await ApplicationNav.waitLinkReady(props.app);
  }

  const workspaceOptions = (props: GridCellProps) => {
    return <td className="user-workspace-action-cell">
      <div className="btn-container">
        <Button className="btn btn-secondary support-btn" onClick={() => openSupport(props.dataItem)}><i className="fas fa-tools"></i> Relativity Support</Button>
        <Button className="btn btn-primary" primary={true} onClick={() => openLogin(props.dataItem)}><i className="fas fa-right-to-bracket"></i>Login</Button>
      </div>
    </td>;
  };

  const columns = new Array<IGridColumnProps>(
    { field: "workspaceName", title: "Relativity Workspace Name", filterable: true, preCalcPercentageWidth: 25, sortable: true },
    { field: "app.url", title: "Relativity Website URL", filterable: true, preCalcPercentageWidth: 25, sortable: true },
    { field: "relativityStatusName", title: "Status", filterable: true, sortable: true },
    { title: "", cell: workspaceOptions, sortable: false, headerClassName: "no-sort user-workspace-action-cell", preCalcFixedWidth: 400}
  );
    
  const fetchDataAsync = async (gridParams: IRefreshGridParams, caller?: string) => {

    setIsGridLoading(true);
    setDataState(gridParams);

    var results = await RelativityService.getMyWorkspaces(gridParams);

    if (results.ok) {
      setIsGridLoading(false);

      setWorkspaces(results.data.results);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }
  }

  return <div className="user-workspace-grid">
    <AdvancedGrid
      ref={(standardGridInstance: AdvancedGrid<IRelWorkspace, IRefreshGridParams>) => {
        gridRef.current = standardGridInstance;
      }}
      showErrorState={hasError}
      showLoadingIndicator={isGridLoading}
      data={workspaces}
      dataFetch={fetchDataAsync}
      dataState={dataState}
      columns={columns}
      paging={false}
      noRecordsRender={<p>No Workspaces Found.</p>}
      noLoadOnMount={false}
      filteredDataOnly={false}
      hidePaper={false}
      filterOperators={{
        text: [
          { text: 'grid.filterContainsOperator', operator: 'contains' }
        ]
      }}
    />
  </div>
}