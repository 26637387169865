import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";
import * as SessionStore from "../../../../store/Session";
import SupportService, { IActivity, ITicketPersona } from "../../../../services/SupportService";
import JobService, { ISubmitJobRequestData } from "../../../../services/JobService";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { DataLoader } from "../../../common/DataLoader";
import NotificationService from "../../../../services/NotificationService";
import "./ActivityLog.scss";
import { Checkbox } from "@progress/kendo-react-inputs";
import CollapsibleSection from "../../../administration/common/CollapsibleSection";
import { Button } from "@progress/kendo-react-buttons";

interface IProps {
  incidentId: string;
  projectId: number;
  sysId: string;
  requestTypeCode: string;
  disableAdd?: boolean;
  watchListDataSource: boolean; // TODO I can probably get rid of this
}

type Props = IProps & SessionStore.ISessionState;

type State = {
  isLoading: boolean;
  total: number;
  comment: string;
  commentFocused: boolean;
  data: Array<IActivity>;
  showAttachFiles: boolean;
  jobId: string;
  jobType: number;
  attachFilesJobProcessing: boolean;
  attachFilesUploading: boolean;
  attachFilesUploadedSuccess: boolean;
  isInternalCheckboxChecked: boolean;
};

class ActivityLog extends React.Component<Props, State> {
  attachButtonAnchor: HTMLButtonElement = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      total: 0,
      comment: "",
      commentFocused: false,
      data: new Array<IActivity>(),
      showAttachFiles: false,
      jobId: null,
      jobType: 4,
      attachFilesJobProcessing: false,
      attachFilesUploading: false,
      attachFilesUploadedSuccess: false,
      isInternalCheckboxChecked: true
    };
  }

  componentDidMount() {
    this.initialize();
  }

  async dataFetch() {
    this.setState({ isLoading: true }, async () => {
      if (this.state.comment.length > 0) {
        const updateResult = await SupportService.updateActivity({
          incident: this.props.incidentId,
          jobId: this.state.jobId,
          comment: this.state.comment,
          watchListDataSource: this.props.watchListDataSource
        });

        if (updateResult.ok) {
          NotificationService.showSuccessToast("New comment successfully submitted.");
        } else {
          NotificationService.showErrorToast("Failed to update ticket with new comment.");
        }
      }

      const showWorkNotes = (this.props.sessionData.permissions.has("EpiqAdminViewSupportWorkNotes") && this.state.isInternalCheckboxChecked); // by default both(external & worknotes) comments are displaying if user has EpiqAdminViewSupportWorkNotes permission
      const result = await SupportService.getActivity(this.props.incidentId, this.props.watchListDataSource, showWorkNotes);

      if (result.ok) {
        this.setState({
          ...this.state,
          comment: "",
          data: result.data.results,
          total: result.data.count
        });
      }

      this.setState({ isLoading: false });
    });
  }

  handleOnBlur() {
    this.setState({ commentFocused: false });
  }

  handleSendOnClick() {
    this.dataFetch();
  }

  handleOnFocus() {
    this.setState({ commentFocused: true });
  }

  handleOnValueChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ comment: event.currentTarget.value });
  }

  async initialize(forceNewJob: boolean = false) {
    if (!this.state.jobId || forceNewJob) {
      const result = await JobService.initializeJob({ jobType: 4 });

      if (result.ok) {
        this.setState({ jobId: result.data.jobId });
      }
    }
  }

  async resetJob() {
    const result = await JobService.resetJob(this.state.jobId);

    if (!result.ok) {
      this.initialize(true);
    }
  }

  onCheckBoxChange = async (isChecked: boolean) => {
    this.setState({ isLoading: true, isInternalCheckboxChecked: isChecked });

    const result = await SupportService.getActivity(this.props.incidentId, this.props.watchListDataSource, isChecked ? isChecked : false);

    if (result.ok) {
      this.setState({
        ...this.state,
        comment: "",
        data: result.data.results,
        total: result.data.count,
        isLoading: false
      });
    }
  };

  render() {

    const workNotesCheck = this.props.sessionData.permissions.has("EpiqAdminViewSupportWorkNotes") ? <span className="request-activity-log-internal-check"><Checkbox
      defaultChecked={true}
      onChange={(event) => this.onCheckBoxChange(event.value)}
      name="worknotes"
      label="Show Internal Notes"
    /></span> : null;

    return (
      <CollapsibleSection title="Notes" noCollapse={true} headerContent={workNotesCheck}>
        <>
          <div className="ticket-activity-log-wrapper">
            <div className={this.props.disableAdd ? "items-container no-add" : "items-container"}>
              <div className="activity-cards-container">
                {this.state.data.map(activity => (
                  <Card className={activity.element == "comments" ? "activity-card external-comments" : "activity-card internal-comments"}>
                    <CardBody>
                      <div className="created">{activity.created}</div>
                      <p>{activity.value}</p>
                    </CardBody>
                  </Card>
                ))}
              </div>
            </div>
            {!this.props.disableAdd && (
              <>
                <div className="bottom-container">
                  <div className="comment-container">
                    <span className="comment-box-label">Add Note</span>
                    <div className="comment-box-container">
                      <textarea
                        value={this.state.comment}
                        name="comment"
                        placeholder="Type a new note"
                        disabled={this.state.isLoading || this.state.attachFilesJobProcessing}
                        onChange={this.handleOnValueChange.bind(this)}
                        onBlur={this.handleOnBlur.bind(this)}
                        onFocus={this.handleOnFocus.bind(this)}
                        maxLength={4000}
                        rows={2}
                      />
                      <Button primary={false}
                        onClick={this.handleSendOnClick.bind(this)}
                        className={this.state.commentFocused ? "send-comment-button focused" : "send-comment-button"}
                        disabled={
                          this.state.comment.trim().length === 0 ||
                          this.state.isLoading ||
                          this.state.attachFilesJobProcessing ||
                          this.props.sessionData.preImpersonatedUser != null
                        }
                        color="primary"
                        aria-label="send comment">
                        <i className="fa-solid fa-circle-arrow-up"></i>
                      </Button>
                    </div>
                    <div className="comments-length">{this.state.comment.length}/4000 characters used</div>
                  </div>
                </div>
              </>
            )}
          </div>
          <DataLoader
            loading={this.state.isLoading}
            dataState={{}}
            getDataAsync={this.dataFetch.bind(this)}
            dataContainerClassesForScope=".ticket-activity-log-wrapper"
          />
        </>
      </CollapsibleSection>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(ActivityLog);
