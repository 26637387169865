import { IGridParams } from "../components/common/grid/AdvancedGrid";
import { JobMessagingConfig } from "../JobManager";
import ResourceService from "../services/ResourceService";
import SupportService from "../services/SupportService";
import { IFieldValueLookup, IRequestTypes, IWizardDropDownListItem, IWizardRequest, IWizardSection, IWizardSummary, IWizardValues, IWizardWarningMessage, IGridDataColumns, IFieldValidation, IHostInfo } from "./Wizard";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import NotificationService from "../services/NotificationService";
import { IResourceParams } from "./ResourceModels";
import UserService from "../services/UserService";
import { User } from "../models/UserModels";
import AdminService from "../services/AdminService";
import { Application, ApplicationStatus } from "../models/ApplicationModels";
import { ServiceResponseJson } from "../services/ServiceBase";
import CommonHelper from "../components/common/utilities/CommonHelper";

const kDefaultSelectionName = "Not Applicable";
const kMaxDropDownLength = 100;
export const kAddRelUserDescPrefix = "Create or update Relativity Accounts for";
export const kRemoveRelUserDescPrefix = "Update Relativity Accounts for";

export enum TicketTypes {
  AccountAdministration = "accountadministration",
  GoAnywhereSupport = "goanywheresupport",
  ManageRelativityUsers = "managerelativityusers",
  TechnicalSupport = "technicalsupport",
  DRSITServices = "drsitservices"
}

const issueTypesConfig = {
  [TicketTypes.AccountAdministration.toString()]: {
    "issues": [{
      id: 1,
      title: "Cannot access Relativity"
    },
    {
      id: 2,
      title: "Relativity seems very slow"
    },
    {
      id: 3,
      title: "Cannot see document list",
    },
    {
      id: 4,
      title: "Document not opening",
    },
    {
      id: 5,
      title: "Search isn`t loading",
    },
    {
      id: 6,
      title: "Other issue",
    }]
  },
  [TicketTypes.GoAnywhereSupport.toString()]: {
    "issues": [{
      id: 1,
      title: "Cannot access GoAnywhere"
    },
    {
      id: 2,
      title: "Issue transferring files "
    },
    {
      id: 3,
      title: "Missing folders or files",
    },
    {
      id: 4,
      title: "Other issue",
    }]
  }
}


export const requestSupportSteps = [
  {
    fields: [
      {
        type: "hiddenField",
        fieldId: "1.0.0",
        name: "Request By",
        label: "Request By",
        hideFieldIdLabel: true
      },
      {
        type: "hiddenField",
        fieldId: "1.0.1",
        name: "Date Submitted",
        label: "Date Submitted",
        hideFieldIdLabel: true,
        value: new Date().toLocaleDateString()
      },
      {
        type: "hiddenField",
        fieldId: "1.0.2",
        name: "Support Category",
        label: "Support Category",
        hideFieldIdLabel: true,
        value: "Add Relativity Users",
        hideFieldInSummary: true
      },
      {
        type: "hiddenField",
        fieldId: "1.0.3",
        name: "External Email",
        label: "External Email",
        hideFieldIdLabel: true,
        hideFieldInSummary: true
      },
      {
        type: "hiddenField",
        fieldId: "1.0.4",
        name: "Employer Id",
        label: "Employer Id",
        hideFieldInSummary: true
      },
      {
        type: "dropdown",
        fieldId: "1.1",
        name: "Request Type",
        label: "Select the type of support you need.",
        active: true,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        hideSelectOne: false,
        tooltipTitle: "Select the type of support you need."
      },
      {
        type: "dropdown",
        fieldId: "1.1.1",
        name: "Sub Request Type",
        label: "What do you want to do?",
        active: false,
        hideFieldIdLabel: true,
        resetForField: "1.1",
        requiredWhenActive: true,
        hideSelectOne: false,
        tooltipTitle: "What do you want to do?",
        dependency: { fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers },
        DropDownOptions: [
          {
            id: 1,
            title: "Provide Relativity access to users",
            lookupValues: { code: "relativityaccess", databaseHeader: "Provide Relativity access to users", }
          },
          {
            id: 2,
            title: "Remove users from a Relativity Group",
            lookupValues: { code: "removerelativityaccess", databaseHeader: "Remove users from a Relativity Group" }
          }
        ]
      },
      {
        type: "singleselect",
        fieldId: "1.2",
        name: "Project",
        label: (fieldValues?: IFieldValueLookup) => {
          return (fieldValues["1.1"].value === TicketTypes.ManageRelativityUsers && fieldValues["1.1.1"].value === 1) ? "* What project do you want to add the users to?" : "Select the related project.";
        },
        hideFieldIdLabel: true,
        active: false,
        tooltipTitle: "Select a project. Supply additional project information in the description if needed.",
        requiredWhenActive: true,
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration },
          { fieldId: "1.1", compare: "eq", value: TicketTypes.TechnicalSupport },
          { fieldId: "1.1", compare: "eq", value: TicketTypes.DRSITServices },
          {
            logic: "and",
            subConditions: [{ fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers },
            { fieldId: "1.1.1", compare: "neq", value: null }]
          },
          { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport },
          ]
        },
        summaryTitle: "Project",
        GetData: ResourceService.getResourcesUserWithDefaultEmpty,
        resetForField: "1.1",
        postChange: async (valueInfo: IWizardValues, fieldValues?: IFieldValueLookup) => {

          if (valueInfo.lookupValues && valueInfo.lookupValues["IsDummy"]) {

            return {
              ...valueInfo,
              lookupValues: {
                ...valueInfo.lookupValues, ticketTypeFields: {
                  [valueInfo.lookupValues["Permission"].toString()]: {
                    [valueInfo.value.toString()]: {
                      websites: {
                        [kDefaultSelectionName]: [{
                          relativityHostId: null,
                          relativityHost: null,
                          dbRecordType: null,
                          databaseName: null,
                          eqdWorkspaceName: null
                        }]
                      }
                    }
                  }
                }
              }
            };
          }

          try {
            const typePermision = getRequestPermission(fieldValues, "1.1");
            const projectNumber = fieldValues["1.2"].lookupValues["ProjectNumber"].toString();
            const resourcesRequestData: IResourceParams = {
              skip: 0,
              take: 100,
              typeCode: "ediscoveryproject",
              showGroupsWithDupePermissions: true,
              flattenResults: true,
              joinFields: ["ApplicationDatabases"],
              additionalQueries: ["RelativityProjects"],
              filters: [{
                logic: "or",
                filters: [{ field: "DisplayName", operator: "contains", value: projectNumber },
                { field: "Description", operator: "contains", value: projectNumber }
                ] as Array<FilterDescriptor>
              }] as Array<CompositeFilterDescriptor>,

              permissionsDelimited: typePermision,
            };

            let resourceResponse = await ResourceService.getResourcesUserWithDefaultEmpty(resourcesRequestData);

            if (!resourceResponse.ok || !resourceResponse.data.results) {
              NotificationService.showErrorToast("could not load project and ticket details");

              return;
            } else if (resourceResponse.data.results.length === 0 && !valueInfo.value) {
              NotificationService.showErrorToast(
                "You currently don't have any permissions needed to submit project support ticket requests. You will need an administrator to assign ticket permissions for the projects."
              );

              return;
            }


            const ticketTypeFields = {} as IRequestTypes;
            for (let i = 0; i < resourceResponse.data.results.length; i++) {
              const id = resourceResponse.data.results[i].objectId;
              const requestTypePermission = resourceResponse.data.results[i].permissionCodes[0]; // it's always one item because of param above: flattenResults: true

              if (!(requestTypePermission in ticketTypeFields)) {
                ticketTypeFields[requestTypePermission] = {};
              }

              if (!(id in ticketTypeFields[requestTypePermission])) {
                ticketTypeFields[requestTypePermission][id] = {
                  projectNumber: resourceResponse.data.results[i].fields["Project Number"],
                  projectName: resourceResponse.data.results[i].displayName,
                  websites: {}
                };
              }

              const websiteUrl = resourceResponse.data.results[i].fields["Website Url"] || kDefaultSelectionName;

              if (!(websiteUrl in ticketTypeFields[requestTypePermission][id].websites)) {
                ticketTypeFields[requestTypePermission][id].websites[websiteUrl] = [];
              }

            }

            if (!valueInfo.lookupValues) {
              return Promise.resolve({ ...valueInfo, lookupValues: { ticketTypeFields: ticketTypeFields } });
            }
            return {
              ...valueInfo,
              lookupValues: { ...valueInfo.lookupValues, ticketTypeFields: ticketTypeFields }
            };
          } catch {
            return { ...valueInfo, lookupValues: { ...valueInfo.lookupValues } };
          }
        },
        DataConverter: (dataItems: any, fieldValues?: IFieldValueLookup) => {
          const projectList = dataItems.map((item: any) => {
            const text = item.fields["Project Number"] ? `${item.displayName} (${item.fields["Project Number"]})` : item.displayName;
            return {
              text,
              id: item.objectId,
              data: {
                lookupValues: {
                  "ProjectNumber": item.fields["Project Number"],
                  "ProjectName": item.fields["Name"],
                  "IsDummy": item.fields["IsDummy"] || false,
                  "Permission": item.permissionCodes && item.permissionCodes.length ? item.permissionCodes[0] : null
                }
              }
            };
          });

          const hasDummyProject = (projectList && projectList.length > 0 && projectList[0].data.lookupValues["IsDummy"] === true) ? true : false;

          //--- hiding internal project for managerelativity users
          if (hasDummyProject && fieldValues["1.1"].value === TicketTypes.ManageRelativityUsers) {
            projectList.shift();
          }

          const allowNoAssociatedProject = fieldValues
            && fieldValues["1.0.4"].value === 1 //---- epiq employee
            && (fieldValues["1.1"].value === TicketTypes.TechnicalSupport || fieldValues["1.1"].value === TicketTypes.DRSITServices || fieldValues["1.1"].value === TicketTypes.GoAnywhereSupport) //--- general ticket or drsit or goanywhere
            && !hasDummyProject //--- not a dummy project
            && projectList;

          if (allowNoAssociatedProject) {
            projectList.unshift({ text: "No associated project", id: -1, data: { lookupValues: { IsDummy: true, Permission: "SubmitTechnicalTicket" } } });
          }
          return projectList;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return {
            skip: 0,
            take: 100,
            typeCode: "ediscoveryproject",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "SalesForceId",
            additionalQueries: ["RelativityProjects"],
            orderBy: "Name",
            filters: searchText ? [{
              logic: "or",
              filters: [{ field: "DisplayName", operator: "contains", value: searchText },
              { field: "Description", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }] as Array<CompositeFilterDescriptor> : null,

            permissionsDelimited: getRequestPermission(fieldValues, "1.1")
          };
        }
      },
      {
        type: "dropdown",
        fieldId: "1.3",
        name: "Application URL",
        label: "Select the application URL related to the request.",
        labelCaption: "If multiple URLs appear, choose the appropriate URL for this ticket.",
        tooltipTitle: "Select the application URL related to the request.",
        hideFieldIdLabel: true,
        hideSelectOne: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1.2", compare: "neq", value: null },
            { fieldId: "1.2", compare: "neq", value: -1 },
            { fieldId: "1.1", compare: "neq", value: TicketTypes.GoAnywhereSupport }
          ]
        },
        resetForField: "1.2",
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          const ticketTypeFields = fieldValues["1.2"].lookupValues && fieldValues["1.2"].lookupValues["ticketTypeFields"] as IRequestTypes;

          if (ticketTypeFields) {
            const projectId = fieldValues["1.2"].value as number;
            const requestTypePermission = getRequestPermission(fieldValues, "1.1");

            if (requestTypePermission && ticketTypeFields[requestTypePermission]) {
              let websitesSource = Object.keys(ticketTypeFields[requestTypePermission][projectId].websites)
              // Get default to beginning, whether it exists or not
              const defaultIndex = websitesSource.indexOf(kDefaultSelectionName);
              if (defaultIndex > 0) {
                websitesSource = websitesSource.filter(item => item !== kDefaultSelectionName);
              }
              if (defaultIndex !== 0) {
                websitesSource.unshift(kDefaultSelectionName);
              }

              const autoSelected = websitesSource[websitesSource.length === 2 ? 1 : 0]; // only auto select if one non-default

              const websitesDropdown = websitesSource.slice(0, kMaxDropDownLength)
              const websites = websitesDropdown.map(item => { const dropdownItem = { id: item, title: item, selected: (autoSelected === item) } as IWizardDropDownListItem; return dropdownItem; });
              return websites;
            }

            return null;
          }

        }
      },
      {
        type: "input",
        fieldId: "1.3b",
        name: "Application URL",
        label: "Provide the application URL related to the request.",
        tooltipTitle: "Provide the application URL related to the request.",
        placeholder: "https://example.epiqglobal.com",
        hideFieldIdLabel: true,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "1.2", compare: "neq", value: null},
                { fieldId: "1.2", compare: "eq", value: -1 },
                { fieldId: "1.1", compare: "neq", value: TicketTypes.GoAnywhereSupport }
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "1.2", compare: "neq", value: null },
                { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport }
              ]
            },
          ],
        },
        resetForField: "1.2",
        maxLength: 2048
      },
      {
        type: "singleselect",
        fieldId: "1.4",
        name: "Workspace",
        label: "Select the workspace name.",
        tooltipTitle: "Select the name of the workspace or database if applicable.",
        hideFieldIdLabel: true,
        hideSelectOne: true,
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "1.1", compare: "neq", value: null },
            { fieldId: "1.2", compare: "neq", value: null },
            { fieldId: "1.3", compare: "neq", value: null },
            { fieldId: "1.1", compare: "neq", value: TicketTypes.GoAnywhereSupport }
          ]
        },
        resetForField: "1.2,1.3",
        AutoSelectPreference: "firstOrSecond",
        resetToPreSelectChangeField: "1.3",
        DataConverter: (dataItems: any, fieldValues: IFieldValueLookup) => {
          let hosts = [] as IHostInfo[];
          let dropDownListItems = [] as IWizardDropDownListItem[];

          dataItems && dataItems.forEach((dbHost: any, index: number) => {

            let eqdWorkspace = dbHost.fields["EqD Workspace Name"] ? dbHost.fields["EqD Workspace Name"] + "" + (dbHost.fields["EqD Database Name"] ? " - " + dbHost.fields["EqD Database Name"] : "") : dbHost.fields["EqD Database Name"];
            if (
              !hosts.some(
                f =>
                  (f["relativityHostId"] &&
                    f["relativityHostId"] === dbHost.fields["Relativity Host Id"]) ||
                  (f["databaseName"] && (f["databaseName"] === dbHost.fields["Database Name"])) ||
                  (f["eqdWorkspaceName"] && (f["eqdWorkspaceName"] === eqdWorkspace))
              )
            ) {
              hosts.push({
                relativityHostId: dbHost.fields["Relativity Host Id"],
                relativityHost: dbHost.fields["Relativity Host"],
                dbRecordType: dbHost.fields["Db Record Type"],
                databaseName: dbHost.fields["Database Name"],
                eqdWorkspaceName: eqdWorkspace
              });

              if (dbHost.fields["Relativity Host"] || dbHost.fields["Database Name"] || eqdWorkspace || dbHost.fields["Db Record Type"] || dbHost.fields["Relativity Host Id"]) {
                dropDownListItems.push({
                  title: dbHost.fields["Relativity Host"] || dbHost.fields["Database Name"] || eqdWorkspace || dbHost.fields["Db Record Type"] || dbHost.fields["Relativity Host Id"],
                  id: index,
                  lookupValues: {
                    "RelativityHost": dbHost.fields["Relativity Host"], "DatabaseName": dbHost.fields["Database Name"],
                    "EqdWorkspaceName": eqdWorkspace,
                    "DbRecordType": dbHost.fields["Db Record Type"], "RelativityHostId": dbHost.fields["Relativity Host Id"]
                  }
                });
              }
            }
          });

          const sortedHosts = CommonHelper.sortArrayObjectByString(dropDownListItems, "title");
          sortedHosts.unshift({ title: kDefaultSelectionName, id: kDefaultSelectionName });

          return sortedHosts.map((item) => {
            return {
              text: item.title,
              id: item.id,
              data: {
                lookupValues: item.lookupValues
              }
            }
          });
        },
        GetData: ResourceService.getResourcesUserWithDefaultEmpty,
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const typePermision = getRequestPermission(fieldValues, "1.1");
          const projectNumber = (fieldValues["1.2"].lookupValues && fieldValues["1.2"].lookupValues["ProjectNumber"]) ? fieldValues["1.2"].lookupValues["ProjectNumber"].toString() : "";
          const websiteUrl = fieldValues["1.3"].value as string;

          const compFilters = [{
            logic: "or",
            filters: [{ field: "DisplayName", operator: "contains", value: projectNumber },
            { field: "Description", operator: "contains", value: projectNumber }
            ] as Array<FilterDescriptor>
          }] as Array<CompositeFilterDescriptor>;

          if (websiteUrl) {
            compFilters.push({
              logic: "or",
              filters: [{ field: "Website Url", operator: "eq", value: websiteUrl !== kDefaultSelectionName ? websiteUrl : "" }
              ] as Array<FilterDescriptor>
            });
          }

          if (searchText) {
            compFilters.push({
              logic: "or",
              filters: [
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Workspace Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText },
                { field: "Relativity Host Id", operator: "contains", value: searchText }
              ]
            });
          }

          const resourcesRequestData: IResourceParams = {
            skip: 0,
            take: 100,
            typeCode: "ediscoveryproject",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            joinFields: ["ApplicationDatabases"],
            additionalQueries: ["RelativityProjects"],
            filters: compFilters,
            permissionsDelimited: typePermision,
          };
          return resourcesRequestData;
        }
      },
      {
        type: "dropdown",
        name: "Application Name",
        fieldId: "1.5",
        resetForField: "1.3",
        hideFieldIdLabel: true,
        active: false,
        label:
          "Select Application",
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "1.1.1", compare: "eq", value: 1 }, { fieldId: "1.2", compare: "neq", value: null }, { fieldId: "1.3", compare: "neq", value: null }]
        },
        requiredWhenActive: true,
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          const appResult = fieldValues["applications"] && fieldValues["applications"].lookupValues && fieldValues["applications"].lookupValues["apps"] as Array<Application>;
          const apps = [] as IWizardDropDownListItem[];

          if (appResult && appResult.length > 0) {
            appResult.forEach(item => {
              apps.push({
                title: item.name,
                id: item.id,
                lookupValues: {
                  "Url": item.url
                }
              });
            });

            if (apps.length === 1) {
              apps.map(x => { x.selected = x.id === apps[0].id; return x; });
            }

            return apps;
          }

          return [];
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "applications",
        name: "Applications",
        lookupCompletion: (dataResult: any) => {
          return {
            success: true,
            lookupValues: { "apps": dataResult && dataResult.results.length > 0 ? dataResult.results : null }
          };
        },
        lookupError:
          "Error determining applications for this project. Please contact your client service representative.",
        watchField: "1.3",
        resetForField: "1.3",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "1.1.1", compare: "eq", value: 1 }, { fieldId: "1.3", compare: "neq", value: null }]
        },
        GetData: AdminService.getApplicationsList,
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "and",
              filters: [{ field: "Url", operator: "eq", value: fieldValues["1.3"].value }] as Array<FilterDescriptor>
            },
            {
              logic: "and",
              filters: [{ field: "statusId", operator: "neq", value: ApplicationStatus.deactivated }] as Array<FilterDescriptor>
            },
            {
              logic: "and",
              filters: [{ field: "statusId", operator: "neq", value: ApplicationStatus.deleted }] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return { filters };
        }
      },
    ],
    header: "New Support Request",
    subHeader: "WELCOME TO TECHNICAL SUPPORT",
    description: "To help us address and route your technical support ticket, select the options that best match your needs. Provide the project name and verify related information as needed to continue.",
    summaryTitle: "REQUEST",
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "1.1", compare: "neq", value: null },
        { fieldId: "1.1", compare: "neq", value: "" },
        { fieldId: "1.2", compare: "neq", value: null },
        { fieldId: "1.2", compare: "neq", value: "" },
        {
          logic: "or",
          subConditions: [
            { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport },
            {
              logic: "and",
              subConditions: [{ fieldId: "1.3", compare: "neq", value: null },
                { fieldId: "1.4", compare: "neq", value: null },
                { fieldId: "1.1", compare: "neq", value: TicketTypes.GoAnywhereSupport }
              ]
            },
            {
              logic: "and",
              subConditions: [{ fieldId: "1.3b", compare: "neq", value: null },
                { fieldId: "1.3b", compare: "neq", value: "" },
                { fieldId: "1.1", compare: "neq", value: TicketTypes.GoAnywhereSupport }
              ],
            }
          ]
        }
      ]
    }
  },
  {
    fields: [
      {
        type: "dropdown",
        fieldId: "2.1.1",
        name: "Issue",
        label: "Select the option that best represents the issue",
        active: false,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        hideSelectOne: false,
        resetForField: "1.2",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration },
            { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport }
          ]
        },
        getDropdownOptions: (fieldValues: IFieldValueLookup) => {
          if (fieldValues["1.1"] && fieldValues["1.1"].value) {
            return issueTypesConfig[fieldValues["1.1"].value.toString()].issues;
          }
        }
      },
      {
        type: "textarea",
        maxLength: 100,
        resetForField: "2.1.1",
        fieldId: "2.1.2",
        label: "Provide the Document ID of the document.",
        name: "Document ID",
        showCharacterCount: false,
        hideFieldIdLabel: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "2.1.1", compare: "eq", value: 4 },
            { fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration }
          ]
        },
        requiredWhenActive: true,
        textAreaRows: 1,
      },
      {
        type: "textarea",
        maxLength: 100,
        resetForField: "2.1.1",
        fieldId: "2.1.3",
        label: "Provide the saved search name.",
        name: "Search Name",
        showCharacterCount: false,
        hideFieldIdLabel: true,
        dependency: { fieldId: "2.1.1", compare: "eq", value: 5 },
        requiredWhenActive: true,
        textAreaRows: 1,
      },
      {
        type: "textarea",
        maxLength: 130,
        resetForField: "2.1.1",
        fieldId: "2.1.6",
        label: "Add a short description of the situation, incident, or request.",
        name: "Summary",
        showCharacterCount: true,
        hideFieldIdLabel: true,
        dependency: {
          logic: "or",
          subConditions: [
            {
              logic: "and",
              subConditions: [
                { fieldId: "2.1.1", compare: "neq", value: null },
                { fieldId: "2.1.1", compare: "eq", value: 6 },
                { fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration }
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2.1.1", compare: "neq", value: null },
                { fieldId: "2.1.1", compare: "eq", value: 4 },
                { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport }
              ]
            }
          ]
        },
        requiredWhenActive: true,
        textAreaRows: 1,
      },
      {
        type: "textarea",
        label: "Add a full description of the situation, incident, or request.",
        name: "Description",
        requiredWhenActive: true,
        fieldId: "2.1.4",
        hideFieldIdLabel: true,
        textAreaRows: 5,
        resetForField: "1.2",
        cssName: () => "techsupport-full-description info-text",
        tooltipTitle: "Provide context about the situation, including all impacted projects, URLs, and workspaces.",
        fieldAdditionalNotes:"Upload screenshots of any error messages or impacted pages on the next page.",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "2.1.1", compare: "eq", value: 1 },
            { fieldId: "2.1.1", compare: "eq", value: 2 },
            { fieldId: "2.1.1", compare: "eq", value: 3 },
            { fieldId: "2.1.1", compare: "eq", value: 6 },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2.1.6", compare: "neq", value: null },
                { fieldId: "2.1.1", compare: "eq", value: 6 },
                { fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration }
              ]
            },
            {
              logic: "and",
              subConditions: [
                { fieldId: "2.1.6", compare: "neq", value: null },
                { fieldId: "2.1.1", compare: "eq", value: 4 },
                { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport }
              ]
            }
          ]
        },
      },
      {
        type: "textarea",
        label: "Add a full description of the situation, incident, or request.",
        name: "Description",
        fieldId: "2.1.5",
        hideFieldIdLabel: true,
        textAreaRows: 5,
        cssName: () => "techsupport-full-description",
        tooltipTitle: "Provide context about the situation, including all impacted projects, URLs, and workspaces.",
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "2.1.2", compare: "neq", value: null },
            { fieldId: "2.1.3", compare: "neq", value: null },
          ]
        },
      }
    ],
    header: "Support: [`fieldLookup:1.1``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:1.1``lookupName:subHeader`]",
    description: (fieldValues?: IFieldValueLookup) => {
      const appName = fieldValues["1.1"] && fieldValues["1.1"].value === TicketTypes.GoAnywhereSupport ? "GoAnywhere" : "Relativity";
      return `To help us resolve this issue, provide details about your ${appName} experience. Select one of the choices from the following list to help us focus on the type of issue. Then, provide additional information to describe what you see, including any error messages.`
    },
    summaryTitle: "[`fieldLookup:1.1``lookupName:subHeader`]",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration },
        { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport }
      ]
    },
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "2.1.1", compare: "neq", value: null },
        { fieldId: "2.1.1", compare: "neq", value: "" }
      ]
    }
  },
  {
    fields: [
      {
        type: "textarea",
        maxLength: 130,
        fieldId: "2.1",
        label: "Provide a brief summary of the support needed.",
        requiredWhenActive: true,
        name: "Brief Summary",
        showCharacterCount: true,
        hideFieldIdLabel: true,
        active: true,
        tooltipTitle: "Briefly describe the request.",
        resetForField: "1.1",
        cssName: () => "techsupport-short-description",
        textAreaRows: 1,
        defaultValueFunc: (existingFieldVals?: IFieldValueLookup) => {
          if (existingFieldVals["1.1"].value === TicketTypes.AccountAdministration) {
            return "Account Administration";
          }
          else {
            return "";
          }
        }
      },
      {
        type: "textarea",
        label: "Add a full description of the situation, incident, or request.",
        name: "Description",
        fieldId: "2.2",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "2.1", compare: "neq", value: null },
            { fieldId: "2.1", compare: "neq", value: "" }
          ]
        },
        textAreaRows: 8,
        hasMarkdown: true,
        cssName: () => "techsupport-full-description",
        tooltipTitle: "Provide context about the situation, including all impacted projects, URLs, and workspaces."
      }
    ],
    header: "Support: [`fieldLookup:1.1``lookupName:databaseHeader`]",
    subHeader: "DESCRIBE THE ISSUE",
    description: "To help us resolve this issue, describe the issue or assistance you need. First, summarize the issue using 1-2 lines. Similar to a subject line in email, this helps us target your request. Then, describe the situation, incident, or request by adding any pertinent details.",
    summaryTitle: "DESCRIPTION",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "1.1", compare: "eq", value: TicketTypes.TechnicalSupport },
        { fieldId: "1.1", compare: "eq", value: TicketTypes.DRSITServices }
      ]
    },
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "2.1", compare: "neq", value: null },
        { fieldId: "2.1", compare: "neq", value: "" },
        { fieldId: "2.2", compare: "neq", value: null },
        { fieldId: "2.2", compare: "neq", value: "" }
      ]
    }
  },
  {
    fields: [
      {
        type: "fileupload",
        label: "Upload screenshots (error messages or impacted pages).",
        name: "Uploaded files",
        labelCaption: "Drop or select your files and click UPLOAD. Then, click NEXT.",
        fieldId: "2.4",
        hideFieldIdLabel: true,
        active: true,
        multiple: true,
        restrictAutoUpload: true,
        maxFileSize: 10485760, //10MB as bytes
        noInputText: "None"
      },
      {
        type: "sliderField",
        label: "How does the issue impact my work?",
        labelCaption: "Move the slider to represent the impact.",
        name: "Impact of Issue",
        fieldId: "2.3",
        requiredWhenActive: false,
        hideFieldIdLabel: true,
        active: true,
        tooltipTitle: "How does the issue impact my work?",
        cssName: () => "tech-support-wizard-slider",
        radioButtonOptions: [
          {
            id: 1,
            title: "Standard",
            selected: true,
            lookupValues: { description: "General support priority" }
          },
          {
            id: 2,
            title: "Affected",
            lookupValues: { description: "Some tasks affected" }
          },
          {
            id: 3,
            title: "Highly Affected",
            lookupValues: { description: "Daily tasks affected" }
          },
          {
            id: 4,
            title: "Blocked",
            lookupValues: { description: "Work is blocked" }
          }
        ]
      }
    ],
    header: "Support: [`fieldLookup:1.1``lookupName:databaseHeader`]",
    subHeader: "ADD MORE CONTEXT",
    description: "Upload files, such as screenshots of error messages or impacted screens, to help us assess the issue. It is also important that we understand how the situation, incident, or request is affecting your daily work. Move the slider from left to right to represent the impact.",
    summaryTitle: "MORE CONTEXT",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "1.1", compare: "eq", value: TicketTypes.AccountAdministration },
        { fieldId: "1.1", compare: "eq", value: TicketTypes.TechnicalSupport },
        { fieldId: "1.1", compare: "eq", value: TicketTypes.DRSITServices },
        { fieldId: "1.1", compare: "eq", value: TicketTypes.GoAnywhereSupport }
      ]
    },
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "2.3", compare: "neq", value: null },
        { fieldId: "2.3", compare: "neq", value: "" }
      ]
    },
  },

  {
    fields: [
      {
        type: "radiogroup",
        label: "Select the user type to which you want to provide Relativity access.",
        name: "User Type",
        fieldId: "4.1",
        hideFieldIdLabel: true,
        resetForField: "1.1",
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers }]
        },
        radioButtonOptions: [
          {
            id: 1,
            title: "Epiq Employee"
          },
          {
            id: 2,
            title:
              "Epiq DRS Employee"
          },
          {
            id: 3,
            title:
              "Client User"
          }
        ]
      },
      {
        type: "radiogroup",
        label: "Do you want to automatically send Epiq Access invitation emails to these users?",
        name: "Invitation Email",
        fieldId: "4.2",
        resetForField: "4.1",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        active: false,
        dependency: { fieldId: "4.1", compare: "eq", value: 3 },
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes, send invitations to the users"
          },
          {
            id: 2,
            title:
              "No invitations needed, notify me instead "
          }
        ]
      }
    ],
    header: "Support: Provide Relativity access to users",
    subHeader: "Relativity User Type ",
    description: "With this request, you can provide Relativity access to users of the same user type. In addition, designate whether to automatically send email invitations to these users.",
    summaryTitle: "Relativity User Type",
    dependency: { fieldId: "1.1.1", compare: "eq", value: 1 },
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "4.1", compare: "neq", value: null }
      ]
    }
  },
  {
    fields: [
      {
        type: "radiogroup",
        label: (fieldValues: IFieldValueLookup) => {
          if (fieldValues && fieldValues["1.1.1"].value === 2) {
            return "How do you want to provide the users to remove?";
          }
          return "How do you want to add new users ?";
        },
        name: "Input Type",
        fieldId: "9.1",
        hideFieldIdLabel: true,
        active: true,
        requiredWhenActive: true,
        resetForField: "1.1",
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers }]
        },
        radioButtonOptions: [
          {
            id: 1,
            title: "Manually enter users"
          },
          {
            id: 2,
            title:
              "Upload multiple users via file"
          }
        ]
      },
      {
        type: "downLoadExcelTemplate",
        label: "Download Template",
        name: "Download Excel",
        fieldId: "9.2",
        resetForField: "9.1",
        hideFieldInSummary: true,
        hideFieldIdLabel: true,
        active: false,
        addButtonLabel: "Download Template",
        description: "Enter a maximum of 50 users, one per row with last name, first name, username and email. Then, save the users list file.",
        exportExcelSettings: (dataItems: any) => {
          const exportItems = {
            fileName: "Relativity User Import Template",
            fields: [
              { field: "firstName", title: "FirstName" },
              { field: "lastName", title: "LastName" },
              { field: "username", title: "Username" },
              { field: "email", title: "Email" },
            ]
          }
          return exportItems;
        },
        dependency: { fieldId: "9.1", compare: "eq", value: 2 }
      },
      {
        type: "fileupload",
        name: "RelativityBulkUserImport",
        fieldId: "9.3",
        hideFieldIdLabel: true,
        resetForField: "1.2, 9.1",
        label: "Upload bulk import users file.",
        active: false,
        requiredWhenActive: true,
        restrictAutoUpload: true,
        hideFieldInSummary: true,
        showSupportedFileTypesString: true,
        multiple: false,
        bulkImportMode: true,
        allowedFileExtensions: ["xls", "xlsx"],
        maxFileSize: 10485760, //10MB as bytes
        dependency: { fieldId: "9.1", compare: "eq", value: 2 }
      }
    ],
    header: (fieldValues: IFieldValueLookup) => {
      if (fieldValues && fieldValues["1.1.1"].value === 2) {
        return "Support: Remove users from a Relativity Group";
      }
      return "Support: Provide Relativity access to users";
    },
    subHeader: "USER SPECIFICATION METHOD",
    description: (fieldValues: IFieldValueLookup) => {
      if (fieldValues && fieldValues["1.1.1"].value === 2) {
        return "Choose how you want to specify the users whose Relativity access you want to remove. You can manually enter them or bulk enter users from a file, using the provided template.";
      }
      return "Choose how you want to enter users.You can manually add them or bulk import users from a file, using the provided template.";
    },
    summaryTitle: "User specification method",
    dependency: {
      logic: "and",
      subConditions: [{ fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers }]
    },
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "9.1", compare: "eq", value: 1 },
        {
          logic: "and",
          subConditions: [
            { fieldId: "9.1", compare: "neq", value: null },
            { fieldId: "9.3", compare: "neq", value: null }
          ]
        }
      ]
    }
  },
  {
    fields: [
      {
        type: "editGridField",
        fieldId: "5.1",
        resetForField: "1.2,9.1,9.3",
        name: "Users",
        label: (fieldValues: IFieldValueLookup) => {
          if (fieldValues && fieldValues["1.1.1"].value === 2) {
            return "Supply the users to remove.";
          }
          return "Supply the users to add.";
        },
        columns: [
          { field: "firstName", title: "First Name", editor: "text", validations: [{ name: "required", errorMessage: "First Name is required." }], placeHolder: "Enter first name" },
          { field: "lastName", title: "Last Name", editor: "text", validations: [{ name: "required", errorMessage: "Last Name is required." }], placeHolder: "Enter last name" },
          { field: "username", title: "Username / UPN", editor: "text", validations: [{ name: "required", errorMessage: "Username is required." }, { name: "customUserName", errorMessage: "Enter proper Email Id" }, { name: "unique", errorMessage: "User already exists with same username." }, { name: "usernameFormat", errorMessage: "Username must have '@' with something after" }], placeHolder: "Enter username", toolTipText: "To find an Epiq UPN, have user go to SharePoint, click the account icon, and give you the email address shown." },
          { field: "email", title: "Email", editor: "text", validations: [{ name: "required", errorMessage: "Email is required" }, { name: "email", errorMessage: "Enter proper Email Id" }], placeHolder: "Enter email" }
        ] as IGridDataColumns[],
        hideFieldIdLabel: true,
        maxRecordCount: 50,
        addButtonLabel: "User",
        getBulkImport: (fieldValues: IFieldValueLookup) => {
          return fieldValues["9.3"] && fieldValues["9.3"].lookupValues ? fieldValues["9.3"].lookupValues : null;
        },
        dependency: {
          logic: "or",
          subConditions: [
            { fieldId: "9.3", compare: "neq", value: null },
            { fieldId: "9.1", compare: "eq", value: 1 }
          ]
        }
      }
    ],
    header: (fieldValues: IFieldValueLookup) => {
      if (fieldValues && fieldValues["1.1.1"].value === 2) {
        return "Support: Remove users from a Relativity Group";
      }
      return "Support: Provide Relativity access to users";
    },
    subHeader: "USER INFORMATION",
    summaryTitle: "User Information",
    description: (fieldValues: IFieldValueLookup) => {
      if (fieldValues && fieldValues["1.1.1"].value === 2) {
        return "This list contains users who needs Relativity access removed. If the table is populated, review the users you uploaded. If not, type the user information, by clicking + USER to add more. For Epiq users, supply the Active Directory UPN email. The maximum number of users cannot exceed 50.";
      }
      return "This list contains users who needs Relativity access. If the table is populated, review the users you uploaded. If not, type the user information, by clicking + USER to add more. For Epiq users, supply the Active Directory UPN email. The maximum number of users cannot exceed 50.";
    },
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers }
      ]
    },
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "5.1", compare: "neq", value: null },
        { fieldId: "5.1", compare: "neq", value: [] }
      ]
    }
  },
  {
    fields: [
      {
        type: "radiogroup",
        label: "Select the user type to which you want to provide Relativity access.",
        name: "Group Request Type",
        fieldId: "6.1",
        hideFieldIdLabel: true,
        active: true,
        requiredWhenActive: true,
        dependency: { fieldId: "1.1.1", compare: "eq", value: 1 },
        resetForField: "1.1.1",
        radioButtonOptions: [
          {
            id: 1,
            title: "Add to existing Relativity group"
          },
          {
            id: 2,
            title: "Create new Relativity group"
          }
        ]
      },
      {
        type: "input",
        fieldId: "6.2",
        resetForField: "6.1",
        name: "Relativity Group Name",
        label: "Provide the new Relativity Group name.",
        labelCaption: "Group names follow the standard shown in this example: ABC0123_AD-987654 - Basic Users.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        dependency: { fieldId: "6.1", compare: "eq", value: 2 },
      },
      {
        type: "input",
        fieldId: "6.3",
        resetForField: "6.1",
        name: "Copy Permissions From",
        label: "Specify an existing group to copy permissions from.",
        labelCaption: "Enter the Relativity group name that models the permissions needed for the new group. ",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "6.1", compare: "eq", value: 2 },
          { fieldId: "6.2", compare: "neq", value: null }]
        }
      },
      {
        type: "input",
        fieldId: "6.4",
        resetForField: "6.1",
        name: "Relativity Group Name",
        label: "Provide the existing Relativity Group name.",
        labelCaption: "Enter the Relativity Group where you want these users placed.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        dependency: { fieldId: "6.1", compare: "eq", value: 1 },
      },
      {
        type: "input",
        fieldId: "6.5",
        name: "Relativity Group Name",
        label: "Provide the existing Relativity Group name.",
        labelCaption: "Enter the Relativity Group from which you want to remove the users.  \nGroup names follow the standard shown in this example: ABC0123_AD- 987654 - Basic Users.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        dependency: { fieldId: "1.1.1", compare: "eq", value: 2 },
      }
    ],
    header: "Support: Provide Relativity access to users",
    subHeader: "SPECIFY RELATIVITY GROUP",
    summaryTitle: "Relativity Group",
    description: "To provide access to the users, specify the appropriate Relativity Group or create a new Relativity Group with this request.",
    dependency: { fieldId: "1.1.1", compare: "eq", value: 1 },
  },
  {
    fields: [
      {
        type: "radiogroup",
        label: "Does this client rely on Epiq to provide multifactor authentication (MFA) to log in?",
        labelCaption: "If multi-factor authentication is required, provide the Okta Group to authenticate the new users.",
        name: "Multi-factor authenticaion",
        fieldId: "7.1",
        resetForField: "1.1",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes, this client requires MFA for some or all users."
          },
          {
            id: 2,
            title:
              "No known MFA requirements."
          }
        ]
      },
      {
        type: "singleselect",
        fieldId: "7.2",
        resetForField: "7.1",
        name: "Okta Group",
        label: "To enable multi-factor authentication, select the existing Okta Group.",
        labelCaption: "If multi-factor authentication is required, choose the client-specific Okta Group.",
        hideFieldIdLabel: true,
        active: false,
        tooltipTitle: "Select Okta Group.",
        requiredWhenActive: true,
        dependency: { fieldId: "7.1", compare: "eq", value: 1 },
        GetData: UserService.getOktaGroups,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const text = `${item.profile.name}`;
            return {
              text,
              id: item.id,
              data: {
                lookupValues: {
                  "groupName": item.profile.name,
                  "groupId": item.id
                }
              }
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return {
            take: 100,
            groupName: searchText
          };
        }
      }
    ],
    header: "Support: Provide Relativity access to users",
    subHeader: "SELECT OKTA GROUP",
    summaryTitle: "Okta Group",
    description: "Some clients use Epiq multi-factor authentication (MFA) as part of their login process for specific users, like outside counsel, or all users. If you are aware that this client requires MFA for these users, provide that client-specific Okta Group.",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "1.1.1", compare: "eq", value: 1 },
        { fieldId: "4.1", compare: "neq", value: 1 }
      ]
    }
  },
  {
    fields: [
      {
        type: "input",
        fieldId: "10.1",
        name: "Relativity Group Name",
        label: "Provide the existing Relativity Group name.",
        labelCaption: "Enter the Relativity Group from which you want to remove the users.  \nGroup names follow the standard shown in this example: ABC0123_AD- 987654 - Basic Users.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        dependency: { fieldId: "1.1.1", compare: "eq", value: 2 },
      }
    ],
    header: "Support: Remove users from a Relativity Group",
    subHeader: "SPECIFY RELATIVITY GROUP",
    summaryTitle: "Relativity Group",
    description: "To remove access from the Relativity workspace, enter the associated Relativity Group. This request only removes the users from this specific group. Membership in other groups is not affected.",
    dependency: { fieldId: "1.1.1", compare: "eq", value: 2 }
  },
  {
    fields: [
      {
        type: "input",
        fieldId: "11",
        name: "Company Name",
        label: "Enter the client’s company name.",
        hideFieldIdLabel: true,
        maxLength: 150,
        requiredWhenActive: true,
      },
      {
        type: "input",
        fieldId: "11.1",
        name: "Requestor’s Email",
        label: "Enter the requestor’s email address.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "11", compare: "neq", value: null },
            { fieldId: "11", compare: "neq", value: "" }
          ]
        },
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenIsValidEmailId",
        name: "IsValidEmail",
        lookupCompletion: (dataResult: any) => {
          const emailRegex = new RegExp(/\S+@\S+\.\S+/);
          const isValid = dataResult.email ? emailRegex.test(dataResult.email) : true;
          return {
            success: isValid,
            value: isValid
          };
        },
        lookupError:
          "You have entered an invalid email address",
        watchField: "11.1",
        label: "Is Valid Email",
        resetForField: "11.1",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "11.1", compare: "neq", value: null },
          ]
        },
        GetData: (dataResult: any) => {
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { email: fieldValues["11.1"].value };
        }
      },

      {
        type: "radiogroup",
        label: "Does this client need to restrict IP addresses?",
        name: "Restricted IP",
        fieldId: "11.2",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "1.1.1", compare: "eq", value: 1 },
            { fieldId: "11.1", compare: "neq", value: null },
            { fieldId: "11.1", compare: "neq", value: "" },
            { fieldId: "11", compare: "neq", value: null },
            { fieldId: "11", compare: "neq", value: "" },
            { fieldId: "hiddenIsValidEmailId", compare: "eq", value: true },
          ]
        },
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes",
          },
          {
            id: 2,
            title: "No",
            selected: true
          }
        ]
      },
      {
        type: "textarea",
        fieldId: "11.3",
        label: "Enter the restricted IP addresses.",
        name: "Restricted IP Addresses",
        requiredWhenActive: true,
        active: false,
        hideFieldIdLabel: true,
        dependency: { fieldId: "11.2", compare: "eq", value: 1 },
      }
    ],
    header: (fieldValues: IFieldValueLookup) => {
      if (fieldValues && fieldValues["1.1.1"].value === 2) {
        return "Support: Remove users from a Relativity Group";
      }
      return "Support: Provide Relativity access to users";
    },
    subHeader: "CLIENT AUTHORIZATION",
    summaryTitle: "Client Authorization",
    description: "Include information about the person requesting the Relativity access changes, including the client’s company name and email address.",
    dependency: { fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers }
  },
  {
    fields: [
      {
        type: "input",
        fieldId: "3.1",
        name: "First Name",
        label: "First Name",
        active: true,
        tooltipTitle: "Supply the first name of the person to contact.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        cssName: () => "half-wrap",
        summaryCssName: "summary-half-wrap"
      },
      {
        type: "input",
        fieldId: "3.2",
        name: "Last Name",
        label: "Last Name",
        active: true,
        tooltipTitle: "Supply the last name of the person to contact.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        cssName: () => "half-wrap",
        summaryCssName: "summary-half-wrap"
      },
      {
        type: "input",
        fieldId: "3.4",
        name: "Call Back Number",
        label: "Call Back Number",
        active: true,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        cssName: () => "half-wrap tech-support-callback-number",
        placeholder: "(Enter your phone number)",
        summaryCssName: "summary-half-wrap"
      },
      {
        type: "input",
        fieldId: "3.3",
        name: "Email",
        label: "Email",
        active: true,
        tooltipTitle: "Provide an email to contact.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        cssName: () => "half-wrap",
        summaryCssName: "summary-half-wrap"
      },
      {
        type: "inputChiplist",
        fieldId: "3.5",
        name: "Followers",
        label: "Add Followers to the ticket.",
        labelCaption: "Include your project team email so they can follow this ticket. For each follower, add an email and press ENTER.",
        active: true,
        tooltipTitle: "Add Followers to the ticket.",
        hideFieldIdLabel: true,
        noInputText: "Ticket has no followers."
      }
    ],
    header: (fieldValues: IFieldValueLookup) => {
      return `Support: ${fieldValues["1.1"].value === TicketTypes.ManageRelativityUsers
        ? fieldValues["1.1.1"].lookupValues["databaseHeader"]
        : fieldValues["1.1"].lookupValues["databaseHeader"]}`
    },
    subHeader: "CONTACT INFORMATION",
    description: "The following contact information is associated with your account. Update displayed information as needed. You must provide a phone number for callback purposes. You can also add email addresses for other users to follow this ticket. By following this ticket, you include them in email updates.",
    summaryTitle: "CONTACT INFORMATION",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "1.1", compare: "neq", value: null },
        { fieldId: "1.1", compare: "neq", value: "" }
      ]
    },
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "3.1", compare: "neq", value: null },
        { fieldId: "3.1", compare: "neq", value: "" },
        { fieldId: "3.2", compare: "neq", value: null },
        { fieldId: "3.2", compare: "neq", value: "" },
        { fieldId: "3.3", compare: "neq", value: null },
        { fieldId: "3.3", compare: "neq", value: "" },
        { fieldId: "3.4", compare: "neq", value: null },
        { fieldId: "3.4", compare: "neq", value: "" },
        {
          logic: "or",
          subConditions: [{ fieldId: "3.5", compare: "eq", value: "", lookupName: "inputText" },
          { fieldId: "3.5", compare: "eq", value: null, lookupName: "inputText" }]
        }
      ]
    },
  },
  {
    fields: [
      {
        type: "textarea",
        maxLength: 255,
        resetForField: "1.1",
        fieldId: "8.1",
        label: "Add your comments.",
        name: "Comments",
        showCharacterCount: true,
        hideFieldIdLabel: true,
        active: true
      },
    ],

    header: (fieldValues: IFieldValueLookup) => {
      return `Support: ${fieldValues["1.1"].value === TicketTypes.ManageRelativityUsers
        ? fieldValues["1.1.1"].lookupValues["databaseHeader"]
        : fieldValues["1.1"].lookupValues["databaseHeader"]}`
    },
    subHeader: "ADD COMMENTS",
    summaryTitle: "Comments",
    description: "Provide any additional comments or notes that you want us to know as we address your support request.",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "1.1", compare: "eq", value: TicketTypes.ManageRelativityUsers }
      ]
    }
  },

] as Array<IWizardSection>


export const createSupportRequest = function (valueDictionary: IFieldValueLookup) {
  const request = {} as IWizardRequest;

  const getFieldValue = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].value : null;
  };

  const getFieldText = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].text : null;
  };

  const lookup = function (id: string, lookup: string) {
    return valueDictionary[id] ? (valueDictionary[id].lookupValues ? valueDictionary[id].lookupValues[lookup] : null) : null;
  };

  const selSupportTypeValue = getFieldValue("1.1");
  const hasManageRelUsers = selSupportTypeValue === TicketTypes.ManageRelativityUsers;
  const websiteUrl = getFieldValue("1.3") || getFieldValue("1.3b");
  const shortDescWebURL = websiteUrl !== kDefaultSelectionName ? " - " + websiteUrl : "";

  const getManageRelUsersLongDesc = () => {
    const usersArray = getFieldValue("5.1") as User[];
    const usersList = () => {

      if (usersArray) {
        let users = "Ex:- First Name, Last Name: Username: Email \n\n";

        for (let i = 0; i < usersArray.length; i++) {
          users = users + `${usersArray[i].firstName}, ${usersArray[i].lastName}: ${usersArray[i].username}: ${usersArray[i].email}  \n`
        }
        return users;
      }

      return "";
    }

    let longDesc = `${hasManageRelUsers && lookup("1.1.1", "code") === "removerelativityaccess" ? kRemoveRelUserDescPrefix : kAddRelUserDescPrefix}  \n` +
      `Rel Site: ${websiteUrl}  \n` +
      `Rel Workspace: ${lookup("1.4", "RelativityHost") || lookup("1.4", "DatabaseName") || lookup("1.4", "EqdWorkspaceName") }`;


    const clientInfo = `Company Name: ${getFieldValue("11")}  \n` +
      `Requested By: ${getFieldValue("11.1")} \n`;

    if (getFieldValue("1.1.1") === 1) {

      const relGrpSelType = getFieldValue("6.1");
      const copyGrpPermssion = relGrpSelType === 2
        ? `Create New Rel Group: ${getFieldValue("6.2")}  \n` +
        `Copy permissions from existing Relativity Group: ${getFieldValue("6.3")}\n\n` :
        `Rel Group to add user to: ${getFieldValue("6.4")} \n`;


      longDesc = `${longDesc} \n\n` +
        `${copyGrpPermssion} ` +
        `Okta Group to add users to: ${lookup("7.2", "groupName") ? lookup("7.2", "groupName") : "No"} \n\n` +
        `Assign Epiq Access Application Name: ${getFieldText("1.5")} \n` +
        `Send Epiq Access Invite Emails ? ${!getFieldValue("4.2") || getFieldValue("4.2") === 2 ? 'No, notify requester.' : 'Yes, send Epiq Access invite emails to any new Epiq Access accounts.'} \n\n` +
        `${clientInfo}\n` +
        `Trusted IPs: ${getFieldValue("11.2") === 1 ? "Restrict Access to these IPs \n" + getFieldValue("11.3") : "No"} \n\n`;;
    }
    else {
      longDesc = `${longDesc} \n\n` +
        `Remove user from Rel Group: ${getFieldValue("10.1")} \n\n` +
        `${clientInfo}\n`;
    }

    longDesc = `${longDesc}\n` +
      `User Details  \n` +
      `${(usersArray && usersArray.length <= 5) ? usersList() : 'See Attached Import file'} \n` +
      `${getFieldValue("8.1") ? "Comments: " + getFieldValue("8.1") : ""}`;

    return longDesc.toString().trim();
  }

  const getShortDescription = () => {
    let shortDesc = ";"
    if (getFieldValue("1.1") === TicketTypes.AccountAdministration) {
      shortDesc = `${getFieldText("2.1.1")} ${shortDescWebURL} `;
      if (getFieldValue("2.1.1") == 6) {
        shortDesc = `${getFieldValue("2.1.6")} ${shortDescWebURL} `;
      }
    }
    else if (getFieldValue("1.1") === TicketTypes.GoAnywhereSupport) {
      shortDesc = `${getFieldText("2.1.1")} ${shortDescWebURL} `;
      if (getFieldValue("2.1.1") == 4) {
        shortDesc = `${getFieldText("2.1.1")} - ${getFieldValue("2.1.6")} ${shortDescWebURL} `;
      }
    }
    else if (hasManageRelUsers) {
      shortDesc = `Rel User Request - ${getFieldText("4.1") ? getFieldText("4.1") : "Remove users from a Relativity Group"} ${shortDescWebURL} `;
    }
    else {
      shortDesc = `${getFieldValue("2.1")} ${shortDescWebURL} `;
    }

    return shortDesc;
  }

  const getLongDesc = () => {
    // only for getFieldValue("1.1") === TicketTypes.AccountAdministration
    const searchName = getFieldValue("2.1.3") ? `Search Name: ${getFieldValue("2.1.3")} \n\n` : "";
    const docId = getFieldValue("2.1.2") ? `Document Id: ${getFieldValue("2.1.2")} \n\n` : "";
    const desc = (getFieldValue('2.1.1') == 4 || getFieldValue('2.1.1') == 5) && getFieldValue('2.1.5') ? getFieldValue('2.1.5') : (getFieldValue('2.1.4') ? getFieldValue('2.1.4') : "");
    const accountadministrationLongDesc = `Workspace: ${getFieldText("1.4")} \n\n ${docId} ${searchName} ${desc} `;
    const goAnywhereLongDesc = getFieldValue("2.1.4") ? getFieldValue("2.1.4") : "";
    const generalTicketLongDesc = getFieldText("1.4") === null ? `${getFieldValue("2.2")} ` : `Workspace: ${getFieldText("1.4")} \n\n ${getFieldValue("2.2")} `;

    switch (selSupportTypeValue) {
      case TicketTypes.ManageRelativityUsers:
        return getManageRelUsersLongDesc();
      case TicketTypes.AccountAdministration:
        return accountadministrationLongDesc;
      case TicketTypes.GoAnywhereSupport:
        return goAnywhereLongDesc;
      default:
        return generalTicketLongDesc;
    }
  }

  
  const longDescription = getLongDesc();

  request["ShortDescription"] = getShortDescription();
  request["ExternalEmail"] = getFieldValue("3.3") ? getFieldValue("3.3") : getFieldValue("1.0.3");
  request["Description"] = longDescription;
  request["ProjectId"] = getFieldValue("1.2");
  request["IsDummyProject"] = lookup("1.2", "IsDummy");
  request["ProjectNumber"] = lookup("1.2", "ProjectNumber");
  request["ProjectName"] = lookup("1.2", "ProjectName");
  request["CallBackNumber"] = getFieldValue("3.4");
  request["IsRemove"] = getFieldValue("1.1.1") === 2 ? true : false;

  request["WebsiteUrl"] = websiteUrl;

  request["DatabaseName"] = lookup("1.4", "DatabaseName") || lookup("1.4", "EqdWorkspaceName");
  request["RelativityHostId"] = lookup("1.4", "RelativityHostId");
  request["RelativityHost"] = lookup("1.4", "RelativityHost");
  request["DbRecordType"] = lookup("1.4", "DbRecordType");

  request["WorkImpact"] = getFieldText("2.3");
  request["ExternalWatchList"] = getFieldValue("3.5");
  request["RequestTypeCode"] = hasManageRelUsers ? lookup("1.1.1", "code") : lookup("1.1", "code");
  request["RequestTypeName"] = hasManageRelUsers ? getFieldText("1.1.1") : getFieldText("1.1");
  request["RelativityUserGroupName"] = getFieldValue("1.1.1") === 1 ? (getFieldValue("6.1") === 2 ? getFieldValue("6.2") : getFieldValue("6.4")) : getFieldValue("10.1");
  request["OktaGroupName"] = lookup("7.2", "groupName");
  request["OktaGroupId"] = lookup("7.2", "groupId");
  request["UserType"] = getFieldText("4.1");
  request["SendEAInvitationMailToUsers"] = getFieldValue("4.2") == 1 ? "YES" : "NO";
  request["ImportUsers"] = getFieldValue("5.1");
  request["ApplicationName"] = getFieldText("1.5");

  request["CompanyName"] = getFieldValue("11");
  request["RequestorEmail"] = getFieldValue("11.1");
  request["RestrictedIPAddresses"] = getFieldValue("11.3");
  
 request["IsExistingRelativityUserGroupName"] = getFieldValue("6.1") === 1 ? 1 : 0;
 request["IsMultiFactorAuthenticaion"] = getFieldValue("7.1") === 1 ? 1 : 0;
 
 request["Comments"] = getFieldValue("8.1");

  if (hasManageRelUsers) {
    request["AssignmentGroup"] = "GSS Express";
    request["Category"] = "Account Admin";
  }

  if (selSupportTypeValue === TicketTypes.AccountAdministration) {
    switch (getFieldValue("2.1.1")) {
      case 1:
        request["Category"] = "Account Admin";
        request["SubCategory"] = "";
        break;
      case 2:
        request["Category"] = "Application";
        request["SubCategory"] = "Performance Issue";
        break;
      default:
        request["Category"] = "Application";
        request["SubCategory"] = "Application Usage Related";
        break;
    }

    request["Issue"] = getFieldText("2.1.1");

  } else if (selSupportTypeValue === TicketTypes.DRSITServices) {
    request["AssignmentGroup"] = "DRSITSvcs";
    request["Category"] = "Corp IT";
    request["SubCategory"] = "VDI Azure";
  }
  else if (selSupportTypeValue === TicketTypes.GoAnywhereSupport) {
    switch (getFieldValue("2.1.1")) {
      case 1:
        request["Category"] = "Account Admin";
        request["SubCategory"] = "Permissions";
        request["AssignmentGroup"] = "GSS Express";
        break;
      default:
        request["Category"] = "Application";
        request["SubCategory"] = "Application Alerts/Errors";
        request["AssignmentGroup"] = "GSS Transfer";
        break;
    }
  }

  if (getFieldValue("6.1") === 2) {
    request["CopyPermissionsFromExistingRelGroup"] = getFieldValue("6.3");
  }

  return request;
};

export const wizardConfirmSubmitMessage: IWizardWarningMessage = {
  title: "Submit Technical Support",
  getDescription: (existingFieldVals: IFieldValueLookup) => {
    let confirmMsgs = new Array<string>();
    confirmMsgs.push('Confirm that you reviewed the Support Ticket Summary and want to submit this ticket.');
    return confirmMsgs;
  }
};


export const wizardSummary: IWizardSummary = {
  title: "SUPPORT TICKET SUMMARY",
  canDownload: (request: IWizardRequest) => {
    return request != null && request["RequestTypeCode"] === "relativityaccess" || request["RequestTypeCode"] === "removerelativityaccess";
  },
  description:
    "This summary shows the selections you made for this support ticket. Please review your selections. To make changes, click the Edit pencil to return to a section. When you are satisfied with this summary, submit your ticket.",
};


export const warningMessage: IWizardWarningMessage = {
  title: "Cancel the technical support?",
  description:
    "Closing the Technical Support Wizard removes any selections you made. Do you want to cancel this technical support?"
};

enum SupportJobStates {
  Support_TicketReady = 59,
  Support_SubmittingTicket = 60,
  Support_SubmittingTicketFailed = 61,
  Support_FilesUploaded = 62,
  Support_FileUploadFailed = 63,
  Support_TicketSubmittedSuccessfully = 64,
  Support_FilesSubmittedSuccessly = 65,
  Support_SubmittingTicketUpdateFilesFailed = 68,
  Support_TicketUpdateFilesSubmittedSuccessfully = 69,
  Support_FilesPendingTicketSubmittedSuccessfully = 70,
  Approval_Ready = 96,
  Approval_Progress = 100
}

export const jobMessagingConfig: JobMessagingConfig = {
  genericErrorMessage: "New request data submission failed.",
  genericSuccessMessage: "New request data submitted successfully.",
  approvalPendingMessage: "Ticket pending approval.",
  specialStatusCases: {
    statusTypes: {
      [SupportJobStates.Support_TicketReady]: { keepChecking: true },
      [SupportJobStates.Support_SubmittingTicket]: { keepChecking: true },
      [SupportJobStates.Support_FilesUploaded]: { keepChecking: true },
      [SupportJobStates.Support_TicketUpdateFilesSubmittedSuccessfully]: {
        degree: "success",
        message: "New request, file upload successful."
      },
      [SupportJobStates.Support_FilesPendingTicketSubmittedSuccessfully]: {
        degree: "info",
        message: "New request submitted, awaiting file upload.",
        keepChecking: true
      },
      [SupportJobStates.Support_FileUploadFailed]: {
        degree: "error",
        message: "New request, file upload failed."
      },
      [SupportJobStates.Support_SubmittingTicketUpdateFilesFailed]: {
        degree: "error",
        message: "New request, file upload failed."
      }
    }
  }
};

const getRequestPermission = function (fieldValues: IFieldValueLookup, fieldName: string) {
  if (!fieldValues || !fieldValues[fieldName] || !fieldValues[fieldName].lookupValues || !fieldValues[fieldName].lookupValues["permission"]) {
    return "SubmitTechnicalTicket";
  }

  return fieldValues[fieldName].lookupValues["permission"].toString();
}