import { IApplicationsState, reducer as applicationReducer } from "./Applications";
import { IHeaderState, reducer as headerReducer } from "./Header";
import { IUsersState, reducer as userReducer } from "./Users";
import { IAuthenticationState, reducer as authenticationReducer } from "./Authentication";
import { ISessionState, reducer as sessionReducer } from "./Session";
import { IUserActivityState, userActivityreducer } from "./UserActivity";
import { IReportState, reducer as reportReduer } from "./Report";
import { IJobState, reducer as jobReducer } from "./Jobs";
import { IUserGroupState, reducer as userGroupreducer } from "./UserGroup";
import { IRoleState, reducer as roleReducer } from "./Role";
import { IWarningMessageState, reducer as warningMessageReducer } from "./WarningMessage";
import { IAppStatusState, reducer as appStatusReducer } from "./AppStatus";
import { INotificationMessageState, reducer as notificationMessageReducer } from "./NotificationMessages";
import { IMainDrawerState, reducer as mainDrawerReducer } from "./MainDrawer";
// The top-level state object
export interface IApplicationState {
  applicationsState: IApplicationsState | undefined;
  authenticationState: IAuthenticationState | undefined;
  headerState: IHeaderState | undefined;
  jobState: IJobState | undefined;
  userState: IUsersState | undefined;
  userGroupState: IUserGroupState | undefined;
  sessionState: ISessionState | undefined;
  userActivityState: IUserActivityState | undefined;
  warningMessageState: IWarningMessageState | undefined;
  reportState: IReportState | undefined;
  roleState: IRoleState | undefined;
  appStatus: IAppStatusState | undefined;
  notificationMessageState: INotificationMessageState | undefined;
  mainDrawerState: IMainDrawerState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  applicationsState: applicationReducer,
  headerState: headerReducer,
  userState: userReducer,
  userGroupState: userGroupreducer,
  jobState: jobReducer,
  authenticationState: authenticationReducer,
  sessionState: sessionReducer,
  userActivityState: userActivityreducer,
  warningMessageState: warningMessageReducer,
  reportState: reportReduer,
  roleState: roleReducer,
  appStatus: appStatusReducer,
  notificationMessageState: notificationMessageReducer,
  mainDrawerState: mainDrawerReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => IApplicationState): void;
}

export interface AppThunkActionPromise<TAction> {
  (dispatch: (action: TAction) => void, getState: () => IApplicationState): Promise<any>;
}
