import React, { useEffect, useState } from "react";
import { AppInsightsHelper } from '../../AppInsights';
import Paper from "@material-ui/core/Paper";
import { ApplicationFeature as AppModel } from "../../models/ApplicationModels";
import useStyles from "../common/useStyles";
import unspecifiedAppLogo from "../../images/epiq_access_logo_blue.svg";
import { CircularProgress, Button,IconButton, Input, InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Clear, CreateOutlined, Done } from "@material-ui/icons";
import { SessionManager } from "../../SessionManager";
import { Card } from "@progress/kendo-react-layout";
import { allPrefernceTypeVals, IApplicationPreference, IPreference } from "../../models/Preferences";
import Tooltip from "@material-ui/core/Tooltip";
import "./Application.scss";
import ApplicationNav from "../common/utilities/ApplicationNav";
import { Loader } from "@progress/kendo-react-indicators";
import ConsoleService from "../../services/ConsoleService";

interface AppProps {
  app: AppModel;
  customNames: any;
  appCustomName: string;
  redirectToAppName: string;
  onHelpClicked?: (app: AppModel, workspaceName?: string) => void;
  appFavoriteChange: (appId: number, favorite: boolean) => void;
  hideOverlayFavorite: boolean;
}



const Application = (props: AppProps) => {

  const customNames = props.customNames as IApplicationPreference;

  const defaultNickName = "Click pencil to add nickname";
  const [appCustomName, setAppCustomName] = useState(props.appCustomName ? props.appCustomName : "");
  const [isCustomNameEditMode, setIsCustomNameEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionManager] = useState(new SessionManager());
  const paperStyle = useStyles().paper;
  const [isFavorite, setIsFavorite] = useState(props.app.isFavorite);
  const [isHover, setIsHover] = useState(false);

  //Trim the url to get the domain name.
  let result = "";
  let match = props.app.url.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
  );
  if (match && match.length > 0) {
    result = match[1];
  }

  useEffect(() => {

    setAppCustomName(props.appCustomName);
    setIsFavorite(props.app.isFavorite);
    // returned function will be called on component unmount 
    return () => {
      setIsCustomNameEditMode(false);
      setAppCustomName("");
    }
  }, [props]);

  useEffect(() => { }, [loading]);

  const onCustomNameEdit = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setIsCustomNameEditMode(true);
    setAppCustomName(appCustomName == defaultNickName ? "" : appCustomName);
  }

  const onCustomNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    const customNameMaxLength = 255;

    if (value.length <= customNameMaxLength) {
      setAppCustomName(value);
    }
    else {
      setAppCustomName(value.slice(0, customNameMaxLength));
    }
  }

  const onDoneIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (props.appCustomName !== appCustomName) {
      const id = props.app.id;
      let appPreferenceValue = { ...customNames, [id]: appCustomName };

      const preference: IPreference = {
        prefName: "Application",
        value: appPreferenceValue
      }
      sessionManager.setPreference(preference);
    }
    setIsCustomNameEditMode(false);
  }

  const ensureAuth = async () => {
    setLoading(true);

    await ApplicationNav.waitLinkReady(props.app);
    ConsoleService.auditClickedApp(props.app.id, props.app.name);

    window.open(props.app.startupUrl, '_blank', 'noopener,noreferrer');

    setLoading(false);
  }

  const onClearIconClick = () => {
    const customName = props.appCustomName ? props.appCustomName : ""
    setAppCustomName(customName);
    setIsCustomNameEditMode(false);
  }

  const onFavoriteChange = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const response = await ConsoleService.UpsertApplicationFavorite(props.app.id, props.app.name, !isFavorite);

    if (response.ok) {
      setIsFavorite(!isFavorite);
      props.appFavoriteChange(props.app.id, !isFavorite);
    }
  }

  const onHelpClicked = (e: React.MouseEvent<HTMLButtonElement>, app: AppModel, workspaceName: string) => {
    e.stopPropagation();
    props.onHelpClicked(app, workspaceName);
  }

  const cardButtons = () => {
    return (
        <>
          <Button onClick={(e) => onFavoriteChange(e)} startIcon={<i className={`${isFavorite ? "fa fa-solid fa-star" : "fa fa-regular fa-star"}`}></i>}>{isHover ? "Favorite" : ""}</Button>
        {props.onHelpClicked && <Button onClick={(e) => onHelpClicked(e, props.app, null)} startIcon={<i className="fa fa-tools"></i>}>{isHover ? "Support" : ""}</Button>}
        </>
    )
  }

  const onCardEnter = () => {
    setIsHover(true);
  }

  const onCardLeave = () => {
    setIsHover(false);
  }

  const onCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsHover(false);
    if (loading) {
      return;
    }

    if (props.app.autoReenable) {
      ensureAuth();
      return;
    }

    if (!props.app.autoReenable) {
      setLoading(true);
      AppInsightsHelper.trackCustomEvent("Click App link tile", { applicationName: props.app.name });
      ConsoleService.auditClickedApp(props.app.id, props.app.name);
      window.open(props.app.startupUrl, '_blank', 'noopener,noreferrer');
      setLoading(false);
      return;
    }
  }

  return (
    <div id={`div ${props.app.id}`} className={`app-wrapper ${paperStyle} `} onMouseEnter={onCardEnter} onMouseLeave={onCardLeave} onClick={(e) => onCardClick(e)}>
      <div className="app-card-buttons">
        {cardButtons()}
      </div>
      <div onMouseLeave={(e) => onDoneIconClick(e)}>
        {loading &&
          (<div className="layover-background ">
            <div className="layover-content">
              <div className="layout-description">
                Connecting to <b>{props.redirectToAppName}</b><br></br> through <b>Epiq Access</b><br></br>
                <Loader type={"pulsing"} style={{ color: "#04A0DD" }} />
              </div>
            </div>
          </div>)
        }
        <div className="image-tile-wrapper">
          <img
            src={props.app.imageUrl ? props.app.imageUrl : unspecifiedAppLogo}
            className={props.app.imageUrl ? 'image' : 'unspecified-image'}
            alt=""
          />
        </div>
        {!isCustomNameEditMode ? (<div className="custom-name"><span onClick={(e) => onCustomNameEdit(e)}>{appCustomName ? appCustomName : (isHover ? <><span>Enter Custom Name</span></> : "")}
          <span className={isHover ? "k-icon k-i-edit" : "k-icon"} ></span>
        </span></div>) :
          (<Input
            id="nickName"
            className="custom-name-input"
            type={'text'}
            value={appCustomName}
            onChange={(e) => onCustomNameChange(e)}
            onClick={(e) => e.stopPropagation()}
            endAdornment={
              <InputAdornment position="end">
                {props.appCustomName !== appCustomName && <IconButton
                  aria-label="Done"
                  onClick={(e) => onDoneIconClick(e)}
                  edge="end"
                  className="custom-name-input-icon"
                >
                  <Done />
                </IconButton>}
              </InputAdornment>
            }
          />)}
        <Grid container >
          <Grid item md={6} lg={6} sm={6} xs={12} className="tile-url">{props.app.url}</Grid>
          <Grid item md={6} lg={6} sm={6} xs={12} className="tile-app-name">{props.app.name}</Grid>
        </Grid>
        </div>
    </div>
  );
};

export default Application;
