import { ServiceBase, ResponseMessages, ServiceResponseJson } from "./ServiceBase";
import toastr from "toastr";

const defaultToastOptions: IToastOptions = {
  closeButton: true,
  progressBar: true,
  positionClass: "toast-bottom-right"
};

export type IToastOptions = {
  closeButton?: boolean;
  debug?: boolean;
  newestOnTop?: boolean;
  progressBar?: boolean;
  positionClass?: string;
  preventDuplicates?: boolean;
  showDuration?: string;
  hideDuration?: string;
  timeOut?: number;
  extendedTimeOut?: number;
  showEasing?: string;
  hideEasing?: string;
  showMethod?: string;
  hideMethod?: string;
  tapToDismiss?: boolean;
};
export interface IToast {
  type: IToastType;
  message: string;
  title?: string;
  manualClose?: boolean;
  options?: IToastOptions;
}
export enum IToastType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning"
}

const generateToast = (toast: IToast) => {

  if (toast.manualClose) {
    defaultToastOptions.timeOut = 0;
    defaultToastOptions.extendedTimeOut = 0;
  }
  else {
    defaultToastOptions.timeOut = 5000;
    defaultToastOptions.extendedTimeOut = 1000;
  }

  const options: any = {
    ...defaultToastOptions,
    ...toast.options
  };

  toastr[toast.type](toast.message, toast.title, options);
};

const GetApplicationState: ResponseMessages = {
  FailedMessage: "Failed to get application state.",
  SuccessMessage: "Get application state succeeded."
};

export default class NotificationService extends ServiceBase {
  static showToast(toast: IToast) {
    generateToast(toast);
  }
  static showSuccessToast(message: string, clearToast?: boolean) {

    if (clearToast) {
      toastr.clear();
    }

    generateToast({
      type: IToastType.Success,
      message: message,
      title: "Success!"
    });
  }
  static showErrorToast(message: string, clearToast?: boolean) {

    if (clearToast) {
      toastr.clear();
    }

    generateToast({
      type: IToastType.Error,
      message: message,
      title: "Error"
    });
  }
  static showWarningToast(message: string) {
    generateToast({
      type: IToastType.Warning,
      message: message,
      title: "Warning"
    });
  }

  static showInfoToast(message: string, manualClose?: boolean) {
    generateToast({
      type: IToastType.Info,
      message: message,
      title: "Information",
      manualClose: manualClose
    });
  }

  static clearToast() {
    toastr.clear();
  }

  static async getApplicationState(abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "session/app-state";
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationState))
      .catch(async response => await this.handleResponseJson(response, GetApplicationState));

    return result;
  }
}
