import React, { useEffect, useState } from "react";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
  ListBoxToolbarClickEvent,
  ListBoxDragEvent,
  ListBoxItemClickEvent,
} from "@progress/kendo-react-listbox";
import "./ColumnFieldListBox.scss";
import { GridUserPreferences, IPreference, preferenceTypes } from "../../../../models/Preferences";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DialogActionsBar } from "@progress/kendo-react-dialogs/dist/es/DialogActionsBar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import { SessionManager } from "../../../../SessionManager";


const SELECTED_FIELD = "selected";

type Props = {
  availableFields?: Array<GridUserPreferences>;
  selectedFields?: Array<GridUserPreferences>;
  showSettingFieldsDialog: boolean;
  onSettingsDialogClose?: (reload: boolean) => void;
  tableKey: string;
  preferenceName: preferenceTypes;
};

interface ISelectField {
  [SELECTED_FIELD]: boolean;
  field: string;
  id: string;
}

const ColumnFieldListBox = function (props: Props) {
  const [sessionManager] = useState(new SessionManager());
  const [isDirty, setIsDirty] = useState(false);
  const [fieldSelections, setFieldSelections] = useState({
    availableFields: [],
    columns: [],
    draggedItem: {}
  } as any);

  useEffect(() => {
    setFieldSelections({
      availableFields: props.availableFields,
      columns: props.selectedFields,
      draggedItem: {},
    });
  }, [(props.availableFields || props.selectedFields)]);

  useEffect(() => {
    if (JSON.stringify(fieldSelections.columns) != JSON.stringify(props.selectedFields)) {
      setIsDirty(true);
    }
    else {
      setIsDirty(false);
    }
  }, [(fieldSelections.availableFields || fieldSelections.columns)]);

  const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
    let toolName: string = e.toolName || "";
    const mandatoryField = fieldSelections.columns.filter((x: { isMandatory: any; }) => x.isMandatory);

    if (toolName === "transferAllFrom" && mandatoryField) {
      fieldSelections.columns = fieldSelections.columns.filter((x: { isMandatory: any; }) => !(x.isMandatory))
    }

    let result: any = processListBoxData(
      fieldSelections.availableFields,
      fieldSelections.columns,
      toolName,
      SELECTED_FIELD
    );

    if (toolName === "transferAllFrom" && mandatoryField) {
      result.listBoxTwoData = mandatoryField;
    }

    const changedFieldSelections = {
      ...fieldSelections,
      availableFields: result.listBoxOneData,
      columns: result.listBoxTwoData,
    };

    setFieldSelections(changedFieldSelections);
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    setFieldSelections({
      ...fieldSelections,
      draggedItem: e.dataItem
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    if (fieldSelections.draggedItem && !fieldSelections.draggedItem.hasOwnProperty('isMandatory')) { //mandatory item should not be move
      let result: any = processListBoxDragAndDrop(
        fieldSelections.availableFields,
        fieldSelections.columns,
        fieldSelections.draggedItem,
        e.dataItem,
        "field"
      );

      setFieldSelections({
        ...fieldSelections,
        availableFields: result.listBoxOneData,
        columns: result.listBoxTwoData,
      });

      setIsDirty(true);
    }
  };

  const handleItemClick = (event: ListBoxItemClickEvent, data: string, connectedData: string) => {
    if (event.dataItem && !event.dataItem.hasOwnProperty('isMandatory')) {  //mandatory item should not be move.
      setFieldSelections({
        ...fieldSelections,
        [data]: fieldSelections[data].map((item: ISelectField) => {
          if (item.field === event.dataItem.field) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
          }
          return item;
        }),
        [connectedData]: fieldSelections[connectedData].map((item: ISelectField) => {
          item[SELECTED_FIELD] = false;
          return item;
        })
      });
    }
  };

  const handleApplyFieldsClick = () => {
    const columns = fieldSelections.columns.map((v: any) => {
      v.selected = false
      return v;
    });

    const preferenceValue = {
      [props.tableKey]: columns.map(function (f: any) {
        return ({ field: f.field, width: f.width });
      })
    };

    const preference: IPreference = {
      prefName: props.preferenceName,
      value: preferenceValue
    }

    sessionManager.setPreference(preference);

    if (props.onSettingsDialogClose) {
      props.onSettingsDialogClose(true);
    }
  }

  return (
    <>
      {props.showSettingFieldsDialog && (
        <div>
          <Dialog
            onClose={props.onSettingsDialogClose.bind(null, false)}
            aria-labelledby="customized-dialog-title"
            className="field-listbox-modal"
          >
            <DialogTitle className="modal-title">
              Grid Preferences
              <IconButton
                className="modal-close"
                aria-label="close"
                disabled={false}
                onClick={props.onSettingsDialogClose.bind(null, false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <div className="row justify-content-center field-box">
                  <div className="col k-pr-2">
                    <h4 className="listboxHeader">Available Columns</h4>
                    <ListBox
                      style={{ height: 300, width: '20em' }}
                      data={fieldSelections.availableFields}
                      textField="displayName"
                      valueField="name"
                      selectedField={SELECTED_FIELD}
                      onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, "availableFields", "columns")}
                      onDragStart={handleDragStart}
                      onDrop={handleDrop}
                      toolbar={() => {
                        return (
                          <ListBoxToolbar
                            tools={['moveUp', 'moveDown', 'transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']}
                            data={fieldSelections.availableFields}
                            dataConnected={fieldSelections.columns}
                            onToolClick={handleToolBarClick}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="col k-pl-0">
                    <h4 className="listboxHeader">Displayed Columns</h4>
                    <ListBox
                      style={{ height: 300, width: '18em' }}
                      data={fieldSelections.columns}
                      textField="displayName"
                      valueField="field"
                      selectedField={SELECTED_FIELD}
                      onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, "columns", "availableFields")}
                      onDragStart={handleDragStart}
                      onDrop={handleDrop}
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActionsBar>
              <div className="toolbar-button-container">
                <Button onClick={props.onSettingsDialogClose.bind(null, false)} variant="contained" className="btn-contined">
                  Cancel
                </Button>
                <Button className="btn-primary btn-apply" variant="contained"
                  color="primary" disabled={(fieldSelections.columns.length == 0 || !isDirty)} onClick={handleApplyFieldsClick}>
                  Apply
                </Button>
              </div>
            </DialogActionsBar>
          </Dialog>
        </div>
      )
      }
    </>
  )
}
export { ColumnFieldListBox };