import React, { ComponentType } from "react";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { FilterDropdownData, GridUserPreferences, GridPreferences, IPreference, preferenceTypes } from "../../../../models/Preferences";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import { FilterDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { SessionManager } from "../../../../SessionManager";

type filterTypes = 'text' | 'numeric' | 'boolean' | 'date';

interface fields {
  availableFields: Array<GridUserPreferences>;
  selectedFields: Array<GridUserPreferences>
}

const ColumnFromField = function (pref: GridUserPreferences): GridColumnProps {
  let filterType = null as filterTypes;
  let filterCell = null as ComponentType<GridFilterCellProps>;
  let format = null as string;

  if (pref.type === "DropDown") {
    if (pref.filterDropdownData.length > 0) {
      filterType = "text";
      filterCell = generateDropdownFilter(pref.filterDropdownData);
    }
  }
  else if (pref.type === "DateTime") {
    filterType = "date";
    format = "{0:g}";
  }
  else if (pref.type === "Int" || pref.type === "Float" || pref.type === "Double") {
    filterType = "numeric";
  }
  else if (pref.type === "Bool") {
    filterType = "boolean";
  }
  else {
    filterType = "text";
  }

  return {
    field: pref.field,
    title: pref.displayName.toUpperCase(),
    filterable: true,
    sortable: true,
    filterCell: filterCell,
    filter: filterType,
    format: format
  };
};

const setSavedColumnsToGrid = (masterColumns: Array<GridPreferences>, gridUserPreferences: Record<string, GridUserPreferences>, setColumnProperties: any, defaultWidth?: number): Array<GridColumnProps> => {
  let cols = [] as Array<GridColumnProps>;
  if (masterColumns) {
    masterColumns.forEach((fieldInfo: GridPreferences) => {
      const field = gridUserPreferences[fieldInfo.field];
      if (field) {
        const col = setColumnProperties(field);
        col.width = fieldInfo.colWidth ? fieldInfo.colWidth : defaultWidth;
        cols.push(col);
      }
    })
    return cols;
  }
}

const setDefaultColumnsToGrid = (masterColumns: Array<GridPreferences>, gridUserPreferences: Record<string, GridUserPreferences>, setColumnProperties: any): Array<GridColumnProps> => {
  let cols = [] as Array<GridColumnProps>;
  if (masterColumns) {
    masterColumns.forEach((fieldInfo: GridPreferences) => {
      const field = gridUserPreferences[fieldInfo.field]
      if (field && field.isDefault) {
        const col = setColumnProperties(field);
        if (fieldInfo.colWidth) {
          col.width = fieldInfo.colWidth;
        }
        cols.push(col);
      }
    })
    return cols;
  }
}

const getModifiedCorrectNumericFilter = (gridFilters: Array<CompositeFilterDescriptor>, dateColumnName: string): Array<CompositeFilterDescriptor> => {
  const filters = gridFilters ? gridFilters.map(compositeFilter => {
    const numericFilter: FilterDescriptor = compositeFilter.filters.find(
      filter => (filter as FilterDescriptor).field === dateColumnName
    ) as FilterDescriptor;

    if (numericFilter) {
      const isFilterValueString = numericFilter.value && typeof (numericFilter.value) === "string"

      if (isFilterValueString) {
        return {
          ...compositeFilter,
          filters: [
            {
              ...numericFilter,
              value: -1
            }
          ]
        };
      }
      else if (isFilterValueString === null) {
        return {
          ...compositeFilter,
          filters: [
            {
              ...numericFilter,
              operator: "gte",
              value: 0
            }
          ]
        };
      }

    }
    return compositeFilter;
  }) : [];

  return filters;
}

const generateDropdownFilter = function (dropDownData: Array<FilterDropdownData>) {
  const singleSelectGridFilter = function (props: GridFilterCellProps) {
    const selectedItem = dropDownData.filter(x => x.selected).length > 0 ? dropDownData.filter(x => x.selected)[0] : null
    return <DropDownFilter
      {...props}
      data={dropDownData as Array<FilterDropdownData>}
      textField="name"
      defaultSelectedOption={selectedItem}
    />
  }

  return singleSelectGridFilter;
}

const getFields = (savedGridColumns: Array<GridPreferences>, masterGridColumns: Array<GridPreferences>, gridUserPreferences: Record<string, GridUserPreferences>): fields => {
  let selectedFields: Array<GridPreferences>;
  let availableFields: Array<GridPreferences>

  if (savedGridColumns) {
    selectedFields = savedGridColumns as Array<GridPreferences>;
    availableFields = masterGridColumns.filter(({ field: alField }) => !selectedFields.some(({ field: sField }) => alField === sField)); //excluding selected fields from all fields
  }
  else {
    selectedFields = masterGridColumns.filter(x => (gridUserPreferences[x.field] && gridUserPreferences[x.field].isDefault === true)); //default fields
    availableFields = masterGridColumns.filter(({ field: alField }) => !selectedFields.some(({ field: sField }) => alField === sField)); // excluding default fields from all fields
  }

  return {
    availableFields: generateListBoxFieldsData(availableFields, gridUserPreferences),
    selectedFields: generateListBoxFieldsData(selectedFields, gridUserPreferences)
  } as fields
}

const generateListBoxFieldsData = (fields: Array<GridPreferences>, gridUserPreferences: Record<string, GridUserPreferences>): Array<GridUserPreferences> => {
  const result = fields.map((f: GridPreferences) => {
    let fieldInfo = gridUserPreferences[f.field];
    if (fieldInfo && fieldInfo.width)
      fieldInfo.width = f.colWidth;

    return fieldInfo;
  });

  const filteredResult = result.filter(function (element) {
    return element !== undefined;
  });

  return filteredResult;
}

const saveColumnWidthPreferenceInDB = (tableKey: string, preferenceName: preferenceTypes, fields: Array<GridPreferences>, field: string, newWidth: number | string) => {
  const sessionManager = new SessionManager();

  const gridPreferences = fields.map((f: GridPreferences) => {
    if (field === f.field) {
      f.colWidth = newWidth;
    }
    return {
      field: f.field,
      colWidth: f.colWidth

    } as GridPreferences
  });

  const preferenceValue = { [tableKey]: gridPreferences };
  const preference: IPreference = {
    prefName: preferenceName,
    value: preferenceValue
  }

  sessionManager.setPreference(preference)
}

export { ColumnFromField, setSavedColumnsToGrid, setDefaultColumnsToGrid, getModifiedCorrectNumericFilter, getFields, generateListBoxFieldsData, saveColumnWidthPreferenceInDB };