import { Employer } from "./EmployerModel";
import { IResource } from "./ResourceModels";

class MessageModel {
  notificationId: number = 0;
  agreementId: number = 0;
  name: string = "";
  messageType: number = 0;
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  status: string = "";
  comment: string = "";
  triggerType: number = 0;
  title: string = "";
  message: string = "";
  noEndDate: boolean = false;
  forceLogoutOnDisagree: boolean = false;
  employers: string = "";
  domains: string = "";
  employerList: Employer[] = [];
  employerIds: number[] = [];
  startTime?: Date;
  endTime?: Date;
  notificationResourcesDetails: IResource[] = [];
  addResources: number[] = [];
  timezone: string = "";
  timezoneOffset: number = 0;
  repeat: boolean = false;
}

class CommonMessageModel extends MessageModel {
  resourceDelimeter?: string;
}


export interface IAgreementAcceptanceModel {
  agreementId: number;
  agreementEmployerId: number;
  title: string;
  message: string;
  name: string;
  forceLogoutOnDisagree: boolean;
}

export interface INotificationMessageModel {
  notificationId: number;
  title: string;
  message: string;
  name: string;
  repeat: boolean;
  startDate: Date;
  endDate: Date;
  createdDate: Date;
  notificationResourcesDetails: INotificationResource[];
}

export interface INotificationResource {
  resourceId: number;
  resourceName: string;
  objectId: number;
}
export interface IAuditProps {
  action: string;
  agreement: IAgreementAcceptanceModel;
  employerId: number;
}

enum MessageStatusEnum {
  Active = "Active",
  Ready = "Ready",
  Ended = "Ended",
  NotActive = "Not Active"
}

class NotificationModel {
  id: number = 0;
  name: string = "";
  startDate?: Date;
  endDate?: Date;
  active?: boolean;
  status: string = "";
  comment: string = "";
  title: string = "";
  message: string = "";
  resources: Number[] = [];
  timezone: string = "";
  timezoneOffset: number = 0;
  repeat: boolean = false;
}

class NotificationResourceModel {
  resourceId: number = 0;
  resourceName: string = "";
  objectId: number = 0;
}

enum MessageTypeEnum {
  EulaAgreement = "Eula Agreement",
  UpcomingMaintenance = "Upcoming Maintenance",
};

export { MessageModel, MessageStatusEnum, MessageTypeEnum, CommonMessageModel, NotificationModel };