import React from "react";
import { connect } from "react-redux";
import * as SessionStore from "../../../../store/Session";
import CommonHelper from "../../../common/utilities/CommonHelper";
import { IApplicationState } from "../../../../store";
import CollapsibleSection from "../../common/CollapsibleSection";
import { Grid, Paper } from "@material-ui/core";
import { TextInput } from "../../../common/TextInput";
import { MessageModel, MessageStatusEnum } from "../../../../models/MessageModel";
import { RadioGroup, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { DatePicker, DatePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { EmployerSelectable } from "../../../../models/EmployerModel";
import GridSelector from "../../../common/grid/GridSelector";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Editor, EditorChangeEvent, EditorMountEvent, EditorTools } from "@progress/kendo-react-editor";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import MessageService from "../../../../services/MessageService";
import { IGridParams } from "../../../common/grid/AdvancedGrid";
import NotificationService from "../../../../services/NotificationService";
import { defaultMarkdownParser, defaultMarkdownSerializer } from "prosemirror-markdown";
import { Node } from "prosemirror-model";
import EmployerListWithDomains from "../../Employer/EmployerListWithDomains";
import ViewMore from "../../../common/ViewMore";
import ErrorIcon from "@material-ui/icons/Error";
import ReactMarkdown from 'react-markdown';
import Tooltip from "@material-ui/core/Tooltip";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import { Application } from "../../../../models/ApplicationModels";
import ApplicationList from "../ApplicationList";


enum Mode {
  Edit,
  Create,
  View
}

const {
  Bold, Italic, OrderedList, UnorderedList, Undo, Redo, FormatBlock, Link, Unlink, InsertImage
} = EditorTools;

enum ErrorMessages {
  MessageExists = "Message name already exists.",
  Required = "This field is required."
}

interface TimezoneInfo {
  title: string;
  timezone: string;
  offsetMinutes: number;
  offsetHours: string;
}

interface IProps {
  messageDetails?: MessageModel;
  onMessageEdited: (message: MessageModel, isValid?: boolean) => void;
  createMessage: boolean;
  isMessageNameExist: boolean;
  mode: Mode;
  handleDeleteConfirm: () => void;
  resetMode: (mode: Mode) => void;
  initialMessageDetails?: MessageModel;
  handleEditCancelled?: () => void;
}

type Props = IProps & SessionStore.ISessionState;

type State = {
  messageInEdit: MessageModel;
  selectedEmployers: Array<EmployerSelectable>;
  potentiallySelectedEmployers: Array<EmployerSelectable>;
  openEmployerSelector: boolean;
  enableAddEmployerBtn: boolean;
  copyFromexistingMessage: boolean;
  noEndDate: boolean;
  existingMessages: Array<MessageModel>;
  isLoading: boolean;
  editorValue: Node;
  editable: boolean;
  actionItemAnchorEl: HTMLElement;
  selectedApplications: Array<Application>;
  potentiallySelectedApplications: Array<Application>;
  openApplicationSelector: boolean;
  enableAddApplicationBtn: boolean;
};

const MessageTypes = [{ id: 0, name: "(select one)" }, { id: 1, name: "EULA Agreement" }, { id: 2, name: "Upcoming Maintenance" }];
const TriggerDisplayMessages = [{ id: 0, name: "(select one)" }, { id: 1, name: "Employer" }, { id: 2, name: "App Instance" }];
const editorStyles = `
    .k-content {
        font-family: Roboto;
    }
`;

const timezones: Array<TimezoneInfo> = [
  {
    "title": "Eastern Time (ET)",
    "timezone": "America/New_York",
    "offsetMinutes": -300,
    "offsetHours": "-05:00"
  },
  {
    "title": "Central Time (CT)",
    "timezone": "America/Chicago",
    "offsetMinutes": -360,
    "offsetHours": "-06:00"
  },
  {
    "title": "Mountain Time (MT)",
    "timezone": "America/Denver",
    "offsetMinutes": -420,
    "offsetHours": "-07:00"
  },
  {
    "title": "Pacific Time (PT)",
    "timezone": "America/Los_Angeles",
    "offsetMinutes": -480,
    "offsetHours": "-08:00"
  },
  {
    "title": "Australian Eastern Time (AET)",
    "timezone": "Australia/Sydney",
    "offsetMinutes": 600,
    "offsetHours": "+10:00"
  },
  {
    "title": "Australian Eastern Time (AET)",
    "timezone": "Australia/Melbourne",
    "offsetMinutes": 600,
    "offsetHours": "+10:00"
  },
  {
    "title": "Australian Eastern Time (AET)",
    "timezone": "Australia/Brisbane",
    "offsetMinutes": 600,
    "offsetHours": "+10:00"
  },
  {
    "title": "Australian Western Time (AWST)",
    "timezone": "Australia/Perth",
    "offsetMinutes": 480,
    "offsetHours": "+08:00"
  },
  {
    "title": "India Standard Time (IST)",
    "timezone": "Asia/Kolkata",
    "offsetMinutes": 330,
    "offsetHours": "+05:30"
  }
];

class Edit extends React.Component<Props, State>  {
  constructor(props: Props) {
    super(props);

    const existingEmployers = (props.messageDetails && props.messageDetails.employerList) ? props.messageDetails.employerList.map((item) => { return { ...item } as EmployerSelectable })
      : [] as Array<EmployerSelectable>;

    const existingResources = (props.messageDetails && props.messageDetails.notificationResourcesDetails) ? props.messageDetails.notificationResourcesDetails.map((item) => {
      return {
        id: item.objectId,
        name: item.resourceName,
        appResourceId:item.resourceId
      } as Application 
    }) : [] as Array<Application>;

    const messageDetails = CommonHelper.cloneObject(props.initialMessageDetails);
    messageDetails.addResources = existingResources.map((item) => item.appResourceId);

    this.state = {
      messageInEdit: {
        ...messageDetails
      },
      selectedEmployers: existingEmployers,
      potentiallySelectedEmployers: [] as Array<EmployerSelectable>,
      openEmployerSelector: false,
      enableAddEmployerBtn: false,
      copyFromexistingMessage: false,
      noEndDate: false,
      existingMessages: [] as Array<MessageModel>,
      isLoading: false,
      editorValue: defaultMarkdownParser.parse(messageDetails.message),
      editable: false,
      actionItemAnchorEl: null,
      selectedApplications: existingResources,
      potentiallySelectedApplications: [] as Array<Application>,
      openApplicationSelector: false,
      enableAddApplicationBtn: false
    };
  }

  componentDidMount = () => {
    if (this.props.messageDetails) {
      this.setState({ ...this.state, messageInEdit: this.props.messageDetails });
    }
  };

  dataState: IGridParams = {
    skip: 0,
    take: 100,
    sort: [{ field: "name", dir: "asc" }]
  };

  onFormInputChangeEvent = (value: string, name: string, isValid: boolean) => {
    var message: any = { ...this.state.messageInEdit };
    message[name] = value ? value : "";

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, isValid);
      }
    });
  };

  startDateChange = (event: DatePickerChangeEvent) => {
    var message = { ...this.state.messageInEdit, startDate: event.value };

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  startDateTimeChange = (event: TimePickerChangeEvent) => {
    var message = { ...this.state.messageInEdit, startTime: event.value };

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  endDateChange = (event: DatePickerChangeEvent) => {
    var message = { ...this.state.messageInEdit, endDate: event.value };

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  endDateTimeChange = (event: TimePickerChangeEvent) => {
    var message = { ...this.state.messageInEdit, endTime: event.value };

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  removeSelectedEmployers = (item: EmployerSelectable) => {
    const employers = [...this.state.selectedEmployers];
    for (let s = 0; s < employers.length; s++) {
      if (employers[s].id == item.id) {
        employers.splice(s, 1);
        break;
      }
    }

    const remainingEmpIds = employers.map(x => { return x.id });
    const message = { ...this.state.messageInEdit, employerIds: remainingEmpIds };
    this.setState({ ...this.state, selectedEmployers: employers, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });

  }

  handlePotentiallySelected = (selected: Array<EmployerSelectable>) => {
    this.setState({
      potentiallySelectedEmployers: selected,
      enableAddEmployerBtn: selected && selected.length > 0
    });
  };


  handleEmployerPopupClose = (accepted: boolean) => {
    if (accepted) {

      const dedupeEmployers = this.state.potentiallySelectedEmployers;
      const selectedEmployerIds = dedupeEmployers.map(x => { return x.id });
      const message = { ...this.state.messageInEdit, employerIds: selectedEmployerIds };

      this.setState({ ...this.state, selectedEmployers: dedupeEmployers, messageInEdit: message }, () => {
        if (this.props.onMessageEdited) {
          this.props.onMessageEdited(message, true);
        }
      });
    }

    this.setState({ openEmployerSelector: false, enableAddEmployerBtn: false });
  };

  copyFromExistingMessageChange = async (e: RadioGroupChangeEvent) => {
    this.setState({ copyFromexistingMessage: e.value === "1" ? true : false });
  };

  activationChange = async (e: RadioGroupChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    message.active = e.value === "1" ? true : false;

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  triggerMessageDisplayChange = async (e: DropDownListChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    message.triggerType = e.value.id;

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  messageTypeChange = async (e: DropDownListChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    message.messageType = e.value.id;
    message.triggerType = message.messageType == 1 || message.messageType == 2 ? message.messageType : 0;

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  makeEndDateDisableChange = async (e: CheckboxChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    if (e.value) {
      message.endDate = null;
      message.endTime = null;
    }
    message.noEndDate = e.value;

    this.setState({ ...this.state, noEndDate: e.value, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  forceLogoutOnDisagreeChange = async (e: CheckboxChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    message.forceLogoutOnDisagree = e.value;

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  repeatChange = async (e: CheckboxChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    message.repeat = e.value;

    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  contentChangeEvent = (event: EditorChangeEvent) => {
    var message = { ...this.state.messageInEdit };
    message.message = defaultMarkdownSerializer.serialize(event.value);


    this.setState({ ...this.state, messageInEdit: message, editorValue: event.value }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  };

  comboBoxFilterChange = (event: any) => {
    let filters: Array<CompositeFilterDescriptor>
    if (event && event.value && event.value != "") {
      filters = [{
        logic: "and",
        filters: [{ field: "name", operator: "contains", value: event.value }] as Array<FilterDescriptor>
      }];
    }
    else {
      filters = [];
    }
    this.getMessageList(filters);
  };

  getMessageList = async (filters: Array<CompositeFilterDescriptor>) => {
    const result = await MessageService.getMessages({ ...this.dataState, filters: filters });
    if (result.ok) {
      this.setState({ existingMessages: result.data.results });
    } else {
      NotificationService.showErrorToast("Something went wrong while fetching messages.");
    }
  };

  existingMsgSelectionChange = async (event: any) => {
    this.setState({ ...this.state, isLoading: true });
    const messageDetailsResult = await MessageService.getMessageInfo(event.value.agreementId);
    if (messageDetailsResult.ok) {
      const messageDetails = messageDetailsResult.data ? messageDetailsResult.data : new MessageModel();

      const existingMessage: MessageModel = { ...messageDetails, triggerType: 1, messageType: 1, agreementId: 0 };

      this.setState({ ...this.state, isLoading: false, messageInEdit: existingMessage }, () => {
        if (this.props.onMessageEdited) {
          this.props.onMessageEdited(messageDetails, true);
        }
      });

    }
  };

  getMessageStatus = (active: boolean, startDate: Date, endDate?: Date) => {
    let status = MessageStatusEnum.NotActive;
    const date = new Date();
    let localStartDateTime = CommonHelper.convertUTCDateToLocalDate(startDate);
    let localEndDateTime = endDate ? CommonHelper.convertUTCDateToLocalDate(endDate) : null;
    if (active) {
      if (localStartDateTime < date && (localEndDateTime === null || localEndDateTime > date)) {
        status = MessageStatusEnum.Active;
      } else if (localStartDateTime > date) {
        status = MessageStatusEnum.Ready;
      } else if (localEndDateTime < date) {
        status = MessageStatusEnum.Ended;
      }
    }
    return status;
  }

  onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement("style");
    style.appendChild(iframeDocument.createTextNode(editorStyles));
    iframeDocument.head.appendChild(style);
  };

  handleOpenActionItemClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ actionItemAnchorEl: event.currentTarget })
  }

  handleCloseActionItemClick = () => {
    this.setState({ actionItemAnchorEl: null })
  }

  onEditClick = () => {
    this.props.resetMode(Mode.Edit);
  }

  onCancelClick = () => {
    if (this.props.handleEditCancelled) {
      this.props.handleEditCancelled();
    }
  }

  handleApplicationsPopupClose = (accepted: boolean) => {
    if (accepted) {

      const dedupeApps = this.state.selectedApplications;
      const selectedApps = dedupeApps.map(x => { return x.appResourceId });
      const message = { ...this.state.messageInEdit, addResources: selectedApps };

      this.setState({ ...this.state, selectedApplications: dedupeApps                                                                                                                                                                               , messageInEdit: message }, () => {
        if (this.props.onMessageEdited) {
          this.props.onMessageEdited(message, true);
        }
      });
    }

    this.setState({ openApplicationSelector: false, enableAddApplicationBtn: false });
  };

  removeSelectedApplications = (application: Application) => {
    const selectedApplications = this.state.selectedApplications.filter(x => x.id != application.id);
    const message = { ...this.state.messageInEdit, addResources: selectedApplications.map(x => { return x.appResourceId }) };
    this.setState({ selectedApplications: [...selectedApplications], potentiallySelectedApplications: [...selectedApplications], messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  }

  handleSelectedApplicationChange = (selectedApps: Application[]) => {
    this.setState({ selectedApplications: [...selectedApps], potentiallySelectedApplications: [...selectedApps], enableAddApplicationBtn: selectedApps && selectedApps.length > 0 });
  }

  onTimezoneChange = (e: DropDownListChangeEvent) => {
    const message = { ...this.state.messageInEdit };
    const timezone = e.value as TimezoneInfo;
    message.timezone = timezone ? timezone.timezone : "";
    message.timezoneOffset = timezone ? timezone.offsetMinutes : 0;
    this.setState({ ...this.state, messageInEdit: message }, () => {
      if (this.props.onMessageEdited) {
        this.props.onMessageEdited(message, true);
      }
    });
  }

  render() {
    const { messageInEdit, copyFromexistingMessage, selectedEmployers } = this.state;
    const msgDetails = this.props.mode === Mode.View ? this.props.initialMessageDetails : this.state.messageInEdit;
    const msgHeaderContent = <p>Type or paste the title and contents that will appear as the message.</p>;
    let employerDomains = [] as Array<string>;
    selectedEmployers && selectedEmployers.map(x => {
      const newList = x.employerDomain ? x.employerDomain.split(",") : [];
      employerDomains.push(...newList);
    });
    employerDomains = employerDomains.sort((a, b) => (a > b ? 1 : -1)); // -- sort asc
    const startDateTime = (msgDetails.startDate && msgDetails.startTime) ? CommonHelper.combineDateWithTime(msgDetails.startDate, msgDetails.startTime) : null;
    const endDateTime = (msgDetails.endDate && msgDetails.endTime) ? CommonHelper.combineDateWithTime(msgDetails.endDate, msgDetails.endTime) : null;
    const actionItems = ['Delete Message'];
    const open = Boolean(this.state.actionItemAnchorEl);
    return (<>
      <Grid container>
        <Grid item sm={8} xs={12} className="message-header">
          <div className={"message-name"}>{this.props.mode === Mode.Create ? "Create Message" : msgDetails.name}</div>
        </Grid>

        <>
          <Grid item sm={4} xs={12} className="button-wrapper">
            {this.props.mode === Mode.Edit && this.props.sessionData.superAdmin && (
              <div className="action-context-menu">
                <Tooltip title={<span style={{ fontSize: "14px", lineHeight: "16.41px" }}>More Actions</span>} style={{ fontSize: "14px" }} placement="top" arrow>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleOpenActionItemClick}
                    className="dots-icon"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={this.state.actionItemAnchorEl}
                  open={open}
                  onClose={this.handleCloseActionItemClick}
                  className="message-action-menu"
                >
                  {actionItems.map((actionItem) => (
                    <MenuItem
                      key={actionItem}
                      onClick={this.props.handleDeleteConfirm}
                      className="message-action-items"
                    >
                      {actionItem}
                    </MenuItem>
                  ))}
                </Menu>
              </div>)}
            <span className="edit-msg-details">
              {
                this.props.sessionData.permissions.has("EpiqAdminUpdateAgreement") && (
                  this.props.mode === Mode.View ? <CreateIcon className="edit-icon" onClick={this.onEditClick} />
                    : this.props.mode === Mode.Edit ? <CancelIcon className="cancel-icon" onClick={this.onCancelClick}/> : null
                )}
            </span>
          </Grid>
        </>
      </Grid>
      <div><CollapsibleSection title="Message Details">
        <Paper className="grid-wrapper">
          <div className="message-details">
            <Grid container justify="space-around" spacing={1}>
              <Grid item sm={7} xs={12} className="msg-left-container">
                <div>
                  <Grid container>
                    <Grid item xs={7} md={7}>
                      <div className="message-info-activation">
                        <label className="input-label">Set Activation</label>
                        <RadioGroup layout="horizontal" name="active" data={[{ value: "1", label: "Enabled" }, { value: "0", label: "Disabled" }]}
                          value={msgDetails.active !== undefined && (msgDetails.active ? "1" : "0")}
                          onChange={this.activationChange}
                          disabled={this.props.mode == Mode.View}
                        ></RadioGroup>
                      </div>
                    </Grid>
                    {!this.props.createMessage && <Grid item xs={5} md={5}>
                      <Grid container>
                        <Grid item xs={6} md={6}>
                          <label className="input-label">Status</label>
                          <div>{this.getMessageStatus(msgDetails.active, msgDetails.startDate, msgDetails.endDate)}</div>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <label className="input-label">Message ID</label>
                          <div>{msgDetails.agreementId ? msgDetails.agreementId : msgDetails.notificationId}</div>
                        </Grid>
                      </Grid>
                    </Grid>}
                  </Grid>
                  <div className="text-input-has-error">
                    <label className="input-label">Enter Message Name</label>
                    <TextInput
                      type="text"
                      name="name"
                      defaultValue={msgDetails.name}
                      maxLength={250} 
                      validations={[
                        { name: "required", errorMessage: ErrorMessages.Required },
                        {
                          name: "CustomValidation",
                          errorMessage: `${ErrorMessages.MessageExists}`,
                          predicate: `${this.props.isMessageNameExist}`
                        }
                      ]}
                      onChange={this.onFormInputChangeEvent}
                      displayCustomValidationMessage={this.props.isMessageNameExist}
                      disabled={this.props.mode == Mode.View}
                    />
                  </div>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <div className="msg-type-container">
                        <label className="input-label">Message Type</label>
                        <DropDownList
                          data={MessageTypes}
                          defaultValue={MessageTypes.find(x => x.id === msgDetails.messageType)}
                          onChange={this.messageTypeChange}
                          name="messageType"
                          key="id"
                          textField="name"
                          dataItemKey="id"
                          defaultItem="(select one)"
                          disabled={this.props.mode == Mode.View || this.props.mode == Mode.Edit}
                        />
                      </div>
                    </Grid>
                    <Grid item sm={6}>
                      <div className="msg-trigger-container">
                        <label className="input-label">Trigger to Display Message</label>
                        <DropDownList
                          data={TriggerDisplayMessages.filter(x => x.id === (messageInEdit.messageType == 1 ? 1 : 2))}
                          defaultValue={TriggerDisplayMessages.find(x => x.id === (messageInEdit.messageType == 1 || messageInEdit.messageType == 2 ? messageInEdit.messageType : 0))}
                          onChange={this.triggerMessageDisplayChange}
                          name="messageAgreementTarget"
                          key="id"
                          textField="name"
                          dataItemKey="id"
                          defaultItem="(select one)"
                          disabled={this.props.mode == Mode.View || this.props.mode == Mode.Edit}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div>
                    {msgDetails.messageType == 1 && this.state.selectedEmployers &&
                      <div>
                        <div className="msg-trigger-selected-container">
                          <label className="input-label">Selected Employer Names</label>
                          <Grid container>
                            <Grid item sm={8} md={9} lg={8} className="msg-trigger-sel-item-grid">
                              {this.state.selectedEmployers.length > 0 && this.state.selectedEmployers.map((item) => (
                                <label className="trigger-type-sel-item-btn">
                                  <span className={this.props.mode == Mode.View ? "" : "k-icon k-i-minus-outline"} onClick={(e) => { this.removeSelectedEmployers(item); }}></span> {item.name}
                                </label>
                              ))}
                            </Grid>

                            <Grid item sm={4} md={3} lg={4} className="msg-trigger-container-btn">
                              <Button
                                icon={"plus"}
                                onClick={() => {
                                  this.setState({
                                    openEmployerSelector: true
                                  });
                                }}
                                className="btn btn-primary"
                                disabled={this.props.mode == Mode.View}
                              >
                                Employer
                              </Button>
                            </Grid>

                          </Grid>
                        </div>
                        {employerDomains && employerDomains.length > 0 && <div className="msg-selected-employer-domain">
                          <label className="input-label">Domains for Employer</label>
                          <div className="msg-domain-list">
                            {employerDomains.length < 15 ? <p>{employerDomains.join(", ")}</p> : <ViewMore>{employerDomains.join(", ")}</ViewMore>}
                          </div>
                        </div>}
                      </div>
                    }
                    {msgDetails.messageType == 2 && 
                      <div>
                        <div className="msg-trigger-selected-container">
                          <label className="input-label">Selected App Instance Names</label>
                          <Grid container>
                            <Grid item sm={8} md={9} lg={8} className="msg-trigger-sel-item-grid">
                              {this.state.selectedApplications.length > 0 && this.state.selectedApplications.map((item) => (
                                <label className="trigger-type-sel-item-btn">
                                  <span className={this.props.mode == Mode.View ? "" : "k-icon k-i-minus-outline"} onClick={(e) => { this.removeSelectedApplications(item); }}></span> {item.name}
                                </label>
                              ))}
                            </Grid>

                            <Grid item sm={4} md={3} lg={4} className="msg-trigger-container-btn">
                              <Button
                                icon={"plus"}
                                onClick={() => {
                                  this.setState({
                                    openApplicationSelector: true
                                  });
                                }}
                                className="btn btn-primary"
                                disabled={this.props.mode == Mode.View}
                              >
                                App Instance
                              </Button>
                            </Grid>

                          </Grid>
                        </div>
                      </div>
                     }
                  </div>
                </div>
              </Grid>
              <Grid item sm={5} xs={12}>
                <div className="msg-comments-container">
                  <label className="input-label">Comments</label>
                  <TextInput
                    type="textarea"
                    name="comment"
                    defaultValue={msgDetails.comment}
                    onChange={this.onFormInputChangeEvent}
                    notesLength={msgDetails.comment ? msgDetails.comment.length : 0}
                    maxLength={255}
                    disabled={this.props.mode == Mode.View}
                  /></div>
              </Grid>
            </Grid>
            <Grid container justify="space-around" spacing={1}>
              <Grid item sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={4} md={4} className="msg-startdate-container">
                    <div className="input-label">Start Date</div>
                    <DatePicker onChange={this.startDateChange} value={msgDetails.startDate} disabled={this.props.mode == Mode.View} />
                    <TimePicker onChange={this.startDateTimeChange} value={msgDetails.startTime} disabled={this.props.mode == Mode.View} />
                  </Grid>
                  <Grid item sm={4} md={4} className="msg-enddate-container">
                    <div className="input-label">End Date</div>
                    <DatePicker onChange={this.endDateChange} value={msgDetails.endDate} min={new Date()} disabled={this.state.noEndDate || this.props.mode == Mode.View} />
                    <TimePicker onChange={this.endDateTimeChange} value={msgDetails.endTime} disabled={this.state.noEndDate || this.props.mode == Mode.View} />
                    {messageInEdit && messageInEdit.messageType == 1 && <><div className="msg-no-enddate-content">
                      <Checkbox
                        defaultValue={this.state.noEndDate}
                        onChange={this.makeEndDateDisableChange}
                        name="msgNoEndDate"
                        label="This message doesn’t have an end date."
                        disabled={this.props.mode == Mode.View}
                      />
                    </div>
                    <div className="msg-force-logout-content">
                      <Checkbox
                        defaultChecked={msgDetails.forceLogoutOnDisagree}
                        defaultValue={msgDetails.forceLogoutOnDisagree}
                        onChange={this.forceLogoutOnDisagreeChange}
                        name="msgForceLogout"
                        label="If the user denies agreement, force logout."
                        disabled={this.props.mode == Mode.View}
                      />
                    </div></>}
                  </Grid>
                  {messageInEdit && messageInEdit.messageType == 2 && <Grid item sm={4} md={4}>
                    <div className="msg-trigger-container">
                      <label className="input-label">Timezone</label>
                      <DropDownList
                        data={timezones}
                        defaultValue={msgDetails}
                        onChange={this.onTimezoneChange}
                        name="messageTimezone"
                        key="timezone"
                        textField="timezone"
                        dataItemKey="timezone"
                        defaultItem="(select one)"
                        disabled={this.props.mode == Mode.View}
                      />
                    </div>
                  </Grid>}
                  {(!msgDetails.noEndDate && endDateTime && endDateTime < startDateTime) &&
                    <Grid item sm={12} className="msg-dates-error">
                      <div className="error-info">
                        <ErrorIcon className="error-icon" />
                        <span className="error-msg">End date must be greater than Start date.</span>
                      </div>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </CollapsibleSection>
        <CollapsibleSection headerContent={messageInEdit.messageType ==2 ? <div><span>Upcoming Maintenance Content</span> <br /> <span className="subTitle">Type or paste the title and contents that will appear as the message</span></div> : <div>Message Content</div>} title={""}>
          <Paper className="grid-wrapper">
            <div >
              {messageInEdit && messageInEdit.messageType == 2 && <div className="repeatCheckbox">
                <Checkbox
                  defaultValue={msgDetails.repeat}
                  onChange={this.repeatChange}
                  name="msgRepeat"
                  label="Let user choose: Do not display the message again"
                  disabled={this.props.mode == Mode.View}
                />
              </div>}
              <div>
                <label className="input-label">Title</label>
                <TextInput
                  type="text"
                  name="title"
                  defaultValue={msgDetails.title}
                  onChange={this.onFormInputChangeEvent}
                  maxLength={255}
                  validations={[
                    { name: "required", errorMessage: ErrorMessages.Required }
                  ]}
                  disabled={this.props.mode == Mode.View}
                />
              </div>
              <div className="message-editor-container">
                <label className="input-label">Content</label>
                {this.props.mode === Mode.View
                  ? <div className="disabled-div"><ReactMarkdown children={msgDetails.message} /></div>
                  : <Editor
                    tools={[
                      [Bold, Italic, Link, Unlink, InsertImage],
                      [Undo, Redo],
                      [OrderedList, UnorderedList],
                      FormatBlock]}
                    contentStyle={{ height: 320 }}
                    onChange={this.contentChangeEvent}
                    value={this.state.editorValue}
                    onMount={this.onMount}
                  />}
              </div>
            </div>
          </Paper>
        </CollapsibleSection>
      </div>
      <div className="msg-employer-list-wrapper">
        <GridSelector
          addClass="msg-employer-list-modal"
          isOpen={this.state.openEmployerSelector}
          acceptBtnText="Save Selection"
          cancelBtnText="Cancel"
          prefixTitleText="Select Employers"
          titleText=""
          addAvailable={this.state.enableAddEmployerBtn}
          onClose={this.handleEmployerPopupClose}
          fullWidth={false}
          maxWidth="md"
        >
          <EmployerListWithDomains enableSelection={true} selectionChange={this.handlePotentiallySelected} selectedEmployers={selectedEmployers} />
        </GridSelector>

        <GridSelector
          addClass="msg-employer-list-modal"
          isOpen={this.state.openApplicationSelector}
          acceptBtnText="Save Selection"
          cancelBtnText="Cancel"
          prefixTitleText="Select App Instances"
          titleText=""
          addAvailable={this.state.enableAddApplicationBtn}
          onClose={this.handleApplicationsPopupClose}
          fullWidth={false}
          maxWidth="md"
        >
          <ApplicationList handleSelectedApplicationsChange={this.handleSelectedApplicationChange} selectedApplications={ this.state.selectedApplications} />
        </GridSelector>
      </div>
    </>);
  };

}

const mapStateToProps = function (state: IApplicationState, ownProps: IProps) {
  return { ...ownProps, ...state.sessionState };
}

export default connect(mapStateToProps, {})(Edit as any);