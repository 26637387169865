import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import { GridCellProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ResourceServices from "../../../services/ResourceService";
import EditGrid, { ChangeErrorEvent, IGridEditItem, IGridToolBarConfig } from "../../common/grid/EditGrid";
import { IPermissionGroup, IResource, IResourceGroups } from "../../../models/ResourceModels";
import SingleSelectDropDown, {
  ISingleSelectDropSelectedEvent,
  SingleSelectDropDownChangeEvent
} from "../../common/SingleSelectDropDown";
import { Role } from "../../../models/Role";
import { UserGroup } from "../../../models/UserModels";
import AdminService from "../../../services/AdminService";
import "./Assignments.scss";
import { IGridParams } from "../../common/grid/AdvancedGrid";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { DropDownFilter } from "../../common/grid/filters/DropDownFilter";
import ResourceService from "../../../services/ResourceService";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import NotificationService from "../../../services/NotificationService";
import * as SessionStore from "../../../store/Session";
import { ResourceTypes } from "../../../models/Enums";
import GridSelector from "../../common/grid/GridSelector";
import AssignmentDetails from "./AssignmentDetails";
import ClientSetupWizard from "../resource/clientsetup/ClientSetupWizard";
import { ServiceResponseJson } from "../../../services/ServiceBase";

interface IResourceType {
  displayName: string;
  filterValue: string;
}

type selectedResource = {
  type: number;
  id: number;
  name: string;
  epiqOnly?: boolean;
  automated?: string;
  showViewButton: false;
  description: string;
}

interface IProps { }

type Props = IProps & SessionStore.ISessionState;

const Assignments = (props: Props) => {
  const [resourceTypes, setResourceTypes] = useState<Array<IResourceType>>(new Array<IResourceType>());
  const [selectedResourceDetails, setSelectedResourceDetails] = useState({} as selectedResource);
  const [isOpenDetailsView, setIsOpenDetailsView] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(true);
  const [disablePlusIcon, setDisablePlusIcon] = useState(true);
  const permissions = props.sessionData.permissions;
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const gridParams: IGridParams = {
    skip: 0,
    take: 100
  };
  const resourceTypeFilterFields = new Array<string>(
    "resourcesGroup.id"
  );
  const resourceGroupFilterValue = "Resource Group";
  const fetchResourceTypes = async () => {
    var result = await ResourceService.getResourceTypes();
    var resourceTypes = new Array<IResourceType>();

    if (result.ok) {
      result.data.forEach((resourceType: string) =>
        resourceTypes.push({ displayName: resourceType, filterValue: resourceType })
      );
      resourceTypes.push({ displayName: resourceGroupFilterValue, filterValue: resourceGroupFilterValue });
      setResourceTypes(resourceTypes);
    }
  };
  const fetchResourceGridData = (dataState: IGridParams) => {
    //We need to look in filters for Resource Type filter, if it is present then handle it in a special way
    //Because it filters on two differnt fields which have differnt value types.
    var filters = dataState.filters.map(compositeFilter => {
      const resourceTypeFilter: CompositeFilterDescriptor = compositeFilter.filters.find(
        filter =>
          (filter as FilterDescriptor).field === resourceTypeFilterFields[0] ||
          (filter as FilterDescriptor).field === resourceTypeFilterFields[1]
      ) as CompositeFilterDescriptor;

      if (resourceTypeFilter) {
        const resourceFilterValue = ((compositeFilter.filters[0] as FilterDescriptor).value as IResourceType)
          .filterValue;

        if (resourceFilterValue === resourceGroupFilterValue) {
          const newFilters = compositeFilter.filters.filter(
            filterDescriptor => (filterDescriptor as FilterDescriptor).field === resourceTypeFilterFields[1]
          );

          return {
            ...compositeFilter,
            filters: [{ ...(newFilters[0] as FilterDescriptor), operator: "gt", value: 0 }]
          };
        } else {
          const newFilters = compositeFilter.filters
            .filter(filterDescriptor => (filterDescriptor as FilterDescriptor).field !== resourceTypeFilterFields[1])
            .map(filter => {
              filter = filter as FilterDescriptor;

              return {
                ...filter,
                value: (filter.value as IResourceType).filterValue || filter.value
              };
            });

          return { ...compositeFilter, filters: newFilters };
        }
      }

      return compositeFilter;
    });

    return ResourceServices.getPermissionGroups({ ...dataState, filters: filters });
  };

  const onErrorMessage = function (error: ChangeErrorEvent, update: boolean) {
    const msg = error.isValid
      ? error.message
      : `Something went wrong. ${update ? "Update not saved" : "Could not be created"}`;
    NotificationService.showErrorToast(msg);
  };

  const userGroup = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {
      const request: IGridParams = { skip: 0, take: 50 };

      return (
        <td>
          <label> Select Group </label>
          <div className="resource-selector">
            <SingleSelectDropDown
              getItems={params => permissions.has("EpiqAdminListUserGroups") ? AdminService.getUserGrups(params) : {} as ServiceResponseJson}
              textToRequestConverter={input => {
                return { ...request, searchText: input };
              }}
              onChange={event => {
                if (event) {
                  const id = event.data ? event.data.groupId : null;
                  const updated = { ...props.dataItem, usersGroup: event.data, usersGroupId: id };
                  disablePlusButton(updated);
                  props.onChange({
                    dataItem: updated,
                    dataIndex: props.dataIndex,
                    field: "dataItem",
                    syntheticEvent: event.subEvent ? (event.subEvent as ComboBoxChangeEvent).syntheticEvent : null
                  });
                }
              }}
              preselectedValue={
                props.dataItem.usersGroup
                  ? {
                    id: props.dataItem.usersGroup.groupId,
                    text: props.dataItem.usersGroup.groupName,
                    data: props.dataItem.usersGroup
                  }
                  : props.dataItem.usersGroup
              }
              typeToListConverter={(gs: UserGroup[]) =>
                gs.map(g => {
                  return {
                    id: g.groupId,
                    text: g.groupName,
                    data: g
                  };
                })
              }
            />
          </div>
        </td>
      );
    }

    const selectedResource = {
      type: ResourceTypes.USER_GROUP,
      id: props.dataItem.usersGroup.groupId,
      name: props.dataItem.usersGroup.groupName,
      epiqOnly: props.dataItem.usersGroup.epiqOnly,
      showViewButton: permissions.has("EpiqAdminGetUserGroup"),
      description: props.dataItem.usersGroup.comments
    } as selectedResource

    return <td>{permissions.has("EpiqAdminGetUserGroup") ? assignmentCellLink(selectedResource) : assignmentCell(selectedResource)}</td>;
  };
  const role = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {
      const request: IGridParams = { skip: 0, take: 50 };

      return (
        <td>
          <label> Select Role </label>
          <div className="resource-selector">
            <SingleSelectDropDown
              getItems={params => permissions.has("EpiqAdminListRoles") ? AdminService.getRoles(params) : {} as ServiceResponseJson}
              textToRequestConverter={input => {
                return { ...request, searchText: input };
              }}
              onChange={event => {
                if (event) {
                  const id = event.data ? event.data.id : null;
                  const updated = { ...props.dataItem, role: event.data, roleId: id };
                  disablePlusButton(updated);
                  props.onChange({
                    dataItem: updated,
                    dataIndex: props.dataIndex,
                    field: "dataItem",
                    syntheticEvent: event.subEvent ? (event.subEvent as ComboBoxChangeEvent).syntheticEvent : null
                  });
                }
              }}
              preselectedValue={
                props.dataItem.role
                  ? {
                    id: props.dataItem.role.id,
                    text: props.dataItem.role.roleName,
                    data: props.dataItem.role
                  }
                  : props.dataItem.role
              }
              typeToListConverter={(roles: Role[]) =>
                roles.map(r => {
                  return {
                    id: r.id,
                    text: r.roleName,
                    data: r
                  };
                })
              }
            />
          </div>
        </td>
      );
    }

    const selectedResource = {
      type: ResourceTypes.ROLE,
      id: props.dataItem.role.id,
      name: props.dataItem.role.roleName,
      epiqOnly: props.dataItem.role.epiqOnly,
      showViewButton: permissions.has("EpiqAdminGetRole"),
      description: props.dataItem.role.description
    } as selectedResource;

    return <td>{permissions.has("EpiqAdminGetRole") ? assignmentCellLink(selectedResource) : assignmentCell(selectedResource)}</td>;

  };
  const resource = (props: GridCellProps) => {
    if (props.dataItem._inEdit) {
      const request: IGridParams = { skip: 0, take: 50 };
      const hasGroup = props.dataItem.resourcesGroupId && !props.dataItem.resourcesGroup.groupName ? false : true;
      const resourcedataItem = props.dataItem.resourcesGroupId
        ? props.dataItem.resourcesGroup
        : props.dataItem.singleResource;
      const textKey = hasGroup ? "groupName" : "name";

      return (
        <td>
          <label> Select Resource </label>
          <div className="resource-selector">
            <SingleSelectDropDown
              getItems={params => {
                if (hasGroup) {
                  return permissions.has("EpiqAdminListResourceGroups") ? ResourceServices.getResourceGroups(params) : {} as ServiceResponseJson;
                }
              }}
              textToRequestConverter={input => {
                return { ...request, searchText: input };
              }}
              onChange={(
                selected: ISingleSelectDropSelectedEvent<IResource & IResourceGroups>,
                e: SingleSelectDropDownChangeEvent
              ) => {
                const resource = selected ? selected.data : null;
                const id = resource ? resource.id : null;
                const updated = hasGroup
                  ? {
                    ...props.dataItem,
                    resourcesGroup: resource,
                    resourcesGroupId: id,
                    resourceId: null,
                    singleResource: null
                  }
                  : {
                    ...props.dataItem,
                    singleResource: null,
                    resourceId: id,
                    resourcesGroupId: null,
                    resourcesGroup: null
                  };

                disablePlusButton(updated);
                props.onChange({
                  dataItem: updated,
                  dataIndex: props.dataIndex,
                  field: "dataItem",
                  syntheticEvent: e ? e.syntheticEvent : null
                });
              }}
              preselectedValue={
                resourcedataItem
                  ? {
                    id: resourcedataItem.id,
                    text: hasGroup
                      ? `${resourcedataItem[textKey]}`
                      : `${resourcedataItem[textKey]} (${resourcedataItem.resourceTypeNavigation.displayName})`,
                    data: resourcedataItem
                  }
                  : null
              }
              typeToListConverter={(resourceItem: (IResource & IResourceGroups)[]) => {
                return resourceItem.map(item => {
                  return {
                    id: item.id,
                    text: hasGroup
                      ? `${item[textKey]}`
                      : `${item[textKey]} (${item.resourceTypeNavigation.displayName})`,
                    data: item
                  };
                });
              }}
            />
          </div>
        </td>
      );
    }

    const selectedResource = {
      type: ResourceTypes.RESOURCE_GROUP,
      id: props.dataItem.resourcesGroupId,
      name: props.dataItem.resourcesGroup.groupName,
      epiqOnly: props.dataItem.resourcesGroup.epiqOnly,
      automated: (props.dataItem.resourcesGroup.automationFilter != null ? "Yes" : "No"),
      showViewButton: permissions.has("EpiqAdminGetResourceGroup"),
      description: props.dataItem.resourcesGroup.description
    } as selectedResource

    return (
      <td>
        {props.dataItem.resourcesGroupId ? (
          <>
            {permissions.has("EpiqAdminGetResourceGroup") ? assignmentCellLink(selectedResource) : assignmentCell(selectedResource)}
          </>
        ) : (
            <>{props.dataItem.singleResource.name}</>
          )}
      </td>
    );
  };
  const resourceType = (gridCellProps: GridCellProps) => {
    return (
      <td>
        <div>
          {gridCellProps.dataItem.resourcesGroupId
            ? "Resource Group"
            : gridCellProps.dataItem.singleResource
              ? gridCellProps.dataItem.singleResource.resourceTypeNavigation.displayName
              : ""}
        </div>
      </td>
    );
  };
  const columnProps = [
    { field: "usersGroup.GroupName", title: "USER GROUP", cell: userGroup, filterable: true },
    { field: "role.RoleName", title: "ROLE", cell: role, filterable: true },
    {
      field: "resourcesGroup.GroupName",
      title: "RESOURCE",
      cell: resource,
      filterable: true,
      sortable: true
    },
    {
      field: `${resourceTypeFilterFields[0]}|${resourceTypeFilterFields[1]}`,
      title: "RESOURCE Type",
      cell: resourceType,
      editable: false,
      filterable: false,
      filterCell: (props: GridFilterCellProps) => (
        <DropDownFilter {...props} data={resourceTypes} textField="displayName" defaultSelectedOption={null} />
      )
    }
  ];

  useEffect(() => {
    fetchResourceTypes();
  }, []);

  const assignmentCellLink = (selectedResourceDetails: selectedResource) => {
    return (
      <div className="assignmentLinks">
        <a
          className="k-link"
          onClick={e => {
            setIsOpenTooltip(false);
            setSelectedResourceDetails(selectedResourceDetails);
            setIsOpenDetailsView(true);
          }}
        >
          <em title={selectedResourceDetails.name} onMouseOver={() => setIsOpenTooltip(true)}>
            {selectedResourceDetails.name}
          </em>
        </a>
      </div>
    );
  };

  const assignmentCell = (selectedResourceDetails: selectedResource) => {
    return (
      <div className="assignmentLinks">
        <em title={selectedResourceDetails.name} onMouseOver={() => setIsOpenTooltip(true)}>{selectedResourceDetails.name}</em>
      </div>
    )
  };

  const handleAddUsersPopupClose = () => {
    setIsOpenDetailsView(false);
  };

  const getViewButtonLink = () => {
    let url = "";

    switch (selectedResourceDetails.type) {
      case ResourceTypes.USER_GROUP:
        url = url + `/administration/userGroupDetails/${selectedResourceDetails.id}`;
        break;
      case ResourceTypes.ROLE:
        url = url + `/administration/roleDetails/${selectedResourceDetails.id}`;
        break;
      case ResourceTypes.RESOURCE_GROUP:
        url = url + `/administration/resourceGroupDetails/${selectedResourceDetails.id}`;
        break;
    }

    return url;
  };

  const handleClientSetupWizard = (isWizardOpen: boolean) => {
    setIsWizardOpen(isWizardOpen);
  };

  const gridToolBarContent = (permissions: Set<string>) => {
    let gridToolBarConfig = {} as IGridToolBarConfig;
    const secondaryElement = (<><Button className="btn-secondary" primary={false} onClick={() => handleClientSetupWizard(true)}>
      CLIENT SETUP WIZARD
    </Button></>);

    if (permissions.has("EpiqAdminCreateAssignment")) {
      gridToolBarConfig.addButtonText = "ASSIGNMENT";
      gridToolBarConfig.cancelButtonText = "Cancel";
      gridToolBarConfig.totalRecordsText = "Assignments";
      gridToolBarConfig.btnClass = "btn-secondary";
    }

    if (permissions.has("EpiqAdminListRoles") && permissions.has("EpiqAdminListClients")
      && permissions.has("EpiqAdminListProjects") && permissions.has("EpiqAdminCreateResourceGroup")
      && permissions.has("EpiqAdminCreateUserGroup") && permissions.has("EpiqAdminCreateAssignment")
      && permissions.has("EpiqAdminGetResourceGroup")
    ) {
      gridToolBarConfig.secondaryToolBarElements = secondaryElement;
    }

    return gridToolBarConfig.addButtonText || gridToolBarConfig.secondaryToolBarElements ? gridToolBarConfig : {
      totalRecordsText: "Assignments"
    };
  };

  const disableSaveButton = (disable: boolean) => {
    setDisablePlusIcon(disable);
  }

  const disablePlusButton = (updatedItem: any) => {
    setDisablePlusIcon(!updatedItem.resourcesGroupId || !updatedItem.roleId || !updatedItem.usersGroupId);
  }

  return (
    <div className="resources-grid-container">
      <Tooltip openDelay={2} position="right" open={isOpenTooltip}>
        <EditGrid
          dataState={gridParams}
          getItems={fetchResourceGridData}
          noRecordsRender={<p>No assignments found.</p>}
          filteredDataOnly={<p>Please filter to search for assignments.</p>}
          updateDataItem={(item: IPermissionGroup & IGridEditItem) =>
            ResourceServices.updatePermissionGroups<IPermissionGroup & IGridEditItem>(item)
          }
          createDataItem={(item: IPermissionGroup & IGridEditItem) =>
            ResourceServices.addPermissionGroups<IPermissionGroup & IGridEditItem>(item)
          }
          deleteItem={(id: number) => ResourceServices.deletePermissionGroups(id)}
          hideDeleteIcon={!permissions.has("EpiqAdminDeleteAssignments")}
          hideUpdateIcon={!permissions.has("EpiqAdminUpdateAssignment")}
          columns={columnProps}
          gridToolbar={gridToolBarContent(permissions)}
          multiFieldFilterDelimiter="|"
          errorUpdatingItem={error => onErrorMessage(error, true)}
          errorCreatingItem={error => onErrorMessage(error, false)}
          disablePlusIcon={disablePlusIcon}
          updateDisablePlusIcon={(disable: boolean) => disableSaveButton(disable)}
        />
        <ClientSetupWizard isWizardOpen={isWizardOpen} setIsWizardOpen={handleClientSetupWizard} />
      </Tooltip>
      <GridSelector
        isOpen={isOpenDetailsView}
        viewBtnText={selectedResourceDetails.showViewButton ? "VIEW DETAILS" : ""}
        viewBtnUrl={getViewButtonLink()}
        prefixTitleText={
          ResourceTypes[selectedResourceDetails.type]
            ? ResourceTypes[selectedResourceDetails.type].replace("_", " ")
            : ""
        }
        titleText={selectedResourceDetails.name}
        addAvailable={false}
        onClose={handleAddUsersPopupClose}
        addClass="details-view-modal modal-as-sidebar"
      >
        <AssignmentDetails
          resourceId={selectedResourceDetails.id}
          resourceType={selectedResourceDetails.type}
          epiqOnly={selectedResourceDetails.epiqOnly ? "Epiq Admin" : "Non-Epiq Admin"}
          automated={selectedResourceDetails.automated}
          description={ selectedResourceDetails.description}
        />
      </GridSelector>
    </div>
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), {})(Assignments as any);
