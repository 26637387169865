import React from "react";
import { IWizardSection, IFieldValueLookup, IApplicationDatabaseFields, IAdditionalSummaryFields } from "./Wizard";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import ResourceService from "../services/ResourceService";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import CommonHelper from "../components/common/utilities/CommonHelper";
import { ftpUrlList } from "./TrackDataWorkRequestSteps";
import { ServiceResponseJson } from "../services/ServiceBase";

export enum DBRecordType {
  Epiq_Discovery_Processing = "Epiq Discovery (Transactional)",
  Nuix = "Nuix",
  DMX14_Processing = "DMX14 - Processing",
  Relativity_Hosting = "Relativity Hosting",
  Relativity_Processing = "Relativity Processing",
  DMX13 = "DMX13",
  Epiq_Discovery = "Epiq Discovery (Self-Service)",
  Everlaw = "Everlaw",
  Reveal = "Reveal",
  EMS_Relativity_Placeholder = "EMS Relativity Placeholder",
  Network_Storage = "Network Storage"
}

export const ILMProjectDeleteWorkRequestSteps = [
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:4``lookupName:describeSubHeader``defaultStr:SELECT DATABASE TO DELETE`]",
    description:
      "[`fieldLookup:4``lookupName:describePageDescription``defaultStr:When authorized to delete a processing or hosting database, Epiq destroys all stored data for that database. For a processing database, this action removes the ability to filter or globally dedupe against that data. When deleting a hosting database, that data is no longer available for review or production.`]",
    summaryTitle: "DATABASE TO DELETE",
    dependency: { fieldId: "3", compare: "eq", value: 1 },
    fields: [
      {
        type: "dropdown",
        fieldId: "11.1",
        label: "Indicate database use (processing or hosting).",
        name: "Use",
        active: false,
        resetForField: "0.1.1,0.1.2",
        dependency: { fieldId: "3", compare: "eq", value: 1 },
        DropDownOptions: [
          {
            id: 1,
            title: "Processing Database",
            lookupValues: {
              label: "Review the Processing databases to delete.",
              describeSubHeader: "SELECT DATABASE TO DELETE",
              describePageDescription:
                "When authorized to delete a processing or hosting database, Epiq destroys all stored data for that database. For a processing database, this action removes the ability to filter or dedupe against that data. When deleting a hosting database, that data is no longer available for review or production."
            }
          },
          {
            id: 2,
            title: "Hosting Database",
            lookupValues: {
              label: "Review the Hosting databases to delete.",
              describeSubHeader: "SELECT DATABASE TO DELETE",
              describePageDescription:
                "When authorized to delete a processing or hosting database, Epiq destroys all stored data for that database. For a processing database, this action removes the ability to filter or dedupe against that data. When deleting a hosting database, that data is no longer available for review or production."
            }
          }
        ]
      },
      {
        type: "tableViewField",
        fieldId: "11.2",
        name: "Type",
        dataItemKey: "Id",
        selectable: true,
        selectionMode: "single",
        getColumns: (dataItems: any) => {
          const columns = [{ field: "Type", title: "Type" }, { field: "Database", title: "Database" }] as Array<
            GridColumnProps
          >;
          return columns;
        },
        label: "Select database.",
        active: false,
        summaryCssName: "summary-tableview-list",
        resetForField: "11.1",
        dependency: { fieldId: "11.1", compare: "eq", value: 1 },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const createdDate = item.fields["AppDb CreatedDate"]
              ? formatDate(new Date(item.fields["AppDb CreatedDate"]), "MMM. dd,yyyy")
              : "--";
            const dbName = item.fields["Db Record Type"] === DBRecordType.Epiq_Discovery ? item.fields["EqD Database Name"] : item.fields["Database Name"];
            const lookupData = GetProcessingAppDbBillingCodes(
              item.fields["Db Record Type"],
              item.fields["AppDbId"],
              dbName
            );

            return {
              Id: item.fields["AppDbId"],
              Type: item.fields["Db Record Type"],
              Database: dbName,
              Workspace: item.fields["Relativity Host"],
              WebsiteUrl: item.fields["Website Url"],
              CreatedDate: item.fields["AppDb CreatedDate"]
                ? formatDate(new Date(item.fields["AppDb CreatedDate"]), "MM/dd/yyyy")
                : "--",
              SummaryTitle: item.fields["Db Record Type"],
              LookupValues: {
                Data: [lookupData] as Array<IApplicationDatabaseFields>,
                Database: dbName,
                AdditionalSummaryFields: {
                  Database: dbName,
                  "Created Date": createdDate,
                  URL: item.fields["Website Url"]
                } as IAdditionalSummaryFields
              }
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            filterPresets: ["NotDeletedPendingProcessingApps"]
          };
        }
      },
      {
        type: "tableViewField",
        fieldId: "11.3",
        resetForField: "11.1",
        dataItemKey: "Id",
        name: "Type",
        label: "Select database.",
        active: false,
        summaryCssName: "summary-tableview-list",
        dependency: { fieldId: "11.1", compare: "eq", value: 2 },
        selectable: true,
        selectionMode: "single",
        getColumns: (dataItems: any) => {
          let dbColumn = { field: "Database", title: "Database" } as GridColumnProps;
          if (dataItems) {
            for (let i = 0; i < dataItems.length; i++) {
              if (dataItems[i].Type === DBRecordType.Relativity_Hosting) {
                dbColumn.field = "Workspace";
                dbColumn.title = "Workspace";
                break;
              }
            }
          }

          const columns = [{ field: "Type", title: "Type" }, dbColumn] as Array<GridColumnProps>;
          return columns;
        },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const createdDate = item.fields["AppDb CreatedDate"]
              ? formatDate(new Date(item.fields["AppDb CreatedDate"]), "MMM. dd,yyyy")
              : "--";
            const dbName =
              item.fields["Db Record Type"] === DBRecordType.Relativity_Hosting
                ? item.fields["Relativity Host"]
                : (item.fields["Db Record Type"] === DBRecordType.Epiq_Discovery ? item.fields["EqD Database Name"] : item.fields["Database Name"]);
            const lookupData = GetHostingAppDbBillingCodes(
              item.fields["Db Record Type"],
              item.fields["AppDbId"],
              dbName
            );

            return {
              Id: item.fields["AppDbId"],
              Type: item.fields["Db Record Type"],
              Database: dbName,
              Workspace: item.fields["Relativity Host"],
              WebsiteUrl: item.fields["Website Url"],
              CreatedDate: item.fields["AppDb CreatedDate"]
                ? formatDate(new Date(item.fields["AppDb CreatedDate"]), "MM/dd/yyyy")
                : "--",
              SummaryTitle: item.fields["Db Record Type"],
              LookupValues: {
                Data: [lookupData] as Array<IApplicationDatabaseFields>,
                Database: dbName,
                AdditionalSummaryFields: {
                  Database: dbName,
                  "Created Date": createdDate,
                  URL: item.fields["Website Url"]
                } as IAdditionalSummaryFields
              }
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "Relativity Host Id", operator: "contains", value: searchText },
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["NotDeletedPendingHostingApps"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [
        { fieldId: "11.1", compare: "neq", value: null },
        {
          logic: "or",
          subConditions: [
            { fieldId: "11.2", compare: "neq", value: null },
            { fieldId: "11.3", compare: "neq", value: null }
          ]
        }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:3``lookupName:describeSubHeader``defaultStr:DESCRIBE THE DATA`]",
    description:
      "[`fieldLookup:3``lookupName:describePageDescription``defaultStr:To process your request, we need to know more about the data that was previously provided and where you want the data loaded.`]",
    summaryTitle: "PROJECT MEDIA TO DELETE",
    dependency: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    },
    fields: [
      {
        type: "tableViewField",
        name: "Media",
        fieldId: "3.1",
        resetForField: "0.1.1,0.1.2",
        label: "Review the media to delete.",
        active: false,
        summaryCssName: "summary-tableview-list",
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
        },
        getColumns: (dataItems: any) => {
          const columns = [
            { field: "MediaDateRecieved", title: "Date Received", width: "250px", format: "{0:MM/dd/yyyy}" },
            { field: "MediaId", title: "Media ID", width: "80px" },
            { field: "MediaType", title: "Media Type", width: "200px" },
            { field: "MediaDescription", title: "Description" }
          ] as Array<GridColumnProps>;
          return columns;
        },
        exportExcelSettings: (dataItems: any) => {
          const exportItems = {
            fileName: `${CommonHelper.getCurrentDate("YYYYMMDD", "")}-Media [projectName]`,
            fields: [
              { field: "MediaDateRecieved", title: "Date Received", width: "120px" },
              { field: "MediaId", title: "Media ID", width: "80px" },
              { field: "MediaType", title: "Media Type", width: "80px" },
              { field: "MediaPosession", title: "Media Possession (CoC)", width: "200px" },
              { field: "MediaCreatedDate", title: "Date Created", width: "120px" },
              { field: "MediaDescription", title: "Media Description", width: "250px" }
            ]
          };
          return exportItems;
        },
        GetData: ResourceService.getResourcesUser,
        ValuesConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const mediaRecievedDate = item.MediaDateRecieved ? formatDate(new Date(item.MediaDateRecieved), "MM/dd/yyyy") : "";
            return `${item.MediaId}: ${mediaRecievedDate} ${item.MediaType ? '-' + item.MediaType : ""} ${item.MediaDescription ? '-' + item.MediaDescription : ""}`;
          });
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const itemField = item.fields ? item.fields : item;
            const mediaRecieved = itemField.MediaDateRecieved
              ? new Date(itemField.MediaDateRecieved)
              : null;
            const recievedDateResultValue = itemField.MediaDateRecieved ? formatDate(new Date(itemField.MediaDateRecieved), "MM/dd/yyyy") : "";

            return {
              SalesforceMediaId: itemField.MediaId,
              MediaName: itemField.MediaName,
              MediaId: itemField.MediaName,
              MediaType: itemField.MediaType,
              MediaDateRecieved: mediaRecieved,
              MediaDescription: itemField.MediaDescription,
              MediaPath: itemField.MediaArrivalPath,
              MediaCreatedDate: itemField.MediaCreatedDate
                ? new Date(itemField.MediaCreatedDate)
                : null,
              MediaPosession: itemField.MediaPosession,
              ResultValue: `${itemField.MediaName}: ${recievedDateResultValue} ${itemField.MediaType ? '-' + itemField.MediaType : ""} ${itemField.MediaDescription ? '-' + itemField.MediaDescription : ""
                }`
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "MediaName", operator: "contains", value: searchText },
                { field: "MediaType", operator: "contains", value: searchText },
                { field: "MediaDescription", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          //const date = searchText ? /^.*?(\d{2}\/\d{2}\/\d{4}).*?$/.exec(searchText) : null;
          const date = searchText ? /^.*?(\d{4}\-\d{2}\-\d{2}).*?$/.exec(searchText) : null;
          if (date) {
            filters[0].filters.push({ field: "MediaDateRecieved", operator: "eq", value: date[1] });
          }

          return {
            take: 1000,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            dedupeFieldName: "MediaId",
            flattenResults: true,
            joinFields: ["Medias"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["ActiveMedia"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "LIST MEDIA",
    description:
      "When removing media from a project, you select the media to remove from a list. By default, the list displays all media in the project. For your convenience, you can also choose to filter the list to display only media with an expired grace period.",
    summaryTitle: "LIST MEDIA",
    dependency: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 2 }]
    },
    fields: [
      {
        type: "radiogroup",
        label: "What do you want the media list to display?",
        name: "Media List",
        fieldId: "3.1.2",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: true,
        resetForField: "0.1.2",
        radioButtonOptions: [
          {
            id: 1,
            title: "Show all media"
          },
          {
            id: 2,
            title: "Filter for media with an expired grace period"
          }
        ]
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3.1.2", compare: "eq", value: 1 }, { fieldId: "3.1.2", compare: "eq", value: 2 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "PAUSE TO RETURN MEDIA",
    description:
      "If you need media returned, that must occur prior to this work request. If you want media returned, select Yes and then click SAVE FOR LATER. This action pauses your closure request so you can contact your Epiq Project Manager. After receiving the media, return here to continue with your saved work request.",
    summaryTitle: "PAUSE TO RETURN MEDIA",
    dependency: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    },
    fields: [
      {
        type: "radiogroup",
        label: "Do you need your media returned?",
        name: "Media Returned",
        fieldId: "3.1.1",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Yes.  I will contact my Project Manager at Epiq."
          },
          {
            id: 2,
            title: "No, I don’t need media returned or have already received it."
          }
        ]
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3.1.1", compare: "eq", value: 2 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "REVIEW PROCESSING DATABASES IN PROJECT",
    description:
      "When authorized to close a project, Epiq destroys all processing databases associated with the project. A processing database is where data is extracted through search, deduplication, and filtering. Review the  processing databases that will be deleted with this project closure.",
    summaryTitle: "PROCESSING DATABASES TO DELETE",
    dependency: {
      logic: "and",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    },
    fields: [
      {
        type: "tableViewField",
        fieldId: "3.2",
        name: "Processing databases",
        dataItemKey: "ResultValue",
        getColumns: (dataItems: any) => {
          const columns = [
            { field: "Type", title: "Type" },
            { field: "Database", title: "Database" },
            { field: "CreatedDate", title: "Created Date", format: "{0:MM/dd/yyyy}" }
          ] as Array<GridColumnProps>;
          return columns;
        },
        exportExcelSettings: (dataItems: any) => {
          const exportItems = {
            fileName: `${CommonHelper.getCurrentDate("YYYYMMDD", "")}-Processing [projectName]`,
            fields: [
              { field: "Type", title: "Type" },
              { field: "Database", title: "Database" },
              { field: "CreatedDate", title: "Created Date" }
            ]
          };
          return exportItems;
        },
        lookupCompletion: (dataResult: any) => {
          const appDbs = dataResult.results.map((item: any) => {
            return GetProcessingAppDbBillingCodes(item.fields["Db Record Type"], item.fields["AppDbId"]);
          });
          return {
            success: true,
            lookupValues: { Data: appDbs }
          };
        },
        lookupError:
          "Error determining processing database for this project. Please contact your client service representative.",
        watchField: "0.1.1",
        label: "Review the Processing databases to delete.",
        resetForField: "0.1.1",
        active: false,
        summaryCssName: "summary-tableview-list",
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
        },
        GetData: ResourceService.getResourcesUser,
        ValuesConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const createdDate = item.CreatedDate ? formatDate(new Date(item.CreatedDate), "MM/dd/yyyy") : "";
            return `${item.Type} - ${item.Database} - ${createdDate}`;
          });
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const createdDate = item.fields["AppDb CreatedDate"]
              ? new Date(item.fields["AppDb CreatedDate"])
              : null;
            const createdDateResultValue = item.fields["AppDb CreatedDate"] ? formatDate(new Date(item.fields["AppDb CreatedDate"]), "MM/dd/yyyy") : "";

            return {
              Type: item.fields["Db Record Type"],
              Database: item.fields["Database Name"],
              Workspace: item.fields["Relativity Host"],
              CreatedDate: createdDate,
              ResultValue: `${item.fields["Db Record Type"]} - ${item.fields["Database Name"]} - ${createdDateResultValue}`
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: filters,
            filterPresets: ["NotDeletedProcessingApps"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "REVIEW HOSTING DATABASES IN PROJECT",
    description:
      "When authorized to close a project, Epiq destroys all hosting databases associated with the project. The hosting database is used for review, export, and production. Review the hosting databases that will be deleted with this project closure.",
    summaryTitle: "HOSTING DATABASES TO DELETE",
    dependency: {
      logic: "and",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    },
    fields: [
      {
        type: "tableViewField",
        fieldId: "3.3",
        resetForField: "0.1.1,0.1.2",
        dataItemKey: "ResultValue",
        name: "Hosting databases",
        label: "Review the Hosting databases to delete.",
        active: false,
        summaryCssName: "summary-tableview-list",
        getColumns: (dataItems: any) => {
          let dbColumn = { field: "Database", title: "Database" } as GridColumnProps;
          if (dataItems) {
            for (let i = 0; i < dataItems.length; i++) {
              if (dataItems[i].Type === DBRecordType.Relativity_Hosting) {
                dbColumn.field = "Workspace";
                dbColumn.title = "Workspace";
                break;
              }
            }
          }

          const columns = [
            { field: "Type", title: "Type" },
            dbColumn,
            { field: "CreatedDate", title: "Created Date", format: "{0:MM/dd/yyyy}" }
          ] as Array<GridColumnProps>;
          return columns;
        },
        exportExcelSettings: (dataItems: any) => {
          let dbColumn = { field: "Database", title: "Database" } as GridColumnProps;
          if (dataItems) {
            for (let i = 0; i < dataItems.length; i++) {
              if (dataItems[i].Type === DBRecordType.Relativity_Hosting) {
                dbColumn.field = "Workspace";
                dbColumn.title = "Workspace";
                break;
              }
            }
          }

          const exportItems = {
            fileName: `${CommonHelper.getCurrentDate("YYYYMMDD", "")}-Hosting [projectName]`,
            fields: [
              { field: "Type", title: "Type" },
              dbColumn,
              { field: "CreatedDate", title: "Created Date" },
              { field: "WebsiteUrl", title: "Website URL" }
            ]
          };
          return exportItems;
        },
        lookupCompletion: (dataResult: any) => {
          const appDbs = dataResult.results.map((item: any) => {
            return GetHostingAppDbBillingCodes(item.fields["Db Record Type"], item.fields["AppDbId"]);
          });
          return {
            success: true,
            lookupValues: { Data: appDbs }
          };
        },
        lookupError:
          "Error determining processing database for this project. Please contact your client service representative.",
        GetData: ResourceService.getResourcesUser,
        ValuesConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const name = item.Type === DBRecordType.Relativity_Hosting ? item.Workspace : item.Database;
            const createdDate = item.CreatedDate ? formatDate(new Date(item.CreatedDate), "MM/dd/yyyy") : "";
            return `${item.Type} - ${name} - ${createdDate}`;
          });
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const dbName = item.fields["Db Record Type"] === DBRecordType.Relativity_Hosting ? item.fields["Relativity Host"] : (item.fields["Db Record Type"] === DBRecordType.Epiq_Discovery ? item.fields["EqD Database Name"] : item.fields["Database Name"]);
            const createdDate = item.fields["AppDb CreatedDate"]
              ? new Date(item.fields["AppDb CreatedDate"])
              : null;
            const createdDateResultValue = item.fields["AppDb CreatedDate"] ? formatDate(new Date(item.fields["AppDb CreatedDate"]), "MM/dd/yyyy") : "";

            return {
              Type: item.fields["Db Record Type"],
              Database: dbName,
              Workspace: item.fields["Relativity Host"],
              WebsiteUrl: item.fields["Website Url"],
              CreatedDate: createdDate,
              ResultValue: `${item.fields["Db Record Type"]} - ${dbName} - ${createdDateResultValue}`
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "Relativity Host Id", operator: "contains", value: searchText },
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Database Name", operator: "contains", value: searchText },
                { field: "EqD Database Name", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["NotDeletedHostingApps"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 4 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:3``lookupName:describeSubHeader``defaultStr:DESCRIBE THE DATA`]",
    description:
      "[`fieldLookup:3``lookupName:describePageDescription``defaultStr:To process your request, we need to know more about the data that was previously provided and where you want the data loaded.`]",
    summaryTitle: "PROJECT MEDIA TO DELETE",
    dependency: {
      logic: "or",
      subConditions: [{ fieldId: "3", compare: "eq", value: 2 }]
    },
    fields: [
      {
        type: "tableViewField",
        name: "Media",
        fieldId: "3.4",
        resetForField: "0.1.2,3.1.2",
        selectable: true,
        hideFieldIdLabel: true,
        selectionMode: "multi", // Checkbox will appeared to select mu;tiple objects from table view
        dataItemKey: "ResultValue", // Needed when selectable = true, dataItemKey is unique value to check object was selected or not
        label: "Select the media to delete.",
        active: false,
        summaryCssName: "summary-tableview-list",
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "3", compare: "eq", value: 2 }]
        },
        getColumns: (dataItems: any) => {
          const columns = [
            { field: "MediaDateRecieved", title: "Date Received", width: "250px", format: "{0:MM/dd/yyyy}" },
            { field: "MediaId", title: "Media ID", width: "80px" },
            { field: "MediaType", title: "Media Type", width: "200px" },
            { field: "MediaDescription", title: "Description" }
          ] as Array<GridColumnProps>;
          return columns;
        },
        exportExcelSettings: (dataItems: any) => {
          const exportItems = {
            fileName: `${CommonHelper.getCurrentDate("YYYYMMDD", "")}-Media [projectName]`,
            fields: [
              { field: "MediaDateRecieved", title: "Date Received", width: "120px" },
              { field: "MediaId", title: "Media ID", width: "80px" },
              { field: "MediaType", title: "Media Type", width: "80px" },
              { field: "MediaPosession", title: "Media Possession (CoC)", width: "200px" },
              { field: "MediaCreatedDate", title: "Date Created", width: "120px" },
              { field: "MediaDescription", title: "Media Description", width: "250px" }
            ]
          };
          return exportItems;
        },
        GetData: ResourceService.getResourcesUser,
        GetTotalCount: ResourceService.getResourcesDetailsCount,
        ValuesConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const mediaDateRecieved = item.MediaDateRecieved ? formatDate(new Date(item.MediaDateRecieved), "MM/dd/yyyy") : "";
            return `${item.MediaId}: ${mediaDateRecieved} ${item.MediaType ? '-' + item.MediaType : ""} ${item.MediaDescription ? '-' + item.MediaDescription : ""}`;
          });
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const itemField = item.fields ? item.fields : item;
            const mediaRecieved = itemField.MediaDateRecieved
              ? new Date(itemField.MediaDateRecieved)
              : null;
            const mediaRecievedResultValue = itemField.MediaDateRecieved ? formatDate(new Date(itemField.MediaDateRecieved), "MM/dd/yyyy") : "";
            return {
              SalesforceMediaId: itemField.MediaId,
              MediaName: itemField.MediaName,
              MediaId: itemField.MediaName,
              MediaType: itemField.MediaType,
              MediaDateRecieved: mediaRecieved,
              MediaDescription: itemField.MediaDescription,
              MediaPath: itemField.MediaArrivalPath,
              MediaCreatedDate: itemField.MediaCreatedDate
                ? new Date(itemField.MediaCreatedDate)
                : null,
              MediaPosession: itemField.MediaPosession,
              ResultValue: `${itemField.MediaName}: ${mediaRecievedResultValue} ${itemField.MediaType ? '-' + itemField.MediaType : ""} ${itemField.MediaDescription ? '-' + itemField.MediaDescription : ""
                }`
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const enableGracedPeriodExpired = fieldValues["3.1.2"].value === 2 ? true : false;
          let filters = [
            {
              logic: "and",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          if (enableGracedPeriodExpired) {
            filters.push({
              logic: "and",
              filters: [
                {
                  field: "ClientMediaStorageGracePeriodRemain",
                  operator: "lt",
                  value: 0
                }
              ] as Array<FilterDescriptor>
            });
          }

          return {
            take: 1000,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            dedupeFieldName: "MediaId",
            flattenResults: true,
            joinFields: ["Medias"],
            filters: filters,
            filterPresets: ["ActiveMedia"]
          };
        },
        GetTotalCountParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const enableGracedPeriodExpired = fieldValues["3.1.2"].value === 2 ? true : false;
          let filters = [
            {
              logic: "and",
              filters: [{ field: "SalesForceId", operator: "eq", value: fieldValues["0.1.1"].lookupValues["SalesForceId"] }] as Array<
                FilterDescriptor
              >
            }
          ] as CompositeFilterDescriptor[];

          if (enableGracedPeriodExpired) {
            filters.push({
              logic: "and",
              filters: [
                {
                  field: "ClientMediaStorageGracePeriodRemain",
                  operator: "lt",
                  value: 0
                }
              ] as Array<FilterDescriptor>
            });
          }

          return {
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            joinFields: ["Medias"],
            filters: filters,
            filterPresets: ["ActiveMedia"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3.4", compare: "neq", value: null }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "RETURN METHOD",
    description: "Choose the return method that best suits your needs. We can return your media online using Epiq’s secure FTP site, or another site. Or, you can request that we ship physical media via a delivery service. When shipping is selected, specify the number of copies to send.",
    summaryTitle: "RETURN METHOD",
    dependency: {
      logic: "and",
      subConditions: [{ fieldId: "15", compare: "eq", value: 2 }]
    },
    fields: [
      {
        type: "radiogroup",
        label: "Select the media return method.",
        name: "Media Return Method",
        fieldId: "15.4",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Return media via FTP."
          },
          {
            id: 2,
            title: "Return hard drive of media."
          }
        ]
      }

    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "15.4", compare: "eq", value: 1 }, { fieldId: "15.4", compare: "eq", value: 2 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "IDENTIFY MEDIA TO RETURN",
    description: (existingFieldVals: IFieldValueLookup) => {
      if (existingFieldVals["15"] && existingFieldVals["15"].value === 1) {
        return "Review the list of physical media you shipped to Epiq for this project. Physical media includes hard drives, thumb drives, and other physical storage devices. Then, select the physical media that you want Epiq to return."
      }
      else if (existingFieldVals["15"] && existingFieldVals["15"].value === 2) {
        if (existingFieldVals["15.4"] && existingFieldVals["15.4"].value === 1) {
          return "Review the list of media you provided to Epiq via FTP. Then, select the media that you want returned from the following list. As a result of submitting this request, Epiq will return this media via FTP."
        } else if (existingFieldVals["15.4"] && existingFieldVals["15.4"].value === 2) {
          return "Review the list of media you provided to Epiq via FTP. Then, select the media that you want returned from the following list. As a result of submitting this request, Epiq will ship this as physical media, such as hard drives or other storage devices."
        }
      }
    },
    summaryTitle: "IDENTIFY MEDIA TO RETURN",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "15", compare: "eq", value: 1 },
        { fieldId: "15", compare: "eq", value: 2 }
      ]
    },
    fields: [
      {
        type: "tableViewField",
        name: "Media",
        fieldId: "15.1",
        resetForField: "0.1.2,15,15.4",
        selectable: true,
        hideFieldIdLabel: true,
        selectionMode: "multi", // Checkbox will appeared to select mu;tiple objects from table view
        dataItemKey: "ResultValue", // Needed when selectable = true, dataItemKey is unique value to check object was selected or not
        label: "Select all project media to return.",
        active: false,
        summaryCssName: "summary-tableview-list",
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "15", compare: "eq", value: 1 }, { fieldId: "15", compare: "eq", value: 2 }]
        },
        getColumns: (dataItems: any) => {
          const columns = [
            { field: "MediaDateRecieved", title: "Date Received", width: "250px", format: "{0:MM/dd/yyyy}" },
            { field: "MediaId", title: "Media ID", width: "80px" },
            { field: "MediaType", title: "Media Type", width: "200px" },
            { field: "MediaDescription", title: "Description" }
          ] as Array<GridColumnProps>;
          return columns;
        },
        exportExcelSettings: (dataItems: any) => {
          const exportItems = {
            fileName: `${CommonHelper.getCurrentDate("YYYYMMDD", "")}-Media [projectName]`,
            fields: [
              { field: "MediaDateRecieved", title: "Date Received", width: "120px" },
              { field: "MediaId", title: "Media ID", width: "80px" },
              { field: "MediaType", title: "Media Type", width: "80px" },
              { field: "MediaPosession", title: "Media Possession (CoC)", width: "200px" },
              { field: "MediaCreatedDate", title: "Date Created", width: "120px" },
              { field: "MediaDescription", title: "Media Description", width: "250px" }
            ]
          };
          return exportItems;
        },
        GetData: ResourceService.getResourcesUser,
        GetTotalCount: ResourceService.getResourcesDetailsCount,
        ValuesConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const mediaDateRecieved = item.MediaDateRecieved ? formatDate(new Date(item.MediaDateRecieved), "MM/dd/yyyy") : "";
            return `${item.MediaId}: ${mediaDateRecieved} ${item.MediaType ? '-' + item.MediaType : ""} ${item.MediaDescription ? '-' + item.MediaDescription : ""}`;
          });
        },
        DataConverter: (dataItems: any) => {
          return dataItems.map((item: any) => {
            const itemField = item.fields ? item.fields : item;
            const mediaRecieved = itemField.MediaDateRecieved
              ? new Date(itemField.MediaDateRecieved)
              : null;
            const mediaRecievedResultValue = itemField.MediaDateRecieved ? formatDate(new Date(itemField.MediaDateRecieved), "MM/dd/yyyy") : "";
            return {
              SalesforceMediaId: itemField.MediaId,
              MediaName: itemField.MediaName,
              MediaId: itemField.MediaName,
              MediaType: itemField.MediaType,
              MediaDateRecieved: mediaRecieved,
              MediaDescription: itemField.MediaDescription,
              MediaPath: itemField.MediaArrivalPath,
              MediaCreatedDate: itemField.MediaCreatedDate
                ? new Date(itemField.MediaCreatedDate)
                : null,
              MediaPosession: itemField.MediaPosession,
              ResultValue: `${itemField.MediaName}: ${mediaRecievedResultValue} ${itemField.MediaType ? '-' + itemField.MediaType : ""} ${itemField.MediaDescription ? '-' + itemField.MediaDescription : ""
                }`
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {

          let filters;
          if (fieldValues["15"].value === 1) {
            filters = [
              {
                logic: "and",
                filters: [{
                  field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value
                }] as Array<FilterDescriptor>
              },
              {
                logic: "or",
                filters: [{ field: "MediaType", operator: "neq", value: "FTP" }] as Array<FilterDescriptor>
              },
            ] as CompositeFilterDescriptor[];
          }
          else {
            filters = [
              {
                logic: "and",
                filters: [{
                  field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value
                }] as Array<FilterDescriptor>
              },
              {
                logic: "or",
                filters: [{ field: "MediaType", operator: "eq", value: "FTP" }] as Array<FilterDescriptor>
              },
            ] as CompositeFilterDescriptor[];
          }

          return {
            take: 1000,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            dedupeFieldName: "MediaId",
            flattenResults: true,
            joinFields: ["Medias"],
            filters: filters,
            filterPresets: ["ActiveMedia"]
          };
        },
        GetTotalCountParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          let filters;
          if (fieldValues["15"].value === 1) {
            filters = [
              {
                logic: "and",
                filters: [{ field: "SalesForceId", operator: "eq", value: fieldValues["0.1.1"].lookupValues["SalesForceId"] }] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [{ field: "MediaType", operator: "neq", value: "FTP" }] as Array<FilterDescriptor>
              },
            ] as CompositeFilterDescriptor[];
          }
          else {
            filters = [
              {
                logic: "and",
                filters: [{ field: "SalesForceId", operator: "eq", value: fieldValues["0.1.1"].lookupValues["SalesForceId"] }] as Array<FilterDescriptor>
              },
              {
                logic: "and",
                filters: [{ field: "MediaType", operator: "eq", value: "FTP" }] as Array<FilterDescriptor>
              },
            ] as CompositeFilterDescriptor[];
          }

          return {
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            joinFields: ["Medias"],
            filters: filters,
            filterPresets: ["ActiveMedia"]
          };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "15.1", compare: "neq", value: null }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "[`fieldLookup:4``lookupName:describeSubHeader``defaultStr:SELECT WORKSPACE TO ARCHIVE`]",
    description:
      "[`fieldLookup:4``lookupName:describePageDescription``defaultStr:When authorized to archive (ARM) a Relativity workspace, Epiq backs up all stored data for that workspace. You can archive one workspace per work request.`]",
    summaryTitle: "WORKSPACE TO ARCHIVE",
    dependency: { fieldId: "3", compare: "eq", value: 5 },
    fields: [
      {
        type: "tableViewField",
        fieldId: "3.5",
        resetForField: "0.1.2",
        dataItemKey: "Id",
        name: "Type",
        label: "Select the Relativity workspace to archive.",
        active: false,
        summaryCssName: "summary-tableview-list",
        dependency: { fieldId: "3", compare: "eq", value: 5 },
        selectable: true,
        selectionMode: "single",
        getColumns: (dataItems: any) => {
          const columns = [{ field: "Type", title: "Type" }, { field: "Workspace", title: "Workspace" }] as Array<GridColumnProps>;
          return columns;
        },
        GetData: ResourceService.getResourcesUser,
        DataConverter: (dataItems: any) => {
          const result = dataItems;
          const relativiyHosts = result.filter((item: any) => item.fields["Db Record Type"] === DBRecordType.Relativity_Hosting);
          return relativiyHosts.map((item: any) => {

            const lookupData = GetHostingAppDbBillingCodes(
              item.fields["Db Record Type"],
              item.fields["AppDbId"],
              "", "Pending Archive"
            );

            return {
              Id: item.fields["AppDbId"],
              Type: item.fields["Db Record Type"],
              Workspace: item.fields["Relativity Host"],
              SummaryTitle: item.fields["Db Record Type"],
              LookupValues: {
                Data: [lookupData] as Array<IApplicationDatabaseFields>,
                AdditionalSummaryFields: {
                  Workspace: item.fields["Relativity Host"],
                } as IAdditionalSummaryFields
              }
            };
          });
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          const filters = [
            {
              logic: "or",
              filters: [{ field: "ObjectId", operator: "eq", value: fieldValues["0.1.1"].value }] as Array<
                FilterDescriptor
              >
            },
            {
              logic: "or",
              filters: [
                { field: "Relativity Host", operator: "contains", value: searchText },
                { field: "Db Record Type", operator: "contains", value: searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[];

          return {
            take: 100,
            typeCode: "ediscoveryproject",
            permissionsDelimited: "SubmitWorkRequest-ProjectClosure",
            showGroupsWithDupePermissions: true,
            flattenResults: true,
            dedupeFieldName: "AppDbId",
            joinFields: ["ApplicationDatabases"],
            filters: searchText ? filters : [filters[0]],
            filterPresets: ["NotDeletedPendingArchiveHostingApps"]
          };
        }
      }],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "3.5", compare: "neq", value: null }]
    },
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "DELIVERY METHOD",
    description: "Choose the delivery method that best suits your needs. We can deliver your ARM archive online using Epiq’s secure FTP site, or another site. Alternatively, you can request that we ship the ARM archive on physical media via a delivery service.",
    summaryTitle: "DELIVERY METHOD",
    dependency: {
      logic: "and",
      subConditions: [{ fieldId: "3", compare: "eq", value: 5 }]
    },
    fields: [
      {
        type: "radiogroup",
        label: "Select the delivery method.",
        name: "Delivery Method",
        fieldId: "15.1.2",
        requiredWhenActive: true,
        hideFieldIdLabel: true,
        active: true,
        radioButtonOptions: [
          {
            id: 1,
            title: "Ship hard drive of ARM archive."
          },
          {
            id: 2,
            title: "Deliver ARM archive via FTP."
          }
        ]
      }

    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "15.1.2", compare: "eq", value: 1 }, { fieldId: "15.1.2", compare: "eq", value: 2 }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "FTP INFORMATION",
    description: "Supply where to return the media. You can select an Epiq FTP URL from the following list, or type an external FTP URL. In addition, provide the path on that site where you want the media placed. For external FTP sites, include the username and password that Epiq personnel will need to access the site.",
    summaryTitle: "FTP INFORMATION",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "15", compare: "eq", value: 2 }, { fieldId: "15.4", compare: "eq", value: 1 }, { fieldId: "15.1", compare: "neq", value: null }
      ]
    },
    fields: [
      {
        type: "combobox",
        name: "FTP Site Type",
        hideFieldIdLabel: true,
        fieldId: "15.5",
        label: "Specify where you uploaded the file. Select an Epiq FTP URL or enter an external FTP URL.",
        active: true,
        DropDownOptions: ftpUrlList,
        requiredWhenActive: true,
      },
      {
        type: "input",
        name: "FTP URL",
        hideFieldIdLabel: true,
        fieldId: "15.6",
        label: "Where do you want us to place the media? (For example: A:\\Client-Test\\FTP\\Client007\\2022.06.09 or should start with \\\\).",
        labelCaption: "On the FTP site, Epiq will place the media in the directory indicated by the path you provide.",
        active: false,
        requiredWhenActive: true,
        validation: { compare: "regexp", regExp: "^(\\\\\\\\|[a-zA-Z]:\\\\)(?!.+(\\\\\\\\|(\\.([a-z][0-9a-z]{1,3}|[0-9][a-z]{1,3}[0-9]?)$)))[^\\<\\>\\:\\\"\\/\\|\\?\\*]+$", value: "", failureMessage: "You have entered an invalid character in Source File Path field" },
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "15.5", compare: "neq", value: null },
          ]
        },
      },
      {
        type: "input",
        name: "Username",
        fieldId: "15.7",
        hideFieldIdLabel: true,
        label: "Provide the username.",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "15.6", compare: "neq", value: null },
            { fieldId: "15.6", compare: "neq", value: "" },
            { fieldId: "15.5", compare: "neq", value: null },
            { fieldId: "hiddenIsExternalFTP", compare: "eq", value: true },
          ]
        },
      },
      {
        type: "passwordField",
        name: "Password",
        subType: "password",
        hideFieldIdLabel: true,
        fieldId: "15.8",
        label: "Enter the password.",
        active: false,
        requiredWhenActive: true,
        dependency:
        {
          logic: "and",
          subConditions: [
            { fieldId: "15.7", compare: "neq", value: null },
            { fieldId: "15.7", compare: "neq", value: "" },
            { fieldId: "15.5", compare: "neq", value: null },
            { fieldId: "hiddenIsExternalFTP", compare: "eq", value: true }
          ]
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenIsExternalFTP",
        name: "IsExternalFTP",
        lookupCompletion: (dataResult: any) => {
          const withOutHttps = ftpUrlList.map(url => url.replace("https://", ""));
          if (dataResult) {
            const url = dataResult.ftpUrlValue.split('://');
            if (url.length > 0) {
              if (withOutHttps.find(x => x === url[url.length - 1])) {
                return { success: true, value: false };
              }
            }
          }

          return { success: true, value: true };
        },
        lookupError:
          "Error determining while cheking the url whether exteranl ftp url or not",
        watchField: "15.5",
        label: "External FTP",
        resetForField: "15",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "15.5", compare: "neq", value: null },
          ]
        },
        GetData: (dataResult: any) => {
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { ftpUrlValue: fieldValues["15.5"].value };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "15.6", compare: "neq", value: null },
        { fieldId: "15.6", compare: "neq", value: "" },
        {
          logic: "and",
          subConditions: [
            { fieldId: "15.8", compare: "neq", value: null },
            { fieldId: "15.8", compare: "neq", value: "" },
            { fieldId: "hiddenIsExternalFTP", compare: "eq", value: true }
          ]
        }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "FTP INFORMATION",
    description: "Supply where to deliver the ARM archive. You can select an Epiq FTP URL from the following list, or type an external FTP URL. In addition, provide the path on the site where you want the archive placed. For external FTP sites, include the username and password that Epiq personnel will need to access the site.",
    summaryTitle: "FTP INFORMATION",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "3", compare: "eq", value: 5 }, { fieldId: "15.1.2", compare: "eq", value: 2 }
      ]
    },
    fields: [
      {
        type: "combobox",
        name: "FTP Site Type",
        hideFieldIdLabel: true,
        fieldId: "15.5.1",
        label: "Specify the URL.",
        labelCaption: "Select an Epiq FTP URL or enter an external FTP URL.",
        active: true,
        DropDownOptions: ftpUrlList,
        requiredWhenActive: true,
      },
      {
        type: "input",
        name: "FTP URL",
        hideFieldIdLabel: true,
        fieldId: "15.6.1",
        label: "Specify the path (For example: A:\\Client-Test\\FTP\\Client007\\2022.06.09 or should start with \\\\).",
        labelCaption: "On the FTP site, Epiq will deliver the ARM archive in the directory indicated by the path you provide.",
        active: false,
        requiredWhenActive: true,
        validation: { compare: "regexp", regExp: "^(\\\\\\\\|[a-zA-Z]:\\\\)(?!.+(\\\\\\\\|(\\.([a-z][0-9a-z]{1,3}|[0-9][a-z]{1,3}[0-9]?)$)))[^\\<\\>\\:\\\"\\/\\|\\?\\*]+$", value: "", failureMessage: "You have entered an invalid character in Source File Path field" },
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "15.5.1", compare: "neq", value: null },
          ]
        },
      },
      {
        type: "input",
        name: "Username",
        fieldId: "15.7.1",
        hideFieldIdLabel: true,
        label: "Provide the username.",
        active: false,
        requiredWhenActive: true,
        cssName: () => "column-span2",
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "15.6.1", compare: "neq", value: null },
            { fieldId: "15.6.1", compare: "neq", value: "" },
            { fieldId: "15.5.1", compare: "neq", value: null },
            { fieldId: "hiddenIsExternalFTPARM", compare: "eq", value: true },
            { fieldId: "15.1.2", compare: "eq", value: 2 },
          ]
        },
      },
      {
        type: "passwordField",
        name: "Password",
        subType: "password",
        hideFieldIdLabel: true,
        fieldId: "15.8.1",
        label: "Enter the password.",
        active: false,
        requiredWhenActive: true,
        cssName: () => "column-span2",
        dependency:
        {
          logic: "and",
          subConditions: [
            { fieldId: "15.1.2", compare: "eq", value: 2 },
            { fieldId: "15.7.1", compare: "neq", value: null },
            { fieldId: "15.7.1", compare: "neq", value: "" },
            { fieldId: "15.5.1", compare: "neq", value: null },
            { fieldId: "hiddenIsExternalFTPARM", compare: "eq", value: true }
          ]
        }
      },
      {
        type: "hiddenlookup",
        fieldId: "hiddenIsExternalFTPARM",
        name: "IsExternalFTP",
        lookupCompletion: (dataResult: any) => {
          const withOutHttps = ftpUrlList.map(url => url.replace("https://", ""));
          if (dataResult) {
            const url = dataResult.ftpUrlValue.split('://');
            if (url.length > 0) {
              if (withOutHttps.find(x => x === url[url.length - 1])) {
                return { success: true, value: false };
              }
            }
          }

          return { success: true, value: true };
        },
        lookupError:
          "Error determining while cheking the url whether exteranl ftp url or not",
        watchField: "15.5.1",
        label: "External FTP",
        resetForField: "15.1.2",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [
            { fieldId: "15.5.1", compare: "neq", value: null },
          ]
        },
        GetData: (dataResult: any) => {
          const result = {
            ok: true,
            data: dataResult
          } as ServiceResponseJson;
          return result;
        },
        GetDataParams: (searchText: string, fieldValues: IFieldValueLookup) => {
          return { ftpUrlValue: fieldValues["15.5.1"].value };
        }
      }
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "15.6.1", compare: "neq", value: null },
        { fieldId: "15.6.1", compare: "neq", value: "" },
        {
          logic: "and",
          subConditions: [
            { fieldId: "15.8.1", compare: "neq", value: null },
            { fieldId: "15.8.1", compare: "neq", value: "" },
            { fieldId: "hiddenIsExternalFTPARM", compare: "eq", value: true }
          ]
        }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "LABEL & ENCRYPTION INFORMATION",
    description: "Provide the information that needs to appear on the label to identify this media, if any. Understand that this media will be zipped and encrypted with an Epiq standard password (10 character, randomly generated). You will receive the password in a separate email.",
    summaryTitle: "LABEL & ENCRYPTION INFORMATION",
    dependency: {
      logic: "or",
      subConditions: [
        { fieldId: "15.1.2", compare: "eq", value: 1 },
        {
          logic: "and",
          subConditions: [{ fieldId: "15", compare: "eq", value: 2 },
          { fieldId: "15.4", compare: "eq", value: 2 },
          { fieldId: "15.1", compare: "neq", value: null }
         ]
        }
      ]
    },
    fields: [
      {
        type: "dropdown",
        name: "Label Type",
        fieldId: "15.9",
        hideFieldIdLabel: true,
        label: "Should Epiq label hard drive?",
        active: false,
        requiredWhenActive: true,
        DropDownOptions: [{ id: "P#, WR#, Date", title: "Yes, label with project number, work request number, and date (P#, WR#, Date)." },
        { id: "Custom", title: "Yes, apply a custom label." },
        { id: "No Label", title: "No, do not label hard drives." }
        ]
      },
      {
        type: "textarea",
        name: "Custom Label",
        fieldId: "15.10",
        hideFieldIdLabel: true,
        label: "Enter a custom label.",
        active: false,
        requiredWhenActive: true,
        dependency: {
          logic: "or",
          subConditions: [{ fieldId: "15.9", compare: "eq", value: "Custom" }]
        }
      },
    ],
    nextCondition: {
      logic: "or",
      subConditions: [
        { fieldId: "15.9", compare: "eq", value: "P#, WR#, Date" },
        { fieldId: "15.9", compare: "eq", value: "No Label" },
        {
          logic: "and",
          subConditions: [
            { fieldId: "15.9", compare: "eq", value: "Custom" },
            { fieldId: "15.10", compare: "neq", value: null },
          ]
        }
      ]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "SHIPPING INFORMATION",
    description: "Provide the full address to where you want the media returned, including company name and location. In addition, provide a phone number for Epiq to contact in case any questions arise.",
    summaryTitle: "SHIPPING INFORMATION",
    dependency: {
      logic: "or",
      subConditions: [
        {
          logic: "and",
          subConditions: [
            { fieldId: "15", compare: "eq", value: 1 },
            { fieldId: "15.1", compare: "neq", value: null }
          ]
        },
        {
          logic: "and",
          subConditions: [
            { fieldId: "15.1", compare: "neq", value: null },
            { fieldId: "15.4", compare: "eq", value: 2 }
          ]
        }
      ]
    },
    fields: [
      {
        type: "textarea",
        maxLength: 250,
        fieldId: "15.2",
        label: "Provide the information for your shipping label.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        name: "Shipping Label",
        active: false,
        resetForField: "15",
        showCharacterCount: false,
        placeholder: "Company name\nShipping address"
      },
      {
        type: "input",
        name: "Phone Number",
        fieldId: "15.3",
        label: "Enter phone number.",
        resetForField: "15",
        hideFieldIdLabel: true,
        active: false,
        dependency: { fieldId: "15.2", compare: "neq", value: null }
      },
      {
        type: "numericTextField",
        name: "Number of Copies",
        fieldId: "15.11",
        min: 1,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        validation: { compare: "gt", value: 0, failureMessage: "Must be one or more copies" },
        label: "Supply the number of copies needed.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "15.2", compare: "neq", value: null }, { fieldId: "15.4", compare: "eq", value: 2 }]
        }
      },
    ],
    nextCondition: {
      logic: "or",
      subConditions: [{ fieldId: "15.2", compare: "neq", value: null },
      {
        logic: "and",
        subConditions: [
          { fieldId: "15.11", compare: "neq", value: null },
          { fieldId: "15.4", compare: "eq", value: 2 },
        ]
      }]
    }
  },
  {
    header: "Request: [`fieldLookup:0.1.2``lookupName:databaseHeader`]",
    subHeader: "SHIPPING INFORMATION",
    description: "Provide the full address to where you want the ARM archive delivered, including company name and location. In addition, provide a phone number for Epiq to contact in case any questions arise.",
    summaryTitle: "SHIPPING INFORMATION",
    dependency: {
      logic: "and",
      subConditions: [
        { fieldId: "15.1.2", compare: "eq", value: 1 },
        { fieldId: "3", compare: "eq", value: 5 }
      ]
    },
    fields: [
      {
        type: "textarea",
        maxLength: 250,
        fieldId: "15.2.1",
        label: "Provide the information for your shipping label.",
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        name: "Shipping Label",
        active: false,
        resetForField: "15",
        showCharacterCount: false,
        placeholder: "Company name\nShipping address"
      },
      {
        type: "input",
        name: "Phone Number",
        fieldId: "15.3.1",
        label: "Enter phone number.",
        resetForField: "15",
        hideFieldIdLabel: true,
        active: false,
        dependency: { fieldId: "15.2.1", compare: "neq", value: null }
      },
      {
        type: "numericTextField",
        name: "Number of Copies",
        fieldId: "15.12",
        min: 1,
        hideFieldIdLabel: true,
        requiredWhenActive: true,
        validation: { compare: "gt", value: 0, failureMessage: "Must be one or more copies" },
        label: "Supply the number of copies needed.",
        active: false,
        dependency: {
          logic: "and",
          subConditions: [{ fieldId: "15.2.1", compare: "neq", value: null }, { fieldId: "15.1.2", compare: "eq", value: 1 }]
        }
      }
    ],
    nextCondition: {
      logic: "and",
      subConditions: [{ fieldId: "15.2.1", compare: "neq", value: null }, { fieldId: "15.12", compare: "neq", value: null },]
    }
  }

] as Array<IWizardSection>;

const GetProcessingAppDbBillingCodes = (dbRecordType: string, appDbId: string, dbName?: string, status?: string) => {
  let appDbFields = {
    id: appDbId,
    status: status ? status : "Pending Deletion",
    databaseName: dbName
  } as IApplicationDatabaseFields;

  if (
    dbRecordType === DBRecordType.Nuix ||
    dbRecordType === DBRecordType.Epiq_Discovery_Processing ||
    dbRecordType === DBRecordType.DMX14_Processing
  ) {
    appDbFields.storageBundle = "Do Not Submit to SAP";
    appDbFields.nonReviewStorageCode = "Do Not Submit to SAP";
    appDbFields.dmxIndexingCode = "Do Not Submit to SAP";
    appDbFields.dmxProcessingCode = "Do Not Submit to SAP";
  }

  return appDbFields;
};

const GetHostingAppDbBillingCodes = (dbRecordType: string, appDbId: string, dbName?: string, status?: string) => {
  let appDbFields = {
    id: appDbId,
    status: status ? status : "Pending Deletion",
    databaseName: dbName
  } as IApplicationDatabaseFields;

  console.log(dbRecordType);

  if (
    dbRecordType === DBRecordType.Relativity_Hosting ||
    dbRecordType === DBRecordType.DMX13 ||
    dbRecordType === DBRecordType.Relativity_Processing ||
    dbRecordType === DBRecordType.Epiq_Discovery ||
    dbRecordType === DBRecordType.Everlaw ||
    dbRecordType === DBRecordType.Reveal ||
    dbRecordType === DBRecordType.EMS_Relativity_Placeholder ||
    dbRecordType === DBRecordType.Network_Storage
  ) {
    appDbFields.storageBundle = "Do Not Submit to SAP";
  }

  if (dbRecordType === DBRecordType.Relativity_Processing) {
    appDbFields.nonReviewStorageCode = "Do Not Submit to SAP";
    appDbFields.dmxProcessingCode = "Do Not Submit to SAP";
  }

  if (dbRecordType === DBRecordType.Relativity_Processing || dbRecordType === "Everlaw") {
    appDbFields.dmxIndexingCode = "Do Not Submit to SAP";
  }

  if (
    dbRecordType === DBRecordType.Relativity_Hosting ||
    dbRecordType === DBRecordType.DMX13 ||
    dbRecordType === DBRecordType.Epiq_Discovery ||
    dbRecordType === DBRecordType.Everlaw ||
    dbRecordType === DBRecordType.Reveal ||
    dbRecordType === DBRecordType.EMS_Relativity_Placeholder
  ) {
    appDbFields.userCode = "Do Not Submit to SAP";
  }

  if (
    dbRecordType === DBRecordType.Relativity_Hosting ||
    dbRecordType === DBRecordType.DMX13 ||
    dbRecordType === DBRecordType.Epiq_Discovery ||
    dbRecordType === DBRecordType.Everlaw ||
    dbRecordType === DBRecordType.Reveal ||
    dbRecordType === DBRecordType.EMS_Relativity_Placeholder ||
    dbRecordType === DBRecordType.Network_Storage
  ) {
    appDbFields.hostingBillingCode = "Do Not Submit to SAP";
  }

  return appDbFields;
};
