import React from "react";
import JobService from "../../../services/JobService";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { IFileInfo, ValidationStatus } from "./FileUploadStep";
import { ServiceBase } from "../../../services/ServiceBase";
import { CircularProgress } from "@material-ui/core";
import "./FileValidation.scss";

type Props = {
  files: Array<IFileInfo>;
  onValidationComplete: (invalidFiles: Array<IFileInfo>) => void;
  onFileUploaded?: (file: IFileInfo) => void;
  jobType?: number;
};

type State = {
  files: Array<IFileInfo>;
};

enum JobStates {
  FileReadyForValidation = 50,
  FileValidatingAv = 51,
  FileValidatedAvError = 52,
  FileValidatedAvSuccess = 53,
  FileValidatingType = 54,
  FileValidatedTypeError = 55,
  FileValidatedTypeSuccess = 56,
  FileInvalidInfected = 57,
  FileInvalidType = 58,
  File_CDNSuccess = 101,
  File_CDNFailed = 102,
  GeneralError = 900
}

class FileValidation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      files: new Array<IFileInfo>()
    };
  }

  componentDidMount() {
    this.props.files.forEach(async (fileInfo) => {
      fileInfo.validationStatus = ValidationStatus.Validating;
      await JobService.submitJob({
        jobId: fileInfo.jobId,
        jobType: this.props.jobType || 1,
        fileExtension: fileInfo.file.extension.toLowerCase(),
        fileName: fileInfo.file.name
      });
      await this.fetchJobStatus(fileInfo);

      if (this.validationProcessComplete()) {
        this.props.onValidationComplete(
          this.state.files.filter((fileInfo) => fileInfo.validationStatus !== ValidationStatus.Valid)
        );
      }
    });

    this.setState({ ...this.state, files: this.props.files });
  }

  private setFilesState(newFileState: IFileInfo) {
    const indexToUpdate = this.state.files.findIndex((fileInfo) => fileInfo.file.uid === newFileState.file.uid);
    const files = [...this.state.files];

    files.splice(indexToUpdate, 1);
    files.splice(indexToUpdate, 0, newFileState);

    this.setState({ ...this.state, files });
  }

  private validationProcessComplete() {
    return !this.state.files.find((fileInfo) => fileInfo.validationStatus === ValidationStatus.Validating);
  }

  private async fetchJobStatus(fileInfo: IFileInfo) {
    const { jobId } = fileInfo;
    const { ok, data } = await JobService.getJobStatus(jobId);

    if (ok) {
      switch (data.statusId) {
        case JobStates.FileReadyForValidation:
        case JobStates.FileValidatingAv:
        case JobStates.FileValidatingType:
          await ServiceBase.setTimeoutPromise(1000);
          await this.fetchJobStatus(fileInfo);
          break;
        case JobStates.FileValidatedTypeError:
        case JobStates.FileValidatedAvError:
        case JobStates.GeneralError:
        case JobStates.File_CDNFailed:
          this.setFilesState({ ...fileInfo, validationStatus: ValidationStatus.Error });
          break;
        case JobStates.FileValidatedAvSuccess:
        case JobStates.FileValidatedTypeSuccess:
          this.setFilesState({ ...fileInfo, validationStatus: ValidationStatus.Valid });
          break;
        case JobStates.FileInvalidInfected:
        case JobStates.FileInvalidType:
          this.setFilesState({
            ...fileInfo,

            validationStatus:
              data.statusId === JobStates.FileInvalidInfected
                ? ValidationStatus.InfectedFile
                : ValidationStatus.InValidFileType
          });
          break;
        case JobStates.File_CDNSuccess:
          const resultData = JSON.parse(data.data);
          const file = { ...fileInfo, validationStatus: ValidationStatus.Valid, url: resultData.Url };
          if (this.props.onFileUploaded) {
            this.props.onFileUploaded(file);
          }
          this.setFilesState(file);
          break;
      }
    }
  }

  render() {
    return (
      <>
        <h2> The Uploaded Files To Validate</h2>
        <p>All uploaded files will now be validated to ensure they are virus free and are the correct file type.</p>
        <div className="files-validation-summary">
          <div className="files-validation-result-container">
            {this.state.files.map((fileInfo: IFileInfo) => {
              return (
                <div className="file-validation-row">
                  <div className="file-name">
                    <span>{fileInfo.file.name}</span>
                  </div>
                  <div className="file-status">
                    {fileInfo.validationStatus === ValidationStatus.Validating && <CircularProgress size={26} />}
                    {fileInfo.validationStatus === ValidationStatus.Valid && (
                      <BadgeContainer>
                        <Badge className="file-status-icon" themeColor="success" cutoutBorder={true}>
                          <span className="k-icon k-i-check"></span>
                        </Badge>
                      </BadgeContainer>
                    )}
                    {fileInfo.validationStatus === ValidationStatus.Error ||
                     fileInfo.validationStatus === ValidationStatus.InfectedFile ||
                     fileInfo.validationStatus === ValidationStatus.InValidFileType
                    &&
                      (
                      <BadgeContainer>
                        <Badge className="file-status-icon" themeColor="error" cutoutBorder={true}>
                          <span className="k-icon k-i-close"></span>
                        </Badge>
                      </BadgeContainer>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default FileValidation;
