import React, { PureComponent } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper, StepProps } from "@progress/kendo-react-layout";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IFieldControlButtons } from "../../../models/Wizard";
import "./StandardWizard.scss";
import Tooltip from "@material-ui/core/Tooltip";
import NotificationService from "../../../services/NotificationService";

export interface IWizardStepConfig extends StepProps {
  validator?: () => boolean;
  action?: (step: number) => void;
}
export interface IWizardStepProps {
  stepConfig: IWizardStepConfig;
  stepContent: (() => JSX.Element) | JSX.Element | JSX.ElementClass;
}
interface IProps {
  steps?: Array<IWizardStepProps>;
  onStepChanged?: (step: number) => void;
  onSubmitClick?: () => void;
  disablePreviousStep?: boolean;
  showLoadingIndicatorOnNextStep?: (step: number) => boolean | boolean;
  nextStepButtonLabel?: string;
  previousStepButtonLabel?: string;
  lastStepButtonLabel?: string;
  hideStepIndicator?: boolean | null | undefined;
  buttonDefinitions?:Array<IFieldControlButtons>;
  children?: React.ReactNode;
  footerContent?: JSX.Element;
}

type Props = IProps;

type State = {
  step: number;
  currentStepValid: boolean;
};

export class StandardWizard extends PureComponent<Props, State> {
  lastStepIndex: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      step: 0,
      currentStepValid: this.props.steps ? this.props.steps[0].stepConfig.isValid : false
    };
    this.lastStepIndex = this.props.steps ? this.props.steps.length - 1 : 0;

    if (!this.props.children && (!this.props.onSubmitClick || !this.props.steps)) {
      throw 'Must pass in child element or else onSubmitClick and step props';
    }
  }

  isLastStep() {
    return this.lastStepIndex === this.state.step;
  }

  isStepValid(step: number) {
    return this.props.steps[step].stepConfig.validator ? this.props.steps[step].stepConfig.validator() : true;
  }

  validateStep(step: number) {
    this.props.steps[step].stepConfig.isValid = this.isStepValid(step);
  }

  onNextStepClick() {
    if (this.isLastStep() && this.props.onSubmitClick) {
      this.props.onSubmitClick();
      NotificationService.showSuccessToast("Upload succeeded.");
    } else {
      this.validateStep(this.state.step);

      if (this.props.steps[this.state.step].stepConfig.isValid) {
        const step = Math.min(this.state.step + 1, this.lastStepIndex);

        this.setState({ ...this.state, step });

        if (this.props.onStepChanged) {
          this.props.onStepChanged(step);
        }

        if (this.props.steps[step].stepConfig.action) {
          this.props.steps[step].stepConfig.action(step);
        }
      }
    }
  }

  onPreviousStepClick() {
    const step = Math.max(this.state.step - 1, 0);

    this.props.steps[this.state.step].stepConfig.isValid = undefined;
    this.props.steps[step].stepConfig.isValid = undefined;

    this.setState({ ...this.state, step });

    if (this.props.onStepChanged) {
      this.props.onStepChanged(step);
    }
  }

  private getNextButtonLabel() {
    return this.props.nextStepButtonLabel || "Next";
  }

  private getPreviousButtonLabel() {
    return this.props.previousStepButtonLabel || "Previous";
  }

  private getLastStepButtonLabel() {
    return this.props.lastStepButtonLabel || "Submit";
  }

  private isLoadingForNext() {
    return this.props.showLoadingIndicatorOnNextStep
    ? typeof this.props.showLoadingIndicatorOnNextStep === "function"
      ? this.props.showLoadingIndicatorOnNextStep(this.state.step)
      : this.props.showLoadingIndicatorOnNextStep
    : false;
  }

  private buttons = (buttonDefs?: Array<IFieldControlButtons>): JSX.Element => {
    const definitions = buttonDefs ? buttonDefs : 
      // defaults below
      [{
        text: this.isLastStep() ? this.getLastStepButtonLabel() : this.getNextButtonLabel(),
        disabled:!this.isStepValid(this.state.step),
        isPrimary:true,
        isLoading:this.isLoadingForNext(),
        onClick:this.onNextStepClick.bind(this)
      }];

    if (!buttonDefs && this.state.step > 0) {
      definitions.unshift({
        text: this.getPreviousButtonLabel(),
        disabled:this.props.disablePreviousStep,
        onClick:this.onPreviousStepClick.bind(this)
      })
    }

    return (<>
      <div className="flex-container">
            {definitions.map(def => {
              if (def.isAlignLeft) {
                return (<Button
                  className={def.isPrimary ? null : "btn-secondary"}
                  disabled={!!def.disabled}
                  onClick={def.onClick}
                  primary={!!def.isPrimary}
                >
                  {def.isLoading ? <CircularProgress
                    className="wizard-last-step-submit-loading-indicator"
                    size={16}
                    color="secondary"
                  /> : def.text}
                </Button>);
              }
            })
            }
          </div>
      <div>     
        {definitions.map(def => {

          if (!def.isAlignLeft) {
          return (def.title ? <Tooltip
            title={def.disabled ? "You cannot submit when using View As to impersonate a user.":""}
            placement="top-start"
            arrow><span>
              <Button
                className={def.isPrimary ? null : "btn-secondary"}
                disabled={!!def.disabled}
                onClick={def.onClick}
                primary={!!def.isPrimary}
              >
                {def.isLoading ? <CircularProgress
                  className="wizard-last-step-submit-loading-indicator"
                  size={16}
                  color="secondary"
                /> : def.text}
              </Button>
            </span>
          </Tooltip> :
            <Button
              className={def.isPrimary ? null : "btn-secondary"}
              disabled={!!def.disabled}
              onClick={def.onClick}
              primary={!!def.isPrimary}
            >
              {def.isLoading ? <CircularProgress
                className="wizard-last-step-submit-loading-indicator"
                size={16}
                color="secondary"
              /> : def.text}
            </Button>);
          }

      })}
    </div> </>)
  };

  render() {

    return (
      <div className={`standard-wizard-container`}>
        <div className={this.props.footerContent ? "standard-wizard-content-wrapper reduce-wrapper-height" : "standard-wizard-content-wrapper"}>
            {!this.props.hideStepIndicator && (
              <div className="standard-wizard-stepper">
                <Stepper
                  value={this.state.step}
                  items={this.props.steps.map(step => ({
                    label: step.stepConfig.label,
                    isValid: step.stepConfig.isValid
                  }))}
                />
              </div>
            )}
          <div className="standard-wizard-content">
            {this.props.children}
            {!this.props.children && (typeof this.props.steps[this.state.step].stepContent === "function"
              ? (this.props.steps[this.state.step].stepContent as Function)()
              : this.props.steps[this.state.step].stepContent)}
          </div>
        </div>
        <div className="standard-wizard-nav">
          <span className={"k-form-separator content-separator"} />
          <div className={"standard-wizard-footer-content"}>{this.props.footerContent && this.props.footerContent}</div>
          <div className={"k-form-buttons k-buttons-end standard-wizard-button-container"}>           
            {this.props.steps && (<span style={{ alignSelf: "center" }}>
              {this.props.steps && <>Step {this.state.step + 1} of {this.props.steps.length}</>}
            </span>)}
            {this.buttons(this.props.buttonDefinitions)}
          </div>
        </div>
      </div>
    );
  }
}
