import React, { useState, useEffect, useRef } from "react";
import { ComboBox, ComboBoxFilterChangeEvent, ComboBoxChangeEvent, DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { ServiceResponseJson } from "../../services/ServiceBase";
import "./SingleSelectDropDown.scss";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from '../../Localization/en.json';
import { AutoSelectPreference, IFieldValueLookup } from "../../models/Wizard";
loadMessages(bgMessages, 'en-US');

export interface ISingleSelectDropSelectedEvent<T> {
  data: T;
  subEvent?: SingleSelectDropDownChangeEvent;
}

export interface IStandardFilterParams {
  searchText: string;
  skip: number;
  take: number;
}

export interface SingleSelectDropDownItem<T> {
  id: number | string;
  text: string;
  data: T;
}

export type SingleSelectDropDownChangeEvent = ComboBoxChangeEvent | DropDownListChangeEvent;


export interface SingleSelectDropdownProps<T extends object = any, TT extends Record<string, TT> = any> {
  getItems: (search: any) => Promise<ServiceResponseJson> | ServiceResponseJson;
  onChange: (data: ISingleSelectDropSelectedEvent<T>, subEvent?: SingleSelectDropDownChangeEvent) => void;
  textToRequestConverter?: (inputText: string) => any;
  typeToListConverter: (type: T[], existingFieldValues?: TT) => SingleSelectDropDownItem<T>[];
  preselectedValue?: SingleSelectDropDownItem<T>;
  preselectedItemCall?: () => Promise<ServiceResponseJson>;
  preSelectFromGetData?: AutoSelectPreference;
  resetToPreSelectChangeField?: string;
  preSelectText?: string;
  disableFiltering?: boolean;
  name?: string;
  placeholder?: string;
  existingFieldValues?: TT;
}

const SingleSelectDropDown = <T extends object = any, TT extends Record<string, TT> = any>(props: SingleSelectDropdownProps<T, TT>) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [preselectedValue, setPreselectedValue] = useState(null);
  const searchRef = useRef("");
  const timeoutRef = useRef(null);
  const resetToPreSelectWhenChangeValue = props.resetToPreSelectChangeField && props.existingFieldValues
    && props.existingFieldValues[props.resetToPreSelectChangeField]
    && props.existingFieldValues[props.resetToPreSelectChangeField].value;

  useEffect(() => { }, [loading]);

  useEffect(() => {

    if (!(props.preselectedValue && props.preselectedValue.id) && (props.preselectedItemCall || props.preSelectFromGetData || props.preSelectText)) {

      const getExistingItem = async () => {
        setLoading(true);
        const response = await (props.preselectedItemCall ? props.preselectedItemCall() : props.getItems(""));

        if (response.ok) {
          if (props.typeToListConverter) {
            // preselectedItemCall is some 3rd party endpoint that returns a single item.  Otherwise we just use the typical listing endpoint which is always an array
            const dataToConvert = props.preselectedItemCall ? [response.data.results || response.data] : response.data.results || response.data;
            const asItems = props.typeToListConverter(dataToConvert, props.existingFieldValues);
            setData(asItems);

            if (props.preSelectFromGetData) {
              const selectedItem = getPreSelectedItem(props.preSelectFromGetData, asItems);
              setPreselectedValue(selectedItem);
              props.onChange(selectedItem, {
                value: selectedItem,
                nativeEvent: undefined,
                syntheticEvent: undefined,
                target: null
              });
            }
            else if (props.preselectedItemCall && asItems.length > 0) {
              setPreselectedValue(asItems[0]);
            }
            else if (props.preSelectText) {
              //get the first item that matches the preSelectText
              const preselectedItem = asItems.find((item) => item.text === props.preSelectText);
              if (preselectedItem) {
                props.onChange(preselectedItem, {
                  value: preselectedItem,
                  nativeEvent: undefined,
                  syntheticEvent: undefined,
                  target: null
                });
              }
            }
          }
        }

        setLoading(false);
      };
      getExistingItem();
    }
  }, [props.preSelectText, props.preselectedItemCall, resetToPreSelectWhenChangeValue]);

  const getPreSelectedItem = <T extends object = any>(type: AutoSelectPreference, asItems: SingleSelectDropDownItem<T>[]) => {
    if (type === "first") {
      return asItems.length === 1 ? asItems[0] : null;
    } else if (type === "firstOrSecond") {
      return asItems.length === 2 ? asItems[1] : asItems[0];
    }
    return null;
  }

  const onOpen = async (event: any) => {
    if (event.nativeEvent.type !== "input") {
      setLoading(true);

      const result = await props.getItems("");

      if (result.ok) {
        setData(props.typeToListConverter(result ? (result.data.results || result.data) : null, props.existingFieldValues));
      }

      setLoading(false);
    }
  }

  const filterChange = async (event: ComboBoxFilterChangeEvent) => {
    console.log(`SingleSelectDropDown==>filterChange==> ${event}`);
    clearTimeout(timeoutRef.current); // TODO I thought I saw two calls still being made, check back later on this
    searchRef.current = event.syntheticEvent.target.value;
    timeoutRef.current = setTimeout(async () => {
      setLoading(true);

      const result = await props.getItems(props.textToRequestConverter ? props.textToRequestConverter(searchRef.current) : searchRef.current);

      if (result.ok) {
        setData(props.typeToListConverter(result.data.results || result.data, props.existingFieldValues));
      }

      setLoading(false);
    }, 300);
  };

  return <div className="single-select-drop-down-wrapper">
    <LocalizationProvider language="en-US">
      <IntlProvider locale="en" >
        {!props.disableFiltering &&
          <ComboBox
            data={data}
            style={{ width: "100%" }}
            filterable={true}
            onFilterChange={filterChange}
            allowCustom={false}
            dataItemKey={"id"}
            textField={"text"}
            defaultValue={preselectedValue || props.preselectedValue}
            value={preselectedValue || props.preselectedValue}
            onChange={(e) => {
              setPreselectedValue(null);
              props.onChange(e.value, e);
            }}
            onOpen={onOpen}
            loading={loading}
            name={props.name}
            placeholder={props.placeholder}
          />}
        {props.disableFiltering && <DropDownList
          data={data}
          style={{ width: "100%" }}
          dataItemKey={"id"}
          textField={"text"}
          defaultValue={preselectedValue || props.preselectedValue}
          onChange={(e) => {
            setPreselectedValue(null);
            props.onChange(e.value, e);
          }}
          onOpen={onOpen}
          loading={loading}
          name={props.name}
        />}
      </IntlProvider>
    </LocalizationProvider>
  </div>;
}
export default SingleSelectDropDown;
