import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "@progress/kendo-react-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, GridCellProps, GridColumn, GridColumnProps, GridToolbar, GridNoRecords, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { updateValForField, updateStatusForField } from "./WizardElements";
import { Paper } from "@material-ui/core";
import "./TableViewField.scss";
import { v4 as uuid } from "uuid";
import { SortDescriptor } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import { ILookupCompletion } from "../../../models/Wizard";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import { IResourceParams } from "../../../models/ResourceModels";
import { formatDate } from "@telerik/kendo-intl";
import { RadioButton, RadioButtonChangeEvent, Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import Tooltip from "@material-ui/core/Tooltip";


const TableViewField = (fieldRenderProps: FieldRenderProps) => {
  const maxRecordCount = 1000;
  const isSelectable = fieldRenderProps.fieldData.selectable;
  const selectionMode = fieldRenderProps.fieldData.selectionMode;
  const dataItemKey = fieldRenderProps.fieldData.dataItemKey;
  const existingLookUpValues = fieldRenderProps.valInfo.lookupValues ? fieldRenderProps.valInfo.lookupValues : {}; 
  const existingSelectedItems = existingLookUpValues["selectedItems"] && (existingLookUpValues["selectedItems"]).length > 0 ? existingLookUpValues["selectedItems"] : [];
  const existingSelectedValues = existingSelectedItems && fieldRenderProps.fieldData.ValuesConverter ? fieldRenderProps.fieldData.ValuesConverter([...existingSelectedItems]) : [];
  const [items, setItems] = useState([] as any);
  const [columns, setColumns] = useState([] as Array<GridColumnProps>);
  const [isLoading, setLoading] = useState(false);
  const [sort, setSort] = useState([] as Array<SortDescriptor>);
  const [sortable, setSortable] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<any>>([...existingSelectedItems]);
  const [selectedValues, setSelectedValues] = useState<Array<string>>([...existingSelectedValues]);
  const [lookupInfoValues, setLookupInfoValues] = useState<any>({});
  const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
  
  const _export = React.useRef(null);

  const handleRadioButtonChange = (e: RadioButtonChangeEvent, dataItem: any) => {
    setSelectedValues(e.value);
    updateValForField(e.value, fieldRenderProps, dataItem.SummaryTitle, dataItem.LookupValues);
    updateStatusForField(false,fieldRenderProps,false,"");
  }

  const handleCheckBoxChange = (e: any, dataItem: any) => {  
    const newItems = e.value ? [...selectedItems, dataItem] : selectedItems.filter(v => v[dataItemKey] != dataItem[dataItemKey]);
    const newValues = fieldRenderProps.fieldData.ValuesConverter && fieldRenderProps.fieldData.ValuesConverter([...newItems]);

    const lookupValues = lookupInfoValues;
    lookupValues["selectedItems"] = newItems;

    setSelectedItems(newItems);
    setSelectedValues(newValues);
 
    updateValForField([...newValues], fieldRenderProps, null, lookupValues);
    updateStatusForField(newValues.length > 0 ? false : true,fieldRenderProps,false,"");
    
  }

  const handleHeaderCheckBoxSelectAllChange = (e: any) => {    
    const lookupValues = lookupInfoValues;
    if(e.value)
    {
      const selectedValues = fieldRenderProps.fieldData.ValuesConverter && fieldRenderProps.fieldData.ValuesConverter([...items]);
      lookupValues["selectedItems"] = items;
      updateValForField(selectedValues, fieldRenderProps, null, lookupValues);
      updateStatusForField(false,fieldRenderProps,false,"");
      setSelectedItems(items);
      setSelectedValues(selectedValues);
    }
    else
    {
       lookupValues["selectedItems"] = [];
       updateValForField([], fieldRenderProps, null, lookupInfoValues);
       setSelectedItems([]);
       setSelectedValues([]);
       updateStatusForField(true,fieldRenderProps,false,"");
    }
  }

  const radioSelect = (props: GridCellProps) => {
    const fieldValue = fieldRenderProps.fieldValues[fieldRenderProps.name] && fieldRenderProps.fieldValues[fieldRenderProps.name].value;
    const selectedValue = selectedValues && selectedValues.length > 0 && selectedValues.indexOf(props.dataItem[dataItemKey]) > -1;
    const isRadioButtonChecked = selectedValue || (fieldValue && fieldValue.length > 0 && fieldValue.indexOf(props.dataItem[dataItemKey]) > -1) ? true : false;
    return (
      <td>
        <RadioButton
          name="radioSelect"
          value={props.dataItem[dataItemKey]}
          checked={isRadioButtonChecked}
          label={props.dataItem[props.field]}
          onChange={(e) => handleRadioButtonChange(e, props.dataItem)}
        />
      </td>
    );
  }

  const checkBoxSelect = (props: GridCellProps) => {
    const fieldValue = fieldRenderProps.fieldValues[fieldRenderProps.name] && fieldRenderProps.fieldValues[fieldRenderProps.name].value;
    const selectedValue = selectedValues && selectedValues.length > 0 && selectedValues.findIndex((v: string) => v === props.dataItem[dataItemKey]) > -1;
    const isCheckBoxChecked = selectedValue || (fieldValue && fieldValue.length > 0 && fieldValue.findIndex((v: string) => v === props.dataItem[dataItemKey]) > -1) ? true : false;
    return (
      <td>
        <Checkbox
          name="checkBoxSelect"
          value={props.dataItem[dataItemKey]}
          checked={isCheckBoxChecked}
          label={""}
          onChange={(e) => handleCheckBoxChange(e, props.dataItem)}
        />
      </td>
    );
  }

  const headerCheckBoxSelectAll = (props: GridHeaderCellProps) => {
    const isCheckBoxChecked = selectedItems && selectedItems.length > 0 && items.length === selectedItems.length;
      return  (
      <td style={{border: 'none'}}>
        <Checkbox
          style={{display: (selectionMode !== "multi" ? 'none' : 'block') }}
          name="checkBoxSelectAll"
          value={"selectall"}
          checked={isCheckBoxChecked}
          label={""}
          onChange={(e) => handleHeaderCheckBoxSelectAllChange(e)}
          title="Select All Media"
          disabled={totalRecordCount > maxRecordCount || items.length == 0}
        />
      </td>);
  }

  useEffect(() => { 
    getItems();
  }, []);

  const getTotalRecordCount = async () => {
    let params = fieldRenderProps.fieldData.GetTotalCountParams(null, fieldRenderProps.fieldValues) as IResourceParams;
    const totalCountResult = await fieldRenderProps.fieldData.GetTotalCount(params);
    if (totalCountResult.ok) {
      setTotalRecordCount(totalCountResult.data);
      return totalCountResult.data;
    } else {
      console.log("Failed to fetch total record count");
    }

    return 0;
  };

  const getItems = async () => {
    setLoading(true);
    let totalItemsCount = 0;
    if (isSelectable) {
      updateStatusForField(true, fieldRenderProps, false, "");
      if (selectionMode === "multi") {
        totalItemsCount = await getTotalRecordCount();
      }
    }
    const result = selectionMode === "multi" && totalItemsCount > maxRecordCount ? {ok : true, data: {results: []}} : (await fieldRenderProps.fieldData.GetData(fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues)));
    if (result.ok && result.data.results.length > 0) {
      const gridItems = fieldRenderProps.fieldData.DataConverter(result.data.results);
      setItems(gridItems);

      if (!isSelectable) {
        const mappedValues = fieldRenderProps.fieldData.ValuesConverter && fieldRenderProps.fieldData.ValuesConverter(gridItems);
        const lookupInfo = fieldRenderProps.fieldData.lookupCompletion && fieldRenderProps.fieldData.lookupCompletion(result.data) as ILookupCompletion;
        updateValForField(mappedValues, fieldRenderProps, null, lookupInfo && lookupInfo.lookupValues);
        setLookupInfoValues(lookupInfo && lookupInfo.lookupValues ? lookupInfo.lookupValues : null);
        updateStatusForField(false,fieldRenderProps,false,"");
      }
      else
      {
        const existingVal = fieldRenderProps.fieldValues[fieldRenderProps.name] && fieldRenderProps.fieldValues[fieldRenderProps.name].value;
        updateStatusForField(existingVal && existingVal.length > 0 ? false : true,fieldRenderProps,false,"");
      }

      let tableColumns: Array<GridColumnProps> = fieldRenderProps.fieldData.getColumns(gridItems);
      if (tableColumns && tableColumns.length > 0) {
        const initialSort = [{ field: tableColumns[0].field, dir: "asc" }] as SortDescriptor[];
        setSort(initialSort);
      }

      setColumns(tableColumns);
      setLoading(false);
      setSortable(totalRecordCount <= maxRecordCount);

    } else {
      updateValForField([] as Array<string>, fieldRenderProps, null);
      setColumns(fieldRenderProps.fieldData.getColumns([]));
      setLoading(false);
      setSortable(false);
      setSort([]);
    }


  };

  const gridColumnCell = (props: GridCellProps) => {
    const field = props.dataItem[props.field];
    if (typeof field === "object" && field instanceof Date) {
      const subfield = formatDate(field as Date, "MM/dd/yyyy");
      return (
        <td>
          <Tooltip classes={{ tooltip: "mui-tooltip-no-maxwidth tooltip-app-desc" }} enterDelay={2} title={subfield} placement="top" arrow>
            <span>{subfield}</span>
          </Tooltip>
        </td>
      );
    }

    return (
      <td className={props.field === "MediaDescription" ? "description" : ""}>
        <Tooltip classes={{ tooltip: "mui-tooltip-no-maxwidth tooltip-app-desc" }} enterDelay={2} title={field} placement="top" arrow>
          <span>{field}</span>
         </Tooltip>
      </td>
    );
  }

  const renderColumnCell = (props: GridCellProps) => {
      return selectionMode == "single" ? radioSelect(props) : checkBoxSelect(props);
  }

  const excelExport = async () => {
    if (_export.current !== null) {
      let params = fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues) as IResourceParams;
      params.take = 999999;

      const result = await fieldRenderProps.fieldData.GetData(params);

      if (result.ok && result.data.results.length > 0) {
        const exportItems = fieldRenderProps.fieldData.DataConverter(result.data.results);
        _export.current.save(exportItems, fieldRenderProps.fieldData.exportExcelSettings(items).fields);
      }
    }
  };

  const gridNoDataRender = totalRecordCount > maxRecordCount ? (
      <GridNoRecords>
      <div className="record-count-exceeds">
        <div className="error-icon"><span className="fa-solid fa-circle-exclamation" /></div>
          <div className="error-msg">
            <span>Exceeds the media display maximum (1000). </span><br />
            <span>Close this request and contact your Project Manager. </span>
          </div>
        </div>
      </GridNoRecords>
    ) : (
     !isLoading && (<GridNoRecords>No records available</GridNoRecords>)
    );

  return (
    <div className="tableViewContainer">
      {!isLoading && (
        <>
          <Paper className="table-grid-wrapper">
              {fieldRenderProps.fieldData.exportExcelSettings && <ExcelExport ref={_export} fileName={fieldRenderProps.fieldData.exportExcelSettings(items).fileName.replace('[projectName]', fieldRenderProps.fieldValues["0.1.1"].text + '.xlsx')} />}
              <Grid className={isSelectable && selectionMode === 'multi'? "tableview-grid selectable-multi" : (isSelectable && selectionMode !== 'multi' ? "tableview-grid selectable-single" : "tableview-grid")}
                resizable={true}
                data={isSelectable && selectionMode === 'multi' && totalRecordCount > maxRecordCount ? [] : orderBy(items, sort)}
                sortable={sortable}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
              >
                
                {gridNoDataRender}
                {isSelectable && <GridColumn minResizableWidth={50} cell={ renderColumnCell } headerCell={headerCheckBoxSelectAll} key={uuid()} />}
                {columns && columns.map((value: GridColumnProps, index: number) => {
                  return <GridColumn minResizableWidth={50} cell={gridColumnCell} {...value} key={value.field || uuid()} headerClassName={items.length == 0 ? "empty-rows-header" : ""} />;
                })}

                {fieldRenderProps.fieldData.exportExcelSettings && fieldRenderProps.fieldData.exportExcelSettings(items).fields.length > 0 && (
                  <GridToolbar>
                    <div className="toolbar-button-container">
                      <Button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btn-excel-cwr"
                        onClick={excelExport}
                        disabled={items.length === 0 && totalRecordCount === 0}
                        icon={"hyperlink-open-sm"}
                      >
                        <u>Export List</u>
                      </Button>
                    </div>
                  </GridToolbar>)
                }
              </Grid>
          </Paper>
          <br />
          <div className={"total"}>
            <span><DescriptionOutlined fontSize="small" className={"description-icon"} /> Total: {isSelectable && selectionMode === "multi" ? selectedValues.length.toString()+ ' of '+ (totalRecordCount <= maxRecordCount ? items.length.toString()  : totalRecordCount.toString()): items.length.toString()}</span>
          </div>
          
        </>
      )}
      {isLoading && (
        <div className="align-center tableview-loader">
          <CircularProgress />
        </div>)
      }
    </div>
  );
}
export default TableViewField;