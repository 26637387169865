import { ServiceBase, ResponseMessages, ServiceResponseJson } from "./ServiceBase";
import { IUserApplicationRequest } from "../components/administration/user/application/ApplicationList";
import { IGridParams } from "../components/common/grid/StandardGrid";

const GetApplicationsMessages: ResponseMessages = {
  FailedMessage: "Failed to get applications.",
  SuccessMessage: "Successfully retrieved applications."
};

const UpsertAppFavoriteMessages: ResponseMessages = {
  FailedMessage: "Failed to updated the application favorite.",
  SuccessMessage: "Successfully updated the application favorite."
};

const AuditClickedAppMessages: ResponseMessages = {
  FailedMessage: "Failed to audit the application click.",
  SuccessMessage: "Successfully audit the application click."
};

export default class ConsoleService extends ServiceBase {
  static async getApplications(userApplicationRequest: IUserApplicationRequest, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(userApplicationRequest.filters) || "";
    const sortJSON = JSON.stringify(userApplicationRequest.sort) || "";
    const url = `${window.ApplicationVariables.apiUri}Applications?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(userApplicationRequest, ["filters", "sort", "filter"])}`;

    //const url = window.ApplicationVariables.apiUri + "Applications?" + this.toQueryString(userApplicationRequest);
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async getAppsWithExclusion(userApplicationRequest: IUserApplicationRequest, excludeUserId: number, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(userApplicationRequest.filters) || "";
    const sortJSON = JSON.stringify(userApplicationRequest.sort) || "";
    const url = `${window.ApplicationVariables.apiUri}Applications/getAppsWithExclusion?excludeUserId=${excludeUserId}&${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(userApplicationRequest, ["filters", "sort", "filter"])}`;

    //const url = window.ApplicationVariables.apiUri + "Applications?" + this.toQueryString(userApplicationRequest);
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async getMyApplications(gridParams: IGridParams, abortSignal?: AbortSignal) {
    const filterJSON = JSON.stringify(gridParams.filter) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${window.ApplicationVariables.apiUri}Applications/GetAppsFeatures?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async UpsertApplicationFavorite(
    applicationId: number,
    applicationName: string,
    isFavorite: boolean,
    abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "Applications/upsertAppFavorite";
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody({ applicationId, applicationName, isFavorite }),
      ...this.fetchPostOptions(abortSignal)
    })
      .then(async (response) => await this.handleResponseJson(response, UpsertAppFavoriteMessages))
      .catch(async (response) => await this.handleResponseJson(response, UpsertAppFavoriteMessages));

    return result;
  }

  static async preNavigate(appId: number, abortSignal?: AbortSignal, data?: string) {
    let url = `${window.ApplicationVariables.apiUri}Applications/prenavigate?appId=${appId}`;

    if (data) {
      url = `${url}&data=${data}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async testConnectionForApplication(appId?: string, abortSignal?: AbortSignal) {
    const url = `${window.ApplicationVariables.apiUri}Applications/testConnectionForApplication?appId=${appId}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async testConnection(appTypeId?: number, identifier?: string, code?: string, apiUrl?: string, abortSignal?: AbortSignal) {
    const url = `${window.ApplicationVariables.apiUri}Applications/testConnection?appTypeId=${appTypeId}&identifier=${identifier}&code=${code}&apiUrl=${apiUrl}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async auditClickedApp(appId: number, appName: string, abortSignal?: AbortSignal) {
    const url = window.ApplicationVariables.apiUri + "Applications/auditClickedApp";
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody({ id: appId, name: appName }), ...this.fetchPostOptions(abortSignal)
    })
      .then(async (response) => await this.handleResponseJson(response, AuditClickedAppMessages))
      .catch(async (response) => await this.handleResponseJson(response, AuditClickedAppMessages));

    return result;
  }

}

