import React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import RequestGrid, { RequestType } from "../common/RequestGrid";
import { Routes } from "../../../models/Routing";
import * as SessionStore from "../../../store/Session";
import { workRequestSteps, createWorkRequest, wizardSummary, wizardWarningMessage, jobMessagingConfig, wizardSaveForLater, wizardConfirmSubmitMessage } from "../../../models/SupportRequestSettings";

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps & SessionStore.ISessionState;

const PulseWorkRequests = (props: Props) => {
  return (
    <RequestGrid
      {...props}
      requestType={RequestType.Pulse}
      showCreateRequestButton={true}
      showRequestList={true}
      disableSubmitButton={props.sessionData.preImpersonatedUser != null}
      createWorkRequest={createWorkRequest}
      workRequestSteps={workRequestSteps}
      wizardSummary={wizardSummary}
      wizardWarningMessage={wizardWarningMessage}
      wizardSaveForLatter={wizardSaveForLater}
      wizardConfirmSubmitMessage={wizardConfirmSubmitMessage}
      jobMessagingConfig={jobMessagingConfig}
      jobTypeNumber={6}
    />
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }), null)(PulseWorkRequests);
