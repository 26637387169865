import React, { useEffect, useState } from "react";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { updateValForField } from "./WizardElements";
import { IWizardLookupValues } from "../../../models/Wizard";
import './SecureFileUploadForm.scss';

var proj = 'defaultProject';
var folder = 'defaultFolder';
var secureFormUrl = "defaultURL";

const SecureFileUploadForm = function (fieldRenderProps: FieldRenderProps) {

  useEffect(() => {
    var envsecureFormUrl = window.ApplicationVariables.secureFormUrl;
    var secureUploadDetails = fieldRenderProps.fieldData.GetDataParams(null, fieldRenderProps.fieldValues).secureUploadDetails;
    var newURL = secureUploadDetails && secureUploadDetails.secureFormURL;
    console.log("********       oldURL", envsecureFormUrl);
    console.log("********       newURL", newURL);
    if (newURL) {
      secureFormUrl = newURL;
    } else {
      secureFormUrl = envsecureFormUrl;
    }
    console.log("******** secureFormUrl", secureFormUrl);

    var d = new Date();
    var month = d.getMonth() < 10 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString();
    var day = d.getDate() < 10 ? "0" + d.getDate().toString() : d.getDate().toString();
    var year = d.getFullYear();
    var hour = d.getHours() < 10 ? "0" + d.getHours().toString() : d.getHours().toString();
    var minute = d.getMinutes() < 10 ? "0" + d.getMinutes().toString() : d.getMinutes().toString();
    var datetime = year + month + day + hour + minute;
    console.log("datetime : ", datetime);

    folder = fieldRenderProps.jobId + "_" + datetime;
    secureFormUrl = secureFormUrl.concat("?foldername=").concat(folder);

    // updateValForField("secureFileUploadFolder", fieldRenderProps, "uploading...");
    window.addEventListener("message", (event) => {
      console.log("secureFileUpload event", event);

      if (event.data === "UploadSuccess") {
        const lookupValues = { ftpSiteUrl: secureUploadDetails && secureUploadDetails.ftpSiteUrl } as IWizardLookupValues;
        updateValForField("UploadSuccess", fieldRenderProps, folder, lookupValues);
      }
    }, false);

  }, []);

  return (
    <div id=''>
      <iframe width='100%' height='320px' className='secure-form-iframe' id='secureFormIframe' src={secureFormUrl}
        sandbox="allow-downloads
            allow-forms
            allow-same-origin
            allow-scripts"
      />
      <div className="secure-file-max-upload-size">
        <b>Maximum Upload Size: 10GB </b>
      </div>
      <div className="secure-file-error-message">
        <span className="info-icon">Upload your files and click Submit.  Then, click Next.</span>
      </div>
    </div>
  );
}

export default SecureFileUploadForm;
