import { IJobDownloadRequest, IJobParams } from "../models/JobModels";
import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";

interface IBaseJobRequestData {
  jobType: number;
}
interface IWorkRerquestResonpseData {
  existingData?: {
    submitData: { Fields: [], FormQuestions: [], jobId: string, valuesAudit: any[] }
  }
}
interface IBaseJobResponseData extends IWorkRerquestResonpseData {
  jobId: string;
}
interface IJobRequestData extends IBaseJobRequestData {
  jobId: string;
}
export interface IGetJobStatusResponseData extends IBaseJobResponseData {
  statusId: number;
  data?: string;
}
export interface IInitializeJobResponseData extends IBaseJobResponseData { }
export interface IUpdateJobResponseData extends IBaseJobResponseData {
  jobType: number;
}
interface IJobResponseJson<T extends IBaseJobResponseData> extends ServiceResponseJson {
  data: T;
}

interface IGetJobStatusResponseJson<T extends IGetJobStatusResponseData> extends IJobResponseJson<T> { }

interface IInitializeJobResponseJson<T extends IInitializeJobResponseData> extends IJobResponseJson<T> { }
interface IUpdateJobResponseJson<T extends IUpdateJobResponseData> extends IJobResponseJson<T> { }
export interface IInitializeJobRequestData extends IBaseJobRequestData { }
export interface ISubmitJobRequestData extends IJobRequestData { }
export interface IUpdateJobRequestData extends IJobRequestData {
  jobType: number;
}

const getJobsMessages: ResponseMessages = {
  FailedMessage: "Failed to get job information.",
  SuccessMessage: "Successfully retrieved job information."
};
const initializeJobInfoMessages: ResponseMessages = {
  FailedMessage: "Failed to initialize job.",
  SuccessMessage: "Successfully initialized job."
};
const submitJobInfoMessages: ResponseMessages = {
  FailedMessage: "Failed start job.",
  SuccessMessage: "Successfully started job."
};
const updateJobInfoMessages: ResponseMessages = {
  FailedMessage: "Failed to update job.",
  SuccessMessage: "Successfully updated job."
};
const getUserJobsMessages: ResponseMessages = {
  FailedMessage: "Failed to get user job information.",
  SuccessMessage: "Successfully retrieved user job information."
};

export default class JobsService extends ServiceBase {
  static async getJobStatus<T extends IGetJobStatusResponseData>(jobId: string) {
    const url = `${JobsService.getBaseUri()}/status?jobId=${jobId}`;
    const result: IGetJobStatusResponseJson<T> = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, getJobsMessages))
      .catch(async response => await this.handleResponseJson(response, getJobsMessages));

    return result;
  }

  static async resetJob<T extends IGetJobStatusResponseData>(jobId: string) {
    const url = `${JobsService.getBaseUri()}/reset?jobId=${jobId}`;
    const result: IGetJobStatusResponseJson<T> = await fetch(url, {
      body: this.toPostBody(jobId),

      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, getJobsMessages))
      .catch(async response => await this.handleResponseJson(response, getJobsMessages));

    return result;
  }

  static async submitJob<T extends ISubmitJobRequestData>(jobData: T) {
    const url = `${JobsService.getBaseUri()}/submit`;
    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(jobData),

      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponse(response, submitJobInfoMessages))
      .catch(async response => await this.handleResponse(response, submitJobInfoMessages));

    return result;
  }

  static async download<T extends IJobDownloadRequest>(jobData: T) {
    const url = `${JobsService.getBaseUri()}/download`;
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(jobData),
      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, submitJobInfoMessages))
      .catch(async response => await this.handleResponseJson(response, submitJobInfoMessages));

    return result;
  }

  static async initializeJob<T extends IInitializeJobResponseData, TT extends IInitializeJobRequestData>(jobData: TT) {
    const url = `${JobsService.getBaseUri()}/initialize`;
    const result: IInitializeJobResponseJson<T> = await fetch(url, {
      body: this.toPostBody(jobData),

      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, initializeJobInfoMessages))
      .catch(async response => await this.handleResponseJson(response, initializeJobInfoMessages));

    return result;
  }

  static async updateJobInfo<T extends IUpdateJobResponseData, TT extends IUpdateJobRequestData>(jobData: TT) {
    const url = `${JobsService.getBaseUri()}/update`;
    const result: IUpdateJobResponseJson<T> = await fetch(url, {
      body: this.toPostBody(jobData),

      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, updateJobInfoMessages))
      .catch(async response => await this.handleResponseJson(response, updateJobInfoMessages));

    return result;
  }

  static async getUserJobs(params: IJobParams, abortSignal?: AbortSignal) {

    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const url = `${JobsService.getBaseUri()}/user?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
      .then(async (response) => await ServiceBase.handleResponseJson(response, getUserJobsMessages))
      .catch(async (response) => await ServiceBase.handleResponseJson(response, getUserJobsMessages));

    return result;
  }

  static getBaseUri() {
    // TODO we might move this to a generic 'jobs' path down the road if we deal with non-user stuff
    return `${(<any>window).ApplicationVariables.apiUri}jobs`;
  }
}
