import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, } from "@material-ui/core";
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { AuditEvent } from "../../../../models/UserActivityModels";
import JobsService, { IInitializeJobRequestData, ISubmitJobRequestData } from "../../../../services/JobService";
import AdminService from "../../../../services/AdminService";
import SingleSelectDropDown from "../../../common/SingleSelectDropDown";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { IGridParams } from "../../../common/grid/AdvancedGrid";
import { User } from "../../../../models/UserModels";
import CustomKendoDialog from "../../../common/kendopopup/CustomKendoDialog";
import NotificationService from "../../../../services/NotificationService";
import ErrorIcon from "@material-ui/icons/Error";
import { ServiceBase } from "../../../../services/ServiceBase";
import CommonHelper from "../../../common/utilities/CommonHelper";
import SanitizeTextHelper from "../../../common/utilities/SanitizeTextHelper";

const userFilters: Array<CompositeFilterDescriptor> = [
  {
    logic: "or",
    filters: [{ field: "userName", operator: "contains", value: "" },
    { field: "lastName", operator: "contains", value: "" },
    { field: "firstName", operator: "contains", value: "" }
    ] as Array<FilterDescriptor>
  }
]

const FetchSettings = {
  MaxFetchRetryAttempts: 3
};

const validationMessages = {
  toDateIsLessThanFromDate: "To date should not be less than from date.",
  fromDateIsGreaterThanToDate: "From date should not be greater than to date.",
  requiredField: "This field is required.",
  shouldNotBeGreaterThanCurrentDate: "Selected date should not be greater than current date."
}

enum JobStates {
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

enum ElementNames {
  ToDate = "ToDate",
  FromDate = "FromDate"
}

interface IExportUserActivityJobData {
  [key: string]: string | Date | Array<SortDescriptor> | Array<CompositeFilterDescriptor> | number;
  activity: string;
  userInfo: string;
  //this is for display in the report, the actual filter values are in filter
  fromData: string;
  //this is for display in the report, the actual filter values are in filter
  toDate: string;
  filter: Array<CompositeFilterDescriptor>;
  sort: Array<SortDescriptor>;
  loggedInUserTimeZoneOffset: number;
}

interface IExportActivityJobSubmitModel extends ISubmitJobRequestData {
  readyForProcessing: boolean;
  exportUserActivityJobData: IExportUserActivityJobData;
}

interface IState {
  [key: string]: string | Date | boolean | IExportActivityJobSubmitModel | IGridParams | JobStates;
  errorMessage: string;
  exportProcessState: JobStates;
  enableAddButton: boolean;
  fromDate: Date,
  toDate: Date,
  userListDataState: IGridParams;
  exportActivityJobSubmitModel: IExportActivityJobSubmitModel;
  activity: string;
  validationErrorElement: ElementNames;
}

type Props = {
  handleExportModalClose: () => void;
  isExportSelectorOpen: boolean;
};

class ExportUserActivity extends React.Component<Props, IState> {
  exportFileName: string;

  constructor(props: Props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const initialState: IState = {
      errorMessage: "",
      activity: "",
      exportProcessState: JobStates.ImportReadyForProcessing,
      enableAddButton: false,
      fromDate: new Date(),
      toDate: new Date(),
      exportActivityJobSubmitModel: {
        jobId: "",
        jobType: 7,
        readyForProcessing: false,
        exportUserActivityJobData: {
          activity: "",
          userInfo: "",
          fromData: new Date().getUTCDate().toString(),
          toDate: new Date().getUTCDate().toString(),
          filter: new Array<CompositeFilterDescriptor>(),
          sort: new Array<SortDescriptor>({ field: "when", dir: "desc" }),
          loggedInUserTimeZoneOffset: -(new Date().getTimezoneOffset())
        } as IExportUserActivityJobData
      },
      userListDataState: {
        skip: 0,
        take: 100,
        sort: [{ field: "lastName", dir: "asc" }, { field: "firstName", dir: "asc" }, { field: "userName", dir: "asc" }],
        filters: userFilters
      },
      validationErrorElement: null
    }

    return initialState;
  }

  updateExportFilterCriteria = (name: string, value: string | Date) => {
    let errorMessage = "";
    let validationErrorElement: ElementNames = null;
    const { fromDate, toDate } = this.state;

    if (name === "fromDate" || name === "toDate") {
      const dateValue = value as Date;

      if (dateValue) {
        if (name === "fromDate" && (toDate) && dateValue > (toDate)) {
          errorMessage = dateValue > new Date() ? validationMessages.shouldNotBeGreaterThanCurrentDate : validationMessages.fromDateIsGreaterThanToDate;
          validationErrorElement = ElementNames.FromDate;
        }
        else if (name === "toDate" && (fromDate) && dateValue < (fromDate)) {
          errorMessage = dateValue > new Date() ? validationMessages.shouldNotBeGreaterThanCurrentDate : validationMessages.toDateIsLessThanFromDate;
          validationErrorElement = ElementNames.ToDate;
        } else {
          this.setState({ [name]: value });
        }
      } else {
        errorMessage = validationMessages.requiredField;
        validationErrorElement = name === "toDate" ? ElementNames.ToDate : ElementNames.FromDate;
      }
    }
  }

  getDefaultFromDate = () => {
    let defaultFromdate = new Date();
    defaultFromdate.setDate(defaultFromdate.getDate() - 30);

    return defaultFromdate;
  }

  getMinFilterDate = () => {
    let mindate = new Date();
    mindate.setDate(mindate.getDate() - 90);

    return mindate;
  }

  handleExportClick = async () => {
    this.setState({ ...this.state, exportProcessState: JobStates.ImportProcessing });
    let jobId = this.state.exportActivityJobSubmitModel.jobId;

    if (!jobId) {
      const initializeJobRequest: IInitializeJobRequestData = {
        jobType: 7
      };

      const response = await JobsService.initializeJob(initializeJobRequest);

      if (response.ok) {
        jobId = response.data.jobId;
        await this.submitJobForProcessingExport(jobId);
      } else {
        console.log("Failed to initialize the export process job. Please re-initiate the export process.")
        NotificationService.showErrorToast("Failed to initialize the export process job. Please re-initiate the export process.");
      }
    } else {
      await this.submitJobForProcessingExport(jobId);
    }
  }


  async submitJobForProcessingExport(jobId: string) {
    const startDate = new Date(this.state.fromDate.setHours(0, 0, 0, 0));
    const endDate = new Date(this.state.toDate.setHours(23, 59, 59, 999));
    const dateRangeFilter: CompositeFilterDescriptor = {
      logic: "and",
      filters: [
        { field: "When", value: new Date(startDate.toUTCString()), operator: "gte" } as FilterDescriptor,
        { field: "When", value: new Date(endDate.toUTCString()), operator: "lte" } as FilterDescriptor
      ]
    };
    const userFilter: CompositeFilterDescriptor = {
      logic: "and",
      filters: [
        { field: "actor.userName", value: this.state.exportActivityJobSubmitModel.exportUserActivityJobData.userInfo, operator: "contains" } as FilterDescriptor
      ]
    }
    const activityFilter: CompositeFilterDescriptor = {
      logic: "and",
      filters: [
        { field: "eventName", value: this.state.activity, operator: "contains" } as FilterDescriptor
      ]
    }
    const filters = new Array<CompositeFilterDescriptor>(userFilter, activityFilter, dateRangeFilter);
    const submitJobResponse = await JobsService.submitJob({
      jobId: jobId,
      jobType: this.state.exportActivityJobSubmitModel.jobType,
      readyForProcessing: true,
      exportUserActivityJobData: {
        activity: this.state.activity,
        userInfo: this.state.exportActivityJobSubmitModel.exportUserActivityJobData.userInfo,
        fromDate: startDate.toDateString(),
        toDate: endDate.toDateString(),
        filter: ServiceBase.encodeToBase64(JSON.stringify(filters)),
        sort: btoa(JSON.stringify(this.state.exportActivityJobSubmitModel.exportUserActivityJobData.sort)),
        loggedInUserTimeZoneOffset: this.state.exportActivityJobSubmitModel.exportUserActivityJobData.loggedInUserTimeZoneOffset
      }
    });

    if (submitJobResponse.ok) {
      await this.fetchJobStatus(jobId);
    } else {
      console.log("Failed to submit job for export process. Please re-initiate the export process.");
      NotificationService.showErrorToast("Failed to submit job for export process. Please re-initiate the export process.");
    }
  }

  async fetchJobStatus(jobId: string) {
    if (jobId) {
      const { ok, data } = await JobsService.getJobStatus(jobId);

      if (ok) {
        const jobData = data.data ? JSON.parse(data.data) : null;
        const exportJobDataError = jobData && jobData.Error as string;

        switch (data.statusId) {
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await this.fetchJobStatus(jobId);
            break;
          case JobStates.ImportProcessedError:
            NotificationService.showErrorToast(exportJobDataError);
            this.clearUserInfoFilter();
            this.setState(this.getInitialState());
            this.props.handleExportModalClose();
            break;
          case JobStates.ImportProcessedSuccess:
            if (exportJobDataError) {
              NotificationService.showErrorToast(exportJobDataError);
              this.setState({ ...this.state, exportProcessState: JobStates.ImportProcessedSuccess });
            } else {
              this.downloadExportActivities(jobId)
            }
            this.clearUserInfoFilter();
            this.setState(this.getInitialState());
            this.props.handleExportModalClose();
            break;
        }
      } else {
        console.log("Failed to get job status. Please re-initiate the export process.");
        NotificationService.showErrorToast("Failed to get job status. Please re-initiate the export process.");
      }
    }
  }

  async downloadExportActivities(jobId: string) {
    const response = await AdminService.getDownloadBlobStorageUri(jobId, FetchSettings.MaxFetchRetryAttempts);
    const failedToDownloadErrorMessage = `Unable to download file . Please try again. If this issue continues, contact support.`;

    if (response.ok) {
      ServiceBase.fetchRetry(
        response.data.downloadUrl,
        {
          method: "GET"
        },
        FetchSettings.MaxFetchRetryAttempts
      )
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const anchorElement = document.createElement("a");
          const formattedCurrentDate = CommonHelper.dateFormat(new Date(), "MMDDYYYY", "");

          anchorElement.style.display = "none";
          anchorElement.href = SanitizeTextHelper.sanitizeUrl(url);
          anchorElement.download = `Admin Activity ${formattedCurrentDate}.xls`;
          document.body.appendChild(anchorElement);
          anchorElement.click();
          window.URL.revokeObjectURL(url);
          NotificationService.showSuccessToast(
            "Exported activities successfully."
          );
        })
        .catch((error: any) => {
          console.log(
            `Error occurred while downloading the export activites file from blob storage. Exception Details : ${error}`
          );
          NotificationService.showErrorToast(failedToDownloadErrorMessage);

        });
    } else {
      NotificationService.showErrorToast(failedToDownloadErrorMessage);
    }
  };

  enableExportButton = () => {
    const { fromDate, toDate } = this.state;

    return ((fromDate != null && toDate != null) && (this.state.exportProcessState !== JobStates.ImportProcessing) && this.state.errorMessage.length === 0);
  }

  handleExportModalClose = () => {
    this.props.handleExportModalClose();
    this.clearUserInfoFilter();
    this.setState(this.getInitialState());
  }

  clearUserInfoFilter = () => {
    userFilters[0].filters.filter((filter, index) => {
      (filter as FilterDescriptor).value = "";
    });
  }

  render() {
    return (
      <CustomKendoDialog
        openModel={this.props.isExportSelectorOpen}
        acceptBtnText="EXPORT"
        cancelBtnText="CANCEL"
        titleText="Export Activity"
        isDisableExport={this.enableExportButton()}
        onClose={this.handleExportModalClose}
        onFilterExport={this.handleExportClick}>
        <div className="activity-filter">
          {this.state.exportProcessState === JobStates.ImportProcessing ?
            (
              <Grid container>
                <Grid item xs={12} spacing={1} className="grid-circular-in-progress circular-in-progress">
                  <CircularProgress />
                </Grid>
              </Grid>
            )
            : (
              <Grid container>
                <div className="field-hint">* Required Fields</div>
                <Grid item xs={6}>
                  <label>* From</label>
                  <DatePicker
                    id="fromDate"
                    value={this.state.fromDate}
                    max={new Date()}
                    min={this.getMinFilterDate()}
                    onChange={(event: DatePickerChangeEvent) => {
                      const fromDate = event.target.value;
                      this.updateExportFilterCriteria("fromDate", fromDate);
                    }}
                    required={true}
                  />
                  {this.state.validationErrorElement !== null && (this.state.validationErrorElement === ElementNames.FromDate || !this.state.fromDate) &&
                    <div className="error-info" id="fromDateDiv">
                      <ErrorIcon className="error-icon" />
                      <span className="erro-msg">{!this.state.fromDate ? validationMessages.requiredField : this.state.errorMessage}</span>
                    </div>
                  }
                </Grid>
                <Grid item xs={6}>
                  <label>* To</label>
                  <DatePicker
                    id="toDate"
                    value={this.state.toDate}
                    min={this.getMinFilterDate()}
                    max={new Date()}
                    onChange={(event: DatePickerChangeEvent) => {
                      const toDate = event.target.value;
                      this.updateExportFilterCriteria("toDate", toDate);
                    }}
                    required={true}
                  />
                  {this.state.validationErrorElement !== null && (this.state.validationErrorElement === ElementNames.ToDate || !this.state.toDate) &&
                    <div className="error-info" id="toDateDiv">
                      <ErrorIcon className="error-icon" />
                      <span className="erro-msg">{!this.state.toDate ? validationMessages.requiredField : this.state.errorMessage}</span>
                    </div>
                  }

                </Grid>
                <Grid item xs={6}>
                  <label>Activity</label>
                  <SingleSelectDropDown
                    getItems={() => AdminService.GetAuditEventNames(this.state.activity)}
                    textToRequestConverter={searchText => {
                      this.setState({ ...this.state, activity: searchText ? searchText : "" });
                    }}
                    onChange={event => {
                      const eventDescription = event && event.data ? event.data.eventDescription : "";

                      this.setState({ ...this.state, activity: eventDescription });
                    }}
                    typeToListConverter={(gs: AuditEvent[]) => gs.map(item => { return { id: item.id, text: item.eventDescription, data: item }; })
                    }

                  />
                </Grid>
                <Grid item xs={6}>
                  <label>User Info</label>
                  <SingleSelectDropDown
                    getItems={() => AdminService.getUserList(this.state.userListDataState)}
                    textToRequestConverter={searchText => {
                      userFilters[0].filters.filter((filter, index) => {
                        (filter as FilterDescriptor).value = searchText ? searchText : "";
                      });
                      this.setState({ userListDataState: { ...this.state.userListDataState, filters: userFilters } });
                    }}
                    onChange={event => {
                      const email = event && event.data ? event.data.username : "";
                      const exportUserActivityJobData = { ...this.state.exportActivityJobSubmitModel.exportUserActivityJobData, userInfo: email }

                      this.setState({ exportActivityJobSubmitModel: { ...this.state.exportActivityJobSubmitModel, exportUserActivityJobData: exportUserActivityJobData } });
                    }}
                    typeToListConverter={(gs: User[]) => gs.map(item => { return { id: item.id, text: item.username, data: item }; })
                    }

                  />
                </Grid>
              </Grid>)}
        </div>
      </CustomKendoDialog>
    );
  }
}

export default ExportUserActivity;
