import { UpsertUserModel, User, SendInvite, UserGroup, UpsertUserGroupModel, IUpsertUserGroupUserModel } from "../models/UserModels";
import { ServiceBase, ResponseMessages, ServiceResponse, ServiceResponseJson, ServiceResponseStr } from "./ServiceBase";
import { UserActivity, Target } from "../models/UserActivityModels";
import { IGridParams, IGridParamsEpiq } from "../components/common/grid/StandardGrid";
import CommonHelper from "../components/common/utilities/CommonHelper";
import { IGroupUserRequestParams, IAppUserRequestParams } from "../models/RequestModels";
import { Role, UpsertRoleModel } from "../models/Role";
import { IGridParams as AdvancedGridParams } from "../components/common/grid/AdvancedGrid";
import { Application, ApplicationsType, UpsertUserAppModel } from "../models/ApplicationModels";
import { UpsertEmployerModel, MoveDomainModel } from "../models/EmployerModel";
import { SecureUploadLocation, SecureUploadReqParams } from "../models/SecureUploadLocations";
import { IRelClient, IRelInstance } from "../models/RelativityModel";
import { IAppTypeSystemFeature } from "../models/Features";

const GetUserMessages: ResponseMessages = {
  FailedMessage: "Failed to get users.",
  SuccessMessage: "Successfully retrieved users."
};

const CreateUserMessages: ResponseMessages = {
  FailedMessage: "Create user failed.",
  SuccessMessage: "Create user succeeded."
};

const SendInviteMessages: ResponseMessages = {
  FailedMessage: "Send Invite  failed.",
  SuccessMessage: "Send Invite succeeded."
};

const GetUserDetailsMessages: ResponseMessages = {
  FailedMessage: "Get User details failed.",
  SuccessMessage: "Get User details succeeded."
};

const GetUserGroupsByUserIdMessages: ResponseMessages = {
  FailedMessage: "Get User groups failed.",
  SuccessMessage: "Get User groups succeeded."
};

const UpdateUserMessages: ResponseMessages = {
  FailedMessage: "Update user failed.",
  SuccessMessage: "Update user succeeded."
};

const UpdateUserGroupMessages: ResponseMessages = {
  FailedMessage: "Update users failed.",
  SuccessMessage: "Update users succeeded."
};

const GetUserGroupDetailsMessages: ResponseMessages = {
  FailedMessage: "Get User Group details failed.",
  SuccessMessage: "Get User Group details succeeded."
};

const DeactivateUserMessages: ResponseMessages = {
  FailedMessage: "User deactivation failed.",
  SuccessMessage: "User deactivation succeeded."
};

const AssignGlobalAdminMessages: ResponseMessages = {
  FailedMessage: "Assign Global Admin failed.",
  SuccessMessage: "Assign Global Admin succeeded."
};

const ReactivateUserMessages: ResponseMessages = {
  FailedMessage: "User reactivation failed.",
  SuccessMessage: "User reactivation succeeded."
};

const UnlockUserMessages: ResponseMessages = {
  FailedMessage: "User unlock failed.",
  SuccessMessage: "User unlock succeeded."
};

const GetUserActivityMessages: ResponseMessages = {
  FailedMessage: "Get Users Activity failed.",
  SuccessMessage: "Get Users Activity succeeded."
};

const ImportJobInfoMessages: ResponseMessages = {
  FailedMessage: "Job creation for import process failed.",
  SuccessMessage: "Job creation for import process succeeded."
};

const FileUploadMessages: ResponseMessages = {
  FailedMessage: "File upload failed.",
  SuccessMessage: "File upload succeeded."
};

const GetJobStatusMessages: ResponseMessages = {
  FailedMessage: "Fetching job status details failed.",
  SuccessMessage: "Fetching job status details succeeded."
};

const ImportProcessingMessages: ResponseMessages = {
  FailedMessage: "Import proccess initiation failed.",
  SuccessMessage: "Import proccess initiation succeeded."
};

const GetDownloadUriMessages: ResponseMessages = {
  FailedMessage: "Fetching download uri failed.",
  SuccessMessage: "Fetching download uri succeeded."
};
const CreateUserGroupMessages: ResponseMessages = {
  FailedMessage: "Create usergroup failed.",
  SuccessMessage: "Create usergroup succeeded."
};

const DeleteOrRestoreUserGroupMessages: ResponseMessages = {
  FailedMessage: "Delete/Restore usergroup failed.",
  SuccessMessage: "Delete/Restore usergroup succeeded."
};


const GetUserGroupsMessages: ResponseMessages = {
  FailedMessage: "Get User Groups failed.",
  SuccessMessage: "Get User Groups succeeded."
};

const CheckDeleteUserGroupMessages: ResponseMessages = {
  FailedMessage: "Checking the possibilities of delete user group failed.",
  SuccessMessage: "Checking the possibilities of delete user group succeeded."
};

const GetUsersMessages: ResponseMessages = {
  FailedMessage: "Failed to get users.",
  SuccessMessage: "Successfully retrieved users."
};

const GetProjectsMessages: ResponseMessages = {
  FailedMessage: "Get Projects failed.",
  SuccessMessage: "Get Projects succeeded."
};

const GetProjectClientMessages: ResponseMessages = {
  FailedMessage: "Get Project Clients failed.",
  SuccessMessage: "Get Project Clients succeeded."
};

const GetProjectDetailsMessages: ResponseMessages = {
  FailedMessage: "Get Project details failed.",
  SuccessMessage: "Get Project details succeeded."
};

const CheckExistenceUserGroupName: ResponseMessages = {
  FailedMessage: "Checking the existence of user group name failed.",
  SuccessMessage: "Checking the existence of user group name succeeded."
};

const GetRolesMessages: ResponseMessages = {
  FailedMessage: "Get Roles failed.",
  SuccessMessage: "Get Roles succeeded."
};

const GetRoleDetailsMessages: ResponseMessages = {
  FailedMessage: "Get Role details failed.",
  SuccessMessage: "Get Role details succeeded."
};

const UpdateRoleMessages: ResponseMessages = {
  FailedMessage: "Update role failed.",
  SuccessMessage: "Update role succeeded."
};

const GetRolesPermissionsMessages: ResponseMessages = {
  FailedMessage: "Get Roles Permissions failed.",
  SuccessMessage: "Get Roles Permissions succeeded."
};

const GetPermissionMessages: ResponseMessages = {
  FailedMessage: "Get Permissions failed.",
  SuccessMessage: "Get Permissions succeeded."
};

const CheckExistenceRoleName: ResponseMessages = {
  FailedMessage: "Checking the existence of role name failed.",
  SuccessMessage: "Checking the existence of role name succeeded."
};

const CreateRoleMessages: ResponseMessages = {
  FailedMessage: "Create role failed.",
  SuccessMessage: "Create role succeeded."
};

const CheckDeleteRoleMessages: ResponseMessages = {
  FailedMessage: "Checking the possibilities of delete role failed.",
  SuccessMessage: "Checking the possibilities of delete role succeeded."
};

const GetDelRoleDetailsMessages: ResponseMessages = {
  FailedMessage: "Get Deleted role details failed.",
  SuccessMessage: "Get Deleted role details succeeded."
};

const DeleteRoleMessages: ResponseMessages = {
  FailedMessage: "Delete role failed.",
  SuccessMessage: "Delete role succeeded."
};

const ReactivateRoleMessages: ResponseMessages = {
  FailedMessage: "Reactivate role failed.",
  SuccessMessage: "Reactivate role succeeded."
};

const GetApplicationsMessages: ResponseMessages = {
  FailedMessage: "Get Applications failed.",
  SuccessMessage: "Get Applications succeeded."
};

const GetAppDetailsMessages: ResponseMessages = {
  FailedMessage: "Get Application details failed.",
  SuccessMessage: "Get Application details succeeded."
};

const GetAllApplicationTypesMessages: ResponseMessages = {
  FailedMessage: "Get Application types failed.",
  SuccessMessage: "Get Application types succeeded."
};

const CheckExistenceAppName: ResponseMessages = {
  FailedMessage: "Checking the existence of app name failed.",
  SuccessMessage: "Checking the existence of app name succeeded."
};

const CheckExistenceAppTypeName: ResponseMessages = {
  FailedMessage: "Checking the existence of app type failed.",
  SuccessMessage: "Checking the existence of app type succeeded."
};

const CreateApplicationMessages: ResponseMessages = {
  FailedMessage: "Create application failed.",
  SuccessMessage: "Create application succeeded."
};

const UpdateApplicationMessages: ResponseMessages = {
  FailedMessage: "Update application failed.",
  SuccessMessage: "Update application succeeded."
};

const CreateApplicationTypesMessages: ResponseMessages = {
  FailedMessage: "Create application type failed.",
  SuccessMessage: "Create application type succeeded."
};

const UpdateApplicationTypesMessages: ResponseMessages = {
  FailedMessage: "Update application type failed.",
  SuccessMessage: "Update application type succeeded."
};

const DeleteApplicationsMessages: ResponseMessages = {
  FailedMessage: "Delete application failed.",
  SuccessMessage: "Delete application succeeded."
};

const DeactivateAppMessages: ResponseMessages = {
  FailedMessage: "Application deactivation failed.",
  SuccessMessage: "Application deactivation succeeded."
};

const ReactivateAppMessages: ResponseMessages = {
  FailedMessage: "Application reactivation failed.",
  SuccessMessage: "Application reactivation succeeded."
};

const CheckDeleteAppMessages: ResponseMessages = {
  FailedMessage: "Checking the possibilities of delete app failed.",
  SuccessMessage: "Checking the possibilities of delete app succeeded.",
}

const GetUserLogActivitesMessages: ResponseMessages = {
  FailedMessage: 'Get user log activities failed.',
  SuccessMessage: 'Get user log activites succeeded.'
}

const GetClientMessages: ResponseMessages = {
  FailedMessage: "Get Clients failed.",
  SuccessMessage: "Get Clients succeeded."
};

const GetEmployerDetailsMessages: ResponseMessages = {
  FailedMessage: "Get Employer details failed.",
  SuccessMessage: "Get Employer details succeeded."
};

const CreateEmployerMessages: ResponseMessages = {
  FailedMessage: "Create employer failed.",
  SuccessMessage: "Create employer succeeded."
}

const UpdateEmployerMessages: ResponseMessages = {
  FailedMessage: "Update employer failed.",
  SuccessMessage: "Update employer succeeded."
}

const GetDomainsMessages: ResponseMessages = {
  FailedMessage: "Get Domains failed.",
  SuccessMessage: "Get Domains succeeded."
};

const GetContractClientMessages: ResponseMessages = {
  FailedMessage: "Get Contract Clients failed.",
  SuccessMessage: "Get Contract Clients succeeded."
};

const GetDeactivactionStatusMessages: ResponseMessages = {
  FailedMessage: "Could not check whether user can deactivate.",
  SuccessMessage: "Succeeded in check whether user can deactivate."
};

const GetSecureUploadLocationsMessages: ResponseMessages = {
  FailedMessage: "Get Secure Upload Locations failed.",
  SuccessMessage: "Get Secure Upload Locations succeeded."
};

const CreateLocationMessages: ResponseMessages = {
  FailedMessage: "Create location failed.",
  SuccessMessage: "Create location succeeded."
}

const UpdateLocationMessages: ResponseMessages = {
  FailedMessage: "Update location failed.",
  SuccessMessage: "Update location succeeded."
}

const GetEnvironmentsMessages: ResponseMessages = {
  FailedMessage: "Get environments failed.",
  SuccessMessage: "Get environments succeeded."
}

const GetOktaApplicationsMessages: ResponseMessages = {
  FailedMessage: "Failed to get okta applications.",
  SuccessMessage: "Successfully retrieved okta applications."
};

const GetOktaApplicationGroupsMessages: ResponseMessages = {
  FailedMessage: "Failed to get okta application groups.",
  SuccessMessage: "Successfully retrieved okta application groups."
};

const mapDomainToNewEmployerMessages: ResponseMessages = {
  FailedMessage: "Failed mapping domain to new employer.",
  SuccessMessage: "Successfully mapped domain to new employer."
};

const GetRelInstancesMessages: ResponseMessages = {
  FailedMessage: "Failed getting relativity instances.",
  SuccessMessage: "Successfully retrieved relativity instances."
};

const GetClientsByInstanceMessages: ResponseMessages = {
  FailedMessage: "Failed getting relativity clients by instance.",
  SuccessMessage: "Successfully retrieved relativity cleints by instance."
};

const AddRelInstancesMessages: ResponseMessages = {
  FailedMessage: "Failed adding relativity instance to resource.",
  SuccessMessage: "Successfully added relativity instances to resource."
};

const SettingSystemFeatureMessages: ResponseMessages = {
  FailedMessage: "Failed to set system features",
  SuccessMessage: "Successfully set system features."
};

export default class AdminService extends ServiceBase {
  static async getUser(gridParams: IGridParams, userGroupId: number = 0, domainFilters: string[] = []) {
    let url = AdminService.getBaseUri();
    let symbol = "?";

    if (typeof gridParams.skip !== "undefined" && typeof gridParams.take !== "undefined") {
      url = `${url}?skip=${gridParams.skip}&take=${gridParams.take}`;
      symbol = "&";
    }

    if (typeof gridParams.searchText !== "undefined" && gridParams.searchText !== "") {
      url = `${url}${symbol}searchText=${encodeURIComponent(gridParams.searchText)}`;
    }

    if (
      typeof gridParams.orderBy !== "undefined" &&
      gridParams.orderBy !== "" &&
      typeof gridParams.isAscending !== "undefined"
    ) {
      url = `${url}&orderBy=${gridParams.orderBy}&isAscending=${gridParams.isAscending}`;
    }

    if (userGroupId) {
      url = `${url}&userGroupId=${userGroupId}`;
    }

    if (domainFilters && domainFilters.length > 0) {
      url = `${url}&domainFilters=${domainFilters.join()}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserActivityMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserActivityMessages));

    if (result.ok) {
      result.data.results = result.data.results.map(
        (result: User): User => {
          return {
            ...result,
            invitationsentDate: result.invitationsentDate
              ? CommonHelper.convertUTCDateToLocalDate(result.invitationsentDate)
              : null,
            lastLoginDate: result.lastLoginDate ? CommonHelper.convertUTCDateToLocalDate(result.lastLoginDate) : null,
            lastmodifiedDate: result.lastmodifiedDate
              ? CommonHelper.convertUTCDateToLocalDate(result.lastmodifiedDate)
              : null
          };
        }
      );
    }

    return result;
  }

  static async getUserList(params: AdvancedGridParams) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const url = `${AdminService.getBaseUri()}/getUsersList?${AdminService.getFilterSortQueryString(filterJSON, sortJSON)}&${AdminService.toQueryString(params, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, AdminService.fetchOptions())
      .then(async response => await AdminService.handleResponseJson(response, GetUserMessages))
      .catch(async response => await AdminService.handleResponseJson(response, GetUserMessages));

    if (result.ok) {
      result.data.results = result.data.results.map((result: User): User => {
        return {
          ...result,
          invitationsentDate: result.invitationsentDate ? CommonHelper.convertUTCDateToLocalDate(result.invitationsentDate) : null,
          lastLoginDate: result.lastLoginDate ? CommonHelper.convertUTCDateToLocalDate(result.lastLoginDate) : null,
          lastmodifiedDate: result.lastmodifiedDate ? CommonHelper.convertUTCDateToLocalDate(result.lastmodifiedDate) : null,
          createdDate: result.createdDate ? CommonHelper.convertUTCDateToLocalDate(result.createdDate) : null
        };
      });
    };

    return result;
  }

  static async getBadOktaUserDetails(email: string, limit: number) {
    let url = AdminService.getBaseUri() + "/getOktaUsersAsync";

    if (typeof email !== "undefined" && email !== "") {
      var emailEncode = ServiceBase.encodeToBase64(email);
      url = `${url}?email=${emailEncode}`;
    }

    if (typeof limit !== "undefined" && !isNaN(limit)) {
      url = `${url}&limit=${limit}`;
    }

    this.fetchOptions();

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserDetailsMessages));

    return result;
  }

  static async createUser(user: UpsertUserModel) {
    const result: ServiceResponseStr = await fetch(AdminService.getBaseUri(), {
      body: this.toPostBody(user),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseStr(response, CreateUserMessages))
      .catch(response => this.handleResponseStr(response, CreateUserMessages));

    return result;
  }

  static async sendInvite(sendInvite: SendInvite) {
    let url = AdminService.getBaseUri() + "/sendinvitation";
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(sendInvite),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, SendInviteMessages))
      .catch(response => this.handleResponseJson(response, SendInviteMessages));

    return result;
  }

  static async getUserDetails(userId: number) {
    let url = AdminService.getBaseUri() + "/getUserDetails";

    if (typeof userId !== "undefined" && !isNaN(userId)) {
      url = `${url}?userId=${userId}`;
    }

    this.fetchOptions();

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserDetailsMessages));

    return result;
  }

  static async getUserGroupsByUserId(userId: number, gridParams: AdvancedGridParams) {

    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseGroupUri()}/getUserGroupsByUserId?userId=${userId}&${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    AdminService.fetchOptions();

    const result: ServiceResponseJson = await fetch(url, AdminService.fetchOptions())
      .then(async response => await AdminService.handleResponseJson(response, GetUserGroupsByUserIdMessages))
      .catch(async response => await AdminService.handleResponseJson(response, GetUserGroupsByUserIdMessages));

    return result;
  };

  static async ReconfigureUserInOkta(user: UpsertUserModel) {
    const result: ServiceResponseStr = await fetch(`${AdminService.getBaseUri()}/reConfigureOktaAccount`, {
      body: JSON.stringify(user),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseStr(response, UpdateUserMessages))
      .catch(response => this.handleResponseStr(response, UpdateUserMessages));

    return result;
  }

  static async UpdateUser(user: UpsertUserModel) {
    const result: ServiceResponseStr = await fetch(AdminService.getBaseUri(), {
      body: JSON.stringify(user),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseStr(response, UpdateUserMessages))
      .catch(response => this.handleResponseStr(response, UpdateUserMessages));

    return result;
  }

  static async deactivateUser(user: User) {
    const url = `${AdminService.getBaseUri()}/deactiveUser`;
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(user),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, DeactivateUserMessages))
      .catch(response => this.handleResponseJson(response, DeactivateUserMessages));

    return result;
  }

  static async reactivateUser(user: User) {
    const url = `${AdminService.getBaseUri()}/reactivate`;
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(user),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, ReactivateUserMessages))
      .catch(response => this.handleResponseJson(response, ReactivateUserMessages));
    return result;
  }

  static async assignGlobalAdmin(user: UpsertUserModel) {
    let url = `${AdminService.getBaseUri()}/assignglobaladmin`;
    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(user),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponse(response, AssignGlobalAdminMessages))
      .catch(response => this.handleResponse(response, AssignGlobalAdminMessages));
    return result;
  }

  static async unlockUser(user: User) {
    const url = `${AdminService.getBaseUri()}/unlockUser`;
    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(user),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, UnlockUserMessages))
      .catch(response => this.handleResponseJson(response, UnlockUserMessages));
    return result;
  }

  static async getUserActivity(params: AdvancedGridParams) {

    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    let url = `${AdminService.getBaseAuditsUri()}/?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserActivityMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserActivityMessages));

    if (result.ok) {
      result.data.results = result.data.results.map(
        (result: UserActivity): UserActivity => {
          return {
            ...result,
            when: new Date(result.when)
          };
        }
      );
    }

    return result;
  }

  static async getDownloadUri(jobId: string, maxFetchRetryAttempts: number) {
    const url = `${AdminService.getBaseUri()}/import/downloaduri?jobId=${jobId}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetDownloadUriMessages))
      .catch(async response => await this.handleResponseJson(response, GetDownloadUriMessages));

    return result;
  }

  static async getDownloadBlobStorageUri(jobId: string, maxFetchRetryAttempts: number) {
    const url = `${AdminService.getBaseUri()}/exportActivity/downloaduri?jobId=${jobId}`;
    const result: ServiceResponseJson = await this.fetchRetry(url, this.fetchOptions(), maxFetchRetryAttempts)
      .then(async response => await this.handleResponseJson(response, GetDownloadUriMessages))
      .catch(async response => await this.handleResponseJson(response, GetDownloadUriMessages));

    return result;
  }

  static async createUserGroup(userGroup: UserGroup) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseGroupUri(), {
      body: this.toPostBody(userGroup),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateUserGroupMessages))
      .catch(response => this.handleResponseJson(response, CreateUserGroupMessages));

    return result;
  }

  static async upsertEmailUsersForGroup(userGroup: IUpsertUserGroupUserModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseGroupUri() + "/upsertEmailUsersForGroup", {
      body: this.toPostBody(userGroup),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateUserGroupMessages))
      .catch(response => this.handleResponseJson(response, CreateUserGroupMessages));

    return result;
  }

  static async canDeleteUserGroup(groupId: number) {
    let url = AdminService.getBaseGroupUri() + "/canDeleteUserGroup";

    if (typeof groupId !== "undefined" && !isNaN(groupId)) {
      url = `${url}?groupId=${groupId}`;
    }
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, CheckDeleteUserGroupMessages))
      .catch(async response => await this.handleResponseJson(response, CheckDeleteUserGroupMessages));

    return result;
  }

  static async getUserGroupDetailsByName(groupName: string) {
    let url = AdminService.getBaseGroupUri() + "/getUserGroupDetailsByName";
    if (typeof groupName !== "undefined") {
      url = `${url}?groupName=${groupName}`;
    }
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(response => this.handleResponseJson(response, GetUserGroupDetailsMessages))
      .catch(response => this.handleResponseJson(response, GetUserGroupDetailsMessages));

    return result;
  }

  static async deleteOrRestoreUserGroup(reactivateUserGroups: UserGroup) {
    let url = AdminService.getBaseGroupUri() + "/deleteOrRestore";

    const result: ServiceResponseJson = await fetch(url, {
      body: this.toPostBody(reactivateUserGroups),
      ...this.fetchPostOptions()
    }).then(response => this.handleResponseJson(response, DeleteOrRestoreUserGroupMessages))
      .catch(response => this.handleResponseJson(response, DeleteOrRestoreUserGroupMessages));

    return result;
  }

  static async getUserGrups(gridParams: IGridParams) {
    let url = AdminService.getBaseGroupUri();
    let symbol = "?";

    if (typeof gridParams.skip !== "undefined" && typeof gridParams.take !== "undefined") {
      url = `${url}?skip=${gridParams.skip}&take=${gridParams.take}`;
      symbol = "&";
    }

    if (typeof gridParams.searchText !== "undefined" && gridParams.searchText !== "") {
      url = `${url}${symbol}searchText=${ServiceBase.encodeToBase64(gridParams.searchText)}`;
    }

    if (
      typeof gridParams.orderBy !== "undefined" &&
      gridParams.orderBy !== "" &&
      typeof gridParams.isAscending !== "undefined"
    ) {
      url = `${url}&orderBy=${gridParams.orderBy}&isAscending=${gridParams.isAscending}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserGroupsMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserGroupsMessages));

    return result;
  }

  static async getUserGroupList(params: AdvancedGridParams, domain: string = "") {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";
    const url = `${AdminService.getBaseGroupUri()}/getUserGroupsList?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(params, ["filters", "sort", "filter"])}&domain=${domain}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserGroupsMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserGroupsMessages));

    return result;
  }

  static async getUserGroupDetails(groupId: number) {
    let url = AdminService.getBaseGroupUri() + "/getUserGroupDetails";

    if (typeof groupId !== "undefined" && !isNaN(groupId)) {
      url = `${url}?groupId=${groupId}`;
    }
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserGroupDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserGroupDetailsMessages));

    return result;
  }

  static async getUserGroupDomainsDetails(groupId: number) {
    let url = AdminService.getBaseGroupUri() + "/getUserGroupDomains";

    if (typeof groupId !== "undefined" && !isNaN(groupId)) {
      url = `${url}?groupId=${groupId}`;
    }
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUserGroupDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetUserGroupDetailsMessages));

    return result;
  }

  static async updateUserGroup(userGroup: UpsertUserGroupModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseGroupUri(), {
      body: JSON.stringify(userGroup),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateUserGroupMessages))
      .catch(response => this.handleResponseJson(response, UpdateUserGroupMessages));

    return result;
  }

  static async getGroupUsers(params: IGroupUserRequestParams) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";

    const url = `${AdminService.getBaseGroupUri()}/getGroupUsers?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUsersMessages))
      .catch(async response => await this.handleResponseJson(response, GetUsersMessages));

    if (result.ok) {
      result.data.results = result.data.results.map((result: User): User => {
        return {
          ...result,
          lastLoginDate: result.lastLoginDate ? CommonHelper.convertUTCDateToLocalDate(result.lastLoginDate) : null,
          invitationsentDate: result.invitationsentDate ? CommonHelper.convertUTCDateToLocalDate(result.invitationsentDate) : null,
          createdDate: result.createdDate ? CommonHelper.convertUTCDateToLocalDate(result.createdDate) : null
        };
      });
    };

    return result;
  }

  static async isUserGroupNameAlreadyExist(userGroupName: string, userGroupId: number) {
    const url = `${AdminService.getBaseGroupUri()}/isUserGroupNameAlreadyExist?userGroupName=${userGroupName}&userGroupId=${userGroupId}`;

    const result: ServiceResponseStr = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseStr(response, CheckExistenceUserGroupName))
      .catch(async response => await this.handleResponseStr(response, CheckExistenceUserGroupName));

    return result;
  }

  static async getProjects(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseProjectUri()}?${AdminService.getFilterSortQueryString(filterJSON, sortJSON)}&${AdminService.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, AdminService.fetchOptions())
      .then(async response => await AdminService.handleResponseJson(response, GetProjectsMessages))
      .catch(async response => await AdminService.handleResponseJson(response, GetProjectsMessages));

    return result;
  }

  static async getProjectDetails(projectId: number) {
    let url = AdminService.getBaseProjectUri() + "/getProjectDetails";

    if (typeof projectId !== "undefined" && !isNaN(projectId)) {
      url = `${url}?projectId=${projectId}`;
    }

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetProjectDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetProjectDetailsMessages));

    return result;
  }

  static async getEmptyProject() {
    let url = AdminService.getBaseProjectUri() + "/getEmptyProject";

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetProjectDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetProjectDetailsMessages));

    return result;
  }

  static async getProjectClients(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseProjectUri()}/getProjectClients?${ServiceBase.getFilterSortQueryString(filterJSON, sortJSON)}&${ServiceBase.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetProjectClientMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetProjectClientMessages));

    return result;
  }

  static async getRoles(gridParams?: IGridParams) {
    let url = AdminService.getBaseRoleUri() + "/getRoles";
    let symbol = "?";

    if (typeof gridParams !== "undefined" && typeof gridParams != null) {
      if (typeof gridParams.skip !== "undefined" && typeof gridParams.take !== "undefined") {
        url = `${url}?pageNumber=${gridParams.skip}&pageSize=${gridParams.take}`;
        symbol = "&";
      }

      if (typeof gridParams.searchText !== "undefined" && gridParams.searchText !== "") {
        url = `${url}${symbol}searchText=${ServiceBase.encodeToBase64(gridParams.searchText)}`;
      }

      if (
        typeof gridParams.orderBy !== "undefined" &&
        gridParams.orderBy !== "" &&
        typeof gridParams.isAscending !== "undefined"
      ) {
        url = `${url}&orderBy=${gridParams.orderBy}&isAscending=${gridParams.isAscending}`;
      }
    }

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetRolesMessages))
      .catch(async response => await this.handleResponseJson(response, GetRolesMessages));

    return result;
  }

  static async getRoleList(gridParams?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseRoleUri()}/getRolesList?${ServiceBase.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${ServiceBase.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    ServiceBase.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetRolesMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetRolesMessages));

    return result;
  }


  static async getRoleDetails(roleId: number) {
    let url = AdminService.getBaseRoleUri() + "/roleDetails";

    if (typeof roleId !== "undefined" && !isNaN(roleId)) {
      url = `${url}?roleId=${roleId}`;
    }

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetRoleDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetRoleDetailsMessages));

    return result;
  }

  static async updateRole(role: UpsertRoleModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseRoleUri(), {
      body: JSON.stringify(role),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateRoleMessages))
      .catch(response => this.handleResponseJson(response, UpdateRoleMessages));

    return result;
  }

  static async getPermissionsByRoleId(roleId: number, gridParams?: IGridParams) {
    let url = AdminService.getBaseRoleUri() + "/getRolePermissions";
    let symbol = "?";

    if (roleId) {
      url = `${url}?roleId=${roleId}`;
      symbol = "&";
    }

    if (typeof gridParams !== "undefined" && typeof gridParams != null) {
      if (typeof gridParams.skip !== "undefined" && typeof gridParams.take !== "undefined") {
        url = `${url} ${symbol}pageNumber=${gridParams.skip}&pageSize=${gridParams.take}`;
      }

      if (typeof gridParams.searchText !== "undefined" && gridParams.searchText !== "") {
        url = `${url}${symbol}searchText=${encodeURIComponent(gridParams.searchText)}`;
      }

      if (
        typeof gridParams.orderBy !== "undefined" &&
        gridParams.orderBy !== "" &&
        typeof gridParams.isAscending !== "undefined"
      ) {
        url = `${url}&orderBy=${gridParams.orderBy}&isAscending=${gridParams.isAscending}`;
      }
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetRolesPermissionsMessages))
      .catch(async response => await this.handleResponseJson(response, GetRolesPermissionsMessages));

    return result;
  }

  static async getPermissionsListByRoleId(roleId: number, params?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";

    const url = `${AdminService.getBaseRoleUri()}/getRolePermissionsList?roleId=${roleId}&${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUsersMessages))
      .catch(async response => await this.handleResponseJson(response, GetUsersMessages));

    if (result.ok) {
      result.data.results = result.data.results.map((result: User): User => {
        return {
          ...result,
          lastLoginDate: result.lastLoginDate ? CommonHelper.convertUTCDateToLocalDate(result.lastLoginDate) : null,
        };
      });
    };

    return result;
  }

  static async getPermissions(gridParams?: IGridParamsEpiq) {
    let url = AdminService.getBaseRoleUri() + "/getPermissions";
    let symbol = "?";

    if (typeof gridParams !== "undefined" && typeof gridParams != null) {
      if (typeof gridParams.skip !== "undefined" && typeof gridParams.take !== "undefined") {
        url = `${url}?pageNumber=${gridParams.skip}&pageSize=${gridParams.take}`;
        symbol = "&";
      }

      if (typeof gridParams.searchText !== "undefined" && gridParams.searchText !== "") {
        url = `${url}${symbol}searchText=${encodeURIComponent(gridParams.searchText)}`;
      }

      if (
        typeof gridParams.orderBy !== "undefined" &&
        gridParams.orderBy !== "" &&
        typeof gridParams.isAscending !== "undefined"
      ) {
        url = `${url}&orderBy=${gridParams.orderBy}&isAscending=${gridParams.isAscending}`;
      }
      url = `${url}&epiqOnly=${!!gridParams.epiqOnly}`;
    }

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetPermissionMessages))
      .catch(async response => await this.handleResponseJson(response, GetPermissionMessages));

    return result;
  }

  static async isRoleNameAlreadyExist(roleName: string, excludeRoleId: number) {
    const url = `${AdminService.getBaseRoleUri()}/isRoleNameExists?roleName=${roleName}&excludeRoleId=${excludeRoleId}`;

    const result: ServiceResponseStr = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseStr(response, CheckExistenceRoleName))
      .catch(async response => await this.handleResponseStr(response, CheckExistenceRoleName));

    return result;
  }

  static async createRole(role: UpsertRoleModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseRoleUri(), {
      body: this.toPostBody(role),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateRoleMessages))
      .catch(response => this.handleResponseJson(response, CreateRoleMessages));

    return result;
  }

  static async canDeleteRole(roleId: number) {
    let url = AdminService.getBaseRoleUri() + "/canDeleteRole";

    if (typeof roleId !== "undefined" && !isNaN(roleId)) {
      url = `${url}?roleId=${roleId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, CheckDeleteRoleMessages))
      .catch(async response => await this.handleResponseJson(response, CheckDeleteRoleMessages));

    return result;
  }

  static async getRoleDetailsByRoleName(roleName: string) {
    const url = `${AdminService.getBaseRoleUri()}/isRoleExistsAndDeleted?roleName=${roleName}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetDelRoleDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetDelRoleDetailsMessages));

    return result;
  }

  static async deleteRole(role: Role) {
    let url = AdminService.getBaseRoleUri() + "/delete";

    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(role),
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, DeleteRoleMessages))
      .catch(response => this.handleResponse(response, DeleteRoleMessages));

    return result;
  }

  static async reactivateRole(role: Role) {
    let url = AdminService.getBaseRoleUri() + "/reactivate";

    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(role),
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, ReactivateRoleMessages))
      .catch(response => this.handleResponse(response, ReactivateRoleMessages));

    return result;
  }

  static async getApplicationsList(gridParams?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseApplicationsUri()}/getAppsList?${ServiceBase.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${ServiceBase.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    ServiceBase.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async getApplicationDetails(appId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/appDetails";

    if (typeof appId !== "undefined" && !isNaN(appId)) {
      url = `${url}?appId=${appId}`;
    }

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAppDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetAppDetailsMessages));

    return result;
  }

  static async getAllApplicationTypes() {
    let url = AdminService.getBaseApplicationsUri() + "/getAllAppTypes";
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAllApplicationTypesMessages))
      .catch(async response => await this.handleResponseJson(response, GetAllApplicationTypesMessages));

    return result;
  }

  static async getAllApplicationConnectors() {
    let url = AdminService.getBaseApplicationsUri() + "/getAllAppConnectors";
    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAllApplicationTypesMessages))
      .catch(async response => await this.handleResponseJson(response, GetAllApplicationTypesMessages));

    return result;
  }

  static async isAppNameAlreadyExist(appName: string, excludeAppId: number) {
    const url = `${AdminService.getBaseApplicationsUri()}/IsAppNameAlreadyExist?appName=${appName}&excludeAppId=${excludeAppId}`;

    const result: ServiceResponseStr = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseStr(response, CheckExistenceAppName))
      .catch(async response => await this.handleResponseStr(response, CheckExistenceAppName));

    return result;
  }

  static async isAppTypeAlreadyExist(appTypeName: string, excludeAppTypeId: number) {
    const url = `${AdminService.getBaseApplicationsUri()}/isAppTypeNameExist?appTypeName=${appTypeName}&excludeAppTypeId=${excludeAppTypeId}`;

    const result: ServiceResponseStr = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseStr(response, CheckExistenceAppTypeName))
      .catch(async response => await this.handleResponseStr(response, CheckExistenceAppTypeName));

    return result;
  }

  static async createApplication(app: UpsertUserAppModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseApplicationsUri(), {
      body: JSON.stringify(app),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateApplicationMessages))
      .catch(response => this.handleResponseJson(response, CreateApplicationMessages));

    return result;
  }

  static async updateApplication(app: UpsertUserAppModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseApplicationsUri(), {
      body: JSON.stringify(app),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateApplicationMessages))
      .catch(response => this.handleResponseJson(response, UpdateApplicationMessages));

    return result;
  }

  static async CreateAppType(applicationsType: ApplicationsType) {
    let url = AdminService.getBaseApplicationsUri() + "/createAppType";
    const result: ServiceResponseJson = await fetch(url, {
      body: JSON.stringify(applicationsType),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateApplicationTypesMessages))
      .catch(response => this.handleResponseJson(response, CreateApplicationTypesMessages));

    return result;
  }

  static async UpdateAppType(applicationsType: ApplicationsType) {
    let url = AdminService.getBaseApplicationsUri() + "/updateAppType";
    const result: ServiceResponseJson = await fetch(url, {
      body: JSON.stringify(applicationsType),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateApplicationTypesMessages))
      .catch(response => this.handleResponseJson(response, UpdateApplicationTypesMessages));

    return result;
  }

  static async DeleteApplications(applications: Application[]) {
    let url = AdminService.getBaseApplicationsUri();
    const result: ServiceResponseJson = await fetch(url, ...this.fetchDeleteOptions())
      .then(response => this.handleResponseJson(response, DeleteApplicationsMessages))
      .catch(response => this.handleResponseJson(response, DeleteApplicationsMessages));

    return result;
  }

  static async getAppUsers(params: IAppUserRequestParams) {
    const filterJSON = JSON.stringify(params.filters) || "";
    const sortJSON = JSON.stringify(params.sort) || "";

    const url = `${AdminService.getBaseApplicationsUri()}/getAppUsers?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUsersMessages))
      .catch(async response => await this.handleResponseJson(response, GetUsersMessages));

    if (result.ok) {
      result.data.results = result.data.results.map((result: User): User => {
        return {
          ...result,
          lastLoginDate: result.lastLoginDate ? CommonHelper.convertUTCDateToLocalDate(result.lastLoginDate) : null,
        };
      });
    };

    return result;
  }

  static async getAppUsersCount(appId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/getAppUsersCount";

    if (typeof appId !== "undefined" && !isNaN(appId)) {
      url = `${url}?appId=${appId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetUsersMessages))
      .catch(async response => await this.handleResponseJson(response, GetUsersMessages))
    return result;
  }


  static async deactivateApp(appDetails: Application) {
    const url = `${AdminService.getBaseApplicationsUri()}/deactivate`;
    const result: ServiceResponse = await fetch(url, { body: this.toPostBody(appDetails), ...this.fetchPostOptions() })
      .then(response => this.handleResponse(response, DeactivateAppMessages))
      .catch(response => this.handleResponse(response, DeactivateAppMessages));

    return result;
  }

  static async reactivateApp(appDetails: Application) {
    const url = `${AdminService.getBaseApplicationsUri()}/reactivate`;
    const result: ServiceResponse = await fetch(url, { body: this.toPostBody(appDetails), ...this.fetchPostOptions() })
      .then(response => this.handleResponse(response, ReactivateAppMessages))
      .catch(response => this.handleResponse(response, ReactivateAppMessages));

    return result;
  }

  static async canDeleteApp(appId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/canDeleteApp";

    if (typeof appId !== "undefined" && !isNaN(appId)) {
      url = `${url}?appId=${appId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, CheckDeleteAppMessages))
      .catch(async response => await this.handleResponseJson(response, CheckDeleteAppMessages))
    return result;
  }

  static async DeleteApp(app: Application) {
    let url = AdminService.getBaseApplicationsUri() + "/delete";

    const result: ServiceResponse = await fetch(url, { body: this.toPostBody(app), ...this.fetchPostOptions() })
      .then(async response => await this.handleResponse(response, DeleteApplicationsMessages))
      .catch(async response => await this.handleResponse(response, DeleteApplicationsMessages))

    return result;
  }

  static async GetAuditEventNames(searchText: string = "") {
    let url = `${AdminService.getBaseUri()}/getAuditEventNames?searchText=${searchText}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(response => this.handleResponseJson(response, GetUserLogActivitesMessages))
      .catch(response => this.handleResponseJson(response, GetUserLogActivitesMessages))

    return result;
  }

  static async getClients(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseClientsUri()}?${ServiceBase.getFilterSortQueryString(filterJSON, sortJSON)}&${ServiceBase.toQueryString(gridParams, ["filters", "sort", "filter"])}`;
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetClientMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetClientMessages));

    return result;
  }

  static async TrackAppInsights(data: string = "") {
    let url = `${AdminService.getBaseAppInsightsUri()}/track`;
    const result: ServiceResponseJson = await fetch(url, { body: this.toPostBody(data), ...this.fetchPostOptions() })
      .then(response => this.handleResponseJson(response, GetUserLogActivitesMessages))
      .catch(response => this.handleResponseJson(response, GetUserLogActivitesMessages))

    return result;
  }

  static async getEmployerList(gridParams: AdvancedGridParams, includeAllDomains?: boolean) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseEmployerUri()}?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}&includeAllDomains=${includeAllDomains || false}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }


  static async getEmployerDetails(employerId: number) {
    let url = AdminService.getBaseEmployerUri() + "/employerDetails";

    if (typeof employerId !== "undefined" && !isNaN(employerId)) {
      url = `${url}?employerId=${employerId}`;
    }

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetEmployerDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetEmployerDetailsMessages));

    return result;
  }

  static async createEmployer(emp: UpsertEmployerModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseEmployerUri(), {
      body: JSON.stringify(emp),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateEmployerMessages))
      .catch(response => this.handleResponseJson(response, CreateEmployerMessages));

    return result;
  }

  static async updateEmployer(emp: UpsertEmployerModel) {
    const result: ServiceResponseJson = await fetch(AdminService.getBaseEmployerUri(), {
      body: JSON.stringify(emp),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateEmployerMessages))
      .catch(response => this.handleResponseJson(response, UpdateEmployerMessages));

    return result;
  }

  static async getEmployerDomainListById(employerId: number, gridParams?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseEmployerUri()}/employerDomains?employerId=${employerId}&${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async createEmployerDomain(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseEmployerUri()}?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }

  static async isDomainAlreadyExist(domainName: string, excludeDomainId: number) {
    const url = `${AdminService.getBaseEmployerUri()}/domainExists?domainName=${domainName}&excludeDomainId=${excludeDomainId}`;

    const result: ServiceResponseStr = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseStr(response, CheckExistenceAppTypeName))
      .catch(async response => await this.handleResponseStr(response, CheckExistenceAppTypeName));

    return result;
  }

  static async getDomainsList(gridParams?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseEmployerUri()}/domainsList?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetDomainsMessages))
      .catch(async response => await this.handleResponseJson(response, GetDomainsMessages));

    return result;
  }

  static async getUserGroupDomainsList(gridParams?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseEmployerUri()}/GetUserGroupDomainsList?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetDomainsMessages))
      .catch(async response => await this.handleResponseJson(response, GetDomainsMessages));

    return result;
  }

  static async getContractClients(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseContractsUri()}?${ServiceBase.getFilterSortQueryString(filterJSON, sortJSON)}&${ServiceBase.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetContractClientMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetContractClientMessages));

    return result;
  }

  static async getSecureUploadLocationsList(abortSignal?: AbortSignal) {
    const url = `${AdminService.getBaseLocationsUri()}/getSecureUploadLocations`
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetSecureUploadLocationsMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetSecureUploadLocationsMessages));

    return result;
  }

  static async getSecureUploadLocation(id: number, abortSignal?: AbortSignal) {
    const url = `${AdminService.getBaseLocationsUri()}/getSecureUploadLocation?_id=${id}`
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetSecureUploadLocationsMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetSecureUploadLocationsMessages));

    return result;
  }

  static async createLocation(loc: SecureUploadLocation) {
    const url = `${AdminService.getBaseLocationsUri()}/postSecureUploadLocation`
    const result: ServiceResponseJson = await fetch(url, {
      body: JSON.stringify(loc),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateLocationMessages))
      .catch(response => this.handleResponseJson(response, CreateLocationMessages));

    return result;
  }

  static async updateLocation(loc: SecureUploadLocation) {
    const url = `${AdminService.getBaseLocationsUri()}/putSecureUploadLocation`
    const result: ServiceResponseJson = await fetch(url, {
      body: JSON.stringify(loc),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateLocationMessages))
      .catch(response => this.handleResponseJson(response, UpdateLocationMessages));

    return result;
  }

  static async getLocationEnvironmentListById(locId: number) {
    const url = `${AdminService.getBaseLocationsUri()}/getEnvironmentsForSecureUploadLocation?secureUploadLocationId=${locId}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetEnvironmentsMessages))
      .catch(async response => await this.handleResponseJson(response, GetEnvironmentsMessages));

    return result;
  }

  static async getEnvironments(abortSignal?: AbortSignal) {
    const url = `${AdminService.getBaseLocationsUri()}/getEnvironments`
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions(abortSignal))
      .then(async (response) => await this.handleResponseJson(response, GetSecureUploadLocationsMessages))
      .catch(async (response) => await this.handleResponseJson(response, GetSecureUploadLocationsMessages));

    return result;
  }

  static async getSecureUploadDetails(secureUploadObj: SecureUploadReqParams) {

    let url = "";
    if (secureUploadObj.appDbId) {
      url = `${AdminService.getBaseLocationsUri()}/getSecureUploadDetails?projectId=${secureUploadObj.projectId}&appDbId=${secureUploadObj.appDbId}`;
    } else {
      url = `${AdminService.getBaseLocationsUri()}/getSecureUploadDetailsByProject?projectId=${secureUploadObj.projectId}`;
    }

    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetSecureUploadLocationsMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetSecureUploadLocationsMessages));
    return result;
  }


  static async checkDeactivateUser(userId: number) {
    const url = `${window.ApplicationVariables.apiUri}Users/canDeactiveUser?userId=${userId}`;

    const result: ServiceResponseStr = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseStr(response, GetDeactivactionStatusMessages))
      .catch(async response => await ServiceBase.handleResponseStr(response, GetDeactivactionStatusMessages));

    return result;
  }

  static async getOktaAppsList(startsWith: string) {
    let url = `${AdminService.getBaseApplicationsUri()}/getOktaApps?startsWith=${startsWith}`;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetOktaApplicationsMessages))
      .catch(async response => await this.handleResponseJson(response, GetOktaApplicationsMessages))
    return result;
  }

  static async getOktaApplicationGroups(appId: string) {
    let url = AdminService.getBaseApplicationsUri() + "/getOktaApplicationGroups";

    if (appId) {
      url = `${url}?appId=${appId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetOktaApplicationGroupsMessages))
      .catch(async response => await this.handleResponseJson(response, GetOktaApplicationGroupsMessages))
    return result;
  }

  static async mapDomainToNewEmployer(moveDomainModel: MoveDomainModel) {
    const result: ServiceResponseStr = await fetch(AdminService.getBaseEmployerUri() + "/domainMap", {
      body: JSON.stringify(moveDomainModel),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseStr(response, mapDomainToNewEmployerMessages))
      .catch(response => this.handleResponseStr(response, mapDomainToNewEmployerMessages));

    return result;
  }

  static async getEnabledAppFeatures(appId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/getEnabledAppFeatures";

    if (typeof appId !== "undefined" && !isNaN(appId)) {
      url = `${url}?appId=${appId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAppDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetAppDetailsMessages));

    return result;
  }

  static async systemFeaturesForType(appTypeId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/systemFeaturesForType?appTypeId=" + appTypeId;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAppDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetAppDetailsMessages));

    return result;
  }

  static async systemFeaturesForApp(appId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/systemFeaturesForApp?appId=" + appId;

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAppDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetAppDetailsMessages));

    return result;
  }

  static async upsertSystemFeature(systemFeature: IAppTypeSystemFeature) {
    const url = `${AdminService.getBaseApplicationsUri()}/upsertSystemFeature`
    const result: ServiceResponse = await fetch(url, {
      body: JSON.stringify(systemFeature),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponse(response, SettingSystemFeatureMessages))
      .catch(response => this.handleResponse(response, SettingSystemFeatureMessages));

    return result;
  }

  static async removeSystemFeature(featureId: number, appTypeId: number) {
    const url = `${AdminService.getBaseApplicationsUri()}/removeSystemFeature?featureId=${featureId}&appTypeId=${appTypeId}`
    const result: ServiceResponse = await fetch(url, this.fetchDeleteOptions())
      .then(response => this.handleResponse(response, SettingSystemFeatureMessages))
      .catch(response => this.handleResponse(response, SettingSystemFeatureMessages));

    return result;
  }

  static async getAuthenticationProfiles(appId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/getAuthenticationProfiles";

    if (typeof appId !== "undefined" && !isNaN(appId)) {
      url = `${url}?appId=${appId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAppDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetAppDetailsMessages));

    return result;
  }

  static async getAppTypeFeatures(appTypeId: number) {
    let url = AdminService.getBaseApplicationsUri() + "/getAppTypeFeatures";

    if (typeof appTypeId !== "undefined" && !isNaN(appTypeId)) {
      url = `${url}?appTypeId=${appTypeId}`;
    }

    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetAppDetailsMessages))
      .catch(async response => await this.handleResponseJson(response, GetAppDetailsMessages));

    return result;
  }

  static async getRelInstances(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseRelativityUri()}/RelInstances?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetRelInstancesMessages))
      .catch(async response => await this.handleResponseJson(response, GetRelInstancesMessages));

    return result;
  }

  static async getClientsByInstance(uniqueInstanceId: string, clientFilter?: string) {
    let url = `${AdminService.getBaseRelativityUri()}/GetClientsByInstance?uniqueInstanceId=${uniqueInstanceId}&clientFilter=${clientFilter || ""}`;
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetClientsByInstanceMessages))
      .catch(async response => await this.handleResponseJson(response, GetClientsByInstanceMessages));

    return result;
  }

  static async getClientsResourceByInstance(uniqueInstanceId: string, gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseRelativityUri()}/GetClientsResourceByInstance?uniqueInstanceId=${uniqueInstanceId}&${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetRelInstancesMessages))
      .catch(async response => await this.handleResponseJson(response, GetRelInstancesMessages));

    return result;
  }

  static async addRelativityClientToResouce(relClients: IRelClient) {
    let url = `${AdminService.getBaseRelativityUri()}/AddClientsToResource?relClients=${relClients}`;
    const result: ServiceResponseJson = await fetch(url, {
      body: JSON.stringify(relClients),
      ...this.fetchPostOptions()
    })
      .then(response => this.handleResponseJson(response, CreateLocationMessages))
      .catch(response => this.handleResponseJson(response, CreateLocationMessages));

    return result;
  }

  static async getAppResourcesList(gridParams?: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${AdminService.getBaseApplicationsUri()}/getAppResourceList?${ServiceBase.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${ServiceBase.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    ServiceBase.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions())
      .then(async response => await ServiceBase.handleResponseJson(response, GetApplicationsMessages))
      .catch(async response => await ServiceBase.handleResponseJson(response, GetApplicationsMessages));

    return result;
  }



  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Users`;
  }

  static getBaseGroupUri() {
    return `${(<any>window).ApplicationVariables.apiUri}UserGroups`;
  }

  static getBaseProjectUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Projects`;
  }

  static getBaseRoleUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Roles`;
  }

  static getBaseApplicationsUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Applications`;
  }

  static getBaseClientsUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Clients`;
  }
  static getBaseAppInsightsUri() {
    return `${(<any>window).ApplicationVariables.apiUri}AppInsights`;
  }
  static getBaseEmployerUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Employers`;
  }
  static getBaseAuditsUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Audits`;
  }
  static getBaseContractsUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Contracts`;
  }
  static getBaseLocationsUri() {
    return `${(<any>window).ApplicationVariables.apiUri}SecureUpload`;
  }
  static getBaseRelativityUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Relativity`;
  }
}
