import React, { useState, useRef, useEffect } from "react";
import { AdvancedGrid } from "../common/grid/AdvancedGrid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { GridCellProps, GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import JobsService from "../../services/JobService";
import { IApprovableJobStatus, IJobParams, VoteActions, VoteStatus } from "../../models/JobModels";
import Refresh from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formatDate } from "@telerik/kendo-intl";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import CommonHelper from "../common/utilities/CommonHelper";
import { NavLink } from "react-router-dom";
import { IApplicationState } from "../../store";
import * as SessionStore from "../../store/Session";
import JobDetailsPopup from "./JobDetailsPopup";
import "./JobList.scss";
import GridSelector from "../common/grid/GridSelector";
import { AppInsightsHelper } from '../../AppInsights';
import ResourceService from "../../services/ResourceService";
import ApprovalService from "../../services/ApprovalService";
import { IApprovableStatus, IApproval, IApprovalForUser, approvalResponseTypes, approvalStatus, approvalTypes } from "../../models/approvalModels";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import ApprovalDetails from "../approval/ApprovalDetail";
import { DropDownFilter } from "../common/grid/filters/DropDownFilter";
import GridRefreshButton from "../common/GridRefreshButton";
import NotificationService from "../../services/NotificationService";

interface IProps {
  showAllJobs?: boolean;
  match?: any;
}

interface IVoteInfo {
  voteStatus: VoteStatus;
  discoveredVote: VoteActions;
}

interface IRefreshGridParams extends IJobParams {
  refreshToggle: boolean;
}

// deprecated
interface IVoteToSend {
  approved: boolean;
  jobId: string;
}

interface IApprovalToSend {
  approvalId: number;
  responseId: approvalResponseTypes;
}


type Props = IProps &
  SessionStore.ISessionState;

type approvalJobTypes = "needsapproval" | "rejected" | "approved" | "expired" | "failed" | "allpendingapprovals";
type continuousCheckingStates = "none" | "continuous" | "pause";

interface jobTypeOptions {
  id: approvalJobTypes;
  text: string;
}

const needsapproval = [approvalStatus.pending];
const rejected = [approvalStatus.rejected];
const approved = [approvalStatus.approved];
const expired = [approvalStatus.expired];
const failed = [-1];

const statusesFromJobType = function (jobType: approvalJobTypes) {

  switch (jobType) {
    case "needsapproval":
    case "allpendingapprovals":
      return needsapproval;
    case "rejected":
      return rejected;
    case "approved":
      return approved;
    case "expired":
      return expired;
    case "failed":
      return failed;
    default:
      throw "this job type isn't implemented";
  }
}

const options = [{ id: "needsapproval", text: "Needs Approval" },
{ id: "rejected", text: "Rejected" },
{ id: "approved", text: "Approved" },
{ id: "expired", text: "Expired" },
{ id: "failed", text: "Failed" }] as Array<jobTypeOptions>;

// TODO IApprovableJob is deprecated
let myGrid: AdvancedGrid<IApprovalForUser, IRefreshGridParams>;

const initialState: IRefreshGridParams = {
  skip: 0,
  take: 100,
  sort: [{ field: "Approval.createdDate", dir: "desc" }],
  filters: [],
  refreshToggle: false,
  permissionsFilter: ["ListJobs", "ApproveJob"],
  statusIds: statusesFromJobType("needsapproval"),
  canVote: false
};

const JobList = (props: Props) => {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshingForNewStatus, setRefreshingForNewStatus] = useState(false);
  const [dataState, setDataState] = useState({ ...initialState, canVote: props.sessionData.permissions.has("ApproveJob") });
  const [selectedApproval, setSelectedApproval] = useState<IApprovalForUser>(null);
  const [openDetails, setOpenDetails] = useState(false); // TODO I could bypass this usestate and use selectedJob != null determine whether details is open, but then there might be need for a network call.
  const [approvalWithResponses, setApprovalWithResponses] = useState({ votes: {} as Record<number, VoteActions>, approvals: [] as Array<IApprovableStatus>, jobType: "needsapproval" as approvalJobTypes });
  const [restartContinue, setRestartContinue] = useState(0);
  const [latestApproval, setLatestApproval] = useState<IApprovalToSend | null>(null);
  const readyInterval = useRef(null);
  const abortControlRef = useRef<AbortController | null>(null);
  const continuousChecking = useRef<continuousCheckingStates>("continuous");
  const [isOpenDetailsView, setIsOpenDetailsView] = useState(!!(props.match && props.match.params && props.match.params.id));
  const [dialogTitleText, setDialogTitleText] = useState("Needs Approval");
  const [showApproveBtn, setShowApproveBtn] = useState(false);
  const [showRejectBtn, setShowRejectBtn] = useState(false);
  const [loadingPopup, setloadingPopup] = useState(false);
  const [showAllApprovals, setShowAllApprovals] = useState(false);
  const [selApprovalType, setSelApprovalType] = useState(options[0]);

  useEffect(() => {
      if (props.sessionData.permissions.has("EpiqAdminListApprovals")) {
        const checkId = (obj: { id: string; }) => obj.id === "allpendingapprovals";
        if (!options.some(checkId)){        
            options.push({ id: "allpendingapprovals", text: "All Open Approvals" });
        }
      }

    return () => {
      stopChecking();
    }
  }, [])

  useEffect(() => {
    if (continuousChecking.current === "continuous" && readyInterval.current == null && !loading) {
      setShowApproveBtn(true);
      setShowRejectBtn(true);
      //readyInterval.current = setInterval(async function () {

      //  await checkJobs(dataState);
      //}, 2000);
    }

    if (refreshingForNewStatus) {
      abortControlRef.current = null;
      checkJobs(dataState);
    }

  }, [approvalWithResponses, restartContinue]);

  useEffect(() => {
    if (latestApproval === null) return;

    const requestData = {
      approvalId: latestApproval.approvalId,
      responseId: latestApproval.responseId
    };

    ApprovalService.setApproval(requestData).then(p => {
      if(!p.ok) {
        const title = approvalWithResponses.approvals.find(x=> x.id === latestApproval.approvalId).title;

        overrideVoteStatus(latestApproval.approvalId, "error");
        NotificationService.showErrorToast(`Error: ${p.message} - ${title}`);

        return;
      }

      if (approvalWithResponses.jobType === "needsapproval" && continuousChecking.current === "pause") {
        //continuousChecking.current = "continuous";
        setRestartContinue(restartContinue + 1);
        let needsApprovals = approvalWithResponses.approvals.filter(x => x.voteStatus !== "sending" && x.voteStatus !== "approved" && x.voteStatus !== "rejected");

        if(selectedApproval)
        {
          needsApprovals = needsApprovals.filter(x => x.createdDate < selectedApproval.createdDate);
        }

        if (needsApprovals.length > 0) {
          setSelectedApproval(needsApprovals[0]);
          setloadingPopup(false);
        }
        else {
          setloadingPopup(false);
          setIsOpenDetailsView(false);
        }
      }
    });
}, [latestApproval]);

  const getJobs = async (dataState: IRefreshGridParams) => {
    if(abortControlRef.current !== null) return;
    setLoading(true);
    await checkJobs(dataState);
  };
    
  const getApprovalTypeFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    field: string = "Approval.approvalType"
  ) => {
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const approvalTypeFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === field
        ) as FilterDescriptor;

        if (approvalTypeFilter) {
          const approvalTypeFilterValue = approvalTypeFilter.value.id;

          return {
            ...compositeFilter,
            filters: [
              {
                ...approvalTypeFilter,
                field: field,
                operator: "eq",
                value: approvalTypeFilterValue
              }
            ]
          };
        }

        return compositeFilter;
      }) : [];

    return filters;
  };

  const getModifiedDateFilter = (
    gridFilters: Array<CompositeFilterDescriptor>,
    dateColumnName: string = "Approval.createdDate"
  ) => {
    // This function is used for date column filter in all advance grid pages.
    let isDateFilterExists = false;
    let dateValue: Date;
    const filters = gridFilters
      ? gridFilters.map(compositeFilter => {
        const dateFilter: FilterDescriptor = compositeFilter.filters.find(
          filter => (filter as FilterDescriptor).field === dateColumnName
        ) as FilterDescriptor;

        if (dateFilter) {
          const dateFilterValue = dateFilter.value;

          if (dateFilterValue === "")
            return compositeFilter;

          if (!isNaN(new Date(dateFilterValue).getFullYear()) && new Date(dateFilterValue).getFullYear() > 1970) {
            dateFilter.operator = 'eq';
          }

          dateValue = typeof dateFilterValue === "string" ? new Date("01/01/1901") : ((dateFilter.operator === 'isnull' || dateFilter.operator === 'isnotnull') ? null : new Date(dateFilterValue));

          if (dateFilter.operator === 'isnull' || dateFilter.operator === 'isnotnull') {
            return {
              ...compositeFilter,
              filters: [
                {
                  ...dateFilter,
                  field: dateColumnName,
                  operator: dateFilter.operator,
                  value: dateValue
                }
              ]
            };
          }

          isDateFilterExists = true;

          return {
            ...compositeFilter,
            filters: [
              {
                ...dateFilter,
                field: dateColumnName,
                operator: "gte",
                value: new Date(dateValue.toUTCString())
              }
            ]
          };
        }

        return compositeFilter;
      })
      : [];

    if (isDateFilterExists) {
      filters.push({
        logic: "and",
        filters: [
          {
            field: dateColumnName,
            operator: "lt",
            value: new Date(new Date(dateValue.setUTCDate(dateValue.getUTCDate() + 1)).toUTCString())
          }
        ]
      });
    }

    return filters;
  };

  const checkJobs = async (dataState: IRefreshGridParams, skipError = false) => {
    //if the auto refresh triggers and the last call is still in flight, we should not initiate a new call.
    //if the user manually triggers a refresh via sort on grid, the previous call is aborted so this will work fine.
    //if we decide to add a manual refresh, it should make a call to stopChecking() before it does call to checkJobs.
    if(abortControlRef.current !== null) return;

    const approvalfilters = new Array<CompositeFilterDescriptor>();
    let filters = getApprovalTypeFilter(dataState.filters);
    filters = getModifiedDateFilter (filters,"Approval.createdDate");
    filters.forEach(compositeFilter => {
          let modifiedCompositeFilter: CompositeFilterDescriptor = compositeFilter; 
      
          const descriptionFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "Approval.title"
          ) as FilterDescriptor;

          if (descriptionFilter) {
            if (descriptionFilter.value === "") {
              return;
            }
          }

          const createdByFilter: FilterDescriptor = compositeFilter.filters.find(
            filter => (filter as FilterDescriptor).field === "User.username"
          ) as FilterDescriptor;

          if (createdByFilter) {
            if (createdByFilter.value === "") {
              return;
            }
          }          

          approvalfilters.push(modifiedCompositeFilter);
        });

    const approvalsReq = dataState.canVote ? { ...dataState, filters:approvalfilters, jobsToTrack: Object.keys(approvalWithResponses.votes) } : {...dataState, filters:approvalfilters};
    abortControlRef.current = new AbortController();

    const approvalTask = showAllApprovals ? ApprovalService.getApprovals(approvalsReq, abortControlRef.current.signal) : ApprovalService.getMyApprovals(approvalsReq, abortControlRef.current.signal);
    const approvalResponse = await approvalTask;

    setRefreshingForNewStatus(false);

    if (approvalResponse.ok) {
      setLoading(false);
      updateApprovalStatus(approvalResponse.data);

      if (approvalWithResponses.jobType === "needsapproval" && continuousChecking.current === "pause") {
        continuousChecking.current = "continuous";
        setRestartContinue(restartContinue + 1);
      }
    } else if (approvalResponse.aborted) {
      console.log("call aborted");
    } else if (!skipError) {
      setHasError(true);
      stopChecking();
      setApprovalWithResponses({ votes: {}, approvals: [], jobType: approvalWithResponses.jobType });
    }
    
    abortControlRef.current = null;
  }

  const getUserApprovalStatus = function (approval: IApprovalForUser) {
    let voteStatus: VoteStatus = "error";
    let discoveredVote: VoteActions = null;

    if (approval.status === approvalStatus.approved) {
      voteStatus = "approved";
    } else if (approval.status === approvalStatus.rejected) {
      voteStatus = "rejected"
    } else if (approval.status === approvalStatus.pending) {
      if ((approvalWithResponses.votes[approval.id] && approvalWithResponses.votes[approval.id] === "sending") || !(approval.id in approvalWithResponses.votes)) {
        const parsed = parseApprovalVote(approval);
        if (parsed === "ready" && approvalWithResponses.votes[approval.id] === "sending") {
          voteStatus = "sending"; // API doesn't know that we're still waiting
        } else {
          voteStatus = parsed;
        }

        discoveredVote = voteStatus; // return what's parsed so we don't have to parse again
      } else {
        voteStatus = approvalWithResponses.votes[approval.id];
      }

      if (voteStatus == null) {
        voteStatus = "ready";
      }
    }
    // else default: failed

    return { voteStatus, discoveredVote };
  }

  const updateApprovalStatus = function (approvals: Array<IApprovalForUser>) {

    const updatedVotes = { ...approvalWithResponses.votes };
    let voteChanges = false;

    const jobsWithStatus = [] as Array<IApprovableStatus>;
    approvals && approvals.forEach(a => {
      const status = getUserApprovalStatus(a);
      if (status.discoveredVote != null) {
        updatedVotes[a.id] = status.discoveredVote;
        voteChanges = true;
      }

      jobsWithStatus.push({ ...a, voteStatus: status.voteStatus });
    });

    setApprovalWithResponses({ votes: updatedVotes, approvals: jobsWithStatus, jobType: approvalWithResponses.jobType });
  }

  const parseApprovalVote = function (approval: IApprovalForUser): VoteActions {

    if (!approval) return "error";

    if (approval.userApprovalResponseId === approvalResponseTypes.approved) {
      return "you: approved";
    }
    else if (approval.userApprovalResponseId === approvalResponseTypes.rejected) {
      return "you: rejected";
    }

    return "ready";
  }

  const overrideVoteStatus = function(approvalId: number, status: VoteActions) {
    const approvals = approvalWithResponses.approvals.map(a => {
      if (a.id === approvalId) {
        return { ...a, voteStatus: status };
      }

      return a;
    }) as Array<IApprovableStatus>;

    setApprovalWithResponses({ votes: { ...approvalWithResponses.votes, [approvalId]: status }, approvals, jobType: approvalWithResponses.jobType });
  }

  const approve = async function (approved: boolean, approvalId: number) {
    stopChecking(true);
    overrideVoteStatus(approvalId, "sending");
    setLatestApproval({ approvalId, responseId: approved ? approvalResponseTypes.approved : approvalResponseTypes.rejected });
  }

  const approvalCell = (gprops: GridCellProps) => {
    const dataItem: (IApprovableStatus) = gprops.dataItem;

    if (dataItem.voteStatus !== "ready") {
      return <td>{dataItem.voteStatus}</td>;
    }

    const canApprove = props.sessionData.username !== dataItem.createdByUser.username || dataItem.approvalType !== approvalTypes.newUsers

    return canApprove && <td>
      <div className="approve-cell">
        <Button icon="check" style={{ color: "green" }} onClick={(z) => {
          approve(true, (dataItem as IApprovableStatus).id);
        }}></Button>
        <Button icon="cancel" style={{ color: "red" }} onClick={(z) => {
          approve(false, (dataItem as IApprovableStatus).id);
        }}></Button>
      </div>
    </td>
  };

  const approvalTypeCell = (props: GridCellProps) => {

    const { dataItem: {
        approvalType
      }
    } = props;

    const typeName = approvalType === 1 ? 'New Resource for Group' :
      approvalType === 2 ? 'New Users' :
      ''; 

    return (
      <td>
        <div className="created-date">
          <div className="created-time">{typeName}</div>
        </div>
      </td>
    );
  };

  const createdDateCell = (props: GridCellProps) => {

    let date = "-";
    if (props.dataItem.createdDate) {
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(props.dataItem.createdDate);
      date = formatDate(localDateTime, "g");
    }

    return (
      <td>
        <div className="created-date">
          <div className="created-time">{date}</div>
        </div>
      </td>
    );
  };

  const toggleActionButtons = (dataItem: IApprovalForUser) => {
    const isClosed = dataItem.status !== approvalStatus.pending;

    if (isClosed) {
      setShowApproveBtn(false);
      setShowRejectBtn(false);
    }
    else {
      setShowApproveBtn(true);
      setShowRejectBtn(true);
    }
  }

  const descriptionCell = (cellProps: GridCellProps) => {
    const description = cellProps.dataItem.title || cellProps.dataItem.description;
    const canViewDetails = cellProps.dataItem.approverIsListed || props.sessionData.permissions.has("EpiqAdminGetApprovals");
    const cssClass = canViewDetails ? "job-description" : "job-description job-description-readonly";

    return (
      <td>
        <div className={cssClass}>
          {!canViewDetails && 
            <p>{description}</p>
          }
          {canViewDetails && 
          <a
            className="k-link"
            onClick={e => {
              setSelectedApproval(cellProps.dataItem);
              stopChecking(true);              
              setIsOpenDetailsView(true);
              toggleActionButtons(cellProps.dataItem);
            }}
          >
            <p>{description}</p>
          </a>
          }
        </div>
      </td>
    );
  };

  const usersBackground = (props: any) => {

    const isSimpleApproval = !!props.dataItem.title;

    const fullName = (props.dataItem.creatorLastName || props.dataItem.createdByUser.lastName)+ ", " + 
      (props.dataItem.creatorFirstName || props.dataItem.createdByUser.firstName);
    const email = props.dataItem.creatorUsername || props.dataItem.createdByUser.username;

    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <NavLink to={`/administration/userDetails/${props.dataItem.createdBy || props.dataItem.createdByUser.id}`}>
              <em title={fullName}>{fullName}</em>
            </NavLink>
          </div>
          <p>
            {" "}
            <em title={email}>{email}</em>{" "}
          </p>
        </div>
      </td>
    );
  }

  const usersBackgroundNoLink = (props: any) => {

    const fullName = (props.dataItem.creatorLastName || props.dataItem.createdByUser.lastName)+ ", " + 
      (props.dataItem.creatorFirstName || props.dataItem.createdByUser.firstName);
    const email = props.dataItem.creatorUsername || props.dataItem.createdByUser.username;
    return (
      <td>
        <div className="user-details">
          <div className="user-name">
            <em title={fullName}>{fullName}</em>
          </div>
          <p>
            {" "}
            <em title={email}>{email}</em>{" "}
          </p>
        </div>
      </td>
    );
  }

  const columns = new Array<GridColumnProps>(
    { field: "Approval.createdDate", title: "DATE CREATED", cell: createdDateCell, filterable: true, filter: "date", sortable: true, width: "60px" },
    { field: "Approval.approvalType", title: "REQUEST TYPE",  cell: approvalTypeCell, filterable: true, filter: "text", sortable: true, width: "250px",
      filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={[{ name: "New Resource for Group", id: approvalTypes.newResource }, { name: "New Users", id: approvalTypes.newUsers }]}
            textField="name"
            defaultSelectedOption={null}
          />
        )},
    { field: "Approval.title", title: "DESCRIPTION", cell: descriptionCell, filterable: true, filter: "text", sortable: false, headerClassName: "no-sort", width: "350px" }, // TODO probably could sort this if we indexed it?
    {
      field: "User.username",
      title: "CREATED BY",
      filterable: true,
      filter: "text",
      sortable: false,
      headerClassName: "no-sort",
      cell: props.sessionData.permissions.has("EpiqAdminGetUser") ? usersBackground : usersBackgroundNoLink
    },
    { field: "approve", title: "APPROVE", cell: approvalCell, sortable: false, headerClassName: "no-sort", width: "60px" }
  );

  const reloadData = () => {
    const newState: IRefreshGridParams = { ...dataState, refreshToggle: !dataState.refreshToggle };

    myGrid.resetGridState(newState);
    setDataState(newState);
  };

  const gridToolBarRender = () => {
    return (
      <>
        <Button className="approval-btns btn-secondary" onClick={() => reloadData()}>
          <Refresh />
        </Button>
      </>
    );
  };

  const stopChecking = function (asPause = false) {
    clearTimeout(readyInterval.current);
    readyInterval.current = null;

    if (abortControlRef.current) {
      abortControlRef.current.abort();
    }

    continuousChecking.current = asPause ? "pause" : "none";
  }

  const beforeDataStateChange = function (ds: IRefreshGridParams) {
    setDataState({ ...dataState, filters: ds.filters, sort: ds.sort });
    stopChecking(true);
  }

  const handleOnRequestSourceChanged = (event: DropDownListChangeEvent) => {
    stopChecking();
    setRefreshingForNewStatus(true);

    var updated = { ...dataState, filters: null as Array<CompositeFilterDescriptor>,   statusIds: statusesFromJobType(event.value.id), canVote: event.value.id === "needsapproval" && props.sessionData.permissions.has("ApproveJob") };

    if (event.value.id === "needsapproval" ) {
      continuousChecking.current = "continuous";
      setShowApproveBtn(true);
      setShowRejectBtn(true);
    } else {
      setShowApproveBtn(false);
      setShowRejectBtn(false);
    }

    setShowAllApprovals(event.value.id === "allpendingapprovals");
    setApprovalWithResponses({ votes: approvalWithResponses.votes, approvals: approvalWithResponses.approvals, jobType: event.value.id });

    myGrid.resetGridState(updated);
    setDataState(updated);
    setDialogTitleText(event.value.text);
    setSelApprovalType(event.value);
  };

  const handleJobDetailsPopupClose = (accepted: boolean) => {
    if (!accepted) {
      setIsOpenDetailsView(false);
    }
  };

  const jobApprove = () => {
    setloadingPopup(true);

    approve(true, selectedApproval.id);
  };
  const jobReject = () => {
    setloadingPopup(true);
    approve(false, selectedApproval.id);
  };

  const fetchResource = async (resourceId: number) => {
    var result = await ResourceService.getResource(resourceId);

    if (result.ok) {
      return result.data;
    } else {
      console.log("Something went wrong while fetching resource details.");
    }

    return null;
  };

  const onResponse = (approve?: boolean, comment?: string) => {
    if (approve) {
      jobApprove();
    }
    else if (approve === false) {
      jobReject();
    }

    setSelectedApproval(null);
    setIsOpenDetailsView(false);
  }

  if (isOpenDetailsView && (selectedApproval !== null) && (selectedApproval || (props.match && props.match.params && props.match.params.id))) {
    return <ApprovalDetails
      approvalId={selectedApproval ? selectedApproval.id : props.match.params.id}
      canApprove={(props.sessionData.username !== selectedApproval.createdByUser.username ||
        selectedApproval.approvalType !== approvalTypes.newUsers) && (selectedApproval.status === 1) &&
        (props.sessionData.permissions.has("EpiqAdminUpdateApprovals") ||
          props.match.params.id ||
          selectedApproval.approverIsListed)}
      onFinish={onResponse} />
  }

  return (
    <div className="joblist-approvals">
      <Grid container>
        <Grid item md={12}>
          <Tooltip openDelay={2} position="right">
            <AdvancedGrid
              ref={(standardGridInstance: AdvancedGrid<IApprovalForUser, IRefreshGridParams>) => {
                myGrid = standardGridInstance;
              }}
              showErrorState={hasError}
              showLoadingIndicator={loading || refreshingForNewStatus}
              data={approvalWithResponses.approvals}
              dataFetch={getJobs}
              dataState={dataState}
              columns={dataState.canVote ? columns : columns.slice(0, -1)}
              paging={false}
              gridToolbarContent={gridToolBarRender()}
              noRecordsRender={<p>No Approvals</p>}
              noLoadOnMount={false}
              filteredDataOnly={<p>Please filter to search for Approvals.</p>}
              //beforeDataStateChange={beforeDataStateChange}
              totalRecords={{
                value: approvalWithResponses.approvals ? approvalWithResponses.approvals.length : 0,
                label:
                  <DropDownList
                    data={options}
                    textField={"text"}
                    defaultValue={options[0]}
                    onChange={handleOnRequestSourceChanged}
                    value={selApprovalType}
                  />
              }}
              filterOperators={{
                text: [{ text: "grid.filterContainsOperator", operator: "contains" }],
                date: [{ text: "grid.filterIsNotNullOperator", operator: "isnotnull" }, { text: "grid.filterIsNullOperator", operator: "isnull" }]
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <GridSelector
        fullWidth={true}
        isOpen={!!(isOpenDetailsView && selectedApproval)}
        acceptBtnText={showApproveBtn ? "Approve" : ""}
        rejectBtnText={showRejectBtn ? "Reject" : ""}
        titleText={dialogTitleText}
        addAvailable={true}
        onClose={handleJobDetailsPopupClose}
        onApply={jobApprove}
        onReject={jobReject}
        addClass="approval-job-details-popup details-view-modal modal-as-sidebar">

        <JobDetailsPopup
          approval={selectedApproval}
          loadingPopup={loadingPopup}
          onClose={() => setIsOpenDetailsView(false)}
        ></JobDetailsPopup>

      </GridSelector>
    </div>
  );
};

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState,
  })
)(JobList as any);
