import { IFieldData, IFieldValueLookup, IWizardValues } from "../../../models/Wizard";

export default class WizardHelper {

  static defaultLookupRegex = /\[`fieldLookup:(.*?)``lookupName:([^`]*?)`(?:`defaultStr:([^`]*)`)?]/g;

  public static getLabel = function (label: string | ((existingFieldVals: IFieldValueLookup) => string), existingFieldVals: IFieldValueLookup, defaultLabel: string = "", lookupRegex: RegExp = WizardHelper.defaultLookupRegex) {

    if (typeof label === 'function') {
      return label(existingFieldVals);
    }

    if (!label || label.length === 0) return defaultLabel;
    return label.replace(lookupRegex, function(match, fieldIds, lookupName, defaultStr) {
      let valInfo = null as IWizardValues;
      const allIds = fieldIds.split(",");

      for (let i = allIds.length - 1; i > -1; i--) {
        valInfo = existingFieldVals[allIds[i]];
        if (valInfo && valInfo.active) {
          break;
        }
      }
      if (!valInfo || !valInfo.lookupValues) {
        return defaultStr || defaultLabel;
      }
      if (!valInfo.lookupValues && defaultStr) return defaultStr;

      const val = valInfo.lookupValues[lookupName];

      return val == null ? defaultStr || defaultLabel : val.toString();
    });
  };

  public static isFieldActive = function(preconfiguredFieldData: IFieldData, valDictionary: IFieldValueLookup) {
    const valueInfo = valDictionary[preconfiguredFieldData.fieldId];

    if (valueInfo) return valueInfo.active; // this is a dynamicly changed value as opposed to statically predfined values

    return preconfiguredFieldData.active;
  };
}