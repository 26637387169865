import React, { useState, useRef, useEffect } from "react";
import ApprovalService from "../../services/ApprovalService";
import NotificationService from "../../services/NotificationService";
import { IApproval, IApprovalForUserDetails } from "../../models/approvalModels";
import * as SessionStore from "../../store/Session";
import CircularProgress from "@material-ui/core/CircularProgress";
import { kAddRelUserDescPrefix } from "../../models/ServicenowSupportRequestSettings";
import AddUsersApproval from "./AddUsersApproval";
import * as HeaderStore from "../../store/Header";
import * as MainDrawerStore from "../../store/MainDrawer";
import { IApplicationState } from "../../store";
import { connect } from "react-redux";
import { ISubHeaderbtns } from "../edge/header/EpiqSubHeaderComponent";
import JobDetailsPopup from "../job/JobDetailsPopup";

export interface IApprovalDetailsProps {
  onClose: () => void;
}

interface IProps {
  approvalId: number;
  canApprove: boolean;
  onFinish: (approve?: boolean, comment?: string) => void;
}

type Props = IProps 
  & IApprovalDetailsProps 
  & HeaderStore.IHeaderState 
  & MainDrawerStore.IMainDrawerState 
  & SessionStore.ISessionState
  & typeof HeaderStore.actionCreators;

export type ApprovalDetailsComponent = { approvalDetails: IApprovalForUserDetails }; 

const ApprovalDetails = (props: Props) => {
  
  const [error, setError] = useState(false);
  const [approval, setApproval] = useState<IApprovalForUserDetails>(null);

  useEffect(() => {

    if (!props.approvalId) return;

    const getApproval = async function () {
      const result = await ApprovalService.approvalDetails(props.approvalId);
      
      if (!result.ok || !result.data || !result.data.payloadUnpacked) {
        setError(true);
        NotificationService.showErrorToast("error getting approval information");
        return;
      }

      props.setHeaderButtons(getBtnsList(!props.canApprove, false, result.data), "", "", " ");
      setApproval(result.data);
    }

    getApproval();
    
  }, [props.approvalId]);

  useEffect(() => {
    return () => {
      props.setHeaderButtons([], "", "", " ");
    }
  }, []);

  const onClose = (approve?: boolean, comment?: string) => {
    props.setHeaderButtons([], "", "", " ");
    props.onFinish(approve, comment);
  }

  const getBtnsList = (disabled: boolean, isSaving: boolean = false, approval: IApprovalForUserDetails = null): ISubHeaderbtns[] => {
    const buttons: any = [
      {
        buttonLabel: "Reject",
        type: "button",
        className: "disapprove-button",
        handleClick: () => onClose(false, null),
        disabled,
        isSaving
      },
      {
        buttonLabel: "Approve",
        type: "button",
        className: "approve-button",
        handleClick: () => onClose(true, null),
        tooltip: approval != null && approval.createdByUser != null && approval.createdByUser.username == props.sessionData.username ? "Approval not available for tickets you submit." : null,
        disabled,
        isSaving
      }
    ];

    return buttons;
  }

  if (!approval && !error) {
    return <CircularProgress size={20} />;
  }
  else if (error || !approval.payloadUnpacked || !approval.payloadUnpacked.payload) {
    return <>We couldn't find this approval or you don't have access</>
  }

  if (approval.payloadUnpacked.payload.Fields && approval.payloadUnpacked.payload.Fields.ImportUsers) {
      return <AddUsersApproval approvalDetails={approval} onClose={() => onClose()} mainDrawerExpanded={props.expanded} />
  }
  else if (approval.payloadUnpacked.payload.GroupId) {
    return <JobDetailsPopup loadingPopup={false/* will need to revisit this TODO */} approval={approval} onClose={() => onClose()}/>
  }

  return <div>Unknown approval type</div>
}

export default connect(
  (state: IApplicationState, ownProps: IProps) => ({
    ...ownProps,
    ...state.headerState,
    ...state.sessionState,
    ...state.mainDrawerState
  }),
  {
    ...HeaderStore.actionCreators
  }
)(ApprovalDetails as any);