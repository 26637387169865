import React, { createRef, useState, useEffect } from "react";
import { Paper, Grid, Button, Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

type Props = {
  acceptBtnText?: string;
  cancelBtnText?: string;
  rejectBtnText?: string;
  titleText?: string;
  children: JSX.Element[] | JSX.Element;
  onApply?: (accepted: boolean) => void;
  onReject?: (accepted: boolean) => void;
  onClose: (accepted: boolean) => void;
  onOpen?: () => void;
  isOpen: boolean;
  addAvailable?: boolean;
  addClass?: string;
  fullWidth?: boolean;
  viewBtnText?: string;
  viewBtnUrl?: string;
  prefixTitleText?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  itemsHeaderText?: string;
  itemsCount?: number;
};

export default (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(
    () => {
      setIsOpen(props.isOpen);
    },
    [props.isOpen, props.addAvailable, isOpen]
  );

  const handleClose = () => {
    setIsOpen(false);
    props.onClose(false);
  };

  const handleOpen = () => {
    if (props.onOpen) {
      props.onOpen();
    }
  };

  const OnApplyBtnClick = () => {
    setIsOpen(false);

    if (props.onApply) {
      props.onApply(true);
    }

    props.onClose(true);
  };

  const OnRejectBtnClick = () => {
    setIsOpen(false);

    if (props.onReject) {
      props.onReject(true);
    }

    props.onClose(true);
  };

  const OnViewBtnClick = () => {
    setIsOpen(false);
    props.onClose(true);
  };

  return (
    <div>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        onEntered={handleOpen}
        className={`modal ${props.addClass}`}
      >
        <DialogTitle className="modal-title">
          {props.itemsHeaderText && (
            <>
              <div className="header-count">
                <h3>{props.itemsHeaderText}</h3>
              </div>
            </>
          )}
          {props.prefixTitleText && <div>
            <div className="prefix-title">{props.prefixTitleText}</div>
            <div className="title">{props.titleText}</div>
          </div>}
          {!props.prefixTitleText && props.titleText}
          <IconButton className="modal-close" aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="modal-footer">
          {props.cancelBtnText && <Button variant="contained" onClick={handleClose} className="btn-contined">
            {props.cancelBtnText}
          </Button>}
          {props.acceptBtnText && <Button
            variant="contained"
            onClick={OnApplyBtnClick}
            color="primary"
            className="btn-primary"
            disabled={!props.addAvailable}
          >
            {props.acceptBtnText}
          </Button>}
          {props.rejectBtnText && <Button
            variant="contained"
            onClick={OnRejectBtnClick}
            color="secondary"
            className="btn-danger"
          >
            {props.rejectBtnText}
          </Button>}
          {props.viewBtnText && <Button
            type="link"
            href={props.viewBtnUrl}
            target="_blank"
            variant="contained"
            onClick={OnViewBtnClick}
            color="primary"
            className="btn-primary btn-view"
          >
            {props.viewBtnText}
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
};
