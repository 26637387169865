import React, { useState, useEffect, useRef } from "react";
import { AdvancedGrid } from "../../../common/grid/AdvancedGrid";
import AdvanceGridParams, {
  IResourceFields,
  IResourceParams,
  IResourceTypeDetail,
  IResourceTypeField
} from "../../../../models/ResourceModels";
import ResourceService from "../../../../services/ResourceService";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { GridCellProps, GridColumnProps, GridFilterCellProps, GridFilterChangeEvent } from "@progress/kendo-react-grid";
import "./ResourceGroupAutomation.scss";
import { ColumnFromField } from "./ColumnHelper";
import { GridFilterOperators } from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperators";
import NotificationService from "../../../../services/NotificationService";
import { DropDownFilter } from "../../../common/grid/filters/DropDownFilter";
import CommandCell from "../../../common/grid/columns/CommandCell";
import { Paper } from "@material-ui/core";
import _ from "lodash";
import { DropDownList } from "@progress/kendo-react-dropdowns/dist/es/main";
import { GridDetailRowProps } from "@progress/kendo-react-grid/dist/es/main";
import ResourceAssignments from "../ResourceAssignments";
import RelativityClientPopup from "./RelativityClientPopup";
import * as SessionStore from "../../../../store/Session";
import { connect } from "react-redux";
import { IApplicationState } from "../../../../store";

interface IResourceTypeFields {
  fields: Array<string>;
  joinFields?: Array<string>;
  includeDeleted?: boolean;
}

interface IProps {
  match?: any;
  filterOperators?: GridFilterOperators;
  gridToolbarContent?: JSX.Element;
  selectionChange?: (selected: Array<IResourceFields>, resourceType: IResourceTypeDetail, joinTables?: Array<string>) => void;
  className?: string;
  filterEpiqOnly?: boolean;
  excludedResourceGroupsId?: number;
  isAdminResourceList?: boolean;
  showAssignments? : boolean;
  take?: number;
  showNewRelClientBtn?: boolean;
}

const GridConfigurations = {
  PageSize: 100,
  OrderBy: "ResourceId",
  Ascending: "asc",
  Descending: "desc",
  FieldPrefix: "fields."
};

const includeResourceTypeListFields = {
  "Ediscovery Project": { fields: ["Name", "Project Number", "ESI Project Code", "Client Name", "Project Status"] },
  File: { fields: ["ResourceId", "ObjectId", "File Name", "Description", "Extensions", "Last Modified Date"] },
  Report: { fields: ["ResourceId", "ObjectId", "Name", "reportCode", "isDashboard", "workspaceId"] },
  "Epiq Access": { fields: ["ResourceId", "ObjectId", "Name", "Description"] },
  "Client Name": { fields: ["ResourceId", "ObjectId", "Client Name", "Created Date"] },
  "Sales Contract Number": { fields: ["ResourceId", "ObjectId", "Contract", "Contract Description", "SAP Sold To Client Number", "Customer Name"] },
  "Relativity Client": { fields: ["Name", "Unique Relativity Id", "Unique Instance Id", "Relativity URL"], joinFields: ["Instance"] },
  "User": { fields: ["Username", "Comments", "FirstName", "LastName"] },
  "Relativity Instance": { fields: ["Name", "Relativity URL", "Unique Relativity Id", "Is Active"] },
  "Application": { fields: ["Name", "Instance Name", "URL", "Startup URL"] },
  "Relativity Workspace": { fields: ["Name", "RelativityMatterName", "RelativityMatterNumber", "UniqueInstanceId", "WorkspaceId", "UniqueClientId", "IsActive"], includeDeleted: true },
  "Role": { fields: ["DisplayName", "Description"] },
  "User Group": { fields: ["DisplayName", "Description"] },
  "Okta Group": { fields: ["Group Name", "Description", "Okta Id"] },
  "Whitelabel": { fields: [ "Name" ] },
  "Gbts Client": { fields: ["Customer Name", "Client Id"] },
  "SAP Sold To Client": { fields: ["DisplayName", "Description"] }
} as Record<string, IResourceTypeFields>;

const universalFields = {
  ["ObjectId"]: { name: "ObjectId", type: "Int", displayName: "Object Id" },
  ["ResourceId"]: { name: "ResourceId", type: "Int", displayName: "Resource Id" },
  ["DisplayName"]: { name: "DisplayName", type: "Text", displayName: "Display Name" },
  ["IsDeleted"]: { name: "IsDeleted", type: "Bool", displayName: "Is Deleted" }
} as Record<string,IResourceTypeField>;

interface IRefreshGridParams extends IResourceParams {
  refreshToggle: boolean;
}

interface ISelectableResource extends IResourceFields {
  selected: boolean;
  expanded: boolean;
}

type Props = IProps & SessionStore.ISessionState;

const DynamicResourceList = (props: Props) => {
  const startingGridParams: IRefreshGridParams = {
    skip: 0,
    take: props.take ? props.take : GridConfigurations.PageSize,
    sort: [{ field: GridConfigurations.OrderBy, dir: "desc" }],
    typeCode: "ediscoveryproject",
    refreshToggle: false
  };

  const nameCol = {
    field: "displayName",
    title: props.isAdminResourceList ? " " : "name",
    filterable: false,
    sortable: false,
    headerClassName: props.isAdminResourceList ? "no-sort" : ""
  } as GridColumnProps;

  const [hasError, setHasError] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(props.isAdminResourceList ? false : true);
  const [dataState, setDataState] = useState(startingGridParams);
  const [columns, setColumns] = useState([nameCol]);
  const [resourceTypes, setResourceTypes] = useState(null as Record<string,IResourceTypeDetail>);
  const [selectedResourceType, setSelectedResourceType] = useState(null as IResourceTypeDetail);
  const [fetchResults, setFetchResults] = useState(new Array<ISelectableResource>());
  const [selections, setSelections] = useState<Array<IResourceFields>>([]);
  const filterTimeOut = useRef(null);
  const gridRef = useRef<AdvancedGrid<ISelectableResource, IRefreshGridParams>>(null);
  const selectedStateRef = useRef<Record<number,IResourceFields>>({});
  const resourceTypesRef = useRef<IResourceTypeDetail>(null);
  const permissions = props.sessionData.permissions;

  useEffect(() => {
    if (!isGridLoading && (selectedResourceType && selectedResourceType.code !== "select")) {
      fetchDataAsync(dataState);
    }
    resourceTypesRef.current = selectedResourceType;
  }, [selectedResourceType]);

  useEffect(() => {}, [resourceTypes]);

  useEffect(() => {
    if (props.selectionChange) {
      props.selectionChange(_.values(selections), selectedResourceType);
    }
  }, [selections]);

  async function getResourceTypes(tryAgin: boolean): Promise<IResourceTypeDetail[]> {
    const response = await ResourceService.getResourceTypeDetails();
    if (response.ok) {
      const data = response.data as Array<IResourceTypeDetail>;
      return data;
    } else if (tryAgin) {
      return await getResourceTypes(false);
    } else {
      NotificationService.showErrorToast("Error getting resource types, maybe you can refresh this page.");
      setHasError(true);
      return [];
    }
  }

  const selectResource = (dataItem: ISelectableResource) => {

    const newSelections = { ...selectedStateRef.current };

    if (newSelections[dataItem.resourceId]) {
      delete newSelections[dataItem.resourceId];
    } else {
      newSelections[dataItem.resourceId] = dataItem;
    }

    selectedStateRef.current = newSelections;

    if (props.selectionChange) {
      setSelections(_.values(newSelections));
    }
  };

  const resourceTypeCell = (gridCellProps: GridCellProps) => {
    return (
      <td>
        <div>{resourceTypesRef.current ? resourceTypesRef.current.displayName : ""}</div>
      </td>
    );
  };

  const universalCell = (gridCellProps: GridCellProps) => {

    const fieldName = (gridCellProps.field.charAt(0).toLowerCase() + gridCellProps.field.slice(1));

    return (
      <td>
        <div>{gridCellProps.dataItem[fieldName]}</div>
      </td>
    );
  };

  const isDisplayedField = function (resourceType: string, field: IResourceTypeField) {
    return includeResourceTypeListFields[resourceType].fields.indexOf(field.name) > -1;
  };

  const  detailColumnCell = (props: any) => {
    let dataItem = props.dataItem;

    return (
      <td className="k-hierarchy-cell">
        {dataItem.expanded ? <a onClick={(e) => expandChange(e, dataItem)} href="#" className="k-icon k-minus"></a> : <a onClick={(e) => expandChange(e, dataItem)} href="#" className="k-icon k-plus" ></a>}
      </td>
    )
  }

  const headerCell = () => {
    return (
      <div></div>
    )
  }

  const selectResourceType = (code: string, rTypes: Array<IResourceTypeDetail>) => {

    const selRT = rTypes.find(d => d.code === code);
    if (!selRT) {
      return;
    }

    let cols = [] as Array<GridColumnProps>;

    if(code == "select")
    {
      cols.push(nameCol);
      setColumns(cols);
      setSelectedResourceType(selRT);

      const newState = { ...dataState, typeCode: selRT.code };
      setDataState(newState);

      return;
    }

    if (props.isAdminResourceList && props.showAssignments && code !== "select") {
      cols.push({
        title: "",
        field: "expanded",
        cell: detailColumnCell,
        headerCell: headerCell
      });
    }

    if(props.selectionChange)
    {
      cols.push({
        title: "",
        cell: CommandCell({
          onSelection: selectResource,
          selectedField: "selected"
        }),
        sortable: false,
        headerClassName: "no-sort"
      });
    }

    const selectedIncludeResourceType = includeResourceTypeListFields[selRT.displayName];

    if (selRT.fields && selRT.fields.length) {

      selRT.fields.forEach(f => {
        const universaleField = universalFields[f.name];
        if (universaleField && isDisplayedField(selRT.displayName, f)) {
          const col = ColumnFromField(selRT.code, universaleField);
          col.cell = universalCell;
          col.sortable = true;
          cols.push(col);
        }
        else if (isDisplayedField(selRT.displayName, f)) {
          const col = ColumnFromField(selRT.code, f, true, GridConfigurations.FieldPrefix, true);
          if (f.type === "DateTime") {
            col.filterable = false;
          }
          col.sortable = true;
          cols.push(col);
        }
      });
    }
    else if (selectedIncludeResourceType && selectedIncludeResourceType.fields) {
      selectedIncludeResourceType.fields.forEach(f => {
        cols.push({
          field: "fields." + f,
          title: f,
          filterable: true,
          sortable: true,
          filter: f === "ObjectId" || f === "ResourceId" ? "numeric" : "text"
        });
      });
    }

    if (!props.isAdminResourceList) {
      cols.push({
        field: "resourceTypeNavigation.displayName",
        title: "RESOURCE TYPE",
        cell: resourceTypeCell,
        filter: "text",
        editable: false,
        filterable: true,
        sortable: false,
        headerClassName: "no-sort",
        filterCell: (props: GridFilterCellProps) => (
          <DropDownFilter
            {...props}
            data={rTypes}
            textField="displayName"
            defaultSelectedOption={selRT}
            onChange={(sel) => selectResourceType(sel.value.code, rTypes)}
          />
        )
      });
    }

    setColumns(cols);
    setSelectedResourceType(selRT);

    let filter = props.isAdminResourceList ? undefined : dataState.filter;

    let sort = dataState.sort && dataState.sort[0];

    if(props.isAdminResourceList && sort && sort.field.toLowerCase() !== GridConfigurations.OrderBy.toLowerCase())
    {
      sort.field = GridConfigurations.OrderBy;
      sort.dir = "asc";
    }

    if (!props.isAdminResourceList && !selectedIncludeResourceType.includeDeleted) {
      const excludeSoftDeleteFilter = {field: "IsDeleted", operator: "eq", value: false};

      if (!filter) {
        filter = { logic: 'and', filters: [excludeSoftDeleteFilter] };
      }
      else {
        filter.filters.push(excludeSoftDeleteFilter);
      }
    }

    const newState = { ...dataState, typeCode: selRT.code, filter: filter, sort: [sort], joinFields: selectedIncludeResourceType.joinFields };
    setDataState(newState);
    gridRef.current && gridRef.current.resetGridState(newState, false, true);
  };

  const fetchDataAsync = async (dataState: IRefreshGridParams, caller?: string) => {
    setIsGridLoading(true);

    const loadTypesPromise = ensureResourceTypePopulated(dataState, caller);
    const loadResourcesPromise = requestResources(dataState, caller);

    const [loadedTypes, resourceResult] = await Promise.all([loadTypesPromise, loadResourcesPromise]);

    if (resourceResult.ok) {
      setIsGridLoading(false);
      setFetchResults(resourceResult.data.results);
      setHasError(false);
    } else {
      console.log("Could not load grid.");
      setIsGridLoading(false);
      setHasError(true);
    }
    setDataState(dataState);
  }

  const ensureResourceTypePopulated = async (dataState: IRefreshGridParams, caller?: string) => {
    if (!resourceTypes) {
      const defaultSelectResourceType: IResourceTypeDetail = {id: -1, displayName: "-- Select Resource Type --", code: "select",  supplementalFieldGroups : null, fields : []};
      let rTypes = await getResourceTypes(true);
      let resourceTypeListFields = includeResourceTypeListFields;

      if (!rTypes || !rTypes.length) {
        setHasError(true);
        return;
      }

       if(props.isAdminResourceList)
      {
        rTypes.push(defaultSelectResourceType);
        const selectType = {"-- Select Resource Type --" : [] as any} as any;
        resourceTypeListFields = { ...selectType, ...includeResourceTypeListFields};
      }


      const filtered = rTypes.filter(r => resourceTypeListFields[r.displayName]);
      const obj = filtered.reduce((accumulatedObject, type) => {
        accumulatedObject[type.code] = type;
        return accumulatedObject;
      }, {} as Record<string, IResourceTypeDetail>);

      setResourceTypes(obj);
      selectResourceType(props.isAdminResourceList ? "select" : dataState.typeCode, filtered);
    }
  }

  const requestResources = async (dataState: IRefreshGridParams, caller?: string) => {

    // component reloaded as if it's new
    if (!dataState.typeCode) {
      dataState = startingGridParams;
    }

    const request = _.cloneDeep(dataState);

    request.filters = [];

    if (props.filterEpiqOnly) {
      request.filters.push({ logic: "and", filters: [ { field: "EpiqOnly", operator: "eq", value: false } ] });
    }

    if (props.excludedResourceGroupsId) {
      request.excludedResourceGroupsId = props.excludedResourceGroupsId;
    }

    if (request.filter) {
      for (let i = 0; i < request.filter.filters.length; i++) {
        const filter = request.filter.filters[i] as FilterDescriptor; // so far we don't nest our filters
        filter.field = filter.field.toString().replace(GridConfigurations.FieldPrefix, "");
      }
      request.filters.push({ logic: "and", filters: request.filter.filters });
    }
    if (request.sort && request.sort.length) {
      request.orderBy = request.sort[0].field.replace(GridConfigurations.FieldPrefix, ""); // only one sort column is supported at this time
      request.isAscending = request.sort[0].dir === GridConfigurations.Ascending;
    }

    return await ResourceService.getResourcesDetails(request);
  };

  const gridFilterChange = (event: GridFilterChangeEvent) => {
    const newState = { ...dataState, filter: event.filter };
    setDataState(newState);

    if (filterTimeOut.current) {
      clearTimeout(filterTimeOut.current);
    }

    filterTimeOut.current = setTimeout(() => {
      gridRef.current.resetGridState(newState, false, true);
    }, 500);
  };

  const gridToolbarContent = () => {
    const rTypes = resourceTypes ? Object.keys(resourceTypes) : null;
    const data = new Array<IResourceTypeDetail>();
    for (const k in rTypes) {
      data.push(resourceTypes[rTypes[k]]);
    }
    return (
      <>
        <DropDownList
          data={data}
          textField="displayName"
          defaultValue={selectedResourceType}
          onChange={(sel) => selectResourceType(sel.value.code, data)}
        />
      </>
    )
  }

  const DetailComponent = (props: any) => {
    const resourceId = props.dataItem.resourceId;
    return (<ResourceAssignments {...props.dataItem} />);
  }

  const expandChange = (e: any, dataItem: any) => {
    e.preventDefault();
    const results = gridRef.current.props.data;
    dataItem.expand = !dataItem.expanded;
    let newData = results.map((item) => {
      if (item.resourceId === dataItem.resourceId) {
        item.expanded = !dataItem.expanded;
      }

      return item;
    });
    setFetchResults(newData);
  };

  const gridData = () => {
    return (selectedResourceType && selectedResourceType.code !== "select" ?
      fetchResults.map((item) => {
        return ({
          ...item,
          selected: !!selectedStateRef.current[item.resourceId],
            })}) : []);
  };

  return (
    <>
      <Paper className={`resource-list-wrapper ${props.className}`}>
        <AdvancedGrid
          ref={(standardGridInstance: AdvancedGrid<ISelectableResource, IRefreshGridParams>) => {
            gridRef.current = standardGridInstance;
          }}
          showErrorState={hasError}
          showLoadingIndicator={isGridLoading}
          data={gridData()}
          dataFetch={fetchDataAsync}
          dataState={dataState}
          columns={columns.length ? columns : [nameCol]}
          paging={false}
          totalRecords={ props.isAdminResourceList ? { value: selectedResourceType && selectedResourceType.code !== "select" ? fetchResults.length : 0, label: "Resources" }: null}
          noRecordsRender={ selectedResourceType && selectedResourceType.code == "select" ? <p>Please select resource type to display resources. </p> : <p>No Resources Found</p>}
          noLoadOnMount={false}
          filteredDataOnly={false}
          multiFieldFilterDelimiter="|"
          gridToolbarContent={ props.isAdminResourceList ? gridToolbarContent() : props.gridToolbarContent}
          hidePaper={false}
          filter={dataState.filter}
          onFilterChange={gridFilterChange}
          detail={ props.isAdminResourceList ? DetailComponent : null}
          expandField={ props.isAdminResourceList ? "expanded" : ""}
          filterOperators={props.filterOperators || { text: [
              { text: 'grid.filterContainsOperator', operator: 'contains' },
              { text: 'grid.filterEqOperator', operator: 'eq' },
            ],
            numeric: [
              { text: 'grid.filterEqOperator', operator: 'eq' },
              { text: 'grid.filterGteOperator', operator: 'gte' },
              { text: 'grid.filterGtOperator', operator: 'gt' },
              { text: 'grid.filterLteOperator', operator: 'lte' },
              { text: 'grid.filterLtOperator', operator: 'lt' },
            ],
            date: [
              { text: 'grid.filterEqOperator', operator: 'eq' },
              { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
              { text: 'grid.filterAfterOperator', operator: 'gt' },
              { text: 'grid.filterBeforeOperator', operator: 'lt' },
              { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
            ],
            boolean: [
              { text: 'grid.filterEqOperator', operator: 'eq' }
            ]}}
        />
      </Paper>
    </>
  );
};

export default connect((state: IApplicationState) => ({ ...state.sessionState }))(DynamicResourceList);
