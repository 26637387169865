import React from "react";
import { IApprovalForUserDetails } from "../../models/approvalModels";
import { IApprovalDetailsProps } from "./ApprovalDetail";
import CollapsibleSection from "../administration/common/CollapsibleSection";
import { IGridParams, StandardGrid } from "../common/grid/StandardGrid";
import CloseIcon from "@material-ui/icons/Close";
import "./AddUsersApproval.scss";
import { IconButton, Paper } from "@material-ui/core";
import CommonHelper from "../common/utilities/CommonHelper";
import { formatDate } from "@telerik/kendo-intl";
import ViewMore from "../common/ViewMore";
import ReactMarkdown from 'react-markdown';
import { BaseGrid } from "../common/grid/BaseGrid";

interface IProps {
  approvalDetails: IApprovalForUserDetails;
  mainDrawerExpanded: boolean;
}

type Props = IProps & IApprovalDetailsProps;

const AddUsersApproval = (props: Props) => {

  const { Fields } = props.approvalDetails.payloadUnpacked.payload;

  const columns = [
    { field: "Username", title: "USERNAME", filterable: false, sortable: false, headerClassName: "no-sort" },
    { field: "Email", title: "EMAIL", filterable: false, sortable: false, headerClassName: "no-sort" },
    { field: "FirstName", title: "FIRST NAME", filterable: false, sortable: false, headerClassName: "no-sort" },
    { field: "LastName", title: "LAST NAME", filterable: false, sortable: false, headerClassName: "no-sort" }
  ];

  const restrictedIPAddresses = Fields.RestrictedIPAddresses ? "Yes" : "No";
  const userType = Fields.UserType ? Fields.UserType : "Client User";
  const isAddUser = Fields.RequestTypeCode == "relativityaccess" ? true : false; 
  let relativityUserGroupNameLabel = Fields.IsExistingRelativityUserGroupName === true ? "Existing Relativity Group Name" : "Create new Relativity Group Name";
  relativityUserGroupNameLabel = Fields.IsRemove === true ? "Relativity Group Name" : relativityUserGroupNameLabel;
  const showCopyPermissionsFromExistingRelGroup = Fields.IsExistingRelativityUserGroupName === false ? true : false;
  let requestTypeName = Fields.IsExistingRelativityUserGroupName === true ? "Add to exisiting Relativity Group" : "Create new Relativity Group";
  requestTypeName = Fields.IsRemove === true ? Fields.RequestTypeName : requestTypeName;
  let isMultiFactorAuthenticaion = Fields.IsMultiFactorAuthenticaion === true ? "Yes, this client requires MFA for some or all users." :  "No known MFA requirements.";
  let showMultiFactorAuthenticaion = Fields.IsRemove === true ? false : true;
  Fields.SendEAInvitationMailToUsers = Fields.SendEAInvitationMailToUsers === "YES" ? "Yes, send invitations to the users" : "No invitations needed, notify me instead";

  let createdDate = "";
    if (props.approvalDetails.createdDate) {
      let date = props.approvalDetails.createdDate;
      let localDateTime = CommonHelper.convertUTCDateToLocalDate(date);
      createdDate = formatDate(localDateTime, "g");
    }
    
    return <div className="add_users_approval_details">
      <div className="add_users_approval_details_general_instructions">
        <p className="add_users_approval_details_general_instructions_what">Approvals</p>
        <p>Review and verify details for the selected approval.</p>
      </div>
      <Paper className="add_users_approval_details_paper">
      <div className="add_users_approval_details_general_info">
        <div className="add_users_approval_details_breacrumb-container">
          <div className="close-icon-button">
              <IconButton
                className="modal-close"
                aria-label="close"
                disabled={false}
                onClick={props.onClose}
              ><CloseIcon /></IconButton>
          </div>
        </div>
        <div className="add_users_approval_details_general_deets">
          <div className="info-item">
            <div className="info-label">Request Type</div>
            <div>{Fields.RequestTypeName}</div>
          </div>
          <div className="info-item">
            <div className="info-label">Description</div>
            <div>{Fields.ShortDescription}</div>
          </div>
          <div className="info-item">
            <div className="info-label">Created By</div>
            <div>{props.approvalDetails.createdByUser.firstName} {props.approvalDetails.createdByUser.lastName}</div>
          </div>
          <div className="info-item">
            <div className="info-label">Date Created</div>
            <div>{createdDate}</div>
          </div>
        </div>
        <div className="add_users_approval_details_general_deets">
          <div className="info-item add_users_approval_details_half">
            <div className="info-label">Approvers</div>
            <ViewMore lineHeight={45}>{props.approvalDetails.payloadUnpacked.approverUsers.map((u: any) => u.userName).join(", ")}</ViewMore>
          </div>
          <div className="info-item add_users_approval_details_half"></div>
        </div>
      </div>
      <div className="collapsibleContainer">
        <div className="left-side">
          <CollapsibleSection title="Relativity Details">
            <div className="info-section">
              <div className="info-item">
                <div className="info-label">Project</div>
                <div>
                  {Fields.ProjectName} ({Fields.ProjectNumber})
                </div>
              </div>
              <div className="info-item">
                <div className="info-label">Application URL</div>
                <div>{Fields.WebsiteUrl}</div>
              </div>
              <div className="info-item">
                <div className="info-label">Workspace</div>
                <div>{Fields.RelativityHost}</div>
              </div>
              <div className="info-item">
                <div className="info-label">Group Request Type</div>
                <div>{requestTypeName}</div>
              </div>
              <div className="info-item">
                <div className="info-label">{relativityUserGroupNameLabel}</div>
                <div>{Fields.RelativityUserGroupName}</div>
              </div>
              <div className="info-item">
                {showCopyPermissionsFromExistingRelGroup && Fields.IsRemove === false &&
                    (<><div className="info-label">Copy permissions from existing Relativity Group</div>
                    <div>{Fields.CopyPermissionsFromExistingRelGroup}</div>
                    </>)                  
                }
              </div>
            </div>
          </CollapsibleSection>

          <CollapsibleSection title="Client Authorization">
            <div className="client-container">
              <div className="info-item info-item-50">
                <div className="info-label">Client's Company Name</div>
                <div>
                 {Fields.CompanyName}
                </div>
              </div>
              <div className="info-item info-item-50">
                <div className="info-label">Requester's Email</div>
                <div>{Fields.RequestorEmail}</div>
              </div>
              <div className="info-item info-item-50">
                {isAddUser &&
                    (<>
                      <div className="info-label">Restricted IP</div>
                      <div><ReactMarkdown children={(restrictedIPAddresses ? restrictedIPAddresses.replace(/\\n/gi, "\n").replace(/\n/gi, "  ") : "" )} /></div>
                    </>
                    )
                }
              </div>
              <div className="info-item info-item-50">
                {restrictedIPAddresses != "No" && isAddUser &&
                    (<>
                      <div className="info-label">Restricted IP</div>
                      {/* <div><ReactMarkdown children={(Fields.RestrictedIPAddresses ? Fields.RestrictedIPAddresses.replace(/\n/gi, '\n  &nbsp;') : "")} /></div> */}
                      <div style={{whiteSpace:"pre-wrap"}}>{Fields.RestrictedIPAddresses}</div>
                    </>
                    )
                }
              </div>
            </div>
          </CollapsibleSection>
        </div>
        <div className="right-side">
          <CollapsibleSection title="Related Information">
            <div className="info-section">
                <div className="info-item">
                  <div className="info-label">User Type</div>
                  <div>{userType}</div>
                </div>
                <div className="info-item">
                  {isAddUser &&
                      (<>
                        <div className="info-label">Epiq Access Invitation Email</div>
                        <div>{Fields.SendEAInvitationMailToUsers}</div>
                      </>)
                  }
                </div>                
                <div className="info-item">
                 {showMultiFactorAuthenticaion && 
                      (<><div className="info-label">Okta Requirements</div>
                          <div className="child-info-item">
                            <div className="child-info-label">Multi-Factor Authenticaion</div>
                            <div>{isMultiFactorAuthenticaion}</div>
                            {showMultiFactorAuthenticaion && Fields.OktaGroupName &&
                                  (<>
                                    <div className="child-info-label">Okta Group</div>
                                    <div>{Fields.OktaGroupName}</div>
                                  </>
                                  )
                            }
                          </div>                        
                      </>)
                  }
                </div>
                <div className="info-item">
                  
                  {Fields.Comments && (
                      <>
                        <div className="info-label">Comments</div>
                        <div>{Fields.Comments}</div>
                      </>
                      )
                  }
                </div>
              </div>            
          </CollapsibleSection>

          <CollapsibleSection title="Users" count={Fields.ImportUsers.$values.length}>
            <div className="approval-user-list-info-section">
              <BaseGrid 
                className={props.mainDrawerExpanded ? "main-drawer-expanded" : ""}
                showLoadingIndicator={false} 
                showErrorState={false} 
                data={Fields.ImportUsers.$values} 
                dataFetch={function (params: IGridParams): void {} } 
                dataState={{}} 
                columns={columns}
                resizable={true}
              />
            </div>
          </CollapsibleSection>
          </div>
        </div>
      </Paper>
    </div>
}

export default AddUsersApproval;