import { IGridParams } from "../components/common/grid/AdvancedGrid";
import { JobMessagingConfig } from "../JobManager";
import ResourceService from "../services/ResourceService";
import SupportService from "../services/SupportService";
import { IFieldValueLookup, IRequestTypes, IWizardDropDownListItem, IWizardRequest, IWizardSection, IWizardSummary, IWizardValues, IWizardWarningMessage } from "./Wizard";
import NotificationService from "../services/NotificationService";
import { IResourceParams } from "./ResourceModels";
import { TicketTypes } from "./ServicenowSupportRequestSettings";

const kDefaultSelectionName = "Not Applicable";
const kMaxDropDownLength = 100;

export enum PublicFields {
  AppId = "appId",
  FirstName = "1.3a",
  LastName = "1.3b",
  Email = "1.4",
  IsFederated = "1.5",
  Url = "1.6",
  ContactSummary = "1.7",
  workSpaceName = "1.8"
}

export enum AppType {
  Relativity = "Relativity",
  GoAnywhere = "GoAnywhere"
}

const appTypeConfig = {
  [AppType.Relativity]: {
    "header": "Support: Request help with Relativity",
    "subHeader": "RELATIVITY HELP",
    "issues": [{
      id: 1,
      title: "Cannot access Relativity"
    },
    {
      id: 2,
      title: "Relativity seems very slow"
    },
    {
      id: 3,
      title: "Cannot see document list",
    },
    {
      id: 4,
      title: "Document not opening",
    },
    {
      id: 5,
      title: "Search isn`t loading",
    },
    {
      id: 6,
      title: "Other issue",
    }]
  },
  [AppType.GoAnywhere]: {
    "header": "Support: Request help with GoAnywhere",
    "subHeader": "GOANYWHERE HELP",
    "issues": [{
      id: 1,
      title: "Cannot access GoAnywhere"
    },
    {
      id: 2,
      title: "Issue transferring files "
    },
    {
      id: 3,
      title: "Missing folders or files",
    },
    {
      id: 4,
      title: "Other issue",
    }]
  }
}

export const loginHelpStepsSettings = (appType : AppType) => {
  return (
    [{
      fields: [
        {
          type: "hiddenField",
          fieldId: "hb",
          name: "Date Submitted",
          label: "Date Submitted",
          hideFieldIdLabel: true,
          value: new Date().toLocaleDateString()
        },
        {
          type: "hiddenField",
          fieldId: "hc",
          name: "Support Category",
          label: "* Request Type",
          hideFieldIdLabel: true,
          value: `Request help with ${appType === AppType.GoAnywhere ? AppType.GoAnywhere : AppType.Relativity}`
        },
        {
          type: "hiddenField",
          fieldId: "appType",
          name: "App Type",
          label: "App Type",
          hideFieldIdLabel: true,
          value: appType,
          hideFieldInSummary: true
        },
        {
          type: "dropdown",
          fieldId: "1.1",
          name: "Issue",
          label: "Select the option that best represents the issue.",
          requiredWhenActive: true,
          hideFieldIdLabel: true,
          active: true,
          DropDownOptions: appTypeConfig && appTypeConfig[appType] && appTypeConfig[appType].issues ? appTypeConfig[appType].issues : appTypeConfig[AppType.Relativity].issues
        },
        {
          type: "textarea",
          fieldId: "1.1.2",
          name: "Document ID",
          label: "Provide the Document ID of the document.",
          resetForField: "1.1",
          showCharacterCount: false,
          hideFieldIdLabel: true,
          dependency: {
            logic: "and",
          subConditions: [
            { fieldId: "1.1", compare: "eq", value: 4 },
            { fieldId: "appType", compare: "eq", value: AppType.Relativity },
          ]},
          requiredWhenActive: true,
          active: false,
          textAreaRows: 1,
        },
        {
          type: "textarea",
          maxLength: 100,
          resetForField: "1.1",
          fieldId: "1.1.3",
          label: "Provide the saved search name.",
          name: "Search Name",
          showCharacterCount: false,
          hideFieldIdLabel: true,
          dependency: {
            logic: "and",
            subConditions: [
              { fieldId: "1.1", compare: "eq", value: 5 },
              { fieldId: "appType", compare: "eq", value: AppType.Relativity },
            ]},
          requiredWhenActive: true,
          active: false,
          textAreaRows: 1,
        },
        {
          type: "textarea",
          maxLength: 130,
          resetForField: "1.1",
          fieldId: "1.1.6",
          label: "Add a short description of the situation, incident, or request.",
          name: "Summary",
          showCharacterCount: true,
          hideFieldIdLabel: true,
          dependency: {
            logic: "or",
            subConditions: [
              {
                logic: "and",
                subConditions: [
                  { fieldId: "1.1", compare: "eq", value: 6 },
                  { fieldId: "appType", compare: "eq", value: AppType.Relativity },
                ]
              },
              {
                logic: "and",
                subConditions: [
                  { fieldId: "appType", compare: "eq", value: AppType.GoAnywhere },
                  { fieldId: "1.1", compare: "eq", value: 4 }
                ]
              }
            ]
          },
          requiredWhenActive: true,
          textAreaRows: 1,
        },
        {
          type: "textarea",
          label: "Add a full description of the situation, incident, or request.",
          name: "Description",
          requiredWhenActive: true,
          fieldId: "1.1.4",
          hideFieldIdLabel: true,
          active: false,
          textAreaRows: 5,
          cssName: () => "techsupport-full-description info-text",
          tooltipTitle: "Provide context about the situation, including all impacted projects, URLs, and workspaces.",
          fieldAdditionalNotes: "Upload screenshots of any error messages or impacted pages on the next page.",
          dependency: {
            logic: "or",
            subConditions: [
              { fieldId: "1.1", compare: "eq", value: 1 },
              { fieldId: "1.1", compare: "eq", value: 2 },
              { fieldId: "1.1", compare: "eq", value: 3 },
              {
                logic: "and",
                subConditions: [
                  { fieldId: "1.1", compare: "eq", value: 6 },
                  { fieldId: "1.1.6", compare: "neq", value: "" },
                  { fieldId: "1.1.6", compare: "neq", value: null },
                { fieldId: "appType", compare: "eq", value: AppType.Relativity }
                ]
              },
              {
                logic: "and",
                subConditions: [
                  { fieldId: "1.1", compare: "eq", value: 4 },
                  { fieldId: "1.1.6", compare: "neq", value: "" },
                  { fieldId: "1.1.6", compare: "neq", value: null },
                { fieldId: "appType", compare: "eq", value: AppType.GoAnywhere }
                ]
              },           
            ]
          },
        },
        {
          type: "textarea",
          label: "Add a full description of the situation, incident, or request.",
          name: "Description",
          fieldId: "1.1.5",
          hideFieldIdLabel: true,
          resetForField: "1.1",
          active: false,
          textAreaRows: 5,
          cssName: () => "techsupport-full-description",
          tooltipTitle: "Provide context about the situation, including all impacted projects, URLs, and workspaces.",
          dependency: {
            logic: "or",
            subConditions: [
              { fieldId: "1.1.2", compare: "neq", value: null },
              { fieldId: "1.1.3", compare: "neq", value: null },
            ]
          },
        },
        {
          type: "hiddenField",
          fieldId: PublicFields.AppId,
          name: PublicFields.AppId,
          active: true,
          hideFieldInSummary: true
        },
        {
          type: "hiddenField",
          fieldId: PublicFields.FirstName,
          name: "First Name",
          active: true,
          hideFieldInSummary: true
        },
        {
          type: "hiddenField",
          fieldId: PublicFields.LastName,
          name: "Last Name",
          active: true,
          hideFieldInSummary: true
        },
        {
          type: "displayOnlyField",
          fieldId: "EpiqAccessDetails",
          name: "Epiq Access Details",
          label: "Epiq Access Details",
          hideFieldIdLabel: true,
          active: true,
          cssName: (existingFieldVals?: IFieldValueLookup) => {
            if (!existingFieldVals["1.1"] || existingFieldVals["1.1"].value === null) {
              return "epiq-access-details main";
            }
            else if (existingFieldVals["1.1"] && ((existingFieldVals["1.1"].value === 4 && existingFieldVals["1.1.2"] && existingFieldVals["1.1.2"].value === null)
              || (existingFieldVals["1.1"].value === 5 && existingFieldVals["1.1.3"] && existingFieldVals["1.1.3"].value === null))) {
              return "epiq-access-details sub";
            }
            return "epiq-access-details";
          }
        },
        {
          type: "displayOnlyField",
          fieldId: "1.3",
          name: "User Name",
          label: "User Name",
          hideFieldIdLabel: true,
          active: true,
          cssName: () => "quarter-wrap",
          defaultValueFunc: (existingFieldVals?: IFieldValueLookup) => {

            if (existingFieldVals[PublicFields.FirstName] && existingFieldVals[PublicFields.LastName]) {
              return `${existingFieldVals[PublicFields.FirstName].value} ${existingFieldVals[PublicFields.LastName].value}`;
            }

            return null;
          }
        },
        {
          type: "displayOnlyField",
          fieldId: PublicFields.Email,
          name: "User ID",
          label: "User ID",
          hideFieldIdLabel: true,
          active: true,
          cssName: () => "quarter-wrap",
        },
        {
          type: "displayOnlyField",
          fieldId: PublicFields.IsFederated,
          name: "Federation Status",
          label: "Federation Status",
          active: true,
          hideFieldIdLabel: true,
          cssName: () => "quarter-wrap",
          resetForField: PublicFields.AppId
        },
        {
          type: "displayOnlyField",
          fieldId: PublicFields.Url,
          name: "App URL",
          label: "App URL",
          active: true,
          hideFieldIdLabel: true,
          cssName: () => "quarter-wrap",
        },
        {
          type: "hiddenField",
          fieldId: PublicFields.ContactSummary,
          name: "Epiq Access Details",
          label: "Epiq Access Details",
          active: true,
          hasMarkdown: true,
        },
        {
          type: "hiddenField",
          fieldId: PublicFields.workSpaceName,
          name: "Workspace name",
          label: "Workspace name",
          active: true,
          hideFieldInSummary: true
        }
      ],
      header: appTypeConfig[appType].header,
      subHeader: appTypeConfig[appType].subHeader,
      description: `To help us resolve this issue, provide details about your ${appType} experience. Select one of the choices from the following list to help us focus on the type of issue. Then, provide additional information to describe what you see, including any error messages.`,
      summaryTitle: "REQUEST",
      nextCondition: {
        logic: "and",
        subConditions: [
          { fieldId: "1.1", compare: "neq", value: null },
          { fieldId: "1.1", compare: "neq", value: "" },
        ]
      },
    },
    {
      fields: [
        {
          type: "fileupload",
          label: "Upload screenshots (error messages or impacted pages).",
          name: "Uploaded files",
          labelCaption: "Drop or select your files and click UPLOAD. Then, click NEXT.",
          fieldId: "3.1",
          hideFieldIdLabel: true,
          active: true,
          multiple: true,
          restrictAutoUpload: true,
          maxFileSize: 10485760, //10MB as bytes
          noInputText: "None"
        },
        {
          type: "sliderField",
          label: "How does the issue impact my work?",
          labelCaption: "Move the slider to represent the impact.",
          name: "Impact of Issue",
          fieldId: "3.2",
          requiredWhenActive: false,
          hideFieldIdLabel: true,
          active: true,
          tooltipTitle: "How does the issue impact my work?",
          cssName: () => "tech-support-wizard-slider",
          radioButtonOptions: [
            {
              id: 1,
              title: "Standard",
              selected: true,
              lookupValues: { description: "General support priority" }
            },
            {
              id: 2,
              title: "Affected",
              lookupValues: { description: "Some tasks affected" }
            },
            {
              id: 3,
              title: "Highly Affected",
              lookupValues: { description: "Daily tasks affected" }
            },
            {
              id: 4,
              title: "Blocked",
              lookupValues: { description: "Work is blocked" }
            }
          ]
        }
      ],
      header: appTypeConfig[appType].header,
      subHeader: "ADD MORE CONTEXT",
      description: "Upload files, such as screenshots of error messages or impacted screens, to help us assess the issue. It is also important that we understand how the situation, incident, or request is affecting your daily work. Move the slider from left to right to represent the impact.",
      summaryTitle: "MORE CONTEXT",
      nextCondition: {
        logic: "and",
        subConditions: [
          { fieldId: "3.2", compare: "neq", value: null },
          { fieldId: "3.2", compare: "neq", value: "" }
        ]
      },
    },
    {
      fields: [
        {
          type: "input",
          fieldId: "2.1",
          name: "First Name",
          label: "First Name",
          active: true,
          hideFieldIdLabel: true,
          requiredWhenActive: true,
          cssName: () => "half-wrap",
          defaultValueFunc: (existingFieldVals?: IFieldValueLookup) => {
            return existingFieldVals[PublicFields.FirstName] ? existingFieldVals[PublicFields.FirstName].value : null;
          },
          summaryCssName: "summary-half-wrap"
        },
        {
          type: "input",
          fieldId: "2.2",
          name: "Last Name",
          label: "Last Name",
          active: true,
          hideFieldIdLabel: true,
          requiredWhenActive: true,
          cssName: () => "half-wrap",
          defaultValueFunc: (existingFieldVals?: IFieldValueLookup) => {
            return existingFieldVals[PublicFields.LastName] ? existingFieldVals[PublicFields.LastName].value : null;
          },
          summaryCssName: "summary-half-wrap"
        },
        {
          type: "input",
          fieldId: "2.3",
          name: "Call Back Number",
          label: "Call Back Number",
          active: true,
          hideFieldIdLabel: true,
          requiredWhenActive: true,
          cssName: () => "half-wrap tech-support-callback-number",
          placeholder: "(Enter your phone number)",
          summaryCssName: "summary-half-wrap"
        },
        {
          type: "input",
          fieldId: "2.4",
          name: "Email",
          label: "Email",
          active: true,
          hideFieldIdLabel: true,
          requiredWhenActive: true,
          cssName: () => "half-wrap",
          defaultValueFunc: (existingFieldVals?: IFieldValueLookup) => {
            return existingFieldVals[PublicFields.Email] ? existingFieldVals[PublicFields.Email].value : null;
          },
          summaryCssName: "summary-half-wrap"
        },
        {
          type: "inputChiplist",
          fieldId: "2.5",
          name: "Followers",
          label: "Add Followers to the ticket.",
          labelCaption: "Include your project team email so they can follow this ticket. For each follower, add an email and press ENTER.",
          noInputText: "Ticket has no followers.",
          active: true,
          tooltipTitle: "Add Followers to the ticket.",
          hideFieldIdLabel: true
        }
      ],
      header: appTypeConfig[appType].header,
      subHeader: "CONTACT INFORMATION",
      description: "The following contact information is associated with your account. Update displayed information as needed. You must provide a phone number for callback purposes. You can also add email addresses for other users to follow this ticket. By following this ticket, you include them in email updates.",
      summaryTitle: "CONTACT INFORMATION",
      nextCondition: {
        logic: "and",
        subConditions: [
          { fieldId: "2.1", compare: "neq", value: null },
          { fieldId: "2.1", compare: "neq", value: "" },
          { fieldId: "2.2", compare: "neq", value: null },
          { fieldId: "2.2", compare: "neq", value: "" },
          { fieldId: "2.3", compare: "neq", value: null },
          { fieldId: "2.3", compare: "neq", value: "" },
          { fieldId: "2.4", compare: "neq", value: null },
          { fieldId: "2.4", compare: "neq", value: "" },
          {
            logic: "or",
            subConditions: [
              { fieldId: "2.5", compare: "eq", value: "", lookupName: "inputText" },
              { fieldId: "2.5", compare: "eq", value: null, lookupName: "inputText" }
            ]
          }
        ]
      },
    }
    ] as Array<IWizardSection>);
}

export const createSupportRequest = function (valueDictionary: IFieldValueLookup) {
  let request = {} as IWizardRequest;
  const getFieldValue = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].value : null;
  };

  const getFieldText = function (id: string) {
    return valueDictionary[id] ? valueDictionary[id].text : null;
  };

  const appType = getFieldValue('appType');

  const lookup = function (id: string, lookup: string) {
    return valueDictionary[id] ? (valueDictionary[id].lookupValues ? valueDictionary[id].lookupValues[lookup] : null) : null;
  };

  const setCategoryAssignmentFields = (request: IWizardRequest) => {
    if (appType == AppType.Relativity) {
      switch (getFieldValue("1.1")) {
        case 1:
          request["Category"] = "Account Admin";
          request["SubCategory"] = "";
          break;
        case 2:
          request["Category"] = "Application";
          request["SubCategory"] = "Performance Issue";
          break;
        default:
          request["Category"] = "Application";
          request["SubCategory"] = "Application Usage Related";
          break;
      }
    } else if (appType == AppType.GoAnywhere) {
      switch (getFieldValue("1.1")) {
        case 1:
          request["Category"] = "Account Admin";
          request["SubCategory"] = "Permissions";
          request["AssignmentGroup"] = "GSS Express";
          break;
        default:
          request["Category"] = "Application";
          request["SubCategory"] = "Application Alerts/Errors";
          request["AssignmentGroup"] = "GSS Transfer";
          break;
      }
    }

    return request;
  }

  const workspaceName = getFieldValue(PublicFields.workSpaceName) ? `Workspace: ${getFieldValue(PublicFields.workSpaceName)}\n\n` : "";
  let desc = (getFieldValue('1.1') == 4 || getFieldValue('1.1') == 5) && getFieldValue('1.1.5') ? getFieldValue('1.1.5') : (getFieldValue('1.1.4') ? getFieldValue('1.1.4') : "");
  const searchName = getFieldValue("1.1.3") ? `Search Name: ${getFieldValue("1.1.3")} \n\n` : "";
  const docId = getFieldValue("1.1.2") ? `Document Id: ${getFieldValue("1.1.2")} \n\n` : "";
  desc = `${docId} ${searchName} ${desc}`;
  const shortDescWebURL = getFieldValue(PublicFields.Url) !== kDefaultSelectionName ? " - " + getFieldValue(PublicFields.Url) : "";

  request["ExternalEmail"] = getFieldValue(PublicFields.Email);
  request["WorkImpact"] = getFieldText("3.2");
  request["ShortDescription"] = getFieldValue("1.1") == 6 && getFieldValue("appType") == AppType.Relativity ? `${getFieldValue("1.1.6")} ${shortDescWebURL}` : `${getFieldText("1.1")} ${shortDescWebURL}`;
  request["AppId"] = getFieldValue(PublicFields.AppId);
  request["ExternalWatchList"] = getFieldValue("2.5");
  request["Description"] = `Website: ${getFieldValue(PublicFields.Url)}\n\n` +
    `${workspaceName}` +
    `Epiq Access User: ${getFieldValue(PublicFields.FirstName)} ${getFieldValue(PublicFields.LastName)} ${getFieldValue(PublicFields.Email)}\r\n` +
    `Federation Status: ${getFieldValue(PublicFields.IsFederated)}\r\n` +
    `Contact: ${getFieldValue("2.1")} ${getFieldValue("2.2")} ${getFieldValue("2.4")}` +
    `\r\n\r\n${desc}`;
  request["CallBackNumber"] = getFieldValue("2.3");
  request["WebsiteUrl"] = getFieldValue(PublicFields.Url);
  request["RequestTypeCode"] = TicketTypes.AccountAdministration;
  request["RequestTypeName"] = TicketTypes.AccountAdministration;

  request["Issue"] = getFieldText("1.1");

  request = setCategoryAssignmentFields(request);

  return request;
};



export const wizardConfirmSubmitMessage: IWizardWarningMessage = {
  title: "Submit Login Support",
  getDescription: (existingFieldVals: IFieldValueLookup) => {
    let confirmMsgs = new Array<string>();
    confirmMsgs.push('Confirm that you reviewed the Support Ticket Summary and want to submit this ticket.');
    return confirmMsgs;
  }
};


export const wizardSummary: IWizardSummary = {
  title: "TECHNICAL SUPPORT SUMMARY",
  description:
    "Carefully review the resulting details for the technical support. To make changes, click the Edit pencil. When you are satisfied with this summary, click Submit."
};


export const warningMessage: IWizardWarningMessage = {
  title: "Cancel the technical support?",
  description:
    "Closing the Technical Support Wizard removes any selections you made. Do you want to cancel this technical support?"
};

enum SupportJobStates {
  Support_TicketReady = 59,
  Support_SubmittingTicket = 60,
  Support_SubmittingTicketFailed = 61,
  Support_FilesUploaded = 62,
  Support_FileUploadFailed = 63,
  Support_TicketSubmittedSuccessfully = 64,
  Support_FilesSubmittedSuccessly = 65,
  Support_SubmittingTicketUpdateFilesFailed = 68,
  Support_TicketUpdateFilesSubmittedSuccessfully = 69,
  Support_FilesPendingTicketSubmittedSuccessfully = 70
}

export const jobMessagingConfig: JobMessagingConfig = {
  genericErrorMessage: "New request data submission failed.",
  genericSuccessMessage: "New request data submitted successfully.",
  specialStatusCases: {
    statusTypes: {
      [SupportJobStates.Support_TicketReady]: { keepChecking: true },
      [SupportJobStates.Support_SubmittingTicket]: { keepChecking: true },
      [SupportJobStates.Support_FilesUploaded]: { keepChecking: true },
      [SupportJobStates.Support_TicketUpdateFilesSubmittedSuccessfully]: {
        degree: "success",
        message: "New request, file upload successful."
      },
      [SupportJobStates.Support_FilesPendingTicketSubmittedSuccessfully]: {
        degree: "info",
        message: "New request submitted, awaiting file upload.",
        keepChecking: true
      },
      [SupportJobStates.Support_FileUploadFailed]: {
        degree: "error",
        message: "New request, file upload failed."
      },
      [SupportJobStates.Support_SubmittingTicketUpdateFilesFailed]: {
        degree: "error",
        message: "New request, file upload failed."
      }
    }
  }
};