import * as React from "react";
import CommonHelper from "../../common/utilities/CommonHelper";
import AdminService from "../../../services/AdminService";
import AdminContextService from "../../../services/AdminContextService";
import NotificationService from "../../../services/NotificationService";
import { SendInvite, User as UserModel, ReconfigureUserStatus, SubmittedUpsertUserModel, AccountStates } from "../../../models/UserModels";
import { Paper, Grid, PropTypes, IconButton, CircularProgress } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import LockIcon from "@material-ui/icons/Lock";
import { Checkbox, CheckboxChangeEvent, RadioButton } from "@progress/kendo-react-inputs";
import { formatDate } from "@telerik/kendo-intl";
import DialogBox from "../../common/DialogBox";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TextInput } from "../../common/TextInput";
import "./UserDetails.scss";
import GridSelector from "../../common/grid/GridSelector";
import { Grid as BasicGrid, GridColumn as Column } from "@progress/kendo-react-grid";
import JobManager from "./JobManager";
import { SessionManager } from "../../../SessionManager";
import UserFactorsPopup from "./UserFactorsPopup";
import { Button } from "@material-ui/core";
import CollapsibleSection from "../common/CollapsibleSection";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import "./User.scss";
import "../common/AdminStyles.scss";
import { IContextInfo } from "../../../models/AdminContext";
import UserService from "../../../services/UserService";
import { ICheckObjectCreationModel } from "../../../models/ResourceModels";
import { IGridParamsAdvancedRefreshable } from "../../common/grid/AdvancedGrid";
import ResourceService from "../../../services/ResourceService";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { SiteBranding } from "../../../models/SiteBrandingModel";

interface IElementToValidate {
  name: string;
  isValid: boolean;
}

export interface IOnUserActionChanges {
  inviteSiteBrandingId?: number;
}

enum Messages {
  ValidRequestNumber = "Please enter valid INC# (example: INC1234567)",
  requiredField = "This field is required."
}

interface IProps {
  history: any;
  onUserEdited: (user: SubmittedUpsertUserModel, isValid?: boolean, silentlyUpdateDirtyState?: boolean) => void;
  user: SubmittedUpsertUserModel;
  createUser?: boolean;
  isSaving: boolean;
  onUserEditCancelled?: (user: SubmittedUpsertUserModel, isValid?: boolean) => void;
  permissions: Set<string>;
  internalUsersDomains: Set<string>;
  onReconfigureUser?: (user: SubmittedUpsertUserModel) => void;
  updateLoader?: (isLoading: boolean) => void;
  isLoggedInAsSuperAdmin: boolean;
  context?: IContextInfo;
  contextPermissions: Array<string>;
  onUserActionsChange: (changes: IOnUserActionChanges) => void;
}
type Props = IProps;

interface IState {
  userInfoInEditMode: boolean;
  visible: boolean;
  isReactivatePopupVisible: boolean;
  status: string;
  user: SubmittedUpsertUserModel;
  userInEdit: SubmittedUpsertUserModel;
  notesLength: number;
  dialogBoxProps: DialogBoxProps;
  reactivateDialogBoxProps: DialogBoxProps;
  unlockBtnLabel: string;
  displayUnlockBtn: boolean;
  elementsToValidate: Array<IElementToValidate>;
  superAdmin: boolean;
  disableGlobalAdmin: boolean;
  openReconfigurePopup: boolean;
  badOKTAUserAccounts: Array<SubmittedUpsertUserModel>;
  reconfigureUserStatus: number | null;
  selectedOktaAccount: SubmittedUpsertUserModel;
  badOktaDialogBoxProps: DialogBoxProps;
  userIdpId: string;
  contextPermissions: Array<string>;
  emailExistsInfo?: ICheckObjectCreationModel;
  reconfigureUserStatusDataLoading: boolean;
  disableOpenDialogButton?: boolean;
  openDiaglogButtonTitle?: string;
  whiteLabelInviteState: "noCheck" | "checking" | "checkComplete" | "checkFailed";
  whiteLabelInviteOptions: Array<SiteBranding>;
  selectedWhiteLabelInviteOption?: SiteBranding;
  whiteLabelInviteShowOptions: boolean;
  defaultWhiteLabelClient: SiteBranding;
}

interface DialogBoxProps {
  title: string;
  content: string;
  okButtonLabelText: string;
  cancelButtonLabelText: string;
  openDiaglogButtonText: string;
  dailogInfo: string;
  color: PropTypes.Color;
}

const confirmDialBoxProps: DialogBoxProps = {
  title: "Deactivate User?",
  content: "Are you sure you want to deactivate this user?",
  okButtonLabelText: "YES",
  cancelButtonLabelText: "NO",
  openDiaglogButtonText: "Deactivate",
  dailogInfo: " ",
  color: "default"
};

const infoDialBoxProps: DialogBoxProps = {
  title: "Deactivate User?",
  content: `Before you can deactivate this account, remove the user from all User Groups
        and remove all applications linked to the user. After saving these changes,
        the Okta account and groups will update. After Okta updates this information, you can deactivate the account.`,
  okButtonLabelText: "OK",
  cancelButtonLabelText: "Cancel",
  openDiaglogButtonText: "Deactivate",
  dailogInfo: " ",
  color: "default"
};

type InputState = {
  value: string;
  errorMessage: string;
  isTouchedOnce: boolean;
  isBlurred: boolean;
};

class User extends React.Component<Props, IState> {

  sessionManager: SessionManager;
  emailCheckTimeout: any = null;
  emailInputRef: any = null;

  hasUpsertPermission = this.props.permissions.has("EpiqAdminCreateUser") ||
    (this.props.permissions.has("EpiqAdminUpdateUser") && this.props.permissions.has("EpiqAdminGetUser"));
  constructor(props: Props) {
    super(props);

    const user = CommonHelper.convertPropsToType(props.user, SubmittedUpsertUserModel);

    user.hasValidEmail = CommonHelper.validateEmailFormat(props.user.email) || CommonHelper.validateEmailFormat(props.user.username);

    user.incidentNumber = user.incidentNumber || "";
    this.state = {
      userInfoInEditMode: props.createUser,
      visible: false,
      isReactivatePopupVisible: false,
      status: "",
      user: user,
      userInEdit: user,
      notesLength: props.user.comments ? props.user.comments.length : 0,
      dialogBoxProps: infoDialBoxProps,
      reactivateDialogBoxProps: {
        title: " Activate User?",
        content: "Are you sure you want to activate this user?",
        okButtonLabelText: "YES",
        cancelButtonLabelText: "NO",
        openDiaglogButtonText: "Reactivate",
        dailogInfo: " ",
        color: "default"
      },
      unlockBtnLabel: "UNLOCK",
      displayUnlockBtn: true,
      superAdmin: user.superAdmin,
      disableGlobalAdmin: this.disableGlobalAdminCheckBox(user.username, true),
      openReconfigurePopup: false,
      badOKTAUserAccounts: [],
      reconfigureUserStatus: this.props.user.reconfigureUserStatus,
      selectedOktaAccount: new SubmittedUpsertUserModel(),
      badOktaDialogBoxProps: {
        title: " Activate User?",
        content: "<h1><b>Are</b> you sure you want to activate this user?</h1>",
        okButtonLabelText: "Re-Configure",
        cancelButtonLabelText: "Cancel",
        openDiaglogButtonText: "Re-Configure",
        dailogInfo: " ",
        color: "default"
      },
      userIdpId: props.user.idpId,
      contextPermissions: this.props.contextPermissions,
      reconfigureUserStatusDataLoading: false,
      openDiaglogButtonTitle: "",
      disableOpenDialogButton: null,
      whiteLabelInviteState: "checking",
      whiteLabelInviteOptions: [],
      whiteLabelInviteShowOptions: false,
      defaultWhiteLabelClient: { id: -1, name: "(select one)" } as SiteBranding,
      elementsToValidate: new Array<IElementToValidate>(
        { name: "firstName", isValid: !!user.firstName },
        { name: "lastName", isValid: !!user.lastName },
        { name: "username", isValid: !!user.username },
        { name: "email", isValid: user.email && CommonHelper.validateEmailFormat(user.email)},
        { name: "incidentNumber", isValid: !this.hasUpsertPermission || user.incidentNumber ? true : false },
        { name: "inviteBrandingId", isValid: !props.createUser || user.sendInvite === false || (user.sendInvite === true && user.inviteSiteBrandingId !== null && user.inviteSiteBrandingId as number > -1) },
        { name: "sendInvite", isValid: !props.createUser ||  user.sendInvite === false || (user.sendInvite === true && user.inviteSiteBrandingId !== null && user.inviteSiteBrandingId as number > -1) }
      )
    };

    this.sessionManager = new SessionManager();
    this.emailInputRef = React.createRef();
  }

  componentDidMount() {

    const { firstName, lastName, username } = this.state.user;

    if (firstName && lastName && username && !this.hasUpsertPermission && this.props.createUser) {
      // if we have auto-fill, we can just trigger the validation routine and allow saving. Incidents should be empty anyways.
      this.onUserInformationChange(null, "incidentNumber", true);
    }

    if (!this.props.isLoggedInAsSuperAdmin && this.props.permissions.has("DeactivateUser")) {
      this.checkIfAdminCanDeactivateUser();
    }

    this.checkWhiteLabelOptions();
  }

  componentDidUpdate(prevProps: Props, prevState: IState, snapshot: any) {
    const prevAdminId = prevProps.context.admin ? prevProps.context.admin.contextResourceGroupId : null;
    const currentAdminId = this.props.context.admin ? this.props.context.admin.contextResourceGroupId : null;

    if (prevAdminId !== currentAdminId) {
      this.checkWhiteLabelOptions();
    }
  }

  async checkIfAdminCanDeactivateUser() {
    const result = await UserService.checkAdminCanDeactivateUser(this.state.user.id, this.state.user.idpId);
    if (result.ok) {
      this.setState({
        disableOpenDialogButton: result.value === "false" ? true : false,
        openDiaglogButtonTitle: result.value === "false" ? "If button is disabled, the account has features managed outside of your administration." : ""
      });
    }
  }

  async unlockUser() {
    const response = await AdminService.unlockUser(CommonHelper.convertPropsToType(this.state.user, UserModel));

    if (response.ok && response.data.status && response.data.status !== AccountStates.LockedOut) {
      this.setState({
        user: { ...this.state.user, status: response.data.status }
      });
      NotificationService.showSuccessToast(
        `Success! ${this.state.user.firstName} ${this.state.user.lastName} has been unlocked and is active again.`
      );
      this.setState({ displayUnlockBtn: false });
    } else {
      NotificationService.showErrorToast(
        `Something went wrong.Failed to unlock ${this.state.user.firstName} ${this.state.user.lastName}.`
      );
    }
  }

  async checkWhiteLabelOptions() {
    if (!this.props.context.admin) {
      this.setState({ whiteLabelInviteState: "noCheck" });
      this.props.onUserActionsChange({ inviteSiteBrandingId: null });
      return;
    }

    const query: IGridParamsAdvancedRefreshable = {
      skip: 0,
      take: 500,
      refreshToggle: false,
      queryGroupsInfo: true,
      batchRequests: [
        {
          expandPermissions: true,
          isDistinct: true,
          joinTables: [{ name: "WhitelabelSettings", joinType: 3 }],
          batchFilters:
            [
              {
                logic: "and",
                filters: [{
                  field: "ResourceGroupId",
                  operator: "eq",
                  value: this.props.context.admin.contextResourceGroupId
                }]
              }
            ]
        }
      ]
    };

    var results = await ResourceService.getResourcesDetailsBatch(query, "whitelabel");

    if (!results.ok) {
      this.setState({ whiteLabelInviteState: "checkFailed", whiteLabelInviteOptions: [], selectedWhiteLabelInviteOption: null });
      this.props.onUserActionsChange({ inviteSiteBrandingId: null });
      return;
    }

    const options = (results.data.results as Array<any>).map(s => {
      return { id: s.objectId, name: s.displayName, setting: [] } as SiteBranding;
    });

    const onlyOneOption = options.length === 1;

    this.setState({
      whiteLabelInviteState: "checkComplete",
      whiteLabelInviteOptions: options,
      selectedWhiteLabelInviteOption: onlyOneOption ? options[0] : null,
      whiteLabelInviteShowOptions: options.length > 1,
      defaultWhiteLabelClient: onlyOneOption ? options[0] : { id: -1, name: "(select one)" } as SiteBranding
    });

    if (onlyOneOption) {
      this.props.onUserActionsChange({ inviteSiteBrandingId: options[0].id });
    } else {
      this.props.onUserActionsChange({ inviteSiteBrandingId: null });
    }
  }

  async toggleReactivateDialogEvent(event: React.MouseEvent<HTMLElement>) {
    if (event.currentTarget.textContent === "YES") {
      this.setState({ isReactivatePopupVisible: false });

      const response = await AdminService.reactivateUser(CommonHelper.convertPropsToType(this.state.user, UserModel));

      if (response.ok) {
        if (!response.data.isUserAlreadyActivated) {
          this.setState({
            user: { ...this.state.user, status: response.data.status }
          });
          NotificationService.showSuccessToast(
            `${this.state.user.firstName} ${this.state.user.lastName} has been activated.`
          );
        } else {
          let dialogBoxProps: DialogBoxProps = {
            title: "User Already Reactivated",
            content: "This user is already activated.",
            okButtonLabelText: "OK",
            cancelButtonLabelText: "",
            openDiaglogButtonText: "Reactivate",
            dailogInfo: "info-dailog",
            color: "primary"
          };

          this.setState({
            reactivateDialogBoxProps: dialogBoxProps,
            status: response.data.status,
            isReactivatePopupVisible: !this.state.isReactivatePopupVisible
          });
        }
      } else {
        NotificationService.showErrorToast(
          `Something went wrong.Failed to Reactivate ${this.state.user.firstName} ${this.state.user.lastName}.`
        );
        this.setState({ isReactivatePopupVisible: !this.state.isReactivatePopupVisible });
      }
    } else {
      this.setState({ isReactivatePopupVisible: !this.state.isReactivatePopupVisible });
    }
  }

  async toggleDialogEvent(event: React.MouseEvent<HTMLElement>) {

    if (!this.state.visible) {
      const result = await AdminService.checkDeactivateUser(this.state.user.id);

      if (!result.ok) {
        NotificationService.showErrorToast(`Error: could not check deactivation status`
        );
        return;
      }

      if (result.value === "true") {

        this.setState({
          dialogBoxProps: confirmDialBoxProps, visible: true,
        });
      } else {
        this.setState({
          dialogBoxProps: infoDialBoxProps, visible: true,
        });
      }
      return;
    }
    if (event.currentTarget.textContent === "OK" && this.state.dialogBoxProps.content === infoDialBoxProps.content) {
      this.setState({
        dialogBoxProps: confirmDialBoxProps, visible: true,
      });
    }
    else if (event.currentTarget.textContent === "YES") {
      let response = await AdminService.deactivateUser(CommonHelper.convertPropsToType(this.state.user, UserModel));

      if (response.ok) {
        if (response.data) {
          this.setState({
            user: { ...this.state.user, status: AccountStates.Suspended },
            visible: !this.state.visible
          });
          NotificationService.showSuccessToast(
            `${this.state.user.firstName} ${this.state.user.lastName} has been deactivated.`
          );
        } else {
          let dialogBoxProps: DialogBoxProps = {
            title: "User Already Deactivated",
            content: "This user is already deactivated.",
            okButtonLabelText: "OK",
            cancelButtonLabelText: "",
            openDiaglogButtonText: "Deactivate",
            dailogInfo: "info-dailog",
            color: "primary"
          };

          this.setState({
            dialogBoxProps: dialogBoxProps, visible: true
          });
        }
      } else {
        NotificationService.showErrorToast(
          `Something went wrong.Failed to Deactivate ${this.state.user.firstName} ${this.state.user.lastName}.`
        );
        this.setState({ visible: false });
      }
    }
    else {
      this.setState({ visible: false });
    }
  }

  dialogCloseEvent(event: DialogCloseEvent) {
    this.setState({ visible: false, isReactivatePopupVisible: false });
  }

  onCancelEditUserInfo() {
    this.setState({ ...this.state, userInfoInEditMode: false }, () => {
      if (this.props.onUserEditCancelled) {
        this.props.onUserEditCancelled(this.state.user, true);
      }
    });
  }

  onSendInviteChange(event: CheckboxChangeEvent) {
    const multipleOrNoWhiteLabelOptions = this.state.whiteLabelInviteOptions.length !== 1;
    const inviteBrandingId = multipleOrNoWhiteLabelOptions ? null : this.state.whiteLabelInviteOptions[0].id;
    const editedUser = { ...this.state.userInEdit, inviteBrandingId, sendInvite: event.value };

    this.setState({ ...this.state, userInEdit: editedUser }, () => {
      const isValid = event.value === false || (this.state.whiteLabelInviteOptions.length === 0) || (this.state.userInEdit.inviteBrandingId && this.state.userInEdit.inviteBrandingId > -1);

      this.onUserInformationChange(inviteBrandingId, "inviteBrandingId", multipleOrNoWhiteLabelOptions ? false : true);
      this.onUserInformationChange(event.value, "sendInvite", isValid);
      this.props.onUserActionsChange({ inviteSiteBrandingId: inviteBrandingId });
    });
  }

  onWhiteLabelClientDropDownChange(event: DropDownListChangeEvent) {
    this.onUserInformationChange(event.value.id, "inviteBrandingId", event.value.id > -1);
    this.props.onUserActionsChange({ inviteSiteBrandingId: event.value.id });
    this.setElementValidationState({ name: "sendInvite", isValid: event.value.id > -1 }, () => { });
  }

  onUserInformationChange(value: string | boolean | number, name: string, isValid: boolean, isNotes?: boolean, silentlyUpdateDirtyState?: boolean) {
    const edited: SubmittedUpsertUserModel = CommonHelper.convertPropsToType(
      { ...(this.state.userInEdit ? this.state.userInEdit : this.state.user) },
      SubmittedUpsertUserModel
    );
    edited[name] = value;
    let superAdmin = this.state.superAdmin,
      disableGlobalAdmin = this.state.disableGlobalAdmin;

    if (name.toLowerCase() === "username") {
      const isDisableGlobalAdmin = this.disableGlobalAdminCheckBox(value as string, isValid);
      superAdmin = isDisableGlobalAdmin ? false : this.state.superAdmin;
      disableGlobalAdmin = isDisableGlobalAdmin;
      edited["superAdmin"] = superAdmin;
    } else if (name.toLowerCase() === "superadmin") {
      superAdmin = value as boolean;
    }

    edited["hasValidEmail"] = CommonHelper.validateEmailFormat(edited["email"]) || CommonHelper.validateEmailFormat(edited["username"]);

    this.setElementValidationState({ name, isValid }, () => {
      if (isNotes) {
        this.setState({ userInEdit: edited, user: edited, notesLength: (value as string).length, superAdmin, disableGlobalAdmin });
      } else {
        this.setState({ userInEdit: edited, user: edited, superAdmin, disableGlobalAdmin });
      }

      if (edited['incidentNumber'] !== '' && this.hasUpsertPermission) {
        this.props.onUserEdited(edited, !this.state.elementsToValidate.find(element => !element.isValid), silentlyUpdateDirtyState);
      }
      else if (edited['incidentNumber'] == '' && this.props.createUser && this.hasUpsertPermission) {
        this.props.onUserEdited(edited, false, silentlyUpdateDirtyState);
      }
      else {
        this.props.onUserEdited(edited, !this.state.elementsToValidate.filter(e => e.name.toLowerCase() !== 'incidentnumber').find(element => !element.isValid), silentlyUpdateDirtyState);
      }

      if (isValid && this.props.createUser && this.props.context && name === "username") { // We could check users for non-context admins, but will have to spend that time handling scenarios in another story
        clearTimeout(this.emailCheckTimeout);
        this.emailCheckTimeout = setTimeout(() => {
          const emailValidation = this.state.elementsToValidate.find(e => e.name.toLowerCase() === "username");

          if (emailValidation && emailValidation.isValid) {
            UserService.canCreateUser(value as string).then(r => {
              if (!r.ok) return;

              this.setState({ ...this.state, emailExistsInfo: r.data });
            });
          }
        }, 500);
      }
    });
  }

  async handleSendInvite() {
    const { user, userInEdit } = this.state;
    const jobManager = new JobManager({
      sendInvites: [{ oktaUserId: user.idpId, UserId: user.id } as SendInvite],
      bulk: false,
      siteId: this.state.userInEdit.inviteSiteBrandingId ? this.state.userInEdit.inviteSiteBrandingId as number : null,
      jobSubmittedSuccessMessage: "Send Invite process has been initiated.  We will notify you once complete."
    });

    await jobManager.submitBulkSendInviteJob();

    const isContext = !this.props.permissions.has("EpiqAdminGetUser");
    const response = isContext ? await AdminContextService.getUser(user.id) : await AdminService.getUserDetails(user.id);

    if (response.ok) {
      let updatedUser = { ...user };
      const refreshedUser = isContext ? response.data.data : response.data as SubmittedUpsertUserModel;

      updatedUser.status = refreshedUser.status;
      this.setState({ ...this.state, user: updatedUser, userInEdit: refreshedUser, contextPermissions: (isContext ? response.data.availablePermissions : []) });
    }
  }

  sendInviteLabelText() {
    const userInEdit = this.state.userInEdit;
    const user = this.props.user;

    return (user && user.invitationsentDate) || (userInEdit && userInEdit.invitationsentDate)
      ? "RESEND INVITE"
      : "SEND INVITE";
  }

  needsClient() {
    return !this.hasUpsertPermission /*advanced users don't need context*/ && !this.props.context.relativityClient && !this.props.context.hasNoRelClients;
  }

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  disableGlobalAdminCheckBox = (email: string, isValid: boolean) => {
    const userDomain = email && email.substring(email.indexOf("@") + 1, email.length);

    if (userDomain && isValid) {
      const epiqDomains = this.props.internalUsersDomains;
      return !this.props.isLoggedInAsSuperAdmin || !(epiqDomains && epiqDomains.has(userDomain.toLowerCase()));
    }

    return true;
  };

  handleReconfigurePopupOpen = async () => {
    if (this.state.reconfigureUserStatus === ReconfigureUserStatus.UserAccountDoesNotHaveCorrectOktaId) {
      this.setState({ openReconfigurePopup: true, reconfigureUserStatusDataLoading: true });

      const response = await AdminService.getBadOktaUserDetails(this.state.user.username, 100);

      if (response.ok) {
        const results = response.data ? response.data : [];

        this.setState({ badOKTAUserAccounts: results });
      } else {
        NotificationService.showErrorToast("Something went wrong. Failed to fetch bad Okta accounts");
      }

      this.setState({ reconfigureUserStatusDataLoading: false });
    } else {
      this.setState({ openReconfigurePopup: true });
    }
  };

  handleReconfigurePopupClose = () => {
    this.setState({ openReconfigurePopup: false, selectedOktaAccount: new SubmittedUpsertUserModel() });
  };

  onBadAccountSelectionChange = (e: any) => {
    const idpId = e && e.target && e.target.element && e.target.element.current ? e.target.element.current.id : "";
    const selectedAccountForReconfigure = this.state.badOKTAUserAccounts.filter(f => f.idpId == idpId)[0];
    console.log(selectedAccountForReconfigure);
    this.setState({ selectedOktaAccount: selectedAccountForReconfigure });
  };

  reconfigureUserInOkta = async () => {
    const user = this.state.user;
    user.newIdpId = this.state.reconfigureUserStatus === ReconfigureUserStatus.UserAccountDoesNotHaveCorrectOktaId ? this.state.selectedOktaAccount.idpId : null;;
    user.reconfigureUserStatus = this.state.reconfigureUserStatus;

    if (this.props.onReconfigureUser) {
      this.props.onReconfigureUser(user);
    }
  };

  onUsernameBlur = async (value: string | boolean | number) => {
    const newUser = this.state.userInEdit ? this.state.userInEdit : this.state.user;
    newUser["email"] = newUser["email"] ? newUser["email"] : value as string;

    const isValidEmail = await CommonHelper.validateEmailFormat(newUser["email"])

    const emailInputState: InputState = this.emailInputRef.current ? this.emailInputRef.current.state : null;
    if (emailInputState && emailInputState.errorMessage ) {
      emailInputState.errorMessage = !this.state.user["email"] ? "This field is required." : (isValidEmail ? "" : "Please enter a valid email.");
      this.emailInputRef.current.updateInputState(emailInputState);
      this.props.onUserEdited(newUser, isValidEmail, false);
    }

    this.onUserInformationChange(newUser["email"], "email", isValidEmail);
  }

  render() {
    const userProfile: any = this.state.user;
    const {

      visible,
      reactivateDialogBoxProps,
      isReactivatePopupVisible,
      unlockBtnLabel,
      displayUnlockBtn,
      userInEdit,
      disableGlobalAdmin,
      contextPermissions
    } = this.state;
    const lastLoginDate = userProfile.lastLoginDate
      ? formatDate(CommonHelper.convertUTCDateToLocalDate(userProfile.lastLoginDate), "g")
      : "";
    const oktaLastLoginDate = userProfile.oktaLastLoginDate
      ? formatDate(CommonHelper.convertUTCDateToLocalDate(userProfile.oktaLastLoginDate), "g")
      : "";
    const userFullName = this.state.user.id > 0 ? userProfile.lastName + ", " + userProfile.firstName : "New User";
    const isSuperAdmin = userProfile.superAdmin ? "Yes" : "No";
    const isFederated = this.props.user.isFederated ? "Yes" : "No";
    const isIncidentNumFormatCorrect = !CommonHelper.validateIncidentNumberFormat(
      userInEdit ? userInEdit.incidentNumber : userProfile.incidentNumber
    );

    let dialogBoxProps = this.state.dialogBoxProps;

    let displayInviteButton: any = "";
    let impersonateButton = <></>;

    const disableEditForUser =
      this.props.user && this.props.user.reconfigureUserStatus !== null;

    if (this.state.whiteLabelInviteState !== "checking" && (
      userProfile.status === AccountStates.Staged ||
      userProfile.status === AccountStates.Provisioned ||
      userProfile.status === AccountStates.Active
    )) {
      displayInviteButton = (
        <span className="dialog-btn">
          {(this.props.permissions.has("EpiqAdminUpdateUser") || this.props.permissions.has("EpiqAdminSendInvite") || contextPermissions.find(x => x == "SendInvite") != null) && (
            <Button variant="contained" className="btn-contined" color="primary" onClick={this.handleSendInvite.bind(this)} disabled={disableEditForUser}>
              {this.sendInviteLabelText()}
            </Button>
          )}
        </span>
      );
    }

    if (this.props.permissions.has("EpiqAdminImpersonateUser") && this.state.user.employerId !== 1) {
      impersonateButton = <span className="dialog-btn">
        <Button variant="contained" className="btn-contined" color="primary" onClick={() => this.sessionManager.impersonateUser(this.state.user.idpId, this.props.history)}>
          VIEW AS
        </Button>
      </span>
    }

    let deactivateButton =
      userProfile.status === AccountStates.Active || userProfile.status === AccountStates.Provisioned
        ? (this.props.permissions.has("EpiqAdminDeactivateUser") || (this.props.permissions.has("DeactivateUser") && this.state.disableOpenDialogButton !== null)) && (
          <DialogBox
            title={dialogBoxProps.title}
            content={dialogBoxProps.content}
            visible={visible}
            toggleDialogEvent={this.toggleDialogEvent.bind(this)}
            diagCloseEvent={this.dialogCloseEvent.bind(this)}
            okButtonLabelText={dialogBoxProps.okButtonLabelText}
            dailogInfo={dialogBoxProps.dailogInfo}
            cancelButtonLabelText={dialogBoxProps.cancelButtonLabelText}
            openDiaglogButtonText={dialogBoxProps.openDiaglogButtonText}
            color={dialogBoxProps.color}
            disable={disableEditForUser || this.state.disableOpenDialogButton}
            btnClassName="k-button btn-secondary"
            openDiaglogButtonTitle={this.state.openDiaglogButtonTitle}
          />
        )
        : "";

    let reactivateButton =
      userProfile.status === AccountStates.Suspended
        ? this.props.permissions.has("EpiqAdminActivateUser") && (
          <DialogBox
            title={reactivateDialogBoxProps.title}
            content={reactivateDialogBoxProps.content}
            visible={isReactivatePopupVisible}
            toggleDialogEvent={this.toggleReactivateDialogEvent.bind(this)}
            diagCloseEvent={this.dialogCloseEvent.bind(this)}
            okButtonLabelText={reactivateDialogBoxProps.okButtonLabelText}
            dailogInfo={reactivateDialogBoxProps.dailogInfo}
            cancelButtonLabelText={reactivateDialogBoxProps.cancelButtonLabelText}
            openDiaglogButtonText={reactivateDialogBoxProps.openDiaglogButtonText}
            color={reactivateDialogBoxProps.color}
            disable={disableEditForUser}
            btnClassName="k-button btn-secondary"
          />
        )
        : "";

    let unlockButton: any = "";
    let unlockIcon: any = "";
    let tooltip: any = "";

    if (userProfile.status === AccountStates.LockedOut) {
      unlockButton = (
        <span className="dialog-btn">
          <Button variant="contained" className="btn-contined" color="secondary" onClick={this.unlockUser.bind(this)} disabled={disableEditForUser}>
            {unlockBtnLabel}
          </Button>
        </span>
      );
      unlockIcon = (
        <>
          <span className="user-locked">
            <LockIcon className="locked-icon" />
          </span>

          <div
            className="tooltip-div"
            onMouseOver={event => tooltip && tooltip.handleMouseOver(event)}
            onMouseOut={event => tooltip && tooltip.handleMouseOut(event)}
          >
            <em title={"User is locked"} className="em-wrapper">
              {userFullName}
            </em>
            <Tooltip
              ref={el => (tooltip = el)}
              openDelay={300}
              position="top"
              anchorElement="target"
              className="tooltip-wrapper"
            />
          </div>
        </>
      );
    }

    const displayReconfigureButton = (
      <span className="dialog-btn">
        {disableEditForUser && this.props.permissions.has("EpiqAdminOnlyReconfigureOktaUser") && (
          <Button variant="contained" className="btn-contined" color="secondary" onClick={this.handleReconfigurePopupOpen.bind(this)}>
            {" "}
            Reconfigure{" "}
          </Button>
        )}
      </span>
    );

    const radioButtonSelectionCell = (props: any) => {
      const data = props.dataItem ? (props.dataItem as SubmittedUpsertUserModel) : new SubmittedUpsertUserModel();
      return (
        <td>
          <RadioButton
            name="selection"
            id={data.idpId}
            checked={this.state.selectedOktaAccount.idpId == data.idpId}
            onChange={this.onBadAccountSelectionChange.bind(this)}
          />
        </td>
      );
    };

    const BadOktaAccountsGrid = (
      <BasicGrid data={this.state.badOKTAUserAccounts ? this.state.badOKTAUserAccounts : []}>
        <Column field="" cell={radioButtonSelectionCell} />
        <Column field="idpId" title="Okta Account Id" width="250px" />
        <Column field="firstName" title="First Name" />
        <Column field="lastName" title="Last Name" />
        <Column field="email" title="Account Email" width="250px" />
        <Column field="status" title="Status" />
      </BasicGrid>
    );

    const reconfigureStatusMessage = this.props.user.reconfigureUserStatus === ReconfigureUserStatus.UserAccountDoesNotHaveCorrectOktaId
      ? "User account does not have the correct Okta Id" : this.props.user.reconfigureUserStatus === ReconfigureUserStatus.UserDoesNotExistInOkta
        ? "User does not exist in Okta" : "";

    let contextMsging = <></>;
    let userExistsMsg = <></>;

    if (this.props.createUser && this.props.context.relativityClient) {
      contextMsging = <span className="create-user-context-info"><InfoIcon />This will also create a user in relativity for client: {this.props.context.relativityClient.name} ({this.props.context.relativityClient.relativityClientId})</span>
    }
    else if (this.props.createUser && this.needsClient()) {
      contextMsging = <span className="create-user-context-error"><ErrorIcon />If you don't select a client then the user will not be created outside of Epiq Access</span>
    }
    else if (this.props.createUser && !this.props.context.hasNone && !this.props.context.hasNoRelClients && !this.props.context.relativityClient) {
      contextMsging = <span className="create-user-context-warning"><WarningIcon />You may want to select a relativity client in order to create the user on that client</span>
    }

    if (this.state.emailExistsInfo && this.state.emailExistsInfo.exists && this.props.context.relativityClient) {
      userExistsMsg = <span className="create-user-context-warning"><WarningIcon />User already exists in Epiq Access, but we can make sure the user is setup in Relativity</span>
    }
    else if (this.state.emailExistsInfo && this.state.emailExistsInfo.restricted && this.props.context.relativityClient) {
      userExistsMsg = <span className="create-user-context-error"><ErrorIcon />There are status issues with this user. We will be unable to create the user. Please contact support about this issue.</span>
    }
    else if (this.state.emailExistsInfo && this.state.emailExistsInfo.exists && this.props.context.relativityClient && this.needsClient()) {
      userExistsMsg = <span className="create-user-context-error"><ErrorIcon />User already exists in Epiq Access and there is no selected relativity client to add this user to</span>
    }

    let userInformation = (
      <div className="user-fields-wrapper">
        <Grid container alignItems="center">
          <Grid item sm={6} xs={12}>
            <div className="user-name">
              {displayUnlockBtn ? unlockIcon : ""}
              {userProfile.status !== AccountStates.LockedOut ? userFullName : ""}
              {this.props.user.reconfigureUserStatus !== null && (
                <div className="error-msg"> {reconfigureStatusMessage} </div>
              )}
            </div>
          </Grid>
          {!this.props.createUser && (
            <Grid item sm={6} xs={12} className="button-wrapper">
              {displayUnlockBtn ? unlockButton : ""}
              {reactivateButton}
              {deactivateButton}
              {impersonateButton}
              {displayInviteButton}
              {displayReconfigureButton}
              <span className={disableEditForUser ? "edit-user-icon-button edit-user" : "edit-user"}>
                {this.state.userInfoInEditMode && (
                  <CancelIcon className="edit-icon" onClick={this.onCancelEditUserInfo.bind(this)} />
                )}
                {!this.state.userInfoInEditMode &&
                  (this.props.permissions.has("EpiqAdminUpdateUser") && disableEditForUser ? (
                    <IconButton disabled={disableEditForUser}>
                      <CreateIcon
                        className="edit-icon"
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            userInEdit: this.state.user,
                            userInfoInEditMode: true,
                            notesLength: this.state.user.comments ? this.state.user.comments.length : 0
                          })
                        }
                      />
                    </IconButton>
                  ) : (
                    <>
                      <div><span className="user-edit-icon fa-solid fa-pen-to-square" onClick={() =>
                        this.setState({
                          ...this.state,
                          userInEdit: this.state.user,
                          userInfoInEditMode: true,
                          notesLength: this.state.user.comments ? this.state.user.comments.length : 0
                        })}></span></div>
                    </>
                  ))}
              </span>
            </Grid>
          )}
        </Grid>
        <div className="pnl-user-details">
          <CollapsibleSection title="Details">
            <div>
              {this.state.userInfoInEditMode && (
                <div className="user-info-edit">
                  <div className="user-reset-mfa">
                    {!this.props.createUser && (<UserFactorsPopup userIdpId={this.state.userIdpId} />)}
                  </div>
                  <div className="field-hint">* Required Fields</div>
                  <div className="grid-container">
                    <div>
                      <TextInput
                        type="text"
                        placeholder="First Name"
                        defaultValue={userProfile.firstName}
                        name="firstName"
                        label="* First Name"
                        validations={[{ name: "required", errorMessage: "This field is required." }]}
                        onChange={this.onUserInformationChange.bind(this)}
                        maxLength={50}
                        disabled={(this.props.isSaving || !this.props.createUser && this.props.user.isFederated) as boolean}
                        legacyMode={true}
                        tabIndex={1}
                      />
                      <TextInput
                        type="text"
                        placeholder="Username"
                        defaultValue={userProfile.username}
                        name="username"
                        label="* Username"
                        validations={[{ name: "Username", errorMessage: "This field is required." }]}
                        onChange={this.onUserInformationChange.bind(this)}
                        onBlur={this.onUsernameBlur.bind(this)}
                        disabled={!this.props.createUser || this.props.isSaving}
                        legacyMode={true}
                        tabIndex={3}
                      />
                      {this.hasUpsertPermission && <TextInput
                        type="text"
                        placeholder="Incident Number"
                        defaultValue={userProfile.incidentNumber}
                        name="incidentNumber"
                        label={this.props.createUser ? "* Incident #" : " Incident #"}
                        validations={[
                          {
                            name: "CustomValidation",
                            errorMessage: `${Messages.ValidRequestNumber}`,
                            predicate: `${isIncidentNumFormatCorrect}`
                          }
                        ]}
                        onChange={this.onUserInformationChange.bind(this)}
                        maxLength={10}
                        disabled={this.props.isSaving}
                        legacyMode={true}
                        tabIndex={4}
                      />}

                    </div>
                    <div>
                      <TextInput
                        type="text"
                        placeholder="Last Name"
                        defaultValue={userProfile.lastName}
                        name="lastName"
                        label="* Last Name"
                        validations={[{ name: "required", errorMessage: "This field is required." }]}
                        onChange={this.onUserInformationChange.bind(this)}
                        maxLength={50}
                        disabled={(this.props.isSaving || !this.props.createUser && this.props.user.isFederated) as boolean}
                        legacyMode={true}
                        tabIndex={2}
                      />
                      <TextInput
                        type="email"
                        placeholder="Email"
                        value={userProfile.email}
                        name="email"
                        label="* Email"
                        validations={[{ name: "required", errorMessage: "This field is required." },{ name: "Email", errorMessage: "Please enter a valid email." }]}
                        onChange={this.onUserInformationChange.bind(this)}
                        disabled={(!this.props.createUser && this.props.user.isFederated || this.props.isSaving) as boolean}
                        legacyMode={true}
                        tabIndex={3}
                        ref={this.emailInputRef}
                      />
                    </div>
                    <div>
                      {this.hasUpsertPermission && <TextInput
                        label="Notes"
                        type="textarea"
                        name="description"
                        defaultValue={userProfile.comments}
                        onChange={(value: string) => {
                          this.onUserInformationChange(value, "comments", false, true);
                        }}
                        notesLength={this.state.notesLength}
                        maxLength={255}
                        disabled={this.props.isSaving}
                        placeholder="Comments"
                        legacyMode={true}
                        tabIndex={7}
                      />}
                    </div>
                  </div>
                  <div className="user-preferences-rel-user">
                    <label>User Preferences </label>
                    <div>
                      {this.hasUpsertPermission && <Checkbox
                        onChange={event => this.onUserInformationChange(event.value, "superAdmin", true)}
                        name="superAdmin"
                        label="Global Admin"
                        checked={this.state.superAdmin}
                        defaultChecked={userProfile.superAdmin}
                        disabled={disableGlobalAdmin ? disableGlobalAdmin : this.props.isSaving}
                        tabIndex={6}
                      />}
                    </div>
                    {this.props.createUser && (
                      <>
                        <><br></br></>
                        <label>Invite Email </label>
                        <div>
                          <Checkbox
                            defaultChecked={false}
                            onChange={this.onSendInviteChange.bind(this)}
                            name="sendInvite"
                            label="Send invitation email upon creating user"
                            disabled={this.props.isSaving}
                            tabIndex={5}
                          />
                          {this.state.userInEdit.sendInvite && this.state.whiteLabelInviteOptions.length > 1 && <div className="user-white-label-invite-state">
                            <div className="white-label-container">
                              <div><span>Select the client name to send the welcome invitation.</span></div>
                              <DropDownList
                                name="whiteLabelClient"
                                style={{ width: "400px" }}
                                data={this.state.whiteLabelInviteOptions}
                                defaultValue={this.state.defaultWhiteLabelClient}
                                onChange={this.onWhiteLabelClientDropDownChange.bind(this)}
                                key="id"
                                textField="name"
                                dataItemKey="id"
                              />
                            </div>

                          </div>}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {!this.state.userInfoInEditMode && (
                <div className="user-info">
                  <Grid container>
                    <Grid item md={4} sm={6} xs={12}>
                      <div className="user-info-row">
                        <strong> Username</strong> {userProfile.username}
                      </div>
                      <div className="user-info-row">
                        <strong> Email</strong> {userProfile.email}
                      </div>
                      <div className="user-info-row">
                        <strong> Status</strong>{" "}
                        {userProfile.status === AccountStates.LockedOut ? AccountStates.Locked : userProfile.status}
                      </div>
                      {this.props.permissions.has("EpiqAdminGetUser") && <div className="user-info-row">
                        <strong> Global Admin</strong> {isSuperAdmin}
                      </div>}
                      <div className="user-info-row">
                        <strong> Federated</strong> <span role="federated">{isFederated}</span>
                      </div>
                      <div className="user-info-row">
                        <strong> Last Login</strong> {lastLoginDate}
                      </div>
                      <div className="user-info-row">
                        <strong> Okta Last Login</strong> {oktaLastLoginDate}
                      </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      {this.props.permissions.has("EpiqAdminGetUser") && <>
                        <div className="user-info-row">
                          <strong> Incident #</strong> {userProfile.incidentNumber}
                        </div>
                        <div className="user-info-row">
                          <strong> Employer Domain</strong> {userProfile.employerDomain}
                        </div>

                        <div className="user-info-row employer-name">
                          <strong> Employer Name</strong> {userProfile.employerName}
                        </div>

                        <div className="user-info-row">
                          <strong> Epiq Client</strong> {userProfile.employerClient !== null ? (userProfile.employerClient ? "Epiq Client" : "Non Epiq Client") : ""}
                        </div>

                        <div className="user-info-row">
                          <strong> Epiq Dept</strong> {userProfile.employerDept}
                        </div>

                        <div className="user-info-row">
                          <strong> Entity Type</strong> {userProfile.entityType}
                        </div>
                      </>
                      }

                    </Grid>
                    <Grid item md={4} sm={12}>
                      {this.props.permissions.has("EpiqAdminGetUser") && <div className="user-info-row">
                        <div>
                          <strong> Notes</strong>
                        </div>
                        <p>{userProfile.comments}</p>
                      </div>}
                    </Grid>
                  </Grid>
                </div>
              )}
              <div className="create-user-context-wrapper">{contextMsging}</div>
              <div className="create-user-context-wrapper">{userExistsMsg}</div>
            </div>
          </CollapsibleSection>
        </div>
        <GridSelector
          isOpen={this.state.openReconfigurePopup}
          acceptBtnText="Reconfigure"
          cancelBtnText="Cancel"
          titleText="Reconfigure User in Okta"
          addAvailable={
            (this.state.reconfigureUserStatus == ReconfigureUserStatus.UserAccountDoesNotHaveCorrectOktaId &&
              this.state.selectedOktaAccount &&
              this.state.selectedOktaAccount.username.length > 0) ||
            this.state.reconfigureUserStatus == ReconfigureUserStatus.UserDoesNotExistInOkta
          }
          onClose={this.handleReconfigurePopupClose.bind(this)}
          addClass={"badOktaAccountDialog"}
          onApply={this.reconfigureUserInOkta}
        >
          {this.state.reconfigureUserStatus == ReconfigureUserStatus.UserAccountDoesNotHaveCorrectOktaId && this.state.reconfigureUserStatusDataLoading ? (
            <div className="no-users">
              <CircularProgress />
            </div>
          ) : this.state.reconfigureUserStatus == ReconfigureUserStatus.UserAccountDoesNotHaveCorrectOktaId && !this.state.reconfigureUserStatusDataLoading ? (
            BadOktaAccountsGrid
          ) : (
            <div>
              <br /> Are you sure, you want to reconfigure user in Okta?
              <br />
              <br />
            </div>
          )}
        </GridSelector>
      </div>
    );

    let userNotAvailable = (
      <div>
        <div className="no-users">
          <p> Oops! User details not available. </p>
        </div>
      </div>
    );

    let userDetails = userProfile.id >= 0 ? userInformation : userNotAvailable;
    return (
      <div className="user-info-wrapper">
        <Paper className="grid-wrapper user-grid-wrapper papergrid-space">{userDetails}</Paper>
      </div>
    );
  }
}

export default User;