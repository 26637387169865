import * as React from "react";
import { Input } from "@progress/kendo-react-inputs";
import ErrorIcon from "@material-ui/icons/Error";
import "./TextInput.scss";
import { InputChangeEvent } from "@progress/kendo-react-inputs/dist/npm/input/interfaces/InputChangeEvent";

enum ValidationRules {
  Url = "Url",
  Required = "required",
  Email = "Email",
  Number = "Number",
  MinLength = "MinLength",
  MaxLength = "MaxLength",
  CustomValidation = "CustomValidation",
  Username = "Username"
}

interface Validation {
  name: string;
  errorMessage: string;
  predicate?: string;
}

export interface ITextInputProps {
  type: string;
  label?: string;
  placeholder?: string;
  validations?: Validation[];
  defaultValue?: string;
  value?: string;
  name: string;
  displayCustomValidationMessage?: boolean;
  onChange?: (value: string, name?: string, isErrorOccured?: boolean) => void;
  onBlur?: (value: string, name?: string, isErrorOccured?: boolean) => void;
  onKeyUp?: (value: string, name?: string, isErrorOccured?: boolean) => void;
  maxLength?: number;
  disabled?: boolean;
  notesLength?: number;
  autoComplete?: string;
  legacyMode?: boolean;
  tabIndex?: number;
  ref?: React.RefObject<Input>;
}

type State = {
  value: string;
  errorMessage: string;
  isTouchedOnce: boolean;
  isBlurred: boolean;
};

type Props = ITextInputProps;

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const usernameRegex = new RegExp(/\S+@\S+/);
const urlRegex = new RegExp(/^(https?):\/\/(-\.)?([^\s\/?\.#]+\.?)+(\/[^\s]*)?$/i);

export class TextInput extends React.Component<Props, State> {
  inputRef : React.RefObject<Input>;
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value
        : this.props.defaultValue ? this.props.defaultValue
          : "",
      errorMessage: "",
      isTouchedOnce: false,
      isBlurred: false
    };
    this.inputRef = React.createRef();
  }

  clearInput() {
    this.setState({ value: "" }, this.runValidations);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { value, errorMessage } = this.state;
    const { value: prevValue, errorMessage: prevError } = prevState;

    if ((prevValue !== value || prevError !== errorMessage) && this.props.onChange) {
      this.props.onChange(value.trim(), this.props.name, !errorMessage);
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.value !== undefined && props.value !== state.value) {
      return {
        value : props.value || ""
      };
    }
    return null;
  }

  basicValidation(validator: Validation) {
    const { value } = this.state;

    switch (validator.name) {
      case ValidationRules.Email:
        return emailRegex.test(value) ? "" : validator.errorMessage;
      case ValidationRules.Url:
        return value.trim().length > 0 ? (urlRegex.test(value) ? "" : validator.errorMessage) : "";
      case ValidationRules.Required:
        return value.trim().length > 0 ? "" : validator.errorMessage;
      case ValidationRules.CustomValidation:
        return value.trim().length > 0 && validator.predicate && validator.predicate == "true" ? validator.errorMessage : "";
      case ValidationRules.Username:
        return usernameRegex.test(value) ? "" : validator.errorMessage;
      default:
        return "";
    }
  }

  runValidations() {
    const { isTouchedOnce, value } = this.state;
    const { validations } = this.props;

    if (!isTouchedOnce) return;
    let validationResult: string;

    if (validations) {
      for (let i = 0; i < validations.length; i++) {
        const validator = validations[i];
        validationResult = this.basicValidation(validator);

        if (validationResult) {
          break;
        }
      }
    }

    this.setState({
      errorMessage: validationResult || ""
    });
  }

  onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState(
      {
        value
      },
      this.runValidations
    );
  };

  onInputChange = (event: InputChangeEvent) => {
    const { value } = event;
    this.setState(
      {
        value
      },
      this.runValidations
    );
    if (this.props.onChange) {
      this.props.onChange(value, this.props.name);
    }
  };

  onKeyUp = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    this.setState(
      {
        value
      },
      this.runValidations
    );

    if (this.props.onKeyUp) {
      this.props.onKeyUp(value.trim(), this.props.name, !this.state.errorMessage);
    }
  };

  onFocus = () => {
    this.setState({
      isTouchedOnce: true,
      isBlurred: false
    });
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
    this.setState({ isBlurred: true }, this.runValidations);
    if (this.props.onBlur) {
      this.props.onBlur(event.currentTarget.value.trim(), this.props.name, !this.state.errorMessage);
    }
  };

  updateInputState = (newState: State) => {
    this.setState(newState);
  };

  render() {
    const { value, errorMessage, isTouchedOnce, isBlurred } = this.state;
    const { type, label, placeholder, validations, displayCustomValidationMessage } = this.props;
    let customErrorMessage: string = errorMessage;
    if (displayCustomValidationMessage && errorMessage === "") {
      for (let i = 0; i < validations.length; i++) {
        const validator = validations[i];
        if (validator.name === ValidationRules.CustomValidation && validator.predicate === "true") {
          customErrorMessage = validator.errorMessage;
          break;
        }
      }
    }

    const shouldDisplayError =
      (isTouchedOnce || isBlurred) &&
      ((value.length === 0 && errorMessage.length > 0) || customErrorMessage.length > 0);

    const containerClass = `text-input form-input ${this.props.legacyMode ? "form-input-legacy" : ""} ${customErrorMessage.length > 0 ? "has-error" : ""}`;

    return (
      <>
        {type !== "textarea" ? (
          <div className={containerClass}>
            {this.props.label && <label>{label}</label>}
            <Input
              type={type || "text"}
              value={value}
              placeholder={placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              defaultValue={this.props.defaultValue}
              name={this.props.name}
              required={shouldDisplayError}
              autoComplete={this.props.autoComplete}
              onChange={this.onInputChange}
              onKeyUp={this.onKeyUp}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled}
              tabIndex={this.props.tabIndex}
              ref={this.inputRef}
            />
            {shouldDisplayError ? (
              <div className="error-info">
                <ErrorIcon className="error-icon" />
                <span className="erro-msg">{customErrorMessage}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className={containerClass}>
            {this.props.label && <label>{this.props.label}</label>}
            <textarea
              value={value}
              name={this.props.name}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue}
              onChange={this.onTextAreaChange}
              maxLength={this.props.maxLength}
              disabled={this.props.disabled}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyUp={this.onKeyUp}
              required={shouldDisplayError}
              tabIndex={this.props.tabIndex}
            />
            {this.props.maxLength && (
              <div className="characters-length">
                {this.props.notesLength}/{this.props.maxLength} characters used
              </div>
            )}
            {shouldDisplayError ? (
              <div className="error-info">
                <ErrorIcon className="error-icon" />
                <span className="erro-msg">{errorMessage}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </>
    );
  }
}
