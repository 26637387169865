import * as React from "react";
import { connect } from "react-redux";
import { TextInput } from "../../../common/TextInput";
import ImageUploadPreview, { IImageChangeEvent } from "../../../common/ImageUploadPreview";
import * as SessionStore from "../../../../store/Session";
import "../SiteBranding.scss";
import {
  SiteBrandingModel
} from "../../../../models/SiteBrandingModel";
import CommonHelper from "../../../common/utilities/CommonHelper";
import CollapsibleSection from "../../common/CollapsibleSection";
import { IApplicationState } from "../../../../store";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Grid } from "@material-ui/core";

const validationMessages = {
  requiredField: "This field is required.",
  isAppNameExists: "Client name already exists.",
};
interface IElementToValidate {
  name: string;
  isValid: boolean;
}
interface IProps {
  siteDetails?: SiteBrandingModel;
  createSite: boolean;
  isSaving: boolean;
  isClientNameExists: boolean;
  updateEditedSiteDetails?: (
    editedSiteDetails: SiteBrandingModel,
    isFormReadyForSave: boolean,
    resetClientNameExistMessage: boolean,
  ) => void;
}


type Props = IProps & SessionStore.ISessionState;

type State = {
  hasValue: boolean;
  editedSiteDetails: SiteBrandingModel;
  elementsToValidate: Array<IElementToValidate>;
};

const lightTheme = {
  background: "linear-gradient(180deg, #EEF1F3 53.98%, rgba(198, 198, 198, 0.00) 100%, rgba(183, 187, 191, 0.00) 100%)",
  loginButtonBackground: "linear-gradient(138deg, #9EA5A8 0%, #858C8F 54.17%, #777B7D 100%)",
  poweredByLogoUrl: "WhiteLabelPoweredByLightTheme.png"
};
const darkTheme = {
  background: "linear-gradient(180deg, #171A1C 0%, #2D3234 70.15%, rgba(40, 44, 47, 0.82) 100%)",
  loginButtonBackground: "linear-gradient(315deg, #171A1C 0%, #1E2123 69.87%, rgba(26, 29, 31, 0.90) 100%)",
  poweredByLogoUrl: "WhiteLabelPoweredByDarkTheme.png"
};

class EditSite extends React.Component<Props, State>  {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasValue: true,
      editedSiteDetails: CommonHelper.cloneObject(props.siteDetails),
      elementsToValidate: new Array<IElementToValidate>(
        { name: "name", isValid: !props.createSite },
        { name: "hostName", isValid: !props.createSite },
        { name: "backgroundColor", isValid: !props.createSite },
        { name: "logoUrl", isValid: !props.createSite }
      ),
    };
  }

  onFormInputChangeEvent = (value: string, name: string, isValid: boolean) => {
    const editedSiteDetails: any = this.state.editedSiteDetails;

    editedSiteDetails[name] = value;

    this.setState({ editedSiteDetails: editedSiteDetails });

    const resetClientNameExistMessage: boolean =
      this.props.isClientNameExists && name === "name" ? false : this.props.isClientNameExists;

    this.setElementValidationState({ name, isValid }, () => {
      this.setState({ editedSiteDetails: editedSiteDetails });

      if (this.props.updateEditedSiteDetails) {
        this.props.updateEditedSiteDetails(
          editedSiteDetails,
          !this.state.elementsToValidate.find(element => !element.isValid),
          resetClientNameExistMessage,
        );
      }
    });
  };

  private setElementValidationState(newValidationState: IElementToValidate, callBack: () => void) {
    const indexToUpdate = this.state.elementsToValidate.findIndex(element => element.name === newValidationState.name);
    const elementsToValidate = [...this.state.elementsToValidate];

    if (indexToUpdate === -1) {
      callBack();
    } else {
      elementsToValidate.splice(indexToUpdate, 1);
      elementsToValidate.splice(indexToUpdate, 0, newValidationState);

      this.setState({ ...this.state, elementsToValidate }, callBack);
    }
  }

  onImageUploadFinished = (propName: string, uploadFinishedEvent: IImageChangeEvent) => {
    if (uploadFinishedEvent.imgUrl) {
      this.onFormInputChangeEvent(uploadFinishedEvent.imgUrl, propName, true)
    }
  }

  handleRadioChange = (e: any) => {
    if (e.value) {
      //because state hasn't been updated the dark theme is being selected if current state equals light theme
      const isDarkTheme = this.state.editedSiteDetails.backgroundColor === lightTheme.background;

      this.onFormInputChangeEvent(e.value, "backgroundColor", true)
      this.onFormInputChangeEvent(isDarkTheme ? darkTheme.poweredByLogoUrl : lightTheme.poweredByLogoUrl, "poweredByLogoUrl", true);
      this.onFormInputChangeEvent(isDarkTheme ? darkTheme.loginButtonBackground : lightTheme.loginButtonBackground, "loginButtonBackgroundColor", true);
    }
  }

  render() {
    const { editedSiteDetails } = this.state;
    const { isSaving, isClientNameExists } = this.props;

    return (
      <div className="Site-info-wrapper">
        <CollapsibleSection title="Login Page Details">
          <>
            <div className="field-hint">Required Fields</div>
            <Grid container>
              <Grid item sm={4} xs={12}>
                <ImageUploadPreview allowMultiple={false} value={editedSiteDetails.logoUrl} name="logoUrl" label="Upload Login Page Logo" labelSubText="Reccomended dimensions are 250 X 50" onChange={this.onImageUploadFinished.bind(this, "logoUrl")} jobType={13} />
              </Grid>
              <Grid item sm={6} xs={12} className="input-wrapper">
                <TextInput
                  type="text"
                  name="name"
                  label="Client Name"
                  defaultValue={editedSiteDetails.name}
                  validations={[
                    { name: "required", errorMessage: "This field is required." },
                    {
                      name: "CustomValidation",
                      errorMessage: validationMessages.isAppNameExists,
                      predicate: isClientNameExists.toString()
                    }
                  ]}
                  displayCustomValidationMessage={isClientNameExists}
                  onChange={this.onFormInputChangeEvent}
                  maxLength={100}
                  disabled={isSaving}
                />
                <TextInput
                  type="text"
                  defaultValue={editedSiteDetails.hostName}
                  name="hostName"
                  label="Login Page Custom URL"
                  maxLength={100}
                  disabled={isSaving}
                  validations={[
                    { name: "required", errorMessage: "This field is required." },
                    { name: "Url", errorMessage: "Please enter a valid URL." }
                  ]}
                  onChange={this.onFormInputChangeEvent}
                />
                <div className="site-background-theme">
                  <span>Select Login Page Background</span>
                  <div className="container">
                    <div className="radio-site">
                      <RadioButton name="backgroundtheme" id="rb1" value={lightTheme.background} checked={editedSiteDetails.backgroundColor === lightTheme.background} onChange={this.handleRadioChange.bind(this)}>
                        <label htmlFor="rb1" className="lbl-theme"><div>Light theme</div> <div className='box light'></div>Use with colorful logos.</label>
                      </RadioButton>
                    </div>
                    <div className="radio-site">
                      <RadioButton name="backgroundtheme" id="rb2" value={darkTheme.background} checked={editedSiteDetails.backgroundColor === darkTheme.background} onChange={this.handleRadioChange.bind(this)}>
                        <label htmlFor="rb2" className="lbl-theme"><div>Dark theme</div> <div className='box dark'></div>Use with white or light logos.</label>
                      </RadioButton>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        </CollapsibleSection>
        <CollapsibleSection title="Email Details">
          <>
            <Grid container>
              <Grid item sm={4} xs={12}>
                <ImageUploadPreview allowMultiple={false} value={editedSiteDetails.emailLogoUrl} name="emailLogoUrl" label="Upload Email Template Logo" labelSubText="Reccomended dimensions are 250 X 50" onChange={this.onImageUploadFinished.bind(this, "emailLogoUrl")} jobType={13} />
              </Grid>
            </Grid>
          </>
        </CollapsibleSection>
      </div>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.sessionState
  }),
  null
)(EditSite);

