import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { GridColumnProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import React, { ComponentType } from "react";
import { IResourceTypeField } from "../../../../models/ResourceModels";
import { IFieldValueLookup } from "../../../../models/Wizard";
import AdminService from "../../../../services/AdminService";
import { ServiceResponseJson } from "../../../../services/ServiceBase";
import { IGridParams } from "../../../common/grid/AdvancedGrid";
import { DateTimePickerField, MultiSelectListField } from "../../../common/wizard/WizardElements";

type filterTypes = 'text' | 'numeric' | 'boolean' | 'date';

const ColumnFromField = function (resourceTypeCode: string, field: IResourceTypeField, allCaps = false, fieldPrefix = "", noSpecialFilter = false, filter: FilterDescriptor = null) : GridColumnProps {

  let filterType = null as filterTypes;
  let filterCell = null as ComponentType<GridFilterCellProps>;
  let format = null as string;

  if (!noSpecialFilter && resourceTypeCode === "ediscoveryproject" && (field.name === "Client Name" && (filter && typeof (filter.value) === "object") && filter.value.length > 0)) {
    filterCell = generateMultiFilter(
      field.name,
      AdminService.getProjectClients,
      (dataItems: any) => {
        return dataItems.map((item: any) => {
          return { title: `${item.clientName} (${item.projectAssociatedCount.toLocaleString()})`, id: item.clientName };
        });
      },
      (fieldValues: IFieldValueLookup, searchText?: string) => {
        return {
          take: 100,
          skip: 0,
          sort: [{ field: "clientName", dir: "asc" }],
          filters: [
            {
              logic: "or",
              filters: [
                { field: "clientName", operator: "contains", value: searchText == null ? "" : searchText }
              ] as Array<FilterDescriptor>
            }
          ] as CompositeFilterDescriptor[]
        } as IGridParams;
      });
  }
  else if (field.type === "DateTime") {
    filterType = "date";
    format = "{0:g}";
    filterCell = generateDatePicker(field.name);
  }
  else if (field.type === "Int" || field.type === "Float" || field.type === "Double") {
    filterType = "text"; // "numeric";
  }
  else if (field.type === "Bool") {
    filterType = "boolean";
  }

  const display = field.displayName || field.name;

  return {
    field: fieldPrefix + field.name,
    title: allCaps ? display.toUpperCase() : display,
    filterable: true,
    sortable: false,
    filterCell: filterCell,
    filter: filterType,
    format: format,
  };
};

const emptyFieldRenderProps = {
  onFocus:function () {},
  onBlur:function () {},
  value:undefined as any,
  validationMessage:"",
  touched:false,
  modified:false, 
  visited:false,
  valid:true,
  children:undefined as any
}

const generateMultiFilter = function(name: string,
      getData: (search: any) => Promise<ServiceResponseJson>,
      dataConverter: (input: any) => any,
      getDataParams?: (fieldValues?: IFieldValueLookup, searchText?: string) => IGridParams) {

  const MultiSelectGridFilter = function(props:GridFilterCellProps) {

    const fieldData = {
        GetData: getData,
        DataConverter: dataConverter,
        GetDataParams: getDataParams,
        filterable:true,
    };

    return <MultiSelectListField 
      onChange={e => {
        props.onChange({ value: e.value, operator: "in", syntheticEvent: e.target })} }
      fieldData={fieldData}
      valInfo={props.value ? { value: props.value, text: props.value.join(";") } : null}
      { ... emptyFieldRenderProps }
      name={name} />
  }
  return MultiSelectGridFilter;
}

const generateDatePicker = function(name: string, getDataParams?: (fieldValues?: IFieldValueLookup) => Date) {

    const DatePickerGridFilter = function(props:GridFilterCellProps) {

      const savedDate = props.value ? new Date(props.value) : null;

      const fieldData = {
          GetDataParams: getDataParams,
          filterable:true,
          valInfo: savedDate
      };

      return <DateTimePickerField 
        onChange={e  => {
          props.onChange({ value: e.value, operator: "eq", syntheticEvent: e.target });
        }}
        fieldData={fieldData}
        valInfo={ { value: props.value } }
        { ... emptyFieldRenderProps }
        name={name} />
    }

  return DatePickerGridFilter;
}




export { ColumnFromField };
