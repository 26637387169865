import React, { useState } from "react";
import epiqLogo from "../../images/Epiq_access_logo_horz.svg";
import BritainFlag from "../../images/uk-flag.png";
import USFlag from "../../images/usa-flag.png";
import IndiaFlag from "../../images/india-flag.png";
import { Button } from "@progress/kendo-react-buttons";
import "./LoginHelp.scss";

interface IProps {
  onLeave: (e: any) => void;
}

type Props = IProps

const LoginHelp = (props: Props) => {

  return (
    <>
      <div className="login-help-container">
        <div className="login-help-description">
          <h1>Need Help?</h1>
          <p>Epiq Access is your entrance to all Epiq hosted applications.</p>
          <p>Refer to your Epiq Access Welcome email for information about logging in. This email was sent from <b>noreply@epiqglobal.com</b>.</p>            
          <p>If you did not receive this email or have additional questions, please contact Epiq Global Support Services.</p>
        </div>
        <div className="login-help-call">
          <div className="login-help-phone-call">
            <i className={`fa fa-solid fa-phone`} /><b>Call:</b>
          </div>
          <div className="login-help-phone-numbers">
            <p><img src={USFlag} alt="United States flag" /><b>+1&nbsp;&nbsp;</b><span className="login-help-phone-number-base"> 800 794 4430</span></p>
            <p><img src={BritainFlag} alt="Britain flag" /><b>+44</b> <span className="login-help-phone-number-base"> 333 015 6133</span></p>
            <p><img src={IndiaFlag} alt="India flag" /><b>+91</b> 40 <span className="login-help-phone-number-base"> 7127 9182</span></p>
          </div>
        </div>
        <div className="login-help-email">
          <p><i className={`fa fa-solid fa-envelope`} /><b>Email:</b> support@epiqglobal.com</p>
          <p>For more info, please visit our <b><a href="https://go.epiqglobal.com/epiq-access-faq">FAQ Page</a></b>.</p>
        </div>
        <div className="login-help-options">
          <Button className="btn-secondary" primary={false} onClick={props.onLeave}>
            Return to Log in
          </Button>
        </div>
      </div>
    </>
  );
};

export default LoginHelp;