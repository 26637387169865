import { SiteBranding, SiteBrandingModel } from "../models/SiteBrandingModel";
import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";
import { IGridParams as AdvancedGridParams } from "../components/common/grid/AdvancedGrid";

const GetSitesMessages: ResponseMessages = {
  FailedMessage: "Failed to get sites.",
  SuccessMessage: "Successfully retrieved sites."
};

const CreateSiteMessages: ResponseMessages = {
  FailedMessage: "Create site failed.",
  SuccessMessage: "Create site succeeded."
};

const UpdateSiteMessages: ResponseMessages = {
  FailedMessage: "Update site failed.",
  SuccessMessage: "Update site succeeded."
};

const DeleteSiteMessages: ResponseMessages = {
  FailedMessage: "Delete site failed.",
  SuccessMessage: "Delete site succeeded."
};

const GetSiteInfo: ResponseMessages = {
  FailedMessage: "failed to get site info.",
  SuccessMessage: "getting site info succeeded."
}
export default class SiteBrandingService extends ServiceBase {

  static async getSites(gridParams: AdvancedGridParams) {
    const filterJSON = JSON.stringify(gridParams.filters) || "";
    const sortJSON = JSON.stringify(gridParams.sort) || "";
    const url = `${SiteBrandingService.getSiteBrandingUri()}?${this.getFilterSortQueryString(
      filterJSON,
      sortJSON
    )}&${this.toQueryString(gridParams, ["filters", "sort", "filter"])}`;

    this.fetchOptions();
    const result: ServiceResponseJson = await fetch(url, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetSitesMessages))
      .catch(async response => await this.handleResponseJson(response, GetSitesMessages));

    return result;
  }

  static async getSiteInfo(siteId: number) {
    const result: ServiceResponseJson = await fetch(`${SiteBrandingService.getSiteBrandingUri()}/siteDetails?siteId=${siteId}`, this.fetchOptions())
      .then(async response => await this.handleResponseJson(response, GetSiteInfo))
      .catch(async response => await this.handleResponseJson(response, GetSiteInfo));
    return result;
  }

  static async createSiteBranding(createSite: SiteBranding) {
    const url = SiteBrandingService.getSiteBrandingUri();

    const result: ServiceResponseJson = await fetch(url, {
      body: JSON.stringify(createSite),
      ...this.fetchPostOptions()
    })
      .then(async response => await this.handleResponseJson(response, CreateSiteMessages))
      .catch(async response => await this.handleResponseJson(response, CreateSiteMessages));

    return result;
  }

  static async updateSiteBranding(updateSite: SiteBranding) {
    const result: ServiceResponseJson = await fetch(SiteBrandingService.getSiteBrandingUri(), {
      body: JSON.stringify(updateSite),
      ...this.fetchPutOptions()
    })
      .then(response => this.handleResponseJson(response, UpdateSiteMessages))
      .catch(response => this.handleResponseJson(response, UpdateSiteMessages));

    return result;
  }

  static async DeleteSite(site: SiteBrandingModel) {
    let url = SiteBrandingService.getSiteBrandingUri() + "/delete";

    const result: ServiceResponse = await fetch(url, {
      body: this.toPostBody(site),
      ...this.fetchPostOptions()
    }).then(response => this.handleResponse(response, DeleteSiteMessages))
      .catch(response => this.handleResponse(response, DeleteSiteMessages));

    return result;
  }

  static getSiteBrandingUri() {
    return `${(<any>window).ApplicationVariables.apiUri}Site`;
  }
}