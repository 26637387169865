import { IGridParams } from "../components/common/grid/StandardGrid";
import { UserGroup } from "./UserModels";
import { IGridParams as AdvanceGridParams } from "../components/common/grid/AdvancedGrid";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";

export interface IPermissionGroupListRequest extends IGridParams {
  searchCol: string; //TODO make this have | options?
  orderBy: string; //TODO make this have | options?
}

export interface IPermissionGroup {
  roleId: number;
  usersGroupId: number;
  resourceId?: number;
  resourcesGroupId?: number;
  usersGroup: UserGroup;
  role?: IRoles;
  //singleResource?: IResource;
  resourcesGroup?: IResourceGroups
}

export interface IRoles {
  id: number;
  roleName: string;
  description: string;
}

export interface IResourceType {
  [x: string]: string | number;
  id: number;
  code: string;
  displayName: string;
}

export interface IResourceTypeDetail {
  code: string;
  displayName: string;
  supplementalFieldGroups: Array<IFieldGroup>;
  fields: Array<IResourceTypeField>;
  id: number;
}

export interface IFieldGroup {
  name: string;
  prefix?: string;
}

export interface IResourceTypeField {
  name: string;
  type: string;
  prefix?: string;
  displayName?: string;
}

export interface IResource {
  [x: string]: string | number | boolean | IResourceType;
  id: number;
  objectId: number;
  name: string;
  resourceType: string;
  resourceTypeNavigation?: IResourceType;
  epiqOnly: boolean;
}

export interface IResourceFields {
  objectId: number;
  displayName: string;
  resourceType: string;
  resourceTypeNavigation?: IResourceType;
  epiqOnly: boolean;
  resourceId: number;
  fields: any;
}

export interface IResourceFieldsSelectable extends IResourceFields {
  selected?: boolean;
}

export interface IResourceBasic {
  objectId: number;
  typeCode: string;
}

export interface IResourceDetail {
  objectId: number;
  displayName: string;
  description: string;
  typeCode: string;
  typeDisplayName: string;
  typeId: string;
}

export interface IReportDetails extends IResourceDetail {
  fields: {
    isDashboard: boolean;
    reportCode: boolean;
  }
}

export interface IResourceParams extends IGridParams {
  typeCode: string;
  showGroupsWithDupePermissions?: boolean;
  flattenResults?: boolean;
  dedupeFieldName?: string;
  permissionsDelimited?: string;
  filters?: Array<CompositeFilterDescriptor>;
  joinFields?: Array<string>;
  filterPresets?: Array<string>;
  additionalQueries?: Array<string>;
  excludedResourceGroupsId?: number;
  batchRequests?: Array<any>; // TODO Don't do 'any' 
}

export interface IRefreshGridParams extends IResourceParams {
  refreshToggle: boolean;
}

export interface IResourceGroups {
  id: number;
  groupName: string;
  resources: IResource[];
}

export interface IResourceGroupResourceRequest extends IGridParams {
  resourceGroupId: number;
  resourceTypeId?: number;
}

export class ResourceGroupDtls {
  id: number = 0;
  groupName: string = "";
  createdDate: Date;
  description: string = "";
  epiqOnly: boolean = false;
  automationFilter?: string;
  automationRequireApprove?: boolean;
  automationResourceType?: number;
  isDeleted: boolean;
}

export interface IResourceGroupAutoModel {
  filter?: CompositeFilterDescriptor;
  usedFields?: Array<string>;
  resourceTypeCode?: string;
  isDirty?: boolean;
  resourceTypeId?: number;
  requireApproval?: boolean;
  displayName?: string;
}

export interface IResourceGroupResource extends IResource {
  PNumberOrResourceId: string;
}

export interface IResourceGroupResourceParams extends AdvanceGridParams {
  resourceGroupId?: number;
  resourceTypeId?: number;
  refreshToggle?: boolean;
  excludeResourceGroupResources?: boolean;
  includeResourceIds?: Array<number>;
  isResourceGroupEpiqOnly?: boolean;
}

export class UpsertResourceDetails {
  groupId: number;
  addResourceIds: Array<number>;
  removeResourceIds: Array<number>;
}

export class UpsertResourceGroup {
  resourceGroupDetails: ResourceGroupDtls;
  upsertResourceGroupDetails: UpsertResourceDetails;
}

export default class ResourceUserGroupRole extends UserGroup {
  public roleName: string = "";
  public userGroupComments: string = "";
  public roleId: number = 0;
}

export interface IAssignmentDetailsParams extends AdvanceGridParams {
  searchValue?: string;
}

export interface IExcelImportType {
  keyField?: string;
  valueField?: Array<string>;
  fileName: string;
}

export interface IUserResourcePermissions {
  email: string;
  userId: Array<string>;
  permissionId: string;
  permissionName: string;
  projectId: string;
}

export enum ResourceRequestFieldsToShow {
  None = 0,
  PermissionCode = 100,
  BasicResource = 200,
  GroupInfoWithRes = 300
}

export interface IResourceContent<T> {
  resource: IResource;
  data: T;
}

export interface ICheckObjectCreationModel {
  exists: boolean;
  restricted: boolean;
}