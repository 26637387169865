import React, { useEffect, useState } from "react";
import "./header.scss";
import {
  EpiqHeaderMenuDropDown,
  IRightHeaderMenuOption
} from "./EpiqHeaderMenuDropDown";
import { Container } from "@material-ui/core";
import { EpiqMenuList, MenuOption } from "./EpiqMenuList";
import ContextSwitcher from "../../administration/clientContext/ContextSwitcher";
import { INotificationMessageModel } from "../../../models/MessageModel";
import GridSelector from "../../common/grid/GridSelector";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import { EpiqHeaderNotificationAlert } from "./EpiqHeaderNotificationAlert";

interface IHeaderProps {
  logo?: string;
  logoUrl?: string;
  logoAlt?: string;
  menuBars?: IHeaderMenuBars[];
  rightDropDownMenuOptions?: IRightHeaderMenuOption[];
  userEmail?: string;
  userName?: string;
  rightMenuOptions?: IRightHeaderMenuOption[];
  isEmulating?: boolean;
  isFederated?: boolean;
  isSessionBasic?: boolean;
  hideRightDropDownMenu?: boolean;
  notifications?: INotificationMessageModel[];
  unReadNotificationCount?: number;
  showAlertPopup?: boolean;
}

interface IHeaderMenuBars {
  menuOptions?: MenuOption[];
  menuTitle: string;
  menuUrl: string;
}

const EpiqHeaderComponent: React.FunctionComponent<IHeaderProps> = props => {
  const [open, setOpen] = useState(false);
  const [unReadCount, setUnReadCount] = useState(0);
  const handlePopupClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    setUnReadCount(props.unReadNotificationCount);
  }, [props.unReadNotificationCount])

  useEffect(() => {
    setOpen(props.showAlertPopup)
  }, [props.showAlertPopup])

  return (
    <>
      <div className="header-bar">
        <Container maxWidth={false}>
          <nav className="header-nav-main">
            <span className="header-nav-main-left header-menu">
              <a href={props.logoUrl}>
                <img
                  className="header-nav-logo"
                  src={props.logo}
                  alt={props.logoAlt}
                />
              </a>
              {props.menuBars
                ? props.menuBars.map(item => (
                  <EpiqMenuList
                    menuOptions={item.menuOptions}
                    menuTitle={item.menuTitle}
                    menuUrl={item.menuUrl}
                    key={item.menuTitle}
                  />
                ))
                : ""}
            </span>

            {props.rightDropDownMenuOptions &&
              props.rightDropDownMenuOptions.length > 0 && (
                <span className="header-nav-main-right header-menu">
                  {props.isEmulating && <span className="impersonate-info">View As: &nbsp;<span className="impersonate-info-name">{props.userName}</span></span>}
                  <ContextSwitcher />
                  <span className="header-notification-menu-alert">
                    <Button onClick={() => {
                      setOpen(true);
                      setUnReadCount(0);
                    }} >
                      <Badge color="primary" badgeContent={unReadCount} className="header-notification-badge">
                        <NotificationsIcon className="header-notification-icon" />
                      </Badge>
                    </Button>
                  </span>
                  <EpiqHeaderMenuDropDown
                    rightMenuDropDownOptions={props.rightDropDownMenuOptions}
                    rightMenuOptions={props.rightMenuOptions}
                    userName={props.userName}
                    userEmail={props.userEmail}
                    isEmulating={props.isEmulating}
                    isFederated={props.isFederated}
                    isSessionBasic={props.isSessionBasic}
                    hideRightDropDownMenu={props.hideRightDropDownMenu}
                  />
                </span>
              )}

          </nav>
        </Container >
      </div >
      <div>
        <div className="header-notifcation-wrapper">
          <GridSelector
            isOpen={open}
            onClose={handlePopupClose}
            addClass="header-notification-alerts modal-as-sidebar"
            itemsHeaderText="Alerts"
            itemsCount={props.notifications && props.notifications.length}
          >
            <EpiqHeaderNotificationAlert notifications={props.notifications} />
          </GridSelector>
        </div>
      </div>
    </>
  );
};

EpiqHeaderComponent.defaultProps = {
  logo: "../img/epiq-logo-inline.png",
  logoUrl: "#",
  logoAlt: "Epiq",
  menuBars: [
    {
      menuTitle: "Overview",
      menuUrl: "/Overview"
    },
    {
      menuTitle: "Applications",
      menuUrl: "/Applications"
    },
    {
      menuOptions: [{ title: "Users", navigationUrl: "/Users" }],
      menuTitle: "Administration",
      menuUrl: "#"
    }
  ],
  rightMenuOptions: [{ title: "Request Help", url: "/requesthelp" }]
};
export { EpiqHeaderComponent };
