import * as React from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../../store";
import * as HeaderStore from "../../../store/Header";
import * as SessionStore from "../../../store/Session";
import * as WarningMessageStore from "../../../store/WarningMessage";
import SiteBrandingService from "../../../services/SiteBrandingService";
import NotificationService from "../../../services/NotificationService";
import {
  SiteBrandingModel, SiteBranding
} from "../../../models/SiteBrandingModel";
import { Paper, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CreateIcon from "@material-ui/icons/Create";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditSite from "./details/EditSite";
import View from "./details/View";
import "./SiteBranding.scss";
import { apiErrorTypes } from "../../../models/Enums";
import CollapsibleSection from "../common/CollapsibleSection";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogBox from "../../common/DialogBox";
import Tooltip from "@material-ui/core/Tooltip";
enum Mode {
  Edit,
  Create,
  View
}

const errorMessages = {
  clientNameExists: "Site already exists.",
};

const urlRegex = new RegExp(/^(https?):\/\/(-\.)?([^\s\/?\.#]+\.?)+(\/[^\s]*)?$/i);

interface IProps {
  match?: any;
  history: any;
}

type Props = IProps &
  HeaderStore.IHeaderState &
  typeof HeaderStore.actionCreators &
  WarningMessageStore.IWarningMessageState &
  typeof WarningMessageStore.actionCreators &
  SessionStore.ISessionState;

type State = {
  mode: Mode;
  siteInfoEditMode: boolean;
  siteCreateMode: boolean;
  siteDetails: SiteBrandingModel;
  isLoading: boolean;
  isSaving: boolean;
  isClientNameExists: boolean;
  isFormReadyForSave: boolean;
  actionItemAnchorEl: HTMLElement;
  showDeleteConfirmPopup: boolean;
};
class SiteBrandingDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const mode = Number(this.props.match.params.id) > 0 ? Mode.Edit : Mode.Create;

    this.state = {
      mode,
      siteInfoEditMode: mode === Mode.Create,
      siteCreateMode: props.match.params.id > 0 ? false : true,
      siteDetails: null,
      isLoading: false,
      isSaving: false,
      isClientNameExists: false,
      isFormReadyForSave: false,
      actionItemAnchorEl: null,
      showDeleteConfirmPopup: false
    };
  }

  getBtnsList(isSaveDisabled: boolean, isSaving: boolean = false): any {
    const buttons: any = [
      {
        buttonLabel: "Cancel",
        type: "button",
        handleClick: this.handleCancelClick.bind(this)
      },
      {
        buttonLabel: "Save",
        type: "button",
        disabled: isSaveDisabled,
        color: "primary",
        handleClick: this.handleSaveClick.bind(this),
        isSaving: isSaving
      }
    ];

    return buttons;
  }

  handleCancelClick = () => {
    this.props.history.push("/administration/siteBranding");
  };

  onSiteDetailsEdit = () => {
    this.setState({
      siteInfoEditMode: true
    });
  };

  onCancelEditSiteInfo = () => {
    this.setState({
      siteInfoEditMode: false
    })
  }

  async componentDidMount() {
    this.props.saveUnsavedChanges(JSON.stringify(this.state.siteDetails), false);
    this.props.setHeaderButtons(this.getBtnsList(true), "", "", " ");

    if (this.state.mode == Mode.Edit) {
      await this.getSiteDetails();
    }
  }

  componentWillUnmount() {
    this.props.setHeaderButtons(null);
    this.props.saveUnsavedChanges(null, false);
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevState !== this.state) {
      const { isSaving } = this.state;
      const isDirtyChangesExist = this.isDirtyChangesExist();
      this.props.updateUnsavedChanges(isDirtyChangesExist);
      this.props.setHeaderButtons(
        this.getBtnsList(isSaving ? isSaving : !(isDirtyChangesExist && this.isFormValidationsPassed()), isSaving),
        "",
        "",
        ""
      );
    }
  }

  isDirtyChangesExist = () => {
    const { siteDetails } = this.state;
    return (
      this.props.initialState !== JSON.stringify(siteDetails)
    );
  };

  isFormValidationsPassed = () => {
    const {
      isClientNameExists,
      isFormReadyForSave,
      siteDetails,
      mode
    } = this.state;

    const isLogoUrlValid = siteDetails.logoUrl ? urlRegex.test(siteDetails.logoUrl) : false;

    return (
      (isFormReadyForSave && !isClientNameExists && isLogoUrlValid) ||
      (mode == Mode.Edit
        ? isFormReadyForSave : false)
    );
  };

  handleSaveClick = async () => {
    this.setState({ isSaving: true });

    const { siteDetails, mode } = this.state;
    let failureMessage = "Something went wrong. Update not saved.";
    let successMessage = "Update saved.";
    let response;
    let isClientNameExists = false;
    let hasErrors = false;

    const saveSiteObject = {
      id: siteDetails.siteId,
      name: siteDetails.name,
      setting: [{
        id: siteDetails.settingId,
        hostName: siteDetails.hostName,
        backgroundColor: siteDetails.backgroundColor,
        logoUrl: siteDetails.logoUrl,
        emailLogoUrl: siteDetails.emailLogoUrl,
        poweredByLogoUrl: siteDetails.poweredByLogoUrl,
        loginButtonBackgroundColor: siteDetails.loginButtonBackgroundColor,
        loginButtonFontColor: siteDetails.loginButtonFontColor
      }]
    } as SiteBranding;

    if (Mode.Edit === mode) {
      response = await SiteBrandingService.updateSiteBranding(saveSiteObject);

    } else {
      response = await SiteBrandingService.createSiteBranding(saveSiteObject);
      successMessage = `${siteDetails.name} Created.`;
      failureMessage = "Something went wrong. Site was not created.";
    }

    if (response.ok) {
      if (!response.data.errorType) {
        hasErrors = false;
        NotificationService.showSuccessToast(successMessage);
        if (Mode.Create === mode) {
          this.props.saveUnsavedChanges(null, false);
          this.props.history.push("/administration/siteBranding");
        } else {
          this.props.saveUnsavedChanges(JSON.stringify(siteDetails), false);
        }
      } else {
        hasErrors = true;

        if (response.data.errorType === apiErrorTypes.AlreadyExists) {
          NotificationService.showErrorToast("Client name already exists.");
        }
        else if (response.data.errorType === apiErrorTypes.DataSavedExternalFailed) {
          NotificationService.showErrorToast("After saving the data, there was an error publishing the CDN info");
        }
        else if (response.data.message) {
          NotificationService.showErrorToast(response.data.message);
        }
        else {
          NotificationService.showErrorToast("Unknown error occured");
        }

        if (!isClientNameExists) {
          this.props.saveUnsavedChanges(JSON.stringify(siteDetails), false);
        }
      }

      this.setState({
        siteInfoEditMode: isClientNameExists,
        isClientNameExists,
        isSaving: false,
        siteDetails: siteDetails,
        isFormReadyForSave: !hasErrors
      });
    };
  }

  getSiteDetails = async () => {
    this.setState({ isLoading: true });
    const result = await SiteBrandingService.getSiteInfo(this.props.match.params.id);
    const siteDetails = result ? (result.data as SiteBrandingModel) : null;

    await this.props.saveUnsavedChanges(JSON.stringify(siteDetails), false);

    this.setState({
      isLoading: false,
      siteDetails: siteDetails,
    });
  };

  updateEditedSiteDetails = (
    editedSiteDetails: SiteBrandingModel,
    isFormReadyForSave: boolean,
    resetClientNameExistMessage: boolean,

  ) => {

    this.setState({
      siteDetails: { ...editedSiteDetails },
      isFormReadyForSave,
      isClientNameExists: resetClientNameExistMessage,
    });
  };

  handleOpenActionItemClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ actionItemAnchorEl: event.currentTarget })
  }

  handleCloseActionItemClick = () => {
    this.setState({ actionItemAnchorEl: null })
  }

  handleOpenConfirmationPopup = () => {
    this.setState({ actionItemAnchorEl: null })
  }

  toggleDialogEvent = async (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.textContent.toLocaleLowerCase() === "confirm") {
      this.setState({ showDeleteConfirmPopup: false });
      var result = await SiteBrandingService.DeleteSite(this.state.siteDetails);
      if (result && result.ok) {
        this.props.history.push("/administration/siteBranding");
        NotificationService.showSuccessToast(`Deleted site branding: ${this.state.siteDetails.name}.`);
      }
      else {
        NotificationService.showErrorToast(`${this.state.siteDetails.name} site branding deletion failed`);
      }
    }
    else if (event.currentTarget.textContent.toLocaleLowerCase() === "cancel") {
      this.setState({ showDeleteConfirmPopup: false });
    }
  }

  handleDeleteConfirm = () => {
    this.setState({ showDeleteConfirmPopup: true, actionItemAnchorEl: null });
  }

  render() {
    const {
      siteDetails,
      mode,
      isLoading,
      siteInfoEditMode,
      isSaving
    } = this.state;
    const clientName = mode === Mode.Edit ? siteDetails && siteDetails.name : "Site Branding";
    const open = Boolean(this.state.actionItemAnchorEl);
    const actionItems = [
      'Delete Site Branding',
    ];
    let siteInformation;

    if (isLoading) {
      return (
        <Paper className="site-details-wrapper">
          <div className="no-sites">
            <CircularProgress />
          </div>
        </Paper>
      );
    } else {
      siteInformation = (
        <>
          <Grid container>
            <Grid item sm={8} xs={12} className="site-header">
              <div className={"site-name"}>
                {clientName}
              </div>
              {mode === Mode.Create && (<div className={"site-description"}>Create a custom login page with a company-specific logo, URL, and design choices.</div>)}
            </Grid>
            {!this.state.siteCreateMode && (
              <>
                <Grid item sm={4} xs={12} className="button-wrapper">
                  {siteInfoEditMode && this.props.sessionData.superAdmin && (
                    <div className="action-context-menu">
                      <Tooltip title={<span style={{ fontSize: "14px", lineHeight: "16.41px" }}>More Actions</span>} style={{ fontSize: "14px" }} placement="top" arrow>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={this.handleOpenActionItemClick}
                          className="dots-icon"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={this.state.actionItemAnchorEl}
                        open={open}
                        onClose={this.handleCloseActionItemClick}
                        className="site-brandig-action-menu"
                      >
                        {actionItems.map((actionItem) => (
                          <MenuItem
                            key={actionItem}
                            onClick={this.handleDeleteConfirm}
                            className="site-brandig-action-items"
                          >
                            {actionItem}
                          </MenuItem>
                        ))}
                      </Menu>

                    </div>)}
                  <span className="edit-site-details">
                    {!siteInfoEditMode &&
                      this.props.sessionData.permissions.has("EpiqAdminUpdateSiteBranding") && (
                        <CreateIcon className="edit-icon" onClick={this.onSiteDetailsEdit} />
                      )}
                    {siteInfoEditMode && (
                      <CancelIcon className="cancel-icon" onClick={this.onCancelEditSiteInfo} />
                    )}
                  </span>
                </Grid>
              </>
            )}
          </Grid>
          {!siteInfoEditMode &&
            siteDetails && (
              <CollapsibleSection title="Details">
                <View siteDetails={siteDetails} />
              </CollapsibleSection>
            )}
          {siteInfoEditMode && (
            <div>
              <EditSite
                createSite={mode === Mode.Create}
                siteDetails={siteDetails}
                isSaving={isSaving}
                updateEditedSiteDetails={this.updateEditedSiteDetails}
                isClientNameExists={this.state.isClientNameExists}
              />
            </div>
          )}

          <div className="warning">
            <DialogBox
              title={"Delete Site Branding"}
              content={"Are you sure you want to delete this site branding?"}
              okButtonLabelText={"Cancel"}
              cancelButtonLabelText={"Confirm"}
              visible={this.state.showDeleteConfirmPopup}
              toggleDialogEvent={this.toggleDialogEvent.bind(this)}>
            </DialogBox>
          </div>
        </>
      );
    }

    return (
      <div className="site-info-wrapper">
        <Paper className="grid-wrapper papergrid-space create-edit-site">
          {siteInformation}
        </Paper>
      </div>
    );
  }
}

export default connect(
  (state: IApplicationState) => ({
    ...state.headerState,
    ...state.warningMessageState,
    ...state.sessionState
  }),
  {
    ...HeaderStore.actionCreators,
    ...WarningMessageStore.actionCreators
  }
)(SiteBrandingDetails as any);
