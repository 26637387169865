import { IApprovalParams, ISetApprovalRequest } from "../models/approvalModels";
import { ServiceBase, ResponseMessages, ServiceResponseJson, ServiceResponse } from "./ServiceBase";


const getApprovalsMessages: ResponseMessages = {
    FailedMessage: "Failed to get approval information.",
    SuccessMessage: "Successfully retrieved approval information."
};

const setApprovalsMessages: ResponseMessages = {
    FailedMessage: "Failed to set your approval.",
    SuccessMessage: "Successfully set approval."
};

export default class ApprovalService extends ServiceBase {

    static async getMyApprovals(params: IApprovalParams, abortSignal?: AbortSignal) {

        const filterJSON = JSON.stringify(params.filters) || "";
        const sortJSON = JSON.stringify(params.sort) || "";
        const url = `${ApprovalService.getBaseUri()}/user?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
    
        const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
          .then(async (response) => await ServiceBase.handleResponseJson(response, getApprovalsMessages))
          .catch(async (response) => await ServiceBase.handleResponseJson(response, getApprovalsMessages));
    
        return result;
    }

    
    static async getApprovals(params: IApprovalParams, abortSignal?: AbortSignal) {

      const filterJSON = JSON.stringify(params.filters) || "";
      const sortJSON = JSON.stringify(params.sort) || "";
      const url = `${ApprovalService.getBaseUri()}/?${this.getFilterSortQueryString(filterJSON, sortJSON)}&${this.toQueryString(params, ["filters", "sort", "filter"])}`;
  
      const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
        .then(async (response) => await ServiceBase.handleResponseJson(response, getApprovalsMessages))
        .catch(async (response) => await ServiceBase.handleResponseJson(response, getApprovalsMessages));
  
      return result;
  }

    static async setApproval(approval: ISetApprovalRequest) {
        const url = `${ApprovalService.getBaseUri()}/setApproval`;
        const result: ServiceResponse = await fetch(url, {
          body: JSON.stringify(approval),
    
          ...this.fetchPutOptions()
        })
          .then(async response => await this.handleResponse(response, setApprovalsMessages))
          .catch(async response => await this.handleResponse(response, setApprovalsMessages));
    
        return result;
    }

    static async approvalDetails(approvalId: number, abortSignal?: AbortSignal) {

      const url = `${ApprovalService.getBaseUri()}/approvalDetails?approvalId=${approvalId}`;
  
      const result: ServiceResponseJson = await fetch(url, ServiceBase.fetchOptions(abortSignal))
        .then(async (response) => await ServiceBase.handleResponseJson(response, getApprovalsMessages))
        .catch(async (response) => await ServiceBase.handleResponseJson(response, getApprovalsMessages));
  
      return result;
  }

  static getBaseUri() {
    return `${(<any>window).ApplicationVariables.apiUri}approval`;
  }
}