import NotificationService from "../../../services/NotificationService";
import JobsService, {
  IInitializeJobRequestData,
  ISubmitJobRequestData
} from "../../../services/JobService";
import { ServiceBase } from "../../../services/ServiceBase";

enum JobStates {
  ImportReadyForProcessing = 5,
  ImportProcessing = 6,
  ImportProcessedError = 7,
  ImportProcessedSuccess = 8,
  ImportProcessedPartialSuccess = 9,
  ImportCancelRequested = 10,
  ImportCancelling = 11,
  ImportCancelled = 12
}

export interface IAppUserDeletionJobSubmitModel {
  applicationId?: number;
  applicationName: string;
  jobSubmittedSuccessMessage?: string;
  jobSubmittedFailureMessage?: string;
}

interface IUserDeletionJobSubmitModel extends ISubmitJobRequestData, IAppUserDeletionJobSubmitModel {
  readyForProcessing: boolean;
}


export default class ApplicationJobManager {
  private jobData: IUserDeletionJobSubmitModel;
  private jobSubmittedSuccessMessage: string;
  private jobSubmittedFailureMessage: string;
  public callback: { (): void };

  constructor(jobData: IAppUserDeletionJobSubmitModel) {
    this.jobData = { ...jobData, ...{ readyForProcessing: false } } as IUserDeletionJobSubmitModel;
    this.jobData.jobType = 12;
    this.jobSubmittedSuccessMessage =
      this.jobData.jobSubmittedSuccessMessage ||
      "Remove all users request has been initiated. We will notify you once complete.";
    this.jobSubmittedFailureMessage =
      this.jobData.jobSubmittedFailureMessage ||
      "Failed to submit job for remove all users process. Please re-initiate the process.";
  }

  async submitBulkUserDeletionJob() {
    await this.initializeJobForProcessing();
  }

  private async fetchJobStatus() {
    if (this.jobData.jobId) {
      const { ok, data } = await JobsService.getJobStatus(this.jobData.jobId);

      if (ok) {
        switch (data.statusId) {
          case JobStates.ImportReadyForProcessing:
          case JobStates.ImportProcessing:
            await ServiceBase.setTimeoutPromise(1000);
            await this.fetchJobStatus();
            break;
          case JobStates.ImportProcessedError:
            NotificationService.showErrorToast("Something went wrong. Remove user acess process failed.", true);
            break;
          case JobStates.ImportProcessedSuccess:
            NotificationService.showSuccessToast(`Remove all users from app : ${this.jobData.applicationName}`, true);
            //this.callback();
            break;
        }
      } else {
        console.log("Failed to get job status. Please re-initiate the process.");
        NotificationService.showErrorToast("Failed to get job status. Please re-initiate the process.");
      }
    }
  }

  private async initializeJobForProcessing() {
    const initializeJobRequest: IInitializeJobRequestData = {
      jobType: this.jobData.jobType
    };

    const response = await JobsService.initializeJob(initializeJobRequest);

    if (response.ok) {
      this.jobData.jobId = response.data.jobId;

      await this.submitJobForProcessing();
    } else {
      console.log("Failed to initialize the job. Please re-initiate the process.");
      NotificationService.showErrorToast(
        "Failed to initialize the job. Please re-initiate the process."
      );
    }
  }

  private async submitJobForProcessing() {
    this.jobData.readyForProcessing = true;

    const submitJobResponse = await JobsService.submitJob(this.jobData);

    if (submitJobResponse.ok) {
      NotificationService.showInfoToast(this.jobSubmittedSuccessMessage, true);

      await this.fetchJobStatus();
    } else {
      NotificationService.showErrorToast(this.jobSubmittedFailureMessage);
    }
  }
}
